<div class="d-flex align-items-center">
  <h1 class="page-title"><i class="fa fa-users small"></i> Destinos </h1>
</div>
<div *ngIf="alert.message">
  <p class="alert" [class.alert-success]="alert.type == 1" [class.alert-danger]="alert.type == 2">{{alert.message}}</p>
</div>
<div class="card mt-4">
  <div class="card-body p-3">
    <h5>Buscar por: </h5>
    <form [formGroup]="searchForm" (submit)="applyFilter()">
      <div class="form-row flex-fill">
        <div class="col">
          <input placeholder="Nombre" type="text" formControlName="name" class="form-control form-control-sm">
        </div>
        <div class="col">
          <label><input placeholder="Mis destinos" type="checkbox" formControlName="isFavorites">Mis destinos</label>
        </div>
        <button type="submit" class="btn btn-success btn-sm col-md-2">Buscar</button>
      </div>
    </form>
  </div>
</div>
<div class="card mt-2">
  <div class="card-body">
    <ngx-datatable
      #table
      class="bootstrap"
      [rowHeight]="'auto'"
      [columns]="columns"
      [columnMode]="'force'"
      [headerHeight]="50"
      [footerHeight]="50"
      [limit]="maxTableRows"
      [rows]="rows">
      <ngx-datatable-column *ngFor="let column of columns; let i = index;" name="{{column.name}}" prop="{{column.prop}}">
      </ngx-datatable-column>
      <ngx-datatable-column name="Mis destinos" prop="isFavorite">
        <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
          <input type="checkbox" class="form-check-input" id="isFavorite" (click)="clickFavorite($event.target['checked'], row['@id'])" [checked]="resellerLocations.includes(row['@id'])">
          <label class="form-check-label" for="isFavorite"></label>
        </ng-template>
      </ngx-datatable-column>
    </ngx-datatable>
  </div>
</div>

import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { HttpEventType } from '@angular/common/http';

import { Subscription } from 'rxjs';

import { CropperComponent as AngularCropperjsComponent } from 'angular-cropperjs';


import { MediaExperiencesService } from '../../experiences/experiences/media-experiences.service';
import { MediaMakerService } from '../../maker/maker-profile/media-maker';
import { MediaResellerService } from '../../reseller/reseller-profile/media-reseller';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-cropper',
  styleUrls: ['./cropper.component.scss'],
  templateUrl: './cropper.component.html',
})
export class CropperComponent {

  @Input() loadedImages: number;
  @Input() aspectratio: number;
  @Input() preview: string;
  @Input() type: string;
  @ViewChild('angularCropper', { static: false }) public angularCropper: AngularCropperjsComponent;
  @Output() uploadedImage = new EventEmitter<object>();
  @Output() isTooManyImages = new EventEmitter<boolean>();
  didUploadImage = {};
  featuredImageTemp1 = '';
  config;

  featuredFileToUpload: File = null;
  fileUploadSub: Subscription;
  translate: TranslateService;
  uploadProgress = 0;
  uploadingProgressing = false;
  uploadComplete = false;
  serverResponse: any;
  filesList: any;
  filesDestination: any;

  loading = false;

  constructor(private fb: UntypedFormBuilder,
    private mediaExperiencesService: MediaExperiencesService,
    private mediaMakersService: MediaMakerService,
    private mediaResellersService: MediaResellerService) {
    this.config = { aspectRatio: this.aspectratio, preview: '', autoCropArea: 1 };
  }



  insertImage(files: any, destination: string) {
    if(this.loadedImages + files.length > 10){
      this.isTooManyImages.emit(true)
      console.log('demasiadas imagenes');
      return
    }
    this.filesList = files;
    this.filesDestination = destination;

    this.config.aspectRatio = this.aspectratio;
    this.config.preview = '.' + this.preview;
    var fileItem = files[0];

    if (fileItem === null || fileItem.type.indexOf('image') < 0) {
      this.featuredFileToUpload = null;
      return;
    }
    this.featuredFileToUpload = fileItem;
    const reader = new FileReader();
    reader.readAsDataURL(fileItem);

    reader.onloadend = (progress) => {
      this[destination] = reader.result;
      if (this.angularCropper) {
        this.angularCropper.cropper.destroy();
      }
      return this[destination];
    };

  }

  uploadFeaturedFile(event: any) {
    this.loading = true;
    event.preventDefault();

    function toFile(b: any, name: string) {
      b.lastModifiedDate = new Date();
      b.name = name;
      return b as File;
    }

    this.angularCropper.cropper.getCroppedCanvas({ maxWidth: 4096, maxHeight: 2160, minWidth: 290, minHeight: 232, fillColor: '#fff' }).toBlob(
      (blobToUpload: Blob) => {
        const fileToUpload = toFile(blobToUpload, this.featuredFileToUpload.name);
        if (this.type === 'contact') {
          this.fileUploadSub = this.mediaMakersService.mediaMakersUpload(
            fileToUpload, '/media_contacts',
            null).subscribe(
              uploadProgress => {
                this.handleProgress(uploadProgress);
              },
              error => {
              });
        }
        else if (this.type === 'makerHeader') {
          this.fileUploadSub = this.mediaMakersService.mediaMakersUpload(
            fileToUpload, '/media_makers_header',
            null).subscribe(
              uploadProgress => {
                this.handleProgress(uploadProgress);
              },
              error => {
              });
        }
        else if (this.type === 'makerStaff') {
          this.fileUploadSub = this.mediaMakersService.mediaMakersUpload(
            fileToUpload, '/media_makers_staff',
            null).subscribe(
              uploadProgress => {
                this.handleProgress(uploadProgress);
              },
              error => {
              });
        }
        else if (this.type === 'maker') {
          this.fileUploadSub = this.mediaMakersService.mediaMakersUpload(
            fileToUpload, '/media_makers',
            null).subscribe(
              uploadProgress => {
                this.handleProgress(uploadProgress);
              },
              error => {
              });
        } else if (this.type === 'reseller') {
          this.fileUploadSub = this.mediaResellersService.mediaResellersUpload(
            fileToUpload, '/media_resellers_logos',
            null).subscribe(
              uploadProgress => {
                this.handleProgress(uploadProgress);
              },
              error => {
              });
        } else {
          this.fileUploadSub = this.mediaExperiencesService.mediaExperiencesUpload(
            fileToUpload,
            null).subscribe(
              uploadProgress => {
                this.handleProgress(uploadProgress);
              },
              error => {
              });
        }
      },
      'image/jpeg', 1);

    let fileListArr = Array.from(this.filesList)
    console.log(fileListArr);
    
    if (fileListArr.length > 1) {
      fileListArr.splice(0, 1) // here u remove the file
      this.insertImage(fileListArr, this.filesDestination);
    } else {
      this.featuredImageTemp1 = '';
    }

  }

  handleProgress(event) {
    if (event.type === HttpEventType.DownloadProgress) {
      this.uploadingProgressing = true;
      this.uploadProgress = Math.round(100 * event.loaded / event.total);
    }

    if (event.type === HttpEventType.UploadProgress) {
      this.uploadingProgressing = true;
      this.uploadProgress = Math.round(100 * event.loaded / event.total);
    }

    if (event.type === HttpEventType.Response) {
      // console.log(event.body);
      this.uploadComplete = true;
      this.uploadingProgressing = false;
      this.serverResponse = event.body;

      this.didUploadImage = { id: event.body['@id'], url: event.body['contentUrl'] };
      this.uploadedImage.emit(this.didUploadImage);
      this.loading = false;
    }
  }
}

<div class="d-flex align-items-center">
  <h1 class="page-title m-0"><i class="fa fa-user menu-icon"></i> {{ 'Contactos' | translate}} </h1>
  <a [href]="translate.getDefaultLang() + '/reseller/admin/nuevo-contacto'" class="btn btn-sm btn-primary ml-3 text-capitalize">{{ 'Añadir nuevo contacto' | translate}}</a>
</div>
<div class="mt-4 mb-n4" *ngIf="alert.message">
  <p class="alert" [class.alert-success]="alert.type == 1" [class.alert-danger]="alert.type == 2">{{alert.message}}</p>
</div>
<!--<div class="card mt-4">-->
<!--  <div class="card-body p-3">-->
<!--    <h5>{{ 'Buscar por:' | translate}} </h5>-->
<!--    <form [formGroup]="searchForm" (submit)="applyFilter()">-->
<!--      <div class="form-row flex-fill">-->
<!--        &lt;!&ndash; <div class="col">-->
<!--          <input placeholder="ID" type="number" formControlName="id" class="form-control form-control-sm">-->
<!--        </div> &ndash;&gt;-->
<!--        <div class="col">-->
<!--          <input [placeholder]="translate.instant('Nombre')" type="text" formControlName="name" class="form-control form-control-sm">-->
<!--        </div>-->
<!--        <div class="col">-->
<!--          <input [placeholder]="translate.instant('Posición')" type="text" formControlName="position" class="form-control form-control-sm">-->
<!--        </div>-->
<!--        <button type="submit" class="btn btn-success btn-sm col-sm-3 mt-sm-0 mt-2">{{ 'Buscar' | translate}}</button>-->
<!--      </div>-->
<!--    </form>-->
<!--  </div>-->
<!--</div>-->
<div class="card mt-2">
  <div class="card-body">
    <ngx-datatable
      #table
      class="bootstrap expandable"
      [rowHeight]="'auto'"
      [columns]="columns"
      [columnMode]="'force'"
      [headerHeight]="50"
      [footerHeight]="50"
      [limit]="maxTableRows"
      [rows]="rows">

      <ngx-datatable-column *ngFor="let column of columns; let i = index;" name="{{translate.instant(column.name)}}" prop="{{column.prop}}" [minWidth]="100">
      </ngx-datatable-column>

      <ngx-datatable-column
        [width]="90"
        [resizeable]="false"
        [sortable]="false"
        [draggable]="false"
        [canAutoResize]="false"
        headerClass="d-none d-sm-block text-right"
        cellClass="text-right"
        [name]="translate.instant('Acciones')"
        prop="actions">
        <ng-template let-column="column" let-sort="sortFn" ngx-datatable-header-template>
          <span class="datatable-header-cell-label d-none d-sm-block">{{ column.name }}</span>
        </ng-template>
        <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
          <a class="btn btn-sm btn-success mr-1" [href]="translate.getDefaultLang() + '/reseller/admin/editar-contacto/'+row['@id'].replace('/contacts/', '')" [tooltip]="translate.instant('Editar')" placement="bottom">
            <i class="fa fa-pencil"></i>
          </a>
          <button class="btn btn-sm btn-outline-primary d-none d-sm-inline-block" (click)="deleteContact(row['@id'])" [tooltip]="translate.instant('Eliminar')" placement="bottom">
            <i class="fa fa-trash"></i>
          </button>
        </ng-template>
      </ngx-datatable-column>
    </ngx-datatable>
  </div>
</div>

<div *ngIf="!maker" class="container py-4">
  <div class="row align-items-center pt-lg-2 text-center">
    <div class="col-lg-4 my-3">
      <div class="text-lg font-weight-bold text-center text-lg-left"><span class="text-warning">{{ 'Las experiencias' |
          translate }}</span><br><span class="text-pink">{{ 'nos unen' | translate }}</span></div>
    </div>
    <div class="col-lg-4 my-3">
      <img src="assets/venntur-isotype-70.png" alt="Venntur" class="mb-1">
      <h6 class="pt-4 font-weight-bold">{{ 'Conecta con nosotros' | translate }}</h6>
      <ul class="mb-0 mb-lg-3 p-0 small">
        <li class="list-inline-item"><a href="https://www.instagram.com/venntur_/" target="_blank"
            rel="noopener noreferrer" class="text-dark">Instagram</a></li>
        <li class="list-inline-item"><a href="https://www.facebook.com/vennturcom/" target="_blank"
            rel="noopener noreferrer" class="text-dark">Facebook</a></li>
        <li class="list-inline-item"><a href="https://www.linkedin.com/company/venntur/" target="_blank"
            rel="noopener noreferrer" class="text-dark">LinkedIn</a></li>
      </ul>
    </div>
    <div class="col-lg-4 my-3">
      <a href="https://pro.venntur.com" target="_blank" class="btn btn-secondary btn-sm">{{ 'Publica tus experiencias' |
        translate }}</a>
    </div>
  </div>
  <!-- <hr>
  <div class="row py-4 justify-content-center">
    <div class="w-100 d-flex flex-column mb-5 mb-md-0">
      <div class="col-12 col-md-6 mx-auto pb-md-4 text-center text-md font-weight-bold">{{ 'Organiza tus vacaciones directamente con el proveedor de tus experiencias favoritas.' | translate }}</div>
      <div class="col-12 col-md-6 mx-auto pb-4 text-center text-md font-weight-bold">{{ 'Elige tu destino' | translate }}</div>
    </div>


    <div class="col-12 pt-3">
      <div class="pl-4 mb-3 text-md font-weight-bold">{{ 'Actividades en' | translate }} {{ 'España' | translate }}</div>
      <ul class="w-100 columns4-2 small">
        <li style="cursor: pointer;padding-right: 1rem;" *ngFor="let location of locations"
          (click)="searchExperiences(location.id)">
          {{location.name}}</li>
        <li>Alava</li>
        <li>Albacete</li>
        <li>Alicante - Costa Blanca</li>
        <li>Alicante - Costa Almería</li>
        <li>Astutias</li>
        <li>A Coruña</li>
        <li>Alava</li>
        <li>Albacete</li>
        <li>Alicante - Costa Blanca</li>
        <li>Alicante - Costa Almería</li>
        <li>Astutias</li>
        <li>A Coruña</li>
        <li>Alava</li>
        <li>Albacete</li>
        <li>Alicante - Costa Blanca</li>
        <li>Alicante - Costa Almería</li>
        <li>Astutias</li>
        <li>A Coruña</li>
        <li>Alava</li>
        <li>Albacete</li>
        <li>Alicante - Costa Blanca</li>
        <li>Alicante - Costa Almería</li>
        <li>Astutias</li>
      </ul>
    </div>
  </div> -->
  <hr>
  <div class="row pt-4 text-center text-xs">
    <p class="w-100 m-0">With support from:</p>
    <a target="_blank" href="https://lanzadera.es/proyecto/venntur/"><img
      src="assets/logo-lanzadera.png" style="scale: 30%;"  alt="Venntur" class="w-100" /></a>
    <ul class="w-100 list-inline">
      <li class="list-inline-item"><a target="_blank" href='https://pro.venntur.com/politica-de-privacidad/'
          class="text-dark">{{ 'Política de privacidad' | translate }}</a></li>
      <li class="list-inline-item"><a href='https://pro.venntur.com/politica-de-cookies/' target="_blank"
          class="text-dark">{{
          'Política de cookies' | translate }}</a></li>
    </ul>
  </div>
</div>
<div class="row">
    <div class="col-lg-4 pb-3">
        <div class="card">
            <div class="d-flex align-items-center p-4">
                <strong class="mr-1">Crear nueva landing</strong>
                <span [tooltip]="translate.instant('Crear nueva página web')" class="vi-info"></span>
            </div>
            <!-- <div class="text-center mt-2">
                <button class="btn btn-primary px-3" style="height: 48px;font-weight: 400"
                    (click)="goMakerNewWebBlank()"><span class="vi-add mr-3"></span>Web desde cero</button>
            </div> -->
            <div class="text-center mt-3 mb-5">
                <button class="btn btn-primary px-3" style="height: 48px;font-weight: 400"
                    (click)="goMakerNewWebIa()"><span class="vi-add mr-3"></span>Landing con IA</button>
            </div>
        </div>
    </div>
    <div class="col-lg-4 pb-3" *ngFor="let web of webs">
        <div class="card">
            <div class="d-flex align-items-center p-4">
                <strong class="mr-1">{{web.domain}}</strong>
                <span [tooltip]="translate.instant('Dominio: '+web.domain)" class="vi-info"></span>
            </div>
            <div class="text-center mt-2">
                <button class="btn btn-primary px-3" style="height: 48px;font-weight: 400"
                    (click)="goMakerWeb(web.id)"><span class="vi-user mr-3"></span>Edición visual</button>
            </div>
            <div class="text-center mt-3 mb-3">
                <button class="btn btn-primary px-3" style="height: 48px;font-weight: 400"
                    [routerLink]="'../cms/'+web.id"><span class="vi-list mr-3"></span>Edición CMS</button>
            </div>
            <div class="d-flex justify-content-around p-2 mt-2 mb-2">
                <div>
                    <strong class="mr-1">{{10}}</strong>
                    <span [tooltip]="translate.instant('Impresiones')" class="vi-eye"></span>
                </div>
                <div>
                    <strong class="mr-1">{{2}}</strong>
                    <span [tooltip]="translate.instant('Ventas')" class="vi-check"></span>
                </div>
                <div>
                    <strong class="mr-1">{{'Activa'}}</strong>
                    <span [tooltip]="translate.instant('Estado de la web')" class="vi-globe"></span>
                </div>
            </div>
        </div>
    </div>
</div>
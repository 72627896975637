import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { Location, LocationCollection } from './location.model';

const API_URL = environment.apiUrl;

@Injectable()
export class LocationService {
  constructor(private http: HttpClient) {}

  public getLocationsFiltered(
    filters: any = {},
    language
  ): Observable<Location[]> {
    let httpParams = new HttpParams();
    if (filters['per_page']) {
      httpParams = httpParams.set('itemsPerPage', String(filters['per_page']));
    } else {
      httpParams = httpParams.set('itemsPerPage', '100');
    }

    if (filters['country']) {
      if (typeof filters['country'] === 'object') {
        httpParams = httpParams.set(
          'country',
          String(filters['country']['code'])
        );
      }
      if (typeof filters['country'] === 'string') {
        httpParams = httpParams.set('country', String(filters['country']));
      }
    } else {
      httpParams = httpParams.set('country', 'es');
    }

    return this.http
      .get(API_URL + '/locations', {
        params: httpParams,
        headers: {
          'Accept-Language': language,
        },
      })
      .pipe(
        map((res: Response) => {
          return res['hydra:member'].map((item) => {
            return new Location(item);
          });
        })
      );
  }

  public getLocationsByResellerId(
    resellerId: number
  ): Observable<LocationCollection> {
    return this.http.get<LocationCollection>(
      API_URL + '/resellers/' + resellerId + '/locations'
    );
  }

  public getActiveLocations(): Observable<LocationCollection> {
    return this.http.get<LocationCollection>(
      API_URL + '/locations?exists[experiences]=true'
    );
  }

  public getMakerActiveLocations(): Observable<LocationCollection> {
    return this.http.get<LocationCollection>(
      API_URL + '/locations?exists[makers]=true'
    );
  }

  public getResellerActiveLocations(): Observable<LocationCollection> {
    return this.http.get<LocationCollection>(
      API_URL + '/locations?exists[resellers]=true'
    );
  }

  public getLocations(filters?: any): Observable<LocationCollection> {
    if (filters === undefined) {
      filters = {};
    }

    let httpParams = new HttpParams();

    if (filters['name']) {
      httpParams = httpParams.set('name', String(filters.name));
    }

    if (filters['isFavorites']) {
      httpParams = httpParams.append(
        'resellers.id[]',
        String(filters.resellerId)
      );
    }

    return this.http.get<LocationCollection>(API_URL + '/locations', {
      params: httpParams,
    });
  }

  public getLocation(id: number): Observable<Location> {
    return this.http.get<Location>(API_URL + '/locations/' + id.toString());
  }

  public postLocation(location): Observable<Location> {
    return this.http.post<Location>(API_URL + '/locations', location);
  }

  public deleteLocation(locationId: string): any {
    return this.http.delete<Location>(API_URL + '/locations/' + locationId);
  }

  public putLocation(
    locationId: number,
    location: Location
  ): Observable<Location> {
    return this.http.put<Location>(
      API_URL + '/locations/' + locationId,
      location
    );
  }


  public getZonesFiltered(locationIri: string, filters: any = {}): Observable<Zone[]> {

    let httpParams = new HttpParams();

    return this.http
      .get(API_URL + '/zones?location=' + locationIri, {
        params: httpParams,
      })
      .pipe(
        map((res: Response) => {
          return res['hydra:member'].map((item) => {
            return new Location(item);
          });
        }
      ));

  }

}

import { Component, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-modal-alert',
  templateUrl: './modal-alert.component.html',
  styleUrls: ['./modal-alert.component.scss']
})
export class ModalAlertComponent {
  @Input()

  public alertMessage: string;
  public className: string;
  public iconClass: boolean;
  public status: boolean;

  constructor(public dialogRef: BsModalRef) { }

  confirm() {
    if (this.dialogRef.content.callback != null) {
      this.dialogRef.content.callback(true);
      this.dialogRef.hide();
    }
  }

  decline() {
    if (this.dialogRef.content.callback != null) {
      this.dialogRef.content.callback(false);
      this.dialogRef.hide();
    }
  }

}

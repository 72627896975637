import { Component, ElementRef, Input, Output, ViewChild, EventEmitter } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ExperiencesService } from 'src/app/pages/experiences/experiences/experiences.service';
import { ReviewService } from './review.service';

@Component({
  selector: 'app-review-form',
  templateUrl: './review-form.component.html',
  styleUrls: ['./review-form.component.scss']
})
export class ReviewFormComponent {
  @Input() receivedData: any;
  @Output() formSent = new EventEmitter<void>();

  @ViewChild('starsRef', { static: true }) starsElement: ElementRef;

  reviewForm: UntypedFormGroup;
  rating: number = 0;

  constructor(
    private experienceService: ExperiencesService,
    private formBuilder: UntypedFormBuilder,
    private reloadService: ReviewService
  ) { }

  ngOnInit() {
    this.createForm()
  }

  reloadSibling2() {
    this.reloadService.triggerReload();
  }

  setRating(index) {

    // Empties all the stars so it updates the view
    for (let i = 0; i <= 4; i++) {
      document.getElementsByClassName('star-' + i)[0].classList.remove('full');
    }

    // Fill the Html star elements
    for (let i = 0; i <= index; i++) {
      document.getElementsByClassName('star-' + i)[0].classList.add('full');
    }

    this.rating = index + 1;

  }

  createForm() {
    this.reviewForm = this.formBuilder.group({
      name: [null,
        Validators.required
      ],
      location: [''],
      comments: ['']
    });
  }

  createReview() {
    const review = {
      name: this.reviewForm.get('name').value,
      product: this.receivedData,
      reviewerLocation: this.reviewForm.get('location').value,
      reviewerCountry: '',
      rating: this.rating,
      createdAt: new Date(),
      comments: this.reviewForm.get('comments').value,
      language: "es",
      channel: "Venntur"
    }

    console.log(review);


    this.experienceService.postReview(review).subscribe(
      (result) => {
        console.log(result);
      },
      (error) => {
        console.log(error);
      }
    );

    this.formSent.emit(null);
    this.reloadSibling2()
  }

}

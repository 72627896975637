import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

import { TranslateService } from '@ngx-translate/core';
import { MakerService } from '../../../shared/maker.service';
import { ConfirmationDialogComponent } from '../../../shared/confirmation-dialog/confirmation-dialog.component';
import { maxTableRows } from 'globals';

@Component({
  selector: 'app-maker-contacts-list',
  styleUrls: ['./maker-contacts-list.component.scss'],
  templateUrl: './maker-contacts-list.component.html',

})
export class MakerContactsListComponent implements OnInit {
  maxTableRows = maxTableRows;
  columns = [];
  rows = [];
  rowsGroups = []
  columnsGroups = [];
  searchForm: UntypedFormGroup;
  alert = { message: null, type: null };
  @ViewChild(DatatableComponent) table: DatatableComponent;

  dialogRef: BsModalRef;

  constructor(
    private makerService: MakerService,
    private searchFormBuilder: UntypedFormBuilder,
    public dialog: BsModalService,
    public translate: TranslateService
  ) {
    this.makerService.getContactsByMakerId(makerService.getId()).subscribe((contacts => {
      this.rows = contacts['hydra:member'];

      contacts['hydra:member'].forEach(contact => {
        contact.contactGroups = contact.contactGroups.map(group => group.name).join(', ');
      });

    }));
    this.makerService.getContactsGroupsByMakerId(makerService.getId()).subscribe((res: any) => {
      res['hydra:member'].forEach(group => {
        this.rowsGroups.push({ id: group['@id'], name: group.name });
      });
      this.table.offset = 0;
    });
  }

  ngOnInit(): void {
    this.searchForm = this.searchFormBuilder.group({
      name: [''],
      group: ['']
    });

    this.columns = [
      { prop: 'name', name: 'Nombre' },
      { prop: 'contactGroups', name: 'Grupo' },
      // { prop: 'position', name: 'Cargo' },
      { prop: 'email', name: 'Email' },
      { prop: 'phone', name: 'Teléfono' }];
    this.columnsGroups = [
      { prop: 'name', name: 'Nombre' },];
  }

  applyFilter() {
    this.makerService.getContactsByMakerId(this.makerService.getId(), this.searchForm.value).subscribe(
      data => {
        this.rows = data['hydra:member'];
        this.table.offset = 0;
        console.log(data['hydra:member']);
        data['hydra:member'].forEach(data => {
          data.contactGroups = data.contactGroups.map(group => group.name).join(', ');
        });
      }
    );
  }

  deleteContact(contactId) {
    this.dialogRef = this.dialog.show(ConfirmationDialogComponent, {
      initialState: {
        confirmMessage: '¿Seguro que quieres eliminar este contacto?',
        callback: (confirm) => {
          if (confirm) {
            this.makerService.deleteContact(contactId).subscribe(
              (result) => {
                this.alert.type = 1;
                this.alert.message = 'Contacto eliminado correctamente';
                setTimeout(() => {
                  this.alert = { message: null, type: null };
                }, 5000);
              },
              error => {
                this.alert.message = error.error.code + '- ' + error.error.message;
                this.alert.type = 2;
                setTimeout(() => {
                  this.alert = { message: null, type: null };
                }, 5000);
              },
            );
            setTimeout(() => {
              this.makerService.getContactsByMakerId(this.makerService.getId()).subscribe((contacts => {
                this.rows = contacts['hydra:member'];
                this.table.offset = 0;
              }));
            }, 1000);
          }
          this.dialogRef = null;
        }
      }
    });
  }
  deleteGroup(contact_groupsId) {
    this.dialogRef = this.dialog.show(ConfirmationDialogComponent, {
      initialState: {
        confirmMessage: '¿Seguro que quieres eliminar este grupo?',
        callback: (confirm) => {
          if (confirm) {
            this.makerService.deleteGroup(contact_groupsId).subscribe(
              (result) => {
                this.alert.type = 1;
                this.alert.message = 'Grupo eliminado correctamente';
                setTimeout(() => {
                  this.alert = { message: null, type: null };
                }, 5000);
              },
              error => {
                this.alert.message = error.error.code + '- ' + error.error.message;
                this.alert.type = 2;
                setTimeout(() => {
                  this.alert = { message: null, type: null };
                }, 5000);
              },
            );
            /*  setTimeout(() => {
               this.makerService.getContactsByMakerId(this.makerService.getId()).subscribe((contacts => {
                 this.rows = contacts['hydra:member'];
                 this.table.offset = 0;
               }));
             }, 1000); */
          }
          this.dialogRef = null;
        }
      }
    });
  }

  showTab = 'contacts';

}

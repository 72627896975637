import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { Experience, ExperienceCollection } from './experience.model';
import { Review, ReviewCollection } from 'src/app/shared/review-form/review.model';
import { toArray } from 'rxjs/operators';
import { filter } from 'jszip';
import { map } from 'rxjs/operators';

const API_URL = environment.apiUrl;

@Injectable()
export class ExperiencesService {
  constructor(
    private http: HttpClient
  ) { }

  public postExperience(experience): Observable<Experience> {
    return this.http
      .post<Experience>(API_URL + '/experiences', experience);
  }

  public getExperience(id: string, language = 'es'): Observable<Experience> {
    return this.http
      .get<Experience>(API_URL + '/experiences/' + id, {
        headers: {
          'Accept-Language': language
        }
      });
  }

  public getExperienceBySlug(slug: string, language = 'es'): Observable<Experience> {
    return this.http
      .get<Experience>(API_URL + '/experiences?slug=' + slug, {
        headers: {
          'Accept-Language': language
        }
      });
  }

  public getExperiencesById(ids: [string], language): Observable<Experience> {
    let idsString = ids.map(id => `id[]=${id}`).join('&');
    return this.http
      .get<Experience>(API_URL + '/experiences?' + idsString, {
        headers: {
          'Accept-Language': language
        }
      });
  }

  public getLastExperiences(language): Observable<Experience> {
    return this.http.get<Experience>(API_URL + '/experiences', {
      headers: {
        'Accept-Language': language
      }
    });
  }

  public getExperiencesByMakerId(makerId: number, filters?: any, language = 'es'): Observable<ExperienceCollection> {
    if (filters === undefined) { filters = {}; }

    let httpParams = new HttpParams();

    if (filters['name']) {
      httpParams = httpParams.set('name', String(filters.name));
    }
    if (filters['location']) {
      httpParams = httpParams.set('location.name', String(filters.location));
    }
    if (filters['id']) {
      httpParams = httpParams.set('id', String(filters.id));
    }
    if (filters['localidad']) {
      httpParams = httpParams.set('location.id', String(filters.localidad));
    }
    if (filters['categorias']) {
      filters.categorias.split(',').forEach(category =>
        httpParams = httpParams.append('categories.id[]', category)
      );
    }
    return this.http
      .get<ExperienceCollection>(API_URL + '/makers/' + makerId + '/experiences?isActive=true',
        {
          params: httpParams, headers: {
            'Accept-Language': language
          }
        });
  }

  public getAllExperiencesByMakerId(makerId: number, filters?: any, language = 'es'): Observable<ExperienceCollection> {
    if (filters === undefined) { filters = {}; }

    let httpParams = new HttpParams();

    if (filters['name']) {
      httpParams = httpParams.set('name', String(filters.name));
    }
    if (filters['location']) {
      httpParams = httpParams.set('location.name', String(filters.location));
    }
    if (filters['id']) {
      httpParams = httpParams.set('id', String(filters.id));
    }
    if (filters['localidad']) {
      httpParams = httpParams.set('location.id', String(filters.localidad));
    }
    if (filters['categorias']) {
      filters.categorias.split(',').forEach(category =>
        httpParams = httpParams.append('categories.id[]', category)
      );
    }
    return this.http
      .get<ExperienceCollection>(API_URL + '/makers/' + makerId + '/experiences',
        {
          params: httpParams, headers: {
            'Accept-Language': language
          }
        });
  }

  public getExperiencesByTakerId(takerId: number): Observable<ExperienceCollection> {
    return this.http
      .get<ExperienceCollection>(API_URL + '/takers/' + takerId + '/favorite_experiences');
  }

  public getExperiencesByResellerId(resellerId: number, filters: any): Observable<ExperienceCollection> {
    if (filters === undefined) { filters = {}; }

    let httpParams = new HttpParams();

    if (filters['name']) {
      httpParams = httpParams.set('experience.name', String(filters.name));
    }
    if (filters['location']) {
      httpParams = httpParams.set('experience.location.name', String(filters.location));
    }
    if (filters['id']) {
      httpParams = httpParams.set('experience.id', String(filters.id));
    }
    if (filters['maker']) {
      httpParams = httpParams.append('experience.maker.name', String(filters.maker));
    }
    if (filters['tags']) {
      filters.tags.forEach(tag =>
        httpParams = httpParams.append('experience.tags.id[]', tag)
      );
    }
    if (filters['locations']) {
      filters.locations.forEach(location =>
        httpParams = httpParams.append('experience.location.id[]', location)
      );
    }

    return this.http
      .get<ExperienceCollection>(API_URL + '/resellers/' + resellerId + '/favorite_experiences', { params: httpParams });
  }

  public getExperienceByCivitatisId(id: string, language = 'es'): Observable<Experience> {
    return this.http
      .get<Experience>(API_URL + '/experiences?civitatisId=' + id, {
        headers: {
          'Accept-Language': language
        }
      });
  }

  public getExperiencesByUrl(filters: any, language): Observable<ExperienceCollection> {
    let httpParams = new HttpParams();

    filters.forEach((value, key) => {
      if (key == 'name') {
        httpParams = httpParams.set('name', String(value));
      }
      if (key == 'location') {
        httpParams = httpParams.set('location.name', String(value));
      }
      if (key == 'id') {
        httpParams = httpParams.set('id', String(value));
      }
      if (key == 'localidad') {
        httpParams = httpParams.set('location.id', String(value));
      }
      if (key == 'zonas') {
        httpParams = httpParams.append('zones.id', String(value));
      }
      if (key == 'categorias') {
        value.split(',').forEach(category =>
          httpParams = httpParams.append('categories.id[]', category)
        );
      }
      if (key == 'etiquetas') {
        value.split(',').forEach(tag =>
          httpParams = httpParams.append('tags.id[]', tag)
        );
      }

      if (key == 'maker') {
        httpParams = httpParams.append('maker.name', String(value));
      }
      if (key == 'tags') {
        value.forEach(tag =>
          httpParams = httpParams.append('tags.id[]', tag)
        );
      }
      if (key == 'locations') {
        value.forEach(location =>
          httpParams = httpParams.append('location.id[]', location)
        );
      }
      if (key == 'monthsString') {
        httpParams = httpParams.append('monthsString', String(value))
      }
      if (key == 'isFavorites') {
        httpParams = httpParams.append('resellerFavoriteExperiences.reseller.id[]', String(filters.resellerId));
      }
      if (key == 'page') {
        httpParams = httpParams.append('page', String(value));
      }
    });


    // if (language === 'en') {
    //   httpParams = httpParams.append('isEnglishChecked', 'true');
    // }

    return this.http
      .get<ExperienceCollection>(API_URL + '/experiences?isActive=true&order[maker.makerPlan.plan.level]&order[civitatisId]&exists[name]=true', {
        params: httpParams, headers: {
          'Accept-Language': language
        }
      });

  }

  public getExperiences(filters: any, language): Observable<ExperienceCollection> {
    if (filters === undefined) { filters = {}; }

    let httpParams = new HttpParams();

    if (filters['name']) {
      httpParams = httpParams.set('name', String(filters.name));
    }
    if (filters['location']) {
      httpParams = httpParams.set('location.name', String(filters.location));
    }
    if (filters['id']) {
      httpParams = httpParams.set('id', String(filters.id));
    }
    if (filters['localidad']) {
      httpParams = httpParams.set('location.id', String(filters.localidad));
    }
    if (filters['zonas']) {
      httpParams = httpParams.set('zones.id', String(filters.zona));
    }
    if (filters['categorias']) {
      filters.categorias.split(',').forEach(category =>
        httpParams = httpParams.append('categories.id[]', category)
      );
    }
    if (filters['etiquetas']) {
      filters.etiquetas.split(',').forEach(tag =>
        httpParams = httpParams.append('tags.id[]', tag)
      );
    }

    if (filters['maker']) {
      httpParams = httpParams.append('maker.name', String(filters.maker));
    }
    if (filters['tags']) {
      filters.tags.forEach(tag =>
        httpParams = httpParams.append('tags.id[]', tag)
      );
    }
    if (filters['locations']) {
      filters.locations.forEach(location =>
        httpParams = httpParams.append('location.id[]', location)
      );
    }
    if (filters['isFavorites']) {
      httpParams = httpParams.append('resellerFavoriteExperiences.reseller.id[]', String(filters.resellerId));
    }
    if (filters['page']) {
      httpParams = httpParams.append('page', String(filters.page));
    }

    // if (language === 'en') {
    //   httpParams = httpParams.append('isEnglishChecked', 'true');
    // }

    return this.http
      .get<ExperienceCollection>(API_URL + '/experiences?isActive=true&order[maker.makerPlan.plan.level]&order[civitatisId]&exists[name]=true', {
        params: httpParams, headers: {
          'Accept-Language': language
        }
      });

    // return this.http
    //   .get<ExperienceCollection>(API_URL + '/experiences?isActive=true&order[sheetCompleted]&order[maker.sheetCompleted]&order[civitatisId]', {
    //     params: httpParams, headers: {
    //       'Accept-Language': language
    //     }
    //   });

  }


  public deleteExperience(experienceId: string): any {
    return this.http.delete<Experience>(API_URL + experienceId);
  }

  public putExperience(experienceId: string, experience: Experience, language = 'es'): Observable<Experience> {
    return this.http
      .put<Experience>(API_URL + '/experiences/' + experienceId, experience, {
        headers: {
          'Accept-Language': language
        }
      });
  }

  public translateExperience(body: any): Observable<any> {
    return this.http
      .post<any>(API_URL + '/ia/translate-request', body);
  }

  public importFrom(body: any): Observable<any> {
    return this.http
      .post<any>(API_URL + '/ia/import-from', body);
  }

  public postModality(modality: any): Observable<any> {
    return this.http
      .post<any>(API_URL + '/modalities', modality);
  }

  public putModality(modalityIri: string, modality: any): Observable<any> {
    return this.http
      .put<any>(API_URL + modalityIri, modality);
  }

  public deleteModality(modalityIri: string): Observable<any> {
    return this.http
      .delete<any>(API_URL + modalityIri);
  }

  public getFileDatas(fileUrls: any): Observable<any> {
    return this.http.post<any>(API_URL + '/downloads/images', fileUrls);// Use 'blob' for binary data
  }

  public postReview(review): Observable<Review> {
    return this.http
      .post<Review>(API_URL + '/reviews', review);
  }

  public getReviewsByExperienceId(productId, filters?: any, language = 'es'): Observable<any> {
    if (filters === undefined) { filters = {}; }

    let httpParams = new HttpParams();

    if (filters['name']) {
      httpParams = httpParams.set('name', String(filters.name));
    }
    if (filters['comments']) {
      httpParams = httpParams.set('comments', String(filters.comments));
    }
    if (filters['reviewerLocation']) {
      httpParams = httpParams.set('reviewerLocation', String(filters.reviewerLocation));
    }
    if (filters['date']) {
      httpParams = httpParams.set('date', String(filters.date));
    }
    if (filters['channel']) {
      httpParams = httpParams.set('location.channel', String(filters.channel));
    }

    return this.http
      .get<ReviewCollection>(API_URL + '/experiences/' + productId + '/reviews', {
        params: httpParams, headers: {
          'Accept-Language': language
        }
      });

  }

  public getValidatedReviewsByExperienceId(productId): Observable<any> {
    return this.http
      .get<ReviewCollection>(API_URL + '/experiences/' + productId + '/reviews?status=validated');
  }

  public putReviewStatus(reviewId, status): Observable<any> {
    let sendStatus = {
      status: status
    }
    return this.http
      .put<any>(API_URL + reviewId, sendStatus);
  }

  public importReviews(makerId): Observable<any> {
    return this.http
      .get<any>(API_URL + '/getyourguide_reviews?makerId=' + makerId);
  }
  
  public updatePosition(mediaExperiences): Observable<any> {
    return this.http
      .post<any>(API_URL + '/updatePosition', {mediaExperiences});
  }

}

<div class="d-flex align-items-center">
  <h1 class="page-title m-0 mr-3"><i class="fa fa-binoculars small mr-3"></i> {{ 'Experiencias' | translate}} </h1>
  <button *ngIf="this.maker['makerPlan']['plan']['sku'] != 'FREE'"
    class="btn btn-sm btn-delete d-none d-sm-inline-block mr-1"
    (click)="openShareModal(shareExperiences,{class: 'qr-modal', initialState:{data:{ message: {url: makerPinUrl, type: 'maker'}}}})"
    [tooltip]="translate.instant('Compartir todas las experiencias')" placement="bottom">
    <i class="fa fa-upload"></i>
  </button>
</div>
<div class="mt-4 mb-n4" *ngIf="alert.message">
  <p class="alert" [class.alert-success]="alert.type == 1" [class.alert-danger]="alert.type == 2">{{alert.message}}</p>
</div>
<div class="my-5 d-flex justify-content-between">
  <div class="d-flex flex-column justify-content-between">
    <h5>{{ 'Buscar por:' | translate}} </h5>
    <form [formGroup]="searchForm" (submit)="applyFilter()">
      <div class="d-flex flex-column flex-lg-row mr-3 mr-lg-0">
        <!-- <div class="col">
          <input placeholder="ID" type="number" formControlName="id" class="form-control form-control-sm">
        </div> -->
        <div class="mr-lg-3 mb-2 mb-lg-0">
          <input [placeholder]="translate.instant('Nombre')" type="text" formControlName="name"
            class="form-control form-control-sm">
        </div>
        <div class="mr-lg-3 mb-2 mb-lg-0">
          <input [placeholder]="translate.instant('Ubicación')" type="text" formControlName="location"
            class="form-control form-control-sm">
        </div>
        <button type="submit" class="btn btn-success btn-sm mt-sm-0 mt-2 search-button mr-3">{{ 'Buscar' |
          translate}}</button>
      </div>
    </form>
  </div>
  <div class="total-products d-flex flex-column justify-content-between">
    <span>Número de productos de tu plan</span>
    <span style="font-size: 16px;">{{rows.length}} / {{maxExperiences}}</span>
  </div>
</div>
<div class="card mt-2">
  <div class="card-header d-flex align-items-center justify-content-between">
    <strong>Listado de tus experiencias</strong>
    <div class="d-flex justify-content-end">
      <a *ngIf="rows.length < maxExperiences" (click)="changeLang()"
        [href]="translate.getDefaultLang() + '/maker/admin/nueva-experiencia'" routerLink="../nueva-experiencia"
        class="btn btn-sm btn-primary text-capitalize">{{ 'Añadir nueva
        Experiencia' |
        translate}}</a>
      <a *ngIf="rows.length >= maxExperiences"
        (click)="openMoreProductsModal(moreProductsModal,{class: 'moreProducts-modal'})"
        class="btn btn-sm btn-primary text-capitalize">{{ 'Añadir nueva Experiencia' | translate}}</a>
    </div>
  </div>
  <div class="card-body p-0">
    <ngx-datatable #table class="bootstrap expandable d-none d-lg-block" [rowHeight]="'auto'" [columns]="columns"
      [columnMode]="'force'" [headerHeight]="90" [footerHeight]="50" [limit]="maxTableRows" [rows]="rows">

      <ngx-datatable-column *ngFor="let column of columns; let i = index;" name="{{translate.instant(column.name)}}"
        prop="{{column.prop}}" [minWidth]="100">
      </ngx-datatable-column>

      <!-- <ngx-datatable-column [minWidth]="100" [resizeable]="true" [sortable]="false" [canAutoResize]="true"
        cellClass="d-none d-sm-block" headerClass="d-none d-sm-block" [name]="translate.instant('Estado')"
        prop="status">
        <ng-template let-column="column" let-sort="sortFn" ngx-datatable-header-template>
          <span class="datatable-header-cell-label d-none d-sm-block">{{ column.name }}</span>
        </ng-template>

        <ng-template let-value="value" [minWidth]="100" ngx-datatable-cell-template>
          <div [ngSwitch]="value" class="d-none d-sm-block">
            <ng-container *ngSwitchCase="'completa'">{{translate.instant("Completa")}}</ng-container>
            <ng-container *ngSwitchCase="'incompleta'">{{translate.instant("Incompleta")}}</ng-container>
          </div>
        </ng-template>
      </ngx-datatable-column> -->

      <ngx-datatable-column [width]="20" [resizeable]="true" [sortable]="false" [canAutoResize]="true"
        cellClass="d-none d-sm-block" headerClass="d-none d-sm-block" [name]="translate.instant('Microficha')"
        prop="microsheetCompleted">
        <ng-template let-column="column" let-sort="sortFn" ngx-datatable-header-template>
          <span class="datatable-header-cell-label d-none d-sm-block">{{ column.name }}</span>
        </ng-template>

        <ng-template let-value="value" ngx-datatable-cell-template>
          <div [ngSwitch]="value" class="ml-4 d-none d-sm-block public-icons">
            <ng-container *ngSwitchCase="true">
              <span class="green">✓</span>
            </ng-container>
            <ng-container *ngSwitchCase="false">
              <span class="red">✗</span>
            </ng-container>
          </div>
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column *ngIf="maker.makerPlan.plan.sku != 'FREE'" [width]="20" [resizeable]="true"
        [sortable]="false" [canAutoResize]="true" cellClass="d-none d-sm-block" headerClass="d-none d-sm-block"
        [name]="translate.instant('Ficha')" prop="sheetCompleted">
        <ng-template let-column="column" let-sort="sortFn" ngx-datatable-header-template>
          <span class="datatable-header-cell-label d-none d-sm-block">{{ column.name }}</span>
        </ng-template>

        <ng-template let-value="value" ngx-datatable-cell-template>
          <div [ngSwitch]="value" class="ml-2 d-none d-sm-block public-icons">
            <ng-container *ngSwitchCase="true">
              <span class="green">✓</span>
            </ng-container>
            <ng-container *ngSwitchCase="false">
              <span class="red">✗</span>
            </ng-container>
          </div>
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column [width]="20" [resizeable]="true" [sortable]="false" [canAutoResize]="true"
        cellClass="d-none d-sm-block" headerClass="d-none d-sm-block" [name]="translate.instant('Público')"
        prop="isActive">
        <ng-template let-column="column" let-sort="sortFn" ngx-datatable-header-template>
          <span class="datatable-header-cell-label d-none d-sm-block">{{ column.name }}</span>
        </ng-template>

        <ng-template let-value="value" ngx-datatable-cell-template>
          <div [ngSwitch]="value" class="text-left d-none d-sm-block public-icons">
            <ng-container *ngSwitchCase="true">
              <span class="vi-globe mr-3"></span>
            </ng-container>
            <ng-container *ngSwitchCase="false">
              <span class="vi-globe-crossed mr-3"></span>
            </ng-container>
          </div>
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column [width]="90" [resizeable]="true" [sortable]="false" [draggable]="false"
        [canAutoResize]="true" headerClass="d-none d-sm-block text-right" cellClass="text-right"
        [name]="translate.instant('Acciones')" prop="actions">
        <ng-template let-column="column" let-sort="sortFn" ngx-datatable-header-template>
          <span class="datatable-header-cell-label d-none d-sm-block">{{ column.name }}</span>
        </ng-template>
        <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
          <button *ngIf="this.maker['makerPlan']['plan']['sku'] != 'FREE'"
            class="btn btn-sm btn-delete d-none d-sm-inline-block mr-1"
            (click)="openShareModal(shareExperiences,{class: 'qr-modal', initialState:{data:{ message: {url: productPinUrl, type: 'product', product: row}}}})"
            [tooltip]="translate.instant('Compartir')" placement="bottom">
            <i class="fa fa-upload"></i>
          </button>
          <button class="btn btn-sm btn-edit mr-1"
            [routerLink]="'../editar-experiencia/'+row['@id'].replace('/experiences/', '')"
            [tooltip]="translate.instant('Editar')" placement="bottom">
            <i class="fa fa-pencil"></i>
          </button>
          <button class="btn btn-sm btn-delete d-none d-sm-inline-block" (click)="deleteExperience(row['@id'])"
            [tooltip]="translate.instant('Eliminar')" placement="bottom">
            <i class="fa fa-trash"></i>
          </button>
        </ng-template>
      </ngx-datatable-column>
    </ngx-datatable>
    <ngx-datatable #table class="bootstrap expandable d-lg-none d-block" [rowHeight]="'auto'" [columns]="columns"
      [columnMode]="'force'" [headerHeight]="90" [footerHeight]="50" [limit]="maxTableRows" [rows]="rows">

      <ngx-datatable-column name="{{'Nombre'}}" prop="{{'name'}}">
      </ngx-datatable-column>

      <ngx-datatable-column [resizeable]="true" [sortable]="false" [draggable]="false" [canAutoResize]="true"
        [name]="translate.instant('Acciones')" prop="actions">
        <ng-template let-column="column" let-sort="sortFn" ngx-datatable-header-template>
          <span class="datatable-header-cell-label">{{ column.name }}</span>
        </ng-template>
        <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
          <button *ngIf="this.maker['makerPlan']['plan']['sku'] != 'FREE'" class="btn btn-sm btn-delete mr-1"
            (click)="openShareModal(shareExperiences,{class: 'qr-modal', initialState:{data:{ message: {url: productPinUrl, type: 'product', product: row}}}})"
            [tooltip]="translate.instant('Compartir')" placement="bottom">
            <i class="fa fa-upload"></i>
          </button>
          <button class="btn btn-sm btn-edit mr-1"
            [routerLink]="'../editar-experiencia/'+row['@id'].replace('/experiences/', '')"
            [tooltip]="translate.instant('Editar')" placement="bottom">
            <i class="fa fa-pencil"></i>
          </button>
          <button class="btn btn-sm btn-delete" (click)="deleteExperience(row['@id'])"
            [tooltip]="translate.instant('Eliminar')" placement="bottom">
            <i class="fa fa-trash"></i>
          </button>
        </ng-template>
      </ngx-datatable-column>
    </ngx-datatable>
    <!-- <ul class="nav nav-tabs mb-n1 small mt-4">
      <li class="nav-item">
        <a (click)="changeLang()" [href]="translate.getDefaultLang() + '/maker/admin/nueva-experiencia'"
          routerLink="../nueva-experiencia" class="btn btn-sm btn-primary ml-3 text-capitalize">{{ 'Añadir nueva Experiencia' | translate}}</a>
      </li>
    </ul> -->
    <!-- <div class="d-flex justify-content-end">
      <a (click)="changeLang()" [href]="translate.getDefaultLang() + '/maker/admin/nueva-experiencia'"
        routerLink="../nueva-experiencia" class="btn btn-sm btn-primary ml-3 text-capitalize">{{ 'Añadir nueva Experiencia' | translate}}</a>
    </div> -->
  </div>
</div>
<!-- <div class="d-flex justify-content-end mr-3">
  <ul class="nav mb-n1 small">
    <li class="nav-item">
      <a (click)="changeLang()" [href]="translate.getDefaultLang() + '/maker/admin/nueva-experiencia'"
        routerLink="../nueva-experiencia" class="pink-btn btn btn-sm btn-primary ml-3 text-capitalize">{{ 'Añadir nueva Experiencia' | translate}}</a>
    </li>
  </ul>
</div> -->
<ng-template #shareExperiences id="contactsData">
  <div class="card" style="max-width: 400px;height: 600px;">
    <div class="card-body d-flex flex-column position-relative">
      <button type="button" class="btn btn-lg btn-white btn-close pull-right ml-auto mr-3 p-1" aria-label="Close"
        (click)="modalRef.hide()">
        <span class="vi-close"></span>
      </button>
      <div class="d-flex flex-column mt-3 px-4" style="min-height: max-content;">
        <div class="mb-5 text-center">
          <!-- <img src="assets/images/qr-code.png" alt="qr-code"> -->

          <qr-code id="qrCodeImage" [value]="modalService.config.initialState['data'].message.url" [size]="200"
            [level]="'M'" [background]="'#ffffff'" [foreground]="'#FF5745'">

          </qr-code>

        </div>
        <div>
          <div class="mb-2">
            <strong>
              Todas las experiencias del proveedor
            </strong>
          </div>
          <div class="d-flex">
            <input disabled [placeholder]="translate.instant(modalService.config.initialState['data'].message.url)"
              [value]="modalService.config.initialState['data'].message.url" type="text" id="pinLink"
              class="form-control form-control-sm mb-2 mr-2" style="max-width: 100%;">
            <button class="mb-2 copy-button" (click)="copyLink()"><span class="vi-content_copy"></span></button>
            <button class="mb-2 mx-2 copy-button" (click)="shareUrl()"><span class="vi-plane pointer"
                (click)="shareUrl()"></span></button>

          </div>

          <!-- alert: el texto ha sido copiado correctamente. -->
          <div class="alert alert-success" role="alert" id="alertCopy" *ngIf="copied">
            <strong>{{ '¡Texto copiado!' | translate}}</strong>
          </div>

          <small class="d-block text-muted mb-5">{{ 'Tu código QR abrirá esta URL' | translate}}</small>
          <div class="text-center">
            <button class="btn btn-primary download-button" (click)="downloadQRCode()"><span class="vi-save-as"
                style="color: #fff;"></span> DESCARGAR QR</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #moreProductsModal>
  <div class="card">
    <div class="card-body d-flex flex-column position-relative">
      <button type="button" class="btn btn-lg btn-white btn-close pull-right ml-auto mr-3 p-1" aria-label="Close"
        (click)="modalRef.hide()">
        <span class="vi-close"></span>
      </button>
      <div class="d-flex flex-column px-4 text-center moreProductsModalContent" style="min-height: max-content;">
        <span class="moreProductsModalTitle">Añade productos a tu plan</span>
        <span class="moreProductsModalSubtitle">Alcanzaste el cupo de productos para tu plan.</span>
        <div class="wrapper">
          <input type="radio" name="select" id="option-1" value="monthly" [(ngModel)]="regulation" checked>
          <input type="radio" name="select" id="option-2" value="yearly" [(ngModel)]="regulation">
          <label for="option-1" class="option option-1 mb-0" style="margin-right: 8px;">
            <span>Pago mensual</span>
          </label>
          <label for="option-2" class="option option-2 mb-0">
            <span>Pago anual</span>
          </label>
        </div>
        <div class="card text-center w-100">
          <span class="title">{{productsSelected + maxExperiences + ' productos'}}</span>
          <span class="subtitle">Mejora tu visibilidad con mas experiencias.</span>
          <div class="prices d-flex align-items-center justify-content-center">
            <span class="quantity">{{regulation == 'monthly' ? (6 * productsSelected) + 21 : ((5 *
              (productsSelected))+17.5) * 12}}€ </span>
            <span class="regulation">/ {{regulation == 'monthly' ? 'Mes' : 'Año'}}</span>
          </div>
          <ng-select class="selectPrices" bindLabel="Planes" [placeholder]="translate.instant('Seleccionar productos')"
            [(ngModel)]="productsSelected">
            <ng-option *ngFor="let number of [].constructor(10 - maxExperiences); let i = index"
              [value]='i+1'>{{i+1}}</ng-option>
          </ng-select>
          <button class="btn btn-primary payButton">
            Pagar {{productsSelected + maxExperiences == 1 ? maxExperiences + 1 + ' producto' : maxExperiences +
            productsSelected + ' productos'}}
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>
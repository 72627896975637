import { Component, OnInit, Renderer2, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService, ModalDirective } from 'ngx-bootstrap/modal';
import { ExperiencesService } from '../../experiences/experiences/experiences.service';
import { TranslateService } from '@ngx-translate/core';
import { BsDatepickerConfig, BsLocaleService } from 'ngx-bootstrap/datepicker';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { SalesService } from '../../sales/sales.service';
import { errorMessages } from '../../shared/custom-validators';
import { Taker } from '../../taker/taker-profile/taker.model';
import { TakerService } from '../../shared/taker.service';
import { Meta, Title } from '@angular/platform-browser';
import { JsonLdService } from 'src/app/json-ld.service';

@Component({
  selector: 'app-product-page',
  templateUrl: './product-page.component.html',
  styleUrls: ['./product-page.component.scss']
})
export class ProductPageComponent implements OnInit {
  activeTag = 'description';
  modalRef: BsModalRef;
  myImageArray = ['https://venntur.com/assets/experience/files/649198d9952cf975573530.jpeg', 'https://venntur.com/assets/experience/files/600c20ad9f5d5384735017.jpg', 'https://venntur.com/assets/experience/files/60d2f09f2e710932983928.jpeg']
  productId = null;
  experience = null;
  reviews: any;
  customPhoneTooltip = 'Copiar al portapapeles';
  customEmailTooltip = 'Copiar al portapapeles';
  week = ['Todos los días', 'Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo']
  takerExperiences = [];
  bsInlineValue = new Date();
  bsConfig?: Partial<BsDatepickerConfig>;
  selectedAdults = 2;
  selectedKids = 0;
  bookingRequestForm: UntypedFormGroup;
  bookingEmailData = {
    makerName: null,
    makerEmail: null,
    experienceName: null,
    date: null,
    adults: null,
    kids: null,
    name: null,
    phone: null,
    email: null,
    comments: null,
    url: null
  }
  errors = errorMessages;
  bookingRequestSuccess = false;
  isAlertShown = false;
  turitopId;
  bokunId;
  rezdyId;
  donicusId;
  showDefault: any = false;
  @ViewChild('autoShownModal', { static: false }) autoShownModal: ModalDirective;
  tripAdvisorOpinions = [{
    image: "../../../../assets/venntur-isotype-70.png",
    username: 'Adrian A',
    date: 'October 24',
    title: 'Excursión inolvidable',
    review: 'Es inexplicable lo que puedes disfrutar !!! La compañía , los trabajadores, el PATRON !!!! Se pasa volando , es una pena que excursiones así se acaben tan pronto !!! Me lo he pasado en grande , volvería a repetir una y millones de veces.'
  }, {
    image: "../../../../assets/venntur-isotype-70.png",
    username: 'Adriana B',
    date: 'June 27',
    title: 'Excursión inolvidable',
    review: 'Es inexplicable lo que puedes disfrutar !!! La compañía , los trabajadores, el PATRON !!!! Se pasa volando , es una pena que excursiones así se acaben tan pronto !!! Me lo he pasado en grande , volvería a repetir una y millones de veces.'
  }
  ]

  constructor(
    private router: Router,
    private modalService: BsModalService,
    private experienceService: ExperiencesService,
    public translate: TranslateService,
    public salesService: SalesService,
    public bsLocaleService: BsLocaleService,
    private takerService: TakerService,
    private searchFormBuilder: UntypedFormBuilder,
    private meta: Meta,
    private renderer2: Renderer2,
    private title: Title,
    private jsonLdService: JsonLdService
  ) {
    this.bsConfig = Object.assign({}, { showWeekNumbers: false, adaptivePosition: true });
    this.bsLocaleService.use('es');//fecha en español, datepicker
  }

  ngOnInit(): void {
    // Generate and add JSON-LD schema
    this.removeMetaTags();
    this.loadExperience();

    
    // Initialize the form group with validators
    this.bookingRequestForm = this.searchFormBuilder.group({
      date: ['',
        Validators.required],
      adults: ['',
        Validators.required],
      kids: ['',
        Validators.required],
      name: ['',
        Validators.required],
      phone: ['',
        Validators.required],
      email: ['',
        Validators.required],
      comments: ['',
        Validators.required],
      dataProtection: ['',
        Validators.required]
    });
    
    // Fetch experiences by taker ID
    this.experienceService.getExperiencesByTakerId(this.takerService.getId()).subscribe(experiences => {
      this.takerExperiences = Array.from(experiences['hydra:member']).map((item) => item['@id']);
    });

    
  }

  loadExperience() {
    var productSlug = this.router['rawUrlTree'].root.children.primary.segments[2].path;

    this.experienceService.getExperienceBySlug(productSlug, this.translate.getDefaultLang()).subscribe(experience => {
      experience = experience['hydra:member'][0];
      this.experienceService.getExperience(experience.id, this.translate.getDefaultLang()).subscribe(experience => {

        this.productId = experience.id;
        
        this.experience = experience;

        this.meta.addTags([
          { name: 'description', content: this.experience.description },
          { name: 'lang', content: 'es' },
          { name: 'robots', content: 'index, follow' },
          { property: 'og:title', content: this.experience.name },
          { property: 'og:description', content: this.experience.description },
          { property: 'og:image', content: 'https://venntur.com/assets/experience/files/' + experience.mediaExperiences[0].contentUrl },
          { property: 'og:url', content: 'https://venntur.com/' + this.experience.slug },
          { property: 'og:site_name', content: 'Venntur' }
        ]);
        this.setTitle(this.experience.name);

        if (this.experience.maker.bookingEngine) {
          if (this.experience.bookingEngine == 'Turitop' || (!this.experience.bookingEngine && this.experience.maker.bookingEngine == 'Turitop')) {
            this.turitopId = this.experience.turitopId;
            setTimeout(() => {
              let calendar: any = document.getElementById('turitop-calendar');

              var s = document.createElement("script");
              s.type = "text/javascript";
              s.src = "https://app.turitop.com/js/load-turitop.min.js";
              s.id = "js-turitop";
              s.setAttribute('data-company', this.experience.bookingEngineWidget ? this.experience.bookingEngineWidget : this.experience.maker.bookingEngineWidget);
              s.setAttribute('data-ga', 'no');
              s.setAttribute('data-buttoncolor', 'green');
              s.setAttribute('data-afftag', 'ttafid');
              calendar.appendChild(s);
            }, 1000);
          } else if (this.experience.bookingEngine == 'Fareharbor' || (!this.experience.bookingEngine && this.experience.maker.bookingEngine == 'Fareharbor')) {
            setTimeout(() => {
              let calendar: any = document.getElementById("fareharbor-calendar");
              let fareharbor: any = document.getElementsByClassName("fareharbor-calendar-wrap")[0];
              (fareharbor as any).style.display = "block";
              (fareharbor as any).parentNode.removeChild(fareharbor);
              (fareharbor.firstChild as any).src = "https://fareharbor.com/embeds/calendar/" + (this.experience.bookingEngineWidget ? this.experience.bookingEngineWidget : this.experience.maker.bookingEngineWidget) + "/items/" + this.experience.fareharborId + "?fallback=simple&flow=no&back=https://venntur.com/es/product/" + this.experience.slug + '&language=' + this.translate.getDefaultLang();
              calendar.appendChild(fareharbor);
            }, 1000);
          } else if (this.experience.bookingEngine == 'Bokun' || (!this.experience.bookingEngine && this.experience.maker.bookingEngine == 'Bokun')) {
            setTimeout(() => {
              var s = document.createElement("script");
              s.type = "text/javascript";
              s.src = "https://widgets.bokun.io/assets/javascripts/apps/build/BokunWidgetsLoader.js?bookingChannelUUID=" + (this.experience.bookingEngineWidget ? this.experience.bookingEngineWidget : this.experience.maker.bookingEngineWidget) + "&isDuda=true";
              document.getElementsByTagName('head')[0].appendChild(s);
            }, 1000);

            this.bokunId = this.experience.bokunId;
          } else if (this.experience.bookingEngine == 'Rezdy' || (!this.experience.bookingEngine && this.experience.maker.bookingEngine == 'Rezdy')) {
            this.rezdyId = this.experience.rezdyId;
            var s = document.createElement("script");
            s.type = "text/javascript";
            s.src = "https://catlanza.rezdy.com/pluginJs";
            document.getElementsByTagName('head')[0].appendChild(s);
            setTimeout(() => {
              var w: any = document.getElementById('iFrameResizer0');
              w.src = 'https://' + (this.experience.bookingEngineWidget ? this.experience.bookingEngineWidget : this.experience.maker.bookingEngineWidget) + '.rezdy.com/calendarWidget/' + this.rezdyId + '?iframe=true';
            }, 1000);

          } else if (this.experience.bookingEngine == 'Donicus' || (!this.experience.bookingEngine && this.experience.maker.bookingEngine == 'Donicus')) {
            this.donicusId = this.experience.donicusId;
            setTimeout(() => {
              let calendar: any = document.getElementById('don-'+this.experience.bookingEngineWidget );
              // don-EDCFD2BC-EE8E-4D29-A680-25474C872D85
              // '65a8daf7-e9b5-45ff-bdf6-f3f7d7639499'
              calendar.setAttribute('donref', this.donicusId);
              calendar.setAttribute('donlocale', 'ES'); 
            }, 500);
            
            setTimeout(() => {
              var s = document.createElement("script");
              s.type = "text/javascript";
              s.src = "https://service.donicus.com/dist/loader.min.js";
              s.setAttribute('donname', 'donicusBooking');
              document.getElementsByTagName('head')[0].appendChild(s);
            }, 1000);

          } else {
            this.showDefault = true;
          }
        } else {
          this.showDefault = true;
        }

        this.addJsonLdSchema();


        this.experienceService.getValidatedReviewsByExperienceId(this.productId).subscribe(reviews => {
          this.reviews = reviews['hydra:member'];

          // Dont show the comment if has not text
          this.reviews.forEach(review => {

            if (review.comments === "Sin comentario") {
              review.comments = "";
            }

          });

        });

      });
    })
  }


  scrollToElement(id: any) {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }

  openPhotosModal(template: TemplateRef<any>, config: any) {
    this.modalRef = this.modalService.show(template, config);
  }

  convertTimeToCustomFormat(inputTime: string): string {
    let formattedText;
    const [hours, minutes] = inputTime.split(':').map(part => parseInt(part, 10));

    if (minutes != 0) {
      formattedText = hours + ' h y ' + minutes + ' min'
    } else {
      formattedText = hours + ' h'
    }

    return formattedText;
  }

  changePhoneTooltip(text: string) {
    const textarea = document.createElement('textarea');
    textarea.style.position = 'fixed';
    textarea.style.opacity = '0';
    textarea.value = text;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);
    this.customEmailTooltip = 'Copiar al portapapeles'
    this.customPhoneTooltip = 'Copiado!'
  }

  changeEmailTooltip(text: string) {
    const textarea = document.createElement('textarea');
    textarea.style.position = 'fixed';
    textarea.style.opacity = '0';
    textarea.value = text;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);
    this.customPhoneTooltip = 'Copiar al portapapeles'
    this.customEmailTooltip = 'Copiado!'
  }

  formatDate(inputDate) {
    const date = new Date(inputDate);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Note: Months are zero-based, so we add 1.
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
  }

  storeDate(event) {
    console.log(event);
    this.bsInlineValue = event;
  }

  

  sendBookingEmail() {

    this.bookingEmailData.makerEmail = this.experience.maker.email;
    this.bookingEmailData.makerName = this.experience.maker.name;
    this.bookingEmailData.experienceName = this.experience.name;
    this.bookingEmailData.date = this.formatDate(this.bsInlineValue);
    this.bookingRequestForm.get('date').setValue(this.formatDate(this.bsInlineValue));
    this.bookingEmailData.adults = this.bookingRequestForm.get('adults').value;
    this.bookingEmailData.kids = this.bookingRequestForm.get('kids').value;
    this.bookingEmailData.name = this.bookingRequestForm.get('name').value;
    this.bookingEmailData.phone = this.bookingRequestForm.get('phone').value;
    this.bookingEmailData.email = this.bookingRequestForm.get('email').value;
    this.bookingEmailData.comments = this.bookingRequestForm.get('comments').value;
    this.bookingEmailData.url = 'https://venntur.com/es/product/' + this.experience.slug;

    if (this.bookingRequestForm.status == 'VALID') {
      this.salesService.postBookingEmail(this.bookingEmailData).subscribe(
        response => {
          console.log(response);
          this.bookingRequestSuccess = true;
          setTimeout(() => {
            this.bookingRequestSuccess = false;
          }, 5000);
        },
        error => {
          console.log('error al enviar el email');
          console.log(error);
        });
    } else {
      this.bookingRequestForm.markAllAsTouched();
    }

  }

  showError(formValue) {
    return this.bookingRequestForm.get(formValue).invalid && this.bookingRequestForm.get(formValue).touched;
  }

  clickFavorite(event, experienceId) {
    if (this.takerService.getId()) {
      this.takerExperiences.includes(experienceId) ?
        this.takerExperiences.splice(this.takerExperiences.indexOf(experienceId), 1) : this.takerExperiences.push(experienceId);
      const taker = {
        favoriteExperiences: this.takerExperiences
      };
      const editedTaker = new Taker(taker);
      this.takerService.putTaker(this.takerService.getId(), editedTaker).subscribe();
    } else {
      this.isAlertShown = true;
    }
  }

  shareExperience(experience) {
    var enlace = 'https://venntur.com/product/' + experience.slug;
    var mensaje = this.translate.instant('¡Mira esta experiencia en Venntur!');

    mensaje = encodeURIComponent(mensaje);
    enlace = encodeURIComponent(enlace);

    var enlaceWhatsApp = 'https://wa.me/?text=' + mensaje + '%20' + enlace;

    window.open(enlaceWhatsApp);

  }

  onHidden(): void {
    this.isAlertShown = false;
  }

  hideModal(): void {
    this.autoShownModal.hide();
  }

  removeMetaTags() {
    const metaTags = Array.from(document.querySelectorAll('meta'));

    metaTags.forEach((metaTag: Element) => {
      this.renderer2.removeChild(document.head, metaTag);
    });
  }

  public setTitle(newTitle: string) {
    this.title.setTitle(newTitle);
  }

  getUrl(isCivitatis, image) {
    return !isCivitatis ? 'https://venntur.com/assets/experience/files/' + image : image;
    // return !isCivitatis ? 'https://dev.venntur.com/assets/newExperience/files/' + image : image;
  }

  // New method for adding JSON-LD schema
addJsonLdSchema(): void {
  console.log('Adding JSON-LD schema', this.experience);
  if (this.experience) {
    const currentLanguage = this.experience.language || 'en';
    const schema = {
      "@context": "https://schema.org",
      "@type": "TouristAttraction",
      "name": this.experience.name,
      "description": this.experience.description,
      "image": this.myImageArray,
      "url": `https://venntur.com/${currentLanguage}/product/${this.experience.slug}`,
      "addressLocality": this.experience.location.city,
      "addressRegion": this.experience.location.region
      // "aggregateRating": {
      //   "@type": "AggregateRating",
      //   "ratingValue": this.experience.averageRating || "4.5",
      //   "reviewCount": this.experience.reviewCount || this.reviews.length
      // },
      // "offers": {
      //   "@type": "Offer",
      //   "price": this.experience.price || "0",
      //   "priceCurrency": "EUR",
      //   "availability": "https://schema.org/InStock",
      //   "url": `https://venntur.com/${currentLanguage}/product/${this.experience.slug}`
      // },
      // "address": {
      //   "@type": "PostalAddress",
      //   "addressLocality": this.experience.location.city,
      //   "addressRegion": this.experience.location.region,
      //   "addressCountry": "ES"
      // }
    };
      // Add JSON-LD schema to the page
      console.log("ABOUT TO SEND SCHEMA...");
      this.jsonLdService.setData(schema);
    }
  }
}

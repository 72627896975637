<ng-container *ngIf="field.type=='image'">
    <label class="mb-2">
        <strong>
            {{ "Foto principal" | translate }}
        </strong>
        <span class="form-required text-danger">*</span>
    </label>
    <!-- <app-cropper id="mediaCropper" [loadedImages]="mediaExperiences.length" [type]="'experience'"
    [preview]="'preview2'" [aspectratio]="1" (uploadedImage)="onUploadedImage($event, 'mediaExperiences')"
    (isTooManyImages)="isTooManyImages = true"></app-cropper> -->
    <app-full-width-cropper id="mediaCropper" [valueId]="field.value['id']" [loadedImages]="field.value.valueMedia.length" [type]="'valueMedia'"
        [preview]="'preview2'" [aspectratio]="1" (isTooManyImages)="isTooManyImages = true"></app-full-width-cropper>
    <div class="images d-flex flex-wrap mb-4">
        <img *ngFor="let image of field.value.valueMedia" [src]="CMS_API_URL +  image.contentUrl"
            alt="Imagen sección principal">
    </div>
</ng-container>

<ng-container *ngIf="field.type=='string'">
    <label class="mb-2">
        <strong>
            {{ field.name| translate }}
        </strong>
        <span class="form-required text-danger">*</span>
    </label>
    <input [(ngModel)]="field['value'].valueString" [placeholder]="translate.instant('Botón')" type="text"
        class="form-control" />
    <small class="text-danger" *ngIf="showError('buttonText')">
        {{ translate.instant(errors["buttonText"]) }}
    </small>
</ng-container>

<ng-container *ngIf="field.type=='text'">
    <label for="mediaCropper" class="mb-2">
        <strong>
            {{ field.name | translate }}
        </strong>
    </label>
    <ckeditor [(ngModel)]="field['value'].valueText" name="cktitle" id="cktitle" [editor]="Editor" placeholder="Título">
    </ckeditor>
</ng-container>
<div class="container mt-4 mt-lg-5 pt-lg-3">
  <div class="row">
    <div class="col-12">
      <div class="experiences-search">
        <div class="d-lg-flex justify-content-between">
          <h1 class="pb-md-2 h4">{{ 'Encuentra tu experiencia' | translate}}</h1>
          <p class="pb-md-2 small font-weight-bold" [innerHTML]="'resultsMessage' | translate : {total:total}"></p>
        </div>
        <app-experience-search [parentVariable]="parentVariable" [totalExperiences]="total"
          (parentVariableChange)="changeParentVariable($event)"></app-experience-search>
      </div>
    </div>
    <div class="col-12">
      <div class="row mb-4" infiniteScroll [infiniteScrollDistance]="scrollDistance" [infiniteScrollThrottle]="throttle"
        (scrolled)="onScroll()">
        <article *ngFor="let experience of experiences; let i = index" class="experience col-12 col-md-6 col-lg-4 py-4">
          <app-experience-card [experience]="experience" [isFooterActive]="true"></app-experience-card>
        </article>

      </div>
      <div *ngIf="experiences && experiences.length === 0 && !loading" class="col-12 text-center">
        <h2>{{ 'Sin resultados' | translate}}</h2>
        <p>{{ 'Ups.. Parece que no hay resultados para tu búsqueda' | translate}}</p>
        <p>{{ 'Inténtalo de nuevo utilizando otros filtros o' | translate}} <a href="#">{{ 'ponte en contacto con nosotros' | translate}}</a>{{ ' y te ayudaremos a encontrar la experiencia que estás buscando.' | translate}}
        </p>
      </div>
      <div *ngIf="loading" class="text-center">
        <div class="spinner-border text-primary" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="isAlertShown" [config]="{ show: true }" (onHidden)="onHidden()" bsModal #autoShownModal="bs-modal"
  class="modal fade" tabindex="-1" role="dialog" aria-labelledby="dialog-auto-name">
  <div class="modal-dialog">
    <div class="modal-content">
      <button type="button" class="btn btn-sm btn-light btn-close pull-right ml-auto" aria-label="Close"
        (click)="hideModal()">
        <span class="vi-close"></span>
      </button>
      <div class="modal-body text-center ">
        <p><span class="vi-info text-primary h3"></span></p>
        <p [innerHTML]="'RegFavs' | translate"></p>
        <app-login></app-login>
      </div>
    </div>
  </div>
</div>
<div class="container-fluid w-430 py-4 my-5">
  <div class="text-center mb-4">
    <h1>{{ '¿Olvidaste la contraseña?' | translate}}</h1>
    <p>{{ 'Introduce tu correo electrónico y te enviaremos un email de recuperación de contraseña' | translate}}</p>
  </div>
  <form [formGroup]="passForm" class="pb-5">
    <div class="form-group">
      <input type="email" name="email" formControlName="email" [placeholder]="translate.instant('Correo electrónico')" class="form-control">
      <small class="text-danger" *ngIf="showError('email')">
        {{translate.instant(errors['email'])}}
      </small>
    </div>

    <button class="btn btn-primary w-100" type="submit" name="button" (click)="sendForgotPassword()" [disabled]="passForm.pristine || passForm.invalid || isLoading">{{ 'Recuperar contraseña' | translate}}</button>
    <div class="mt-4" *ngIf="alert.message">
      <p class="alert" [class.alert-success]="alert.type == 1" [class.alert-danger]="alert.type == 2">{{alert.message}}</p>
    </div>
  </form>
</div>

<form [formGroup]="experienceSearchForm">
  <div class="row mb-4">
    <div class="col-12 col-md-6 col-lg-4">
      <div class="filter-selection d-flex align-items-center py-2 px-3 border rounded"
        (click)="openFilterModal(filterModalContent)">
        <span class="vi-search mr-3"></span>
        <div class="filter-selection-text">
          <!-- <div class="small font-weight-bold">{{ 'Todos los destinos' | translate}}</div> -->
          <div *ngIf="cities" class="small font-weight-bold">{{ getLocation() | translate}}</div>

          <!-- <div class="text-xs">{{ getZone() | translate}} - {{getDate()}}</div> -->
        </div>
        <span class="vi-filter ml-auto"></span>
      </div>
    </div>
  </div>
  <div class="container__cartegories-filters small">
    <div class="container__categories position-relative">
      <div class="keen-slider" #catSlider>
        <ng-container *ngFor="let category of categories; let i = index; trackBy: trackByFn">
          <div class="keen-slider__slide m-1" [ngClass]="{'active': i == 0}">
            <input (change)="searchExperiences(false,$event)" class="category-input category d-none" type="checkbox"
              [id]="category.id" [value]="category.name">
            <label class="category-label py-1 px-2 py-lg-2 rounded border border-dark" [for]="category.id">
              <!-- <span
                class="category-count rounded-circle text-primary text-center font-weight-bold bg-white shadow">{{totalCategories}}</span> -->
              <span class="vi-cat-{{category.id}}"></span>
              <span class="ml-2 font-weight-bold">{{category.name}}</span>
            </label>
          </div>
        </ng-container>
      </div>
      <span *ngIf="slider" (click)="slider.prev()" style="cursor: pointer;"
        class="vi-chevron-down border rounded-circle text-center bg-white"
        [ngClass]="'arrow arrow--left ' + (currentSlide === 0 ? 'disabled' : '')"></span>
      <span *ngIf="slider" (click)="slider.next()" style="cursor: pointer;"
        class="vi-chevron-down border rounded-circle text-center bg-white"
        [ngClass]="'arrow arrow--right ' + (slider.track.details.slides.length - 1 === currentSlide ? 'arrow--disabled' : '')"></span>
    </div>


    <!-- <div class="d-flex container__categories mb-4">
      <div class="m-1" *ngFor="let category of categories">
        <input (change)="searchExperiences(false,$event)" class="category-input category d-none" type="checkbox"
          [id]="category.id" [value]="category.name">
        <label class="category-label py-1 px-2 py-lg-2 rounded border border-dark" [for]="category.id"
          style="width: max-content;">
          <span
            class="category-count rounded-circle text-primary text-center font-weight-bold bg-white shadow">xxx</span>
          <span class="vi-cat-{{category.id}}"></span>
          <span class="ml-2 font-weight-bold">{{category.name}}</span>
        </label>
      </div>
    </div> -->
    <div *ngIf="parentsSelected.length > 0"
      class="container__subcategories position-relative mb-4 d-flex align-items-center">
      <div class="keen-slider" #subcatSlider1>
        <span class="keen-slider__slide vi-cat-{{iconsArray[0]}} mr-3 h3 font-weight-normal text-primary"></span>
        <ng-container *ngFor="let subcategory of subcategories[0]; let i = index; trackBy: trackByFn">
          <div *ngIf="subcategory.name != 'Otras'" class="keen-slider__slide custom-control custom-checkbox mr-3">
            <input (change)="searchExperiences(true, false)" class="custom-control-input subcategory" type="checkbox"
              [id]="subcategory.id" [value]="subcategory.name">
            <label class="custom-control-label" [for]="subcategory.id">
              <span class="text">{{subcategory.name}}</span>
              <!-- <span
                class="category-count ml-1 rounded-circle text-primary text-center font-weight-bold bg-white shadow">xx</span> -->
            </label>
          </div>
        </ng-container>
      </div>
      <span *ngIf="subslider1" (click)="subslider1.prev()" style="cursor: pointer;"
        class="vi-chevron-down border rounded-circle text-center bg-white"
        [ngClass]="'arrow arrow--left ' + (currentsubSlide1 === 0 ? 'disabled' : '')"></span>
      <span *ngIf="subslider1" (click)="subslider1.next()" style="cursor: pointer;"
        class="vi-chevron-down border rounded-circle text-center bg-white"
        [ngClass]="'arrow arrow--right ' + (subslider1.track.details.slides.length - 1 === currentsubSlide1 ? 'arrow--disabled' : '')"></span>

    </div>
    <div *ngIf="parentsSelected.length > 1"
      class="container__subcategories position-relative mb-4 d-flex align-items-center">
      <div class="keen-slider" #subcatSlider2>
        <span class="keen-slider__slide vi-cat-{{iconsArray[1]}} mr-3 h3 font-weight-normal text-primary"></span>
        <ng-container *ngFor="let subcategory of subcategories[1]; let i = index; trackBy: trackByFn">
          <div *ngIf="subcategory.name != 'Otras'" class="keen-slider__slide custom-control custom-checkbox mr-3">
            <input (change)="searchExperiences(true, false)" class="custom-control-input subcategory" type="checkbox"
              [id]="subcategory.id" [value]="subcategory.name">
            <label class="custom-control-label" [for]="subcategory.id">
              <span class="text">{{subcategory.name}}</span>
              <span
                class="category-count ml-1 rounded-circle text-primary text-center font-weight-bold bg-white shadow">xx</span>
            </label>
          </div>
        </ng-container>
      </div>
      <span *ngIf="subslider2" (click)="subslider2.prev()"
        class="vi-chevron-down border rounded-circle text-center bg-white"
        [ngClass]="'arrow arrow--left ' + (currentsubSlide2 === 0 ? 'disabled' : '')"></span>
      <span *ngIf="subslider2" (click)="subslider2.next()"
        class="vi-chevron-down border rounded-circle text-center bg-white"
        [ngClass]="'arrow arrow--right ' + (subslider2.track.details.slides.length - 1 === currentsubSlide2 ? 'arrow--disabled' : '')"></span>
    </div>
  </div>

  <ng-template #filterModalContent>
    <div class="modal-content modal-filter">
      <div class="position-relative p-3 border-bottom border-dark">
        <div class="h6 text-center">
          <span class="vi-filter"></span> {{ 'Filtros' | translate }}
        </div>
        <button type="button" class="position-absolute btn btn-lg btn-white btn-close mt-3 mr-3 p-1" aria-label="Close"
          (click)="modalRef.hide()">
          <span class="vi-close"></span>
        </button>
      </div>
      <div class="row justify-content-center">
        <div class="col-8 col-md-8 col-lg-8">
          <div class="p-3 input-group select--location">
            <label class="mb-n2 text-xs">{{ 'Destino' | translate }}</label>
            <ng-select (change)="experienceSearchForm.get('area').setValue(null); searchExperiences(true);" formControlName="location"
              [placeholder]="translate.instant('Elige destino')" class="custom-simple-select small">
              <ng-option [value]="city.id" *ngFor="let city of cities">{{city.name}}</ng-option>
            </ng-select>
          </div>
        </div>
        <!-- <div class="col-6 col-md-4 col-lg-4">
          <div class="p-3 input-group select--area">
            <label class="mb-n2 text-xs">{{ 'Zona' | translate }}</label>
            <ng-select (change)="searchExperiences(true)" formControlName="area"
              [placeholder]="translate.instant('Elige zona')" class="custom-simple-select small">
              <ng-option [value]="zone['@id'].replace('/zones/','')" *ngFor="let zone of zones">{{zone.name}}</ng-option>
            </ng-select>
          </div>
        </div>
        <div class="col-10 col-md-4 col-lg-4 m-auto">
          <div class="p-3 input-group select--area">
            <label class="mb-n2 text-xs">{{ 'Cuando' | translate }}</label>
            <ng-select (change)="searchExperiences(true)" formControlName="date"
              [placeholder]="translate.instant('Elige fecha')" class="custom-simple-select small">
              <ng-container *ngFor="let month of monthsWithYear; let i = index">
                <ng-option [value]="i">{{month}}</ng-option>
              </ng-container>
            </ng-select>
          </div>
        </div> -->
      </div>
      <div class="p-5 border-top border-dark small">
        <!-- <div class="columns2">
          <div class="custom-control custom-checkbox" *ngFor="let tag of tags">
            <input (change)="searchExperiences(true)" class="custom-control-input tag" type="checkbox"
              [id]="'tag-' + tag.id" [value]="tag.name">
            <label class="custom-control-label" [for]="'tag-' + tag.id">
              <span class="text">{{tag.name}}</span>
            </label>
          </div>
        </div> -->
        <div class="row pt-4">
          <div class="col-12 col-md-6 text-center text-md-left mb-md-0 mb-3">
            <input type="button" [value]="translate.instant('Quitar filtros')" class="btn btn-sm btn-secondary"
              (click)="removeFilters(); modalRef.hide()">
          </div>
          <div class="col-12 col-md-6 text-center text-md-right">
            <input type="submit" [value]="'btnFilterM' | translate : {total:totalExperiences}" (click)="modalRef.hide()"
              class="btn btn-sm btn-primary">
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</form>
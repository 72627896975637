<h1 class="page-title text-center text-lg-left">{{translate.instant(title)}}</h1>
<div *ngIf="!maker">
  <p class="alert alert-danger">{{ 'Completa los datos del maker para poder crear experiencias' | translate}}</p>
</div>

<!-- <div class="card mb-3" *ngIf="maker && maker.makerPlan">
  <div class="card-body p-4">
    <form [formGroup]="planForm" class="maker-admin-form">
      <h4 class="border-bottom pb-2 mb-3"><i class="fa fa-certificate small mr-1 text-black-50"></i> {{ 'Tu plan Maker'
        | translate}}</h4>
      <div class="row maker-admin-form">
        <div class="col-md-4 form-group">
          <label>{{ 'Tipo de plan' | translate}} <span class="form-required text-danger"></span></label>
          <ng-select bindLabel="Planes" [placeholder]="translate.instant('Seleccionar plan')" appendTo="body"
            formControlName="plan" [(ngModel)]="planSelected">
            <ng-option value="Gratis">{{"Gratis" | translate}}</ng-option>
            <ng-option value="Basico">{{"Básico" | translate}}</ng-option>
          </ng-select>
        </div>
      </div>
    </form>
  </div>
</div> -->

<div class="d-lg-flex">
  <div class="card mb-3 p-4 mr-lg-3 plan-card" *ngIf="maker && maker.makerPlan">
    <form [formGroup]="planForm" class="maker-admin-form">
      <div class="row p-0 mb-3">
        <div class="col-6">
          <strong>
            Añade más productos a tu plan
          </strong>
        </div>
        <div class="col-6">
          <button class="btn btn-primary w-100 p-0" style="height: 46px;font-weight: 400" routerLink="../experiencias">
            Añadir productos
          </button>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-5 d-flex flex-column mb-3">
          <strong>Plan actual</strong>
          <!-- <small>{{maker['makerPlan']['plan']['name'] | translate}}</small> -->
          <small>{{maker.makerPlan['plan']['shortName'] | translate}}</small>
        </div>
        <div class="col-7 d-flex flex-column mb-3">
          <strong>
            Número de productos
          </strong>
          <small>
            {{totalExperiences}} / {{maker['makerPlan']['plan']['maxProducts']}}
          </small>
        </div>
        <div class="col-5 d-flex flex-column mb-3">
          <strong>
            Tipo de pago
          </strong>
          <small>
            {{maker['makerPlan']['plan']['paymentType'] == 'yearly' ? 'Anual' : 'Mes'}}
          </small>
        </div>
        <div class="col-7 d-flex flex-column mb-3">
          <strong>
            Fecha próximo pago
          </strong>
          <small *ngIf="nextPayment && nextPayment['subscriptions']['data'].length > 0">
            {{timestampToDate(nextPayment['subscriptions']['data'][0]['current_period_end'])}}
          </small>
          <small *ngIf="nextPayment && nextPayment['subscriptions']['data'].length == 0">
            <!-- {{timestampToDate(nextPayment['subscriptions']['data'][0]['current_period_end'])}} -->
          </small>
        </div>
      </div>
      <div class="d-flex flex-column mb-3">
        <strong>
          Importe Plan
        </strong>
        <span style="font-weight: 600; font-size: 40px;">
          {{maker['makerPlan']['price'] + '€ / ' + (maker['makerPlan']['plan']['paymentType'] == 'yearly' ? 'Anual' :
          'Mes')}}
        </span>
      </div>
      <div>
        <button class="btn btn-primary w-100" style="height: 46px;font-weight: 500;"
          (click)="openUpgradePlanModal(moreProductsModal,{class: 'moreProducts-modal'})">MEJORAR
          PLAN</button>
        <!-- <button class="btn btn-primary w-100" style="height: 46px;font-weight: 500;"><a
            style="text-decoration: none;color: inherit;" href="https://www.venntur.com/es/precios-venntur">MEJORAR A
            PLAN
            PREMIUM</a></button> -->
      </div>
    </form>
  </div>

  <div class="card w-100 mb-3 p-4 bill-card">
    <div class="d-flex justify-content-between mb-3">
      <!-- <strong>
        Facturas
      </strong>
      <ng-select placeholder="Año">
        <ng-option [value]="'2024'" selected>
          <a>Año 2024</a>
        </ng-option>
        <ng-option [value]="'2023'">
          <a>Año 2023</a>
        </ng-option>
      </ng-select> -->
    </div>
    <div class="bill-rows" style="display: grid;">
      <div class="d-flex mb-2">
        <div class="w-25">
          <strong>
            Nº de factura
          </strong>
        </div>
        <div class="w-25">
          <strong>
            Precio
          </strong>
        </div>
        <div class="w-25">
          <strong>
            Fecha de pago
          </strong>
        </div>
        <!-- <div class="w-25">
          <strong>
            Descargar
          </strong>
        </div> -->
      </div>
      <ng-container *ngIf="invoices.length > 0">
        <div *ngFor="let invoice of invoices" class="d-flex">
          <div class="w-25">
            <a>
              {{invoice['docNumber']}}
            </a>
          </div>
          <div class="w-25">
            <span>
              {{invoice['paymentsTotal']}} €
            </span>
          </div>
          <div class="w-25">
            <span>
              {{timestampToDate(invoice['date'])}}
            </span>
            <span *ngIf="!invoice['status']" class="vi-cancel-circle" style="color: red;"
              [tooltip]="translate.instant('Pago fallido, intentelo de nuevo.')"></span>
          </div>
          <div class="w-25">
          <a (click)="downloadPdf(invoice['docNumber'], invoice['id'])" target="_blank">
            <span class="vi-save-as-2 pointer" style="color: #999999 !important;font-size: 22px;"></span>
          </a>
        </div>
        </div>
      </ng-container>
      <div *ngIf="invoices.length == 0" class="d-flex">
        <div class="w-25">
          <a>
            -
          </a>
        </div>
        <div class="w-25">
          <span>
            -
          </span>
        </div>
        <div class="w-25">
          <span>
            -
          </span>
        </div>
        <div class="w-25">
          <span class="vi-save-as" style="color: #999999 !important;font-size: 22px;">-</span>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #moreProductsModal>
  <div class="card">
    <div class="card-body d-flex flex-column position-relative">
      <button type="button" class="btn btn-lg btn-white btn-close pull-right ml-auto mr-3 p-1" aria-label="Close"
        (click)="modalRef.hide()">
        <span class="vi-close"></span>
      </button>
      <div class="d-flex flex-column px-4 text-center moreProductsModalContent" style="min-height: max-content;">
        <span class="moreProductsModalTitle">Mejora tu plan</span>
        <!-- <span class="moreProductsModalSubtitle">Alcanzaste el cupo de productos para tu plan.</span> -->
        <div class="wrapper">
          <input type="radio" name="select" id="option-1" value="monthly" [(ngModel)]="regulation" checked>
          <input type="radio" name="select" id="option-2" value="yearly" [(ngModel)]="regulation">
          <label for="option-1" class="option option-1 mb-0" style="margin-right: 8px;">
            <span>Pago mensual</span>
          </label>
          <label for="option-2" class="option option-2 mb-0">
            <span>Pago anual</span>
          </label>
        </div>
        <div class="card text-center w-100">
          <span class="title">{{productsSelected + ' productos'}}</span>
          <span class="subtitle">Mejora tu visibilidad con mas experiencias.</span>
          <div class="prices d-flex align-items-center justify-content-center">
            <span class="quantity">{{regulation == 'monthly' ? (6 * productsSelected) + 3 : ((5 *
              (productsSelected))) * 12 + 30}}€ </span>
            <span class="regulation">/ {{regulation == 'monthly' ? 'Mes' : 'Año'}}</span>
          </div>
          <ng-select class="selectPrices" bindLabel="Planes" [placeholder]="translate.instant('Seleccionar productos')"
            [(ngModel)]="productsSelected">
            <ng-option *ngFor="let number of [3,4,5,6,7,8,9,10]; let i = index" [value]='number'>{{number}}</ng-option>
          </ng-select>
          <button (click)="pay()" class="btn btn-primary payButton">
            Plan Pro {{productsSelected == 1 ? 1 + ' producto' : productsSelected + ' productos'}}
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>



<!-- <div class="card mb-3" *ngIf="maker && maker['makerPlan']">
  <div class="card-body p-4">
    <h4 class="border-bottom pb-2 mb-3"><i class="fa fa-certificate small mr-1 text-black-50"></i> {{ 'Tu plan Maker' |
      translate}}</h4>
    <div class="row maker-admin-form">
      <div class="col-md-4 form-group">
        <label>{{ 'Tipo de plan' | translate}} <span class="form-required text-danger"></span></label>
        <p class="form-control" readonly>{{maker['makerPlan']['plan']['name'] | translate}}</p>
      </div>
      <div class="col-md-4 form-group">
        <label>{{ 'Precio tarifa' | translate}} <span class="form-required text-danger"></span></label>
        <p class="form-control" readonly>{{maker['makerPlan']['price'] > 0 ? maker['makerPlan']['price'].substr(0, 1) +
          '.' + maker['makerPlan']['price'].substr(1) + '€' : 0 + '€'}}</p>
      </div>
      <div class="col-md-4 form-group">
        <label>{{ 'Fecha de inicio' | translate}} <span class="form-required text-danger"></span></label>
        <p class="form-control" readonly>{{maker['makerPlan']['startDate'] | date: 'dd/MM/yyyy'}}</p>
      </div>
    </div>
    <button *ngIf="maker && maker['makerPlan']['plan']['name'] === 'Gratis'" type="button" class="btn btn-primary mb-4"
      (click)="createMakerPlan(true)" style="height: 46px; font-weight: 400;">
      {{ 'Cambiar a plan básico' | translate}}
    </button>
    <accordion *ngIf="maker['makerPlan'] && maker['makerPlan']['stripeCustomerId']" [isAnimated]="true">
      <accordion-group heading="{{ 'Tus recibos' | translate}}">
        <ul *ngFor="let invoice of invoices" class="list-unstyled">
          <li>Fecha: <a [href]="invoice['invoice_pdf']"><span
                class="text-dark font-weight-bold">{{invoice['created']*1000 | date: 'dd/MM/yyyy'}} </span> <i
                class="fa fa-cloud-download"></i></a></li>
        </ul>
      </accordion-group>
    </accordion>
  </div>
</div> -->
<ul class="nav nav-tabs mb-n1 small d-">
  <li class="nav-item">
    <a class="nav-link h-100"
      [ngClass]="{ 'active font-weight-bold': showTab == 'private', 'green': isInfoValid == true, 'red': isInfoValid == false }"
      (click)="showTab = 'private'">{{"Razón Social" | translate}}</a>
  </li>
  <li class="nav-item">
    <a class="nav-link h-100"
      [ngClass]="{ 'active font-weight-bold': showTab == 'public', 'green': isDirectoryValid == true, 'red': isDirectoryValid == false }"
      (click)="showTab = 'public'">{{"Directorio" | translate}}</a>
  </li>
  <li class="nav-item">
    <a class="nav-link h-100"
      [ngClass]="{ 'active font-weight-bold': showTab == 'infoMaker', 'green': isSheetValid == true, 'red': isSheetValid == false }"
      (click)="showTab = 'infoMaker'">{{"Ficha Empresa" | translate}}</a>
  </li>
  <li class="nav-item" *ngIf="maker && maker.makerPlan['plan']['sku'] != 'FREE'">
    <a class="nav-link h-100"
      [ngClass]="{'active font-weight-bold' : showTab == 'pin', 'green': isPinValid == true, 'red': isPinValid == false }"
      (click)="showTab = 'pin'">{{"Ficha ampliada" | translate}}</a>
  </li>
  <li class="nav-item">
    <a class="nav-link h-100" [ngClass]="{'active font-weight-bold' : showTab == 'access'}"
      (click)="showTab = 'access'">{{"Datos Acceso" | translate}}</a>
  </li>
</ul>

<!-- <ul class="nav nav-tabs mb-n1 text-uppercase small">
  <li class="nav-item">
    <a class="nav-link active font-weight-bold" (click)="changeLanguage('es')">{{ 'Español' | translate }}</a>
  </li>
  <li class="nav-item">
    <a class="nav-link active bg-light" (click)="changeLanguage('en')">{{ 'Inglés' | translate }}</a>
  </li>
</ul> -->

<div class="card">
  <div class="card-body p-4">
    <form [formGroup]="makerForm" class="maker-admin-form">
      <div *ngIf="showTab == 'private'">
        <h4 class="border-bottom pb-2 mb-3"><i class="fa fa-lock small mr-1 text-black-50"></i> {{ 'Razón Social' |
          translate}}</h4>
        <div class="row">
          <div class="col form-group">
            <label>{{ 'Denominación social' | translate}} <span class="form-required text-danger">*</span></label>
            <input [placeholder]="translate.instant('Denominación social')" type="text" class="form-control"
              formControlName="companyName">
            <small class="text-danger" *ngIf="showError('companyName')">
              {{translate.instant(errors['companyName'])}}
            </small>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 form-group">
            <label>{{ 'Tipo de documento' | translate}} <span class="form-required text-danger">*</span></label>
            <ng-select bindLabel="Tipo de promoción" [placeholder]="translate.instant('Seleccionar tipo de documento')"
              formControlName="documentType" [(ngModel)]="documentTypeSelected">
              <ng-option [value]="documentType" *ngFor="let documentType of documentTypes">{{documentType}}</ng-option>
            </ng-select>
            <small class="text-danger" *ngIf="showError('documentType')">
              {{translate.instant(errors['documentType'])}}
            </small>
          </div>
          <div class="col-md-6 form-group">
            <label>{{ 'Número de Documento' | translate}} <span class="form-required text-danger">*</span></label>
            <input [placeholder]="translate.instant('Documento')" type="text" class="form-control"
              formControlName="document">
            <small class="text-danger" *ngIf="showError('document')">
              {{translate.instant(errors['document'])}}
            </small>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6 form-group">
            <label>{{ 'Nombre de contacto' | translate}} <span class="form-required text-danger">*</span></label>
            <input [placeholder]="translate.instant('Nombre de contacto')" type="text" class="form-control"
              formControlName="contactName">
            <small class="text-danger" *ngIf="showError('contactName')">
              {{translate.instant(errors['contactName'])}}
            </small>
          </div>
          <div class="col-md-6 form-group">
            <label>{{ 'Apellido de contacto' | translate}} <span class="form-required text-danger">*</span></label>
            <input [placeholder]="translate.instant('Apellido de contacto')" type="text" class="form-control"
              formControlName="contactLastname">
            <small class="text-danger" *ngIf="showError('contactLastname')">
              {{translate.instant(errors['contactLastname'])}}
            </small>
          </div>
          <!-- <div class="col-md-6 form-group">
            <label>{{ 'Email de reservas' | translate}} <span class="form-required text-danger">*</span></label>
            <span class="vi-info-circle" style="color: #999999 !important;font-size: 22px;"
              [tooltip]="translate.instant('Email de reservas')"></span>
            <input [placeholder]="translate.instant('Email de reservas')" type="text" class="form-control"
              formControlName="bookingEmail">
          </div> -->

        </div>
        <!-- <div class="row">
          <div class="col-md-6 form-group">
            <label>{{ 'Teléfono de contacto' | translate}} <span class="form-required text-danger">*</span></label>
            <input [placeholder]="translate.instant('Teléfono de contacto')" type="text" class="form-control"
              formControlName="contactPhone">
            <small class="text-danger" *ngIf="showError('contactPhone')">
              {{translate.instant(errors['contactPhone'])}}
            </small>
          </div>
          <div class="col-md-6 form-group">
            <label>{{ 'Email de contacto' | translate}} <span class="form-required text-danger">*</span></label>
            <input [placeholder]="translate.instant('Email de contacto')" type="text" class="form-control"
              formControlName="contactEmail">
            <small class="text-danger" *ngIf="showError('contactEmail')">
              {{translate.instant(errors['contactEmail'])}}
            </small>
          </div>
        </div> -->
        <div class="row">
          <div class="col-12 text-lg-right text-center">
            <a [href]="translate.getDefaultLang() + '/maker/admin/experiencias'"
              class="btn btn-light mr-2 mb-lg-2 mb-3">{{
              'Cancelar'
              | translate}}</a>
            <!-- <button *ngIf="makerId" type="button" class="btn btn-primary mr-2 mb-2" (click)="updateMaker()">
              {{ 'Guardar datos' | translate}}
            </button> -->
            <button (click)="showTab = 'public'" type="button" class="btn btn-primary mb-2" (click)="createMaker()">
              {{ 'Continuar' | translate}}
            </button>
            <button *ngIf="!maker || maker && !maker['makerPlan']" type="button" class="btn btn-primary mb-2"
              (click)="createMaker()">
              {{ 'Continuar' | translate}}
            </button>
            <!--          <button *ngIf="!makerId" type="button" class="btn btn-primary mr-2 mb-2"-->
            <!--                  (click)="createMaker()" >-->
            <!--            {{ 'Guardar datos' | translate}}-->
            <!--          </button>-->

          </div>
        </div>
      </div>
      <div *ngIf="showTab == 'public'">
        <h4 class="border-bottom pb-2 mb-3"><i class="fa fa-eye small mr-1 text-black-50"></i> {{ 'Directorio' |
          translate}}</h4>
        <div class="row">
          <div class="col-12 form-group">
            <label>{{ 'Nombre comercial' | translate}} <span class="form-required text-danger">*</span></label>
            <input [placeholder]="translate.instant('Nombre empresa u organizador')" type="text" class="form-control"
              formControlName="name">
            <small class="text-danger" *ngIf="showError('name')">
              {{translate.instant(errors['makerName'])}}
            </small>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 form-group">
            <label>{{ 'Teléfono' | translate}} <span class="form-required text-danger">*</span></label>
            <input [placeholder]="translate.instant('Teléfono o móvil de contacto')" type="text" class="form-control"
              formControlName="phone">
            <small class="text-danger" *ngIf="showError('phone')">
              {{translate.instant(errors['phone'])}}
            </small>
          </div>
          <div class="col-md-6 form-group">
            <label>WhatsApp</label>
            <input placeholder="WhatsApp" type="text" class="form-control" formControlName="whatsapp">
            <small class="text-danger" *ngIf="showError('whatsapp')">
              {{translate.instant(errors['phone'])}}
            </small>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 form-group">
            <label>Email <span class="form-required text-danger">*</span></label>
            <input [placeholder]="translate.instant('Email de contacto')" type="text" class="form-control"
              formControlName="email">
            <small class="text-danger" *ngIf="showError('email')">
              {{translate.instant(errors['email'])}}
            </small>
          </div>
          <div class="col-md-6 form-group">
            <label>{{ 'Página web' | translate}} <span class="form-required text-danger">*</span></label>
            <input [placeholder]="translate.instant('Página web')" type="text" class="form-control"
              formControlName="web">
            <small class="text-danger" *ngIf="showError('web')">
              {{translate.instant(errors['makerWeb'])}}
            </small>
          </div>
        </div>
        <div class="row">
          <div class="col form-group">
            <label for="mediaCropper4">{{ 'Subir logo Maker' | translate}}
              <span class="form-required text-danger">*</span>
            </label>
            <small class="d-block text-muted mb-2">
              {{ 'Selecciona un logo de Maker. Formatos aceptados: .jpeg, .jpg, .png'| translate}}
            </small>
            <small class="form-text text-muted">{{ 'Dimensiones recomendadas:135/135px' | translate}}</small>
            <app-cropper-4 id="mediaCropper4" [type]="'maker'" [preview]="'preview4'"
              (uploadedImage)="onUploadedImage($event, 'mediaMakers')"></app-cropper-4>
            <!-- [aspectratio]="135/135" -->
            <small class="text-danger" *ngIf="showError('mediaMakers')">
              <!-- {{translate.instant(errors['mediaMakers'])}} -->
            </small>
          </div>
        </div>
        <div *ngIf="image" class="row mb-4">
          <p class="small">{{"Logo subido:" | translate}}</p>
          <!--        class="img-fluid"-->
          <img [src]="'https://venntur.com' + image" height="100" width="250"> <br />

          <!-- <p class="small">{{"Avatar subido:" | translate}}</p>
          <img [src]="'https://venntur.com' + image" height="30" width="80"> <br /> -->
        </div>
        <div class="row">
          <div class="col-12 text-lg-right text-center">
            <a [href]="translate.getDefaultLang() + '/maker/admin/experiencias'"
              class="btn btn-light mr-2 mb-lg-2 mb-3">{{
              'Cancelar'
              | translate}}</a>
            <button *ngIf="makerId" type="button" class="btn btn-primary mr-2 mb-2" (click)="updateMaker()">
              {{ 'Guardar datos' | translate}}
            </button>
            <button (click)="showTab = 'infoMaker'" type="button" class="btn btn-primary mb-2">
              {{ 'Continuar' | translate}}
            </button>
            <button *ngIf="!maker || maker && !maker['makerPlan']" type="button" class="btn btn-primary mb-2"
              (click)="createMaker()">
              {{ 'Continuar' | translate}}
            </button>
            <!--          <button *ngIf="!makerId" type="button" class="btn btn-primary mr-2 mb-2"-->
            <!--                  (click)="createMaker()" >-->
            <!--            {{ 'Guardar datos' | translate}}-->
            <!--          </button>-->
          </div>
        </div>
      </div>
      <div *ngIf="showTab == 'infoMaker'">
        <h4 class="border-bottom pb-2 mb-3"><i class="fa fa-user-circle small mr-1 text-black-50"></i> {{ 'Ficha
          Empresa' | translate}}</h4>
        <div class="row">
          <div class="col-md-6 form-group">
            <label>{{ 'País' | translate}} <span class="form-required text-danger">*</span></label>
            <ng-select [placeholder]="translate.instant('Seleccionar país')" formControlName="country"
              (change)="loadLocations($event)">
              <ng-option [value]="country.code" *ngFor="let country of countryList">{{country.text}}</ng-option>
            </ng-select>
            <small class="text-danger" *ngIf="showError('country')">
              <!-- {{translate.instant(errors['country'])}} -->
            </small>
          </div>

          <div class="col-md-6 form-group">
            <label>{{ 'Localidad' | translate}} <span class="form-required text-danger">*</span></label>
            <ng-select [placeholder]="translate.instant('Seleccionar la localidad')" aria-label="Location"
              formControlName="location" required [readonly]="!makerForm.get('country').value">
              <ng-option *ngFor="let location of locationList | async" [value]="location['@id']">{{
                location.getName() }}</ng-option>
            </ng-select>
            <small class="text-danger" *ngIf="showError('location')">
              <!-- {{translate.instant(errors['location'])}} -->
            </small>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 form-group">
            <label>{{ 'Dirección' | translate}} <span class="form-required text-danger">*</span></label>
            <input [placeholder]="translate.instant('Dirección')" type="text" class="form-control"
              formControlName="address">
            <small class="text-danger" *ngIf="showError('address')">
              {{translate.instant('La dirección es obligatoria')}}
            </small>
          </div>
          <div class="col-md-6 form-group">
            <label>{{ 'Código postal' | translate}} <span class="form-required text-danger">*</span></label>
            <input [placeholder]="translate.instant('Código Postal')" type="text" class="form-control"
              formControlName="postalCode" maxlength="5" inputmode="numeric" pattern="[0-9]*"
              (keyup)="validatePostalCode($event)">
              <small class="text-danger" *ngIf="showError('postalCode')">
                {{ translate.instant('El código postal es obligatorio') }}
              </small>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6 form-group">
            <label>{{ 'Sistema motor de reservas' | translate}} <span class="form-required text-danger">*</span></label>
            <ng-select bindLabel="Booking Engine" [placeholder]="translate.instant('Seleccionar motor de reservas')"
              formControlName="bookingEngine" (change)="bookingEngine = makerForm.get('bookingEngine').value">
              <ng-option value="Turitop">Turitop</ng-option>
              <ng-option value="Fareharbor">Fareharbor</ng-option>
              <ng-option value="Bokun">Bokun</ng-option>
              <ng-option value="Propio">Sistema propio</ng-option>
              <ng-option value="Others">Otros</ng-option>
            </ng-select>
            <small class="text-danger" *ngIf="showError('bookingEngine')">
            </small>
          </div>
          <div class="col-md-6 form-group">
            <label>
              {{ 'ID Sistema motor de reservas' | translate}}
              ({{ bookingEngine | translate }})
              <span class="form-required text-danger"></span></label>
            <input [placeholder]="translate.instant('ID Sistema motor de reservas')" type="text" class="form-control"
              formControlName="bookingEngineWidget">
            <small class="text-danger" *ngIf="showError('bookingEngineWidget')">
              {{translate.instant(errors['bookingEngineWidget'])}}
            </small>
          </div>
        </div>

        <div class="row">
          <div class="col form-group">
            <label>{{ 'Descripción' | translate}}<span class="form-required text-danger">*</span></label>
            <!-- <textarea [placeholder]="translate.instant('Descripción del maker')" class="form-control"
              formControlName="description" maxlength="{{ descriptionMaxChar }}" rows="3"></textarea> -->
            <!-- <editor apiKey="sjgwd4uj0fldb1yk4wv7846bmubr5yqqcq94k63mz61deiz3" [init]="{ 
                plugins: 'lists link image table code help wordcount', contextmenu: false}"
              [initialValue]="editorTemplate" formControlName="description"></editor> -->
            <ckeditor [editor]="Editor" formControlName="description"></ckeditor>
            <div class="mt-3" [innerHTML]="editorTemplateStaticData"></div>
            <span class="pull-right badge badge-dark mt-1">{{this.makerForm.get('description').value ?
              this.makerForm.get('description').value.length : 0}} / {{
              descriptionMaxChar }}</span>
            <small class="form-text text-muted">{{ 'Max. 1200 caracteres' | translate}}</small>
            <small class="text-danger" *ngIf="showError('description')">
              {{translate.instant(errors['description'])}}
            </small>
          </div>
        </div>
        <div class="row">
          <div class="col form-group">
            <label for="mediaCropper">{{ 'Subir cabecera para tu ficha Maker' | translate}} <span
                class="form-required text-danger">*</span> </label>
            <small class="d-block text-muted mb-2">{{ 'Selecciona una cabecera para mostrar en tu ficha pública de
              Maker. Formatos aceptados: .jpeg, .jpg, .png' | translate}}</small>
            <small class="form-text text-muted">{{ 'Dimensiones recomendadas:' | translate}} 760/505px</small>
            <app-cropper-2 id="mediaCropper2" [type]="'makerHeader'" [aspectratio]="760/505" [preview]="'preview2'"
              (uploadedImage)="onUploadedImage($event, 'mediaMakersHeader')"></app-cropper-2>
            <small class="text-danger" *ngIf="showError('mediaMakersHeader')">
              <!-- {{translate.instant(errors['mediaMakersHeader'])}} -->
            </small>
          </div>
        </div>
        <div *ngIf="headerImage" class="mb-4">
          <p class="small">{{"Cabecera subida a tu ficha:" | translate }}</p>
          <img [src]="'https://venntur.com' + headerImage" height="150" width="300" class="img-fluid"> <br />
        </div>

        <div class="row">
          <div class="col-12 text-lg-right text-center">
            <a [href]="translate.getDefaultLang() + '/maker/admin/experiencias'"
              class="btn btn-light mr-2 mb-lg-2 mb-3">{{
              'Cancelar'
              | translate}}</a>
            <button *ngIf="makerId" type="button" class="btn btn-primary mr-2 mb-2" (click)="updateMaker()">
              {{ 'Guardar datos' | translate}}
            </button>
            <!-- <button (click)="showTab = 'pin'" type="button" class="btn btn-primary mb-2">
              {{ 'Continuar' | translate}}
            </button> -->
            <button *ngIf="!maker || maker && !maker['makerPlan']" type="button" class="btn btn-primary mb-2"
              (click)="createMaker()">
              {{ 'Continuar' | translate}}
            </button>
          </div>
        </div>
      </div>

      <div *ngIf="showTab == 'pin'" class="d-flex flex-column" style="row-gap: 1rem;">
        <h4 class="border-bottom pb-2"><i class="fa fa-map-pin small mr-1 text-black-50"></i> {{ 'Venntur PIN' |
          translate}}</h4>
        <div id="accordion">
          <div class="card shadow">
            <div class="card-header p-0" id="headingOne">
              <h5 class="mb-0">
                <button class="btn btn-link w-100 p-3 text-left" data-toggle="collapse" data-target="#collapseOne"
                  aria-expanded="true" aria-controls="collapseOne">
                  <span>Datos de contacto</span>
                </button>
              </h5>
            </div>
            <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
              <div class="card-body">
                <div class="row">
                  <div class="col-md-6 form-group">
                    <label>{{ 'Email de administración' | translate}} <span
                        class="form-required text-danger"></span></label>
                    <input [placeholder]="translate.instant('Email de administración')" type="text" class="form-control"
                      formControlName="administrationEmail">
                    <small class="text-danger" *ngIf="showError('administrationEmail')">
                      {{translate.instant(errors['administrationEmail'])}}
                    </small>
                  </div>

                  <div class="col-md-6 form-group">
                    <label>{{ 'Teléfono 2' | translate}} <span class="form-required text-danger"></span></label>
                    <input [placeholder]="translate.instant('Teléfono 2')" type="text" class="form-control"
                      formControlName="phone2">
                    <small class="text-danger" *ngIf="showError('phone2')">
                      {{translate.instant(errors['phone2'])}}
                    </small>
                    <small class="form-text text-muted">{{ 'Teléfono de contacto 2' | translate}}</small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="false" id="accordion2">
          <div class="card shadow">
            <div class="card-header p-0" id="headingTwo">
              <h5 class="mb-0">
                <button class="btn btn-link w-100 p-3 text-left" data-toggle="collapse" data-target="#collapseTwo"
                  aria-expanded="true" aria-controls="collapseTwo">
                  <span>Datos Booking Engine</span>
                </button>
              </h5>
            </div>
            <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion2">
              <div class="card-body">
                <div class="row">
                  <!-- <div class="col-md-4 form-group">
                    <label>{{ 'Sistema motor de reservas' | translate}} <span
                        class="form-required text-danger">*</span></label>
                    <ng-select bindLabel="Booking Engine"
                      [placeholder]="translate.instant('Seleccionar motor de reservas')"
                      formControlName="bookingEngine" (change)="bookingEngine = makerForm.get('bookingEngine').value">
                      <ng-option value="Turitop">Turitop</ng-option>
                      <ng-option value="Fareharbor">Fareharbor</ng-option>
                      <ng-option value="Propio">Sistema propio</ng-option>
                      <ng-option value="Others">Otros</ng-option>
                    </ng-select>
                    <small class="text-danger" *ngIf="showError('bookingEngine')">
                    </small>
                  </div>
                  <div class="col-md-4 form-group">
                    <label>
                      {{ 'ID Sistema motor de reservas' | translate}}
                      ({{ bookingEngine | translate }})
                      <span class="form-required text-danger"></span></label>
                    <input [placeholder]="translate.instant('ID Sistema motor de reservas')" type="text"
                      class="form-control" formControlName="bookingEngineWidget">
                    <small class="text-danger" *ngIf="showError('bookingEngineWidget')">
                      {{translate.instant(errors['bookingEngineWidget'])}}
                    </small>
                  </div> -->
                  <div class="col-md-4 form-group">
                    <label>ID Opiniones Tripadvisor<span class="form-required text-danger"></span></label>
                    <input [placeholder]="translate.instant('Id de tripadvisor')" type="text" class="form-control"
                      formControlName="tripadvisor">
                    <small class="text-danger" *ngIf="showError('tripadvisor')">
                      {{translate.instant(errors['tripadvisor'])}}
                    </small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="accordion3">
          <div class="card shadow">
            <div class="card-header p-0" id="headingThree">
              <h5 class="mb-0">
                <button class="btn btn-link w-100 p-3 text-left" data-toggle="collapse" data-target="#collapseThree"
                  aria-expanded="true" aria-controls="collapseThree">
                  <span>Datos Social Media</span>
                </button>
              </h5>
            </div>
            <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordion3">
              <div class="card-body">
                <div class="row">
                  <div class="col-md-6 form-group">
                    <label>{{ 'LinkedIn' | translate}} <span class="form-required text-danger"></span></label>
                    <input [placeholder]="translate.instant('LinkedIn')" type="text" class="form-control"
                      formControlName="linkedIn">
                    <small class="form-text text-muted">{{ 'Usuario de' | translate}} LinkedIn(Ej:
                      company/12345678/admin/)</small>
                    <small class="text-danger" *ngIf="showError('linkedIn')">
                      {{translate.instant(errors['linkedIn'])}}
                    </small>
                  </div>
                  <div class="col-md-6 form-group">
                    <label>{{ 'Facebook' | translate}} <span class="form-required text-danger"></span></label>
                    <input [placeholder]="translate.instant('Facebook')" type="text" class="form-control"
                      formControlName="facebook">
                    <small class="form-text text-muted">{{ 'Nombre de usuario de' | translate}} Facebook</small>
                    <small class="text-danger" *ngIf="showError('facebook')">
                      {{translate.instant(errors['facebook'])}}
                    </small>
                  </div>
                  <div class="col-md-6 form-group">
                    <label>{{ 'Instagram' | translate}} <span class="form-required text-danger"></span></label>
                    <input [placeholder]="translate.instant('Instagram')" type="text" class="form-control"
                      formControlName="instagram">
                    <small class="form-text text-muted">{{ 'Nombre de usuario de' | translate}} Instagram</small>
                    <small class="text-danger" *ngIf="showError('instagram')">
                      {{translate.instant(errors['instagram'])}}
                    </small>
                  </div>
                  <div class="col-md-6 form-group">
                    <label>{{ 'Twitter' | translate}} <span class="form-required text-danger"></span></label>
                    <input [placeholder]="translate.instant('Twitter')" type="text" class="form-control"
                      formControlName="twitter">
                    <small class="form-text text-muted">{{ 'Nombre de usuario de' | translate}} Twitter</small>
                    <small class="text-danger" *ngIf="showError('twitter')">
                      {{translate.instant(errors['twitter'])}}
                    </small>
                  </div>
                  <div class="col-md-6 form-group">
                    <label>{{ 'Google Maps' | translate}} <span class="form-required text-danger"></span></label>
                    <input [placeholder]="translate.instant('Google Maps')" type="text" class="form-control"
                      formControlName="googleMaps">
                    <small class="text-danger" *ngIf="showError('googleMaps')">
                      {{translate.instant(errors['googleMaps'])}}
                    </small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="accordion4">
          <div class="card shadow">
            <div class="card-header p-0" id="headingFour">
              <h5 class="mb-0">
                <button class="btn btn-link w-100 p-3 text-left" data-toggle="collapse" data-target="#collapseFour"
                  aria-expanded="true" aria-controls="collapse">
                  <span>Datos condiciones</span>
                </button>
              </h5>
            </div>
            <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-parent="#accordion4">
              <div class="card-body">
                <div class="row">
                  <div class="col-md-6 form-group">
                    <label>{{ 'Condiciones de reserva' | translate}} <span
                        class="form-required text-danger"></span></label>
                    <input [placeholder]="translate.instant('Condiciones de reserva')" type="text" class="form-control"
                      formControlName="reservationConditions">
                    <small class="text-danger" *ngIf="showError('reservationConditions')">
                      {{translate.instant(errors['reservationConditions'])}}
                    </small>
                  </div>

                  <div class="col-md-6 form-group">
                    <label>{{ 'Condiciones de pago' | translate}} <span
                        class="form-required text-danger"></span></label>
                    <input [placeholder]="translate.instant('Condiciones de pago')" type="text" class="form-control"
                      formControlName="paymentConditions">
                    <small class="text-danger" *ngIf="showError('paymentConditions')">
                      {{translate.instant(errors['paymentConditions'])}}
                    </small>
                  </div>

                  <div class="col-md-6 form-group">
                    <label>{{ 'Condiciones de cancelación' | translate}} <span
                        class="form-required text-danger"></span></label>
                    <input [placeholder]="translate.instant('Condiciones de cancelación')" type="text"
                      class="form-control" formControlName="cancelationConditions">
                    <small class="text-danger" *ngIf="showError('cancelationConditions')">
                      {{translate.instant(errors['cancelationConditions'])}}
                    </small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="accordion5">
          <div class="card shadow">
            <div class="card-header p-0" id="headingFive">
              <h5 class="mb-0">
                <button class="btn btn-link w-100 p-3 text-left" data-toggle="collapse" data-target="#collapseFive"
                  aria-expanded="true" aria-controls="collapse">
                  <span>Multimedia</span>
                </button>
              </h5>
            </div>
            <div id="collapseFive" class="collapse" aria-labelledby="headingFive" data-parent="#accordion5">
              <div class="card-body">
                <div class="row">
                  <div class="col form-group">
                    <label for="mediaCropper" class="mb-0">{{ 'Seleccionar Imagenes del staff' | translate }}
                      <span class="form-required text-danger">
                      </span>
                    </label>
                    <small class="d-block text-muted mb-2">{{ 'Selecciona Imagenes del staff' | translate}} <strong>
                        {{ 'Mín. 1 y máx. 5 Imagenes.' | translate}}
                      </strong>
                      {{ 'Formatos aceptados: .jpeg, .jpg, .png' | translate}}
                    </small>
                    <small class="form-text text-muted">{{ 'Dimensiones recomendadas:' | translate}} 520x520</small>
                    <app-cropper-3 id="mediaCropper3" [type]="'makerStaff'" [aspectratio]="3/2" [preview]="'preview3'"
                      (uploadedImage)="onUploadedImage($event, 'mediaMakersStaff')"></app-cropper-3>
                    <small class="text-danger" *ngIf="showError('mediaMakersStaff')">
                      {{translate.instant(errors['mediaMakersStaff'])}}
                    </small>
                  </div>
                </div>
                <div *ngIf="mediaMakersStaff" class="container__uploaded-images">
                  <h5 *ngIf="mediaMakersStaff?.length"><i class="fa fa-image small"></i> {{ 'Imagenes subidas' |
                    translate}}
                  </h5>
                  <div class="row">
                    <div class="col-lg-2 col-md-4" *ngFor='let mediaMakerStaff of mediaMakersStaff'>
                      <img [src]="'https://venntur.com' + mediaMakerStaff.url" class="img-fluid"><br />
                      <button class="btn btn-outline-primary btn--delete-image"
                        (click)="deleteMediaMakersStaff(mediaMakerStaff.id, true)">
                        <i class="fa fa-trash"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="accordion6">
          <div class="card shadow">
            <div class="card-header p-0" id="headingSix">
              <h5 class="mb-0">
                <button class="btn btn-link w-100 p-3 text-left" data-toggle="collapse" data-target="#collapseSix"
                  aria-expanded="true" aria-controls="collapse">
                  <span>Documentos</span>
                </button>
              </h5>
            </div>
            <div id="collapseSix" class="collapse" aria-labelledby="headingSix" data-parent="#accordion6">
              <div class="card-body">
                <div class="row">
                  <div class="col-12 col-lg-6 col-md-6 form-group">
                    <label for="mediaCropper" class="mb-0">{{ 'Subir Póliza RRCC' | translate }}
                      <span class="form-required text-danger"></span></label>
                    <small class="d-block text-muted mb-2">
                      {{ 'Selecciona un PDF para mostrar en tu ficha pública de Maker. Formatos aceptados: .pdf' |
                      translate}}</small>

                    <!-- input type file pdf -->
                    <div class="custom-file">
                      <input type="file" class="custom-file-input" id="polizaRRCC" formControlName="polizaRRCC"
                        (change)="onFileSelected($event, 'Poliza RRCC')">
                      <label id="polizaRRCCLabel" for="polizaRRCC" class="custom-file-label"
                        [ngClass]="validPolizaRRCC ? 'success-border' : ''">
                        {{ validPolizaRRCC ? namePolizaRRCC : 'Seleccionar PDF Poliza RRCC' }}
                      </label>
                    </div>

                  </div>

                  <div class="col-12 col-lg-6 col-md-6 form-group">
                    <label for="mediaCropper" class="mb-0">{{ 'Subir Recibo RRCC' | translate }}
                      <span class="form-required text-danger"></span></label>
                    <small class="d-block text-muted mb-2">
                      {{ 'Selecciona un PDF para mostrar en tu ficha pública de Maker. Formatos aceptados: .pdf' |
                      translate}}</small>

                    <!-- input type file pdf -->
                    <div class="custom-file">
                      <input type="file" class="custom-file-input" id="reciboRRCC" formControlName="reciboRRCC"
                        (change)="onFileSelected($event, 'Recibo RRCC')">
                      <label class="custom-file-label" id="reciboRRCCLabel" for="reciboRRCC"
                        [ngClass]="validReciboRRCC ? 'success-border' : ''">
                        {{ validReciboRRCC ? nameReciboRRCC : 'Seleccionar PDF Recibo RRCC' }}
                      </label>
                    </div>
                  </div>
                </div>


                <div class="row">
                  <div class="col-12 col-lg-6 col-md-6 form-group">
                    <label for="mediaCropper" class="mb-0">{{ 'Subir Licencia actividad' | translate }}
                      <span class="form-required text-danger"></span></label>
                    <small class="d-block text-muted mb-2">
                      {{ 'Selecciona un PDF para mostrar en tu ficha pública de Maker. Formatos aceptados: .pdf' |
                      translate}}</small>

                    <!-- input type file pdf -->
                    <div class="custom-file">
                      <input type="file" class="custom-file-input" id="licenciaActividad"
                        formControlName="licenciaActividad" (change)="onFileSelected($event, 'Licencia de actividad')">
                      <label class="custom-file-label" id="licenciaActividadLabel" for="licenciaActividad"
                        [ngClass]="validLicenciaActividad ? 'success-border' : ''">
                        {{ validLicenciaActividad ? nameLicenciaActividad : 'Seleccionar PDF Licencia de actividad' }}
                      </label>
                    </div>
                  </div>

                  <div class="col-12 col-lg-6 col-md-6 form-group">
                    <label for="mediaCropper" class="mb-0">{{ 'Subir Plantilla contrato colaboración' | translate }}
                      <span class="form-required text-danger"></span></label>
                    <small class="d-block text-muted mb-2">
                      {{ 'Selecciona un PDF para mostrar en tu ficha pública de Maker. Formatos aceptados: .pdf' |
                      translate}}</small>

                    <!-- input type file pdf -->
                    <div class="custom-file">
                      <input type="file" class="custom-file-input" id="plantillaContratoColaboracion"
                        formControlName="plantillaContratoColaboracion"
                        (change)="onFileSelected($event, 'Plantilla de contrato de colaboración')">
                      <label class="custom-file-label" id="plantillaContratoColaboracionLabel"
                        for="plantillaContratoColaboracion"
                        [ngClass]="validPlantillaContratoColaboracion ? 'success-border' : ''">
                        {{ validPlantillaContratoColaboracion ? namePlantillaContratoColaboracion : 'Seleccionar PDF
                        Plantilla de contrato de colaboración' }}
                      </label>
                    </div>

                  </div>
                </div>


                <div class="row">
                  <div class="col-12 form-group">
                    <label for="mediaCropper" class="mb-0">{{ 'Subir Catálogo' | translate }}
                      <span class="form-required text-danger"></span></label>
                    <small class="d-block text-muted mb-2">
                      {{ 'Selecciona un PDF para mostrar en tu ficha pública de Maker. Formatos aceptados: .pdf' |
                      translate}}</small>

                    <!-- input type file pdf -->
                    <div class="custom-file">
                      <input type="file" class="custom-file-input" id="catalogos" formControlName="catalogos"
                        (change)="onFileSelected($event, 'Catalogos')">
                      <label class="custom-file-label" id="catalogosLabel" for="catalogos"
                        [ngClass]="validCatalogos ? 'success-border' : ''">
                        {{ validCatalogos ? nameCatalogos : 'Seleccionar PDF Catalogos' }}
                      </label>
                    </div>

                  </div>

                  <div class="col-12 form-group" *ngIf="selectedPdfFiles.length > 0">

                    <!-- forEach pdf -->
                    <div *ngFor="let pdf of selectedPdfFiles" class="my-1">

                      <i class="fa fa-file-pdf-o mx-2"></i>
                      <a target="_blank" rel="noopener noreferrer" [href]="pdf.contentUrl ? pdf.contentUrl : pdf.url">
                        {{pdf.typeOf}} ({{pdf.name}})
                      </a>

                      <button class="btn btn-outline-primary btn--delete-image" (click)="deletePdf(pdf.id, pdf)">
                        <i class="fa fa-trash"></i>
                      </button>

                    </div>


                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 text-lg-right text-center">
            <a [href]="translate.getDefaultLang() + '/maker/admin/experiencias'"
              class="btn btn-light mr-2 mb-lg-2 mb-3">{{
              'Cancelar'
              | translate}}</a>
            <button *ngIf="makerId" type="button" class="btn btn-primary mr-2 mb-2" (click)="updateMaker()">
              {{ 'Guardar datos' | translate}}
            </button>
            <button *ngIf="!maker || maker && !maker['makerPlan']" type="button" class="btn btn-primary mb-2"
              (click)="createMaker()">
              {{ 'Continuar' | translate}}
            </button>
            <button *ngIf="!makerId" type="button" class="btn btn-primary mr-2 mb-2" (click)="createMaker()">
              {{ 'Guardar datos' | translate}}
            </button>
          </div>
        </div>


      </div>

    </form>
    <div *ngIf="showTab == 'access'">
      <app-users></app-users>
    </div>



  </div>
</div>

<div class="mt-2" *ngIf="alert.message">
  <p class="alert" [class.alert-success]="alert.type == 1" [class.alert-danger]="alert.type == 2">{{alert.message}}
  </p>
</div>
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { Taker } from './taker.model';
import { TakerService } from '../../shared/taker.service';
import { errorMessages } from '../../shared/custom-validators';
import { AuthService } from '../../shared/auth.service';
import {TranslateService} from '@ngx-translate/core';
import {UsersService} from '../../../shared/users/users.service';
import {User} from '../../../shared/users/user.model';

@Component({
  selector: 'app-taker',
  templateUrl: './taker.component.html',
  styleUrls: ['./taker.component.css']
})
export class TakerComponent implements OnInit {
  takerForm: UntypedFormGroup;
  taker: Taker;
  title = 'Perfil taker';
  alert = {message: null, type: null};
  errors = errorMessages;
  takerId = null;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private userService: UsersService,
    private titleService: Title,
    private takerService: TakerService,
    public translate: TranslateService
  ) {
    if (this.takerService.getId()) {
      this.title = 'Editar perfil taker';
      this.takerId = '/takers/' + this.takerService.getId();
    }
    this.createForm();
  }

  ngOnInit(): void {
    if (this.takerId) {
      this.editTaker(this.takerId);
    }
  }

  createForm() {
    this.takerForm = this.formBuilder.group({
      name: [ '',
        Validators.required
      ],
      surname: [ '',
        Validators.required
      ],
      user: ['/users/' + this.authService.getUserId()]
    });
  }

  createTaker() {
    if (this.takerForm.invalid) {
      this.showErrors(this.takerForm);
      return;
    }
    const taker = {
      name: this.takerForm.get('name').value,
      surname: this.takerForm.get('surname').value,
      user: this.takerForm.get('user').value
    };
    this.takerService.postTaker(taker).subscribe(() => {
      this.alert.type = 1;
      this.alert.message = 'Taker creado correctamente';
      setTimeout(() => {
        this.alert = {message: null, type: null};
      }, 5000);
      const roles = this.authService.roles();
      if (!roles.includes('ROLE_TAKER')) {
        roles.push('ROLE_TAKER');
      }
      const editedUser = new User({roles: roles});
      this.userService.putUser(this.authService.getUserId(), editedUser).subscribe(currentUser => {
        localStorage.setItem('user', JSON.stringify(currentUser));
      });
      this.takerService.getTakerByUserId(this.authService.getUserId()).subscribe(currentTaker => {
        this.taker = currentTaker;
        this.takerId = currentTaker.id;
        localStorage.setItem('taker', JSON.stringify({id: currentTaker.id , name: currentTaker.name}));
      });
    }, (error) => {
      this.alert.message = error.error.code + '- ' + error.error.message;
      this.alert.type = 2;
      setTimeout(() => {this.alert = {message: null, type: null};
      }, 5000);
    });
  }

  editTaker(takerId) {
    this.takerService.getTaker(takerId).subscribe((taker: Taker) => {
      this.taker = taker;
      this.takerForm.get('name').setValue(taker.name);
      this.takerForm.get('surname').setValue(taker.surname);
    });
  }

  updateTaker() {
    if (this.takerForm.invalid) {
      this.showErrors(this.takerForm);
      return;
    }
    const taker = {
      name: this.takerForm.get('name').value,
      surname: this.takerForm.get('surname').value
    };
    const editedTaker = new Taker(taker);
    this.takerService.putTaker(this.taker.id, editedTaker)
      .subscribe(
        (result) => {
          this.alert.type = 1;
          this.alert.message = 'Taker guardado correctamente';
          setTimeout(() => {
            this.alert = {message: null, type: null};
          }, 5000);
        },
        error => {
          this.alert.message = error.error.code + '- ' + error.error.message;
          this.alert.type = 2;
          setTimeout(() => {this.alert = {message: null, type: null};
          }, 5000);
        },
        () => console.log('complete'));
  }

  showErrors(formGroup) {
    Object.keys(formGroup.controls).forEach(key => {
      formGroup.get(key).markAsTouched();
    });
    return;
  }

  showError(formValue) {
    return this.takerForm.get(formValue).invalid && this.takerForm.get(formValue).touched;
  }
}

import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';

import { TranslateService } from '@ngx-translate/core';

import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { errorMessages, regExps } from '../../../shared/custom-validators';
import { ResellerService } from '../../../shared/reseller.service';
import { MediaResellerService } from '../../reseller-profile/media-reseller';
import { environment } from '../../../../../environments/environment';

const FRONT_URL = environment.frontUrl;

@Component({
  selector: 'app-reseller-contacts',
  templateUrl: './reseller-contacts.component.html',
  styleUrls: ['./reseller-contacts.component.css']
})
export class ResellerContactsComponent implements OnInit {
  contactForm: UntypedFormGroup;
  contactImage = null;
  selectedFile: File;
  selectedFiles = [];
  uploadedFiles = [];
  uploadingProgressing = false;
  uploadComplete = false;
  serverResponse: any;
  uploadProgress = 0;
  alert = { message: null, type: null };
  errors = errorMessages;
  reseller = null;
  contact = null;
  title = 'Nuevo contacto';

  dialogRef: BsModalRef;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private mediaResellerService: MediaResellerService,
    private resellerService: ResellerService,
    public translate: TranslateService,
    public dialog: BsModalService
  ) {
    if (this.resellerService.getId()) {
      this.reseller = '/resellers/' + this.resellerService.getId();
      this.createForm();
    } else {
      this.router.navigateByUrl('/reseller/admin/perfil');
    }
  }

  ngOnInit(): void {
    if (this.activatedRoute.snapshot.params.id) {
      this.title = 'Editar contacto';
      setTimeout(() => {
        this.editContact(this.activatedRoute.snapshot.params.id);
      }, 1000);
    }
  }

  createForm() {
    this.contactForm = this.formBuilder.group({
      mediaContacts: ['',
        Validators.required
      ],
      name: ['',
        Validators.required
      ],
      position: ['',
        Validators.required
      ],
      email: ['', [
        Validators.required,
        Validators.email
      ]
      ],
      phone: ['', [
        Validators.required,
        Validators.pattern(regExps.phone)
      ]
      ],
      reseller: [this.reseller]
    });
  }

  editContact(contactId) {
    this.resellerService.getContact(contactId, this.translate.getDefaultLang()).subscribe((contact: any) => {
      this.contact = contact;
      this.contactForm.get('name').setValue(contact.name);
      this.contactForm.get('position').setValue(contact.position);
      this.contactForm.get('email').setValue(contact.email);
      this.contactForm.get('phone').setValue(contact.phone);
      if (contact.mediaContacts.length > 0) {
        this.contactForm.get('mediaContacts').setValue([contact.mediaContacts[0]['@id']]);
        this.contactImage = '/assets/makerContact/files/' + contact.mediaContacts[0]['contentUrl'];
      }
    });
  }

  updateContact() {
    if (this.contactForm.invalid) {
      this.showErrors(this.contactForm);
      return;
    }
    const editedContact = {
      name: this.contactForm.get('name').value,
      position: this.contactForm.get('position').value,
      email: this.contactForm.get('email').value,
      phone: this.contactForm.get('phone').value,
      mediaContacts: this.contactForm.get('mediaContacts').value,
      reseller: this.contactForm.get('reseller').value,
    };
    this.resellerService.putContact(this.contact.id, editedContact, this.translate.getDefaultLang())
      .subscribe(
        (result) => {
          this.alert.type = 1;
          this.alert.message = 'Contacto guardado correctamente';
          setTimeout(() => {
            this.alert = { message: null, type: null };
          }, 5000);
          this.contact = null;
          this.createForm();
          location.replace(this.translate.getDefaultLang() + '/reseller/admin/contactos');
        },
        error => {
          this.alert.message = error.message;
          this.alert.type = 2;
          setTimeout(() => {
            this.alert = { message: null, type: null };
          }, 5000);
        },
        () => console.log('complete'));
  }

  createContact() {
    if (this.contactForm.invalid) {
      this.showErrors(this.contactForm);
      return;
    }
    const contact = {
      mediaContacts: this.contactForm.get('mediaContacts').value,
      name: this.contactForm.get('name').value,
      position: this.contactForm.get('position').value,
      email: this.contactForm.get('email').value,
      phone: this.contactForm.get('phone').value,
      reseller: this.contactForm.get('reseller').value
    };
    this.resellerService.postContact(contact).subscribe(() => {
      this.alert.type = 1;
      this.alert.message = this.translate.instant('Contacto creado correctamente');
      setTimeout(() => {
        location.replace(this.translate.getDefaultLang() + '/reseller/admin/contactos');
        this.alert = { message: null, type: null };
      }, 5000);
    }, (error) => {
      this.alert.message = error.error.code + '- ' + error.error.message;
      this.alert.type = 2;
      setTimeout(() => {
        this.alert = { message: null, type: null };
      }, 5000);
    });
  }

  onFileChanged(event) {
    if (event.target.files[0].type === 'image/jpeg' || event.target.files[0].type === 'image/png') {
      this.selectedFile = event.target.files[0];
    }
  }

  onUploadedImage(uploadedImage: object, formControlName: string) {
    if (formControlName === 'mediaContacts') {
      this.contactForm.get(formControlName).patchValue([uploadedImage['id']]);
      this.contactImage = FRONT_URL + uploadedImage['url'];
    }
  }

  showErrors(formGroup) {
    Object.keys(formGroup.controls).forEach(key => {
      formGroup.get(key).markAsTouched();
    });
    return;
  }

  showError(formValue) {
    return this.contactForm.get(formValue).invalid && this.contactForm.get(formValue).touched;
  }

  changeLanguage(language) {
    this.translate.setDefaultLang(language);
    setTimeout(() => {
      this.editContact(this.activatedRoute.snapshot.params.id);
    }, 100);
  }

}

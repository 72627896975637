import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Maker, MakerCollection } from '../maker/maker-profile/maker.model';
import { LocationCollection } from '../../shared/locations/location.model';

const API_URL = environment.apiUrl;
const CMS_API_URL = environment.cmsApiUrl;

@Injectable()
export class MakerService {
  constructor(private http: HttpClient) { }

  public getMakers(filters?: any): Observable<MakerCollection> {
    if (filters === undefined) { filters = {}; }

    let httpParams = new HttpParams();

    if (filters['name']) {
      httpParams = httpParams.set('name', String(filters.name));
    }
    if (filters['document']) {
      httpParams = httpParams.set('document', String(filters.document));
    }
    if (filters['phone']) {
      httpParams = httpParams.set('phone', String(filters.phone));
    }
    if (filters['email']) {
      httpParams = httpParams.set('email', String(filters.email));
    }
    if (filters['web']) {
      httpParams = httpParams.set('web', String(filters.web));
    }
    if (filters['locations']) {
      filters.locations.forEach(location =>
        httpParams = httpParams.append('experiences.location.id[]', location)
      );
    }
    if (filters['isFavorites']) {
      httpParams = httpParams.append('resellers.id[]', String(filters.resellerId));
    }
    if (filters['isFavoritesProducts']) {
      httpParams = httpParams.append('experiences.resellerFavoriteExperiences.reseller.id', String(filters.resellerId));
    }

    if (filters['categorias']) {
      filters.categorias.split(',').forEach(category =>
        httpParams = httpParams.append('experiences.categories.id[]', category)
      );
    }

    if (filters['localidad']) {
      httpParams = httpParams.append('location', String(filters.localidad));
    }

    if (filters['page']) {
      httpParams = httpParams.append('page', String(filters.page));
    }

    return this.http
      .get<MakerCollection>(API_URL + '/makers?directoryCompleted=true&order[makerPlan.plan.level]' , { params: httpParams });
  }

  public getMakersByUrl(filters: any, language): Observable<MakerCollection> {
    let httpParams = new HttpParams();

    filters.forEach((value, key) => {
      if (key == 'categorias') {
        value.split(',').forEach(category =>
          httpParams = httpParams.append('experiences.categories.id[]', category)
        );
      }
      if (key == 'localidad') {
        httpParams = httpParams.set('location', String(value));
      }
      if (key == 'page') {
        httpParams = httpParams.append('page', String(value));
      }
    });

    return this.http
      .get<MakerCollection>(API_URL + '/makers?directoryCompleted=true&order[makerPlan.plan.level]' , { params: httpParams });
  }


  public getMakerByUserId(userId: number): Observable<any> {
    return this.http
      .get<any>(API_URL + '/users/' + userId + '/maker');
  }
  
  public getMakerById(makerId: number): Observable<any> {
    return this.http
      .get<any>(API_URL + '/makers/' + makerId);
  }

  public getMakersByResellerId(resellerId: string, filters: any): Observable<MakerCollection> {
    if (filters === undefined) { filters = {}; }

    let httpParams = new HttpParams();

    if (filters['name']) {
      httpParams = httpParams.set('maker.name', String(filters.name));
    }
    if (filters['phone']) {
      httpParams = httpParams.set('maker.phone', String(filters.phone));
    }
    if (filters['email']) {
      httpParams = httpParams.set('maker.email', String(filters.email));
    }
    if (filters['web']) {
      httpParams = httpParams.set('maker.web', String(filters.web));
    }
    if (filters['locations']) {
      filters.locations.forEach(location =>
        httpParams = httpParams.append('maker.experiences.location.id[]', location)
      );
    }
    if (filters['isFavoritesProducts']) {
      httpParams = httpParams.append('maker.experiences.resellerFavoriteExperiences.reseller.id', resellerId);
    }
    return this.http
      .get<MakerCollection>(API_URL + '/resellers/' + resellerId + '/favorite_makers', { params: httpParams });
  }

  public getMaker(makerId: string, language = 'es'): Observable<any> {
    return this.http
      .get<any>(API_URL + makerId, {
        headers: {
          'Accept-Language': language
        }
      });
  }

  public getMakerBySlug(makerSlug: string, language = 'es'): Observable<any> {
    return this.http
      .get<any>(API_URL + '/makers/?slug=' + makerSlug, {
        headers: {
          'Accept-Language': language
        }
      });
  }

  public getInvoices(stripeCustomerId): Observable<any> {
    return this.http
      .get<any>(API_URL + '/maker_plans/invoices/' + stripeCustomerId);
  }

  public getInvoicePdf(stripeCustomerId): Observable<any> {
    return this.http
      .get<any>(API_URL + '/maker_plans/invoice/' + stripeCustomerId + '/pdf');
  }

  public getPlans() {
    return this.http
      .get<any>(API_URL + '/plans');
  }

  public getPlanByMakerId(id) {
    return this.http
      .get<any>(API_URL + '/plans/' + id + '/maker_plans');
  }

  // public getInvoices(stripeCustomerId): Observable<any> {
  //   return this.http
  //     .get<any>('https://api.stripe.com/v1/invoices',  { headers: {
  //         Authorization: `Bearer sk_test_ZZVWqw6sdmB1TkkjxGpVucGd`
  //       }, params: {customer: stripeCustomerId}});
  // }

  public getName() {
    return JSON.parse(localStorage.getItem('maker')) ? JSON.parse(localStorage.getItem('maker'))['name'] : null;
  }
  
  public getUserMail() {
    return JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user'))['email'] : null;
  }

  public getId() {
    return JSON.parse(localStorage.getItem('maker')) ? JSON.parse(localStorage.getItem('maker'))['id'] : null;
  }
  /*   public getGroupId() {
      return JSON.parse(localStorage.getItem('group')) ? JSON.parse(localStorage.getItem('group'))['id'] : null;
    } */

  public putMaker(makerId: string, maker: Maker, language = 'es'): Observable<Maker> {
    return this.http
      .put<Maker>(API_URL + '/makers/' + makerId, maker, {
        headers: {
          'Accept-Language': language
        }
      });
  }

  public postMaker(maker): Observable<Maker> {
    return this.http
      .post<Maker>(API_URL + '/makers', maker);
  }

  public postContact(contact): Observable<any> {
    return this.http
      .post<any>(API_URL + '/contacts', contact);
  }
  public postGroup(group): Observable<any> {
    return this.http
      .post<any>(API_URL + '/contact_groups', group);
  }

  public postFreeMakerPlan(makerPlan): Observable<object> {
    return this.http
      .post<any>(API_URL + '/maker_plans', makerPlan);
  }

  public sendFreeMakerPlanEmail(makerPlan): Observable<object> {
    return this.http
      .post<any>(API_URL + '/maker_plans/send_free_maker_plan_email', makerPlan);
  }

  public postMakerContact(makerContact): Observable<object> {
    return this.http
      .post<any>('https://hooks.zapier.com/hooks/catch/8831384/3pqn9kv', makerContact, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      });
  }

  public deleteContact(contactId: string): any {
    return this.http.delete<any>(API_URL + contactId);
  }
  public deleteGroup(contact_groupsId: string): any {
    return this.http.delete<any>(API_URL + contact_groupsId);
  }

  public getStripeCustomer(stripeCustomerId: string): Observable<any> {
    return this.http
      .get<any>(API_URL + '/stripe_customer/' + stripeCustomerId);
  }

  public getContact(contactId: string, language = 'es'): Observable<any> {
    return this.http
      .get<any>(API_URL + '/contacts/' + contactId, {
        headers: {
          'Accept-Language': language
        }
      });
  }
  public getGroup(contact_groupsId: string, language = 'es'): Observable<any> {
    return this.http
      .get<any>(API_URL + '/contact_groups/' + contact_groupsId, {
        headers: {
          'Accept-Language': language
        }
      });
  }

  public getGroups(): Observable<any> {
    return this.http
      .get<any>(API_URL + '/contact_groups'
      );
  }

  public getContactsByMakerId(makerId: number, filters?: any, language = 'es'): Observable<any> {
    if (filters === undefined) { filters = {}; }

    let httpParams = new HttpParams();

    if (filters['name']) {
      httpParams = httpParams.set('name', String(filters.name));
    }
    if (filters['group']) {
      httpParams = httpParams.set('contactGroups.name', String(filters.group));
    }

    return this.http
      .get<MakerCollection>(API_URL + '/makers/' + makerId + '/contacts',
        {
          params: httpParams, headers: {
            'Accept-Language': language
          }
        });
  }
  public getContactsGroupsByMakerId(makerId: number): Observable<any> {
    return this.http
      .get<any>(API_URL + '/makers/' + makerId + '/contacts_groups');
  }

  public putContact(contactId: string, contact, language = 'es'): Observable<any> {
    return this.http
      .put<Maker>(API_URL + '/contacts/' + contactId, contact, {
        headers: {
          'Accept-Language': language
        }
      });
  }
  public putGroup(contact_groupsId: string, group, language = 'es'): Observable<any> {
    return this.http
      .put<any>(API_URL + '/contact_groups/' + contact_groupsId, group, {
        headers: {
          'Accept-Language': language
        }
      });
  }

  public putGroupIri(contact_groupsId: string, group, language = 'es'): Observable<any> {
    return this.http
      .put<any>(API_URL + contact_groupsId, group, {
        headers: {
          'Accept-Language': language
        }
      });
  }

  public postFavoriteReseller(favoriteReseller): Observable<any> {
    return this.http
      .post<any>(API_URL + '/favorite_resellers', favoriteReseller);
  }

  public deleteFavoriteReseller(favoriteResellerId: string): any {
    return this.http.delete<any>(API_URL + favoriteResellerId);
  }

  public putFavoriteReseller(favoriteResellerId: string, favoriteReseller: any): Observable<any> {
    return this.http
      .put<any>(API_URL + favoriteResellerId, favoriteReseller);
  }

  public postDirectoryAnalytics(directoryAnalytics): Observable<any> {
    return this.http
      .post<any>(API_URL + '/directory_analytics', directoryAnalytics);
  }

  public postPdfFile(file): Observable<any> {
    return this.http
      .post<any>(API_URL + '/media_makers_docs', file);
  }

}

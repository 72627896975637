<div class="row justify-content-between">
    <div class="col-md-8 d-md-flex align-items-center">
        <h1 class="page-title m-0 mr-3">
            <i class="fa fa-binoculars small mr-3"></i>
            {{ translate.instant(title) + ' ' + experienceName }}
        </h1>
    </div>
</div>
<div class="my-5 d-flex justify-content-between">
    <div class="d-flex flex-column justify-content-between">
        <h5>{{ 'Buscar por:' | translate}} </h5>
        <form [formGroup]="searchForm" (submit)="applyFilter()">
            <div class="d-flex flex-column flex-lg-row mr-3 mr-lg-0">
                <div class="mr-lg-3 mb-2 mb-lg-0">
                    <input [placeholder]="translate.instant('Contenido')" type="text" formControlName="comments"
                        class="form-control form-control-sm">
                </div>
                <button type="submit" class="btn btn-success btn-sm mt-sm-0 mt-2 search-button mr-3">{{ 'Buscar' |
                    translate}}</button>
            </div>
        </form>
    </div>
</div>
<div class="card mt-2">
    <div class="card-header">
        <strong>Reviews por producto:</strong>
    </div>
    <div class="card-body p-0">
        <ngx-datatable #table [sorts]="[{ prop: 'rating', dir: 'desc' }]" class="bootstrap expandable d-none d-lg-block"
            [rowHeight]="'auto'" [columns]="columns" [columnMode]="'force'" [headerHeight]="90" [footerHeight]="50"
            [limit]="maxTableRows" [rows]="rows">
            <ngx-datatable-column [width]="90" [resizeable]="true" [sortable]="true" [draggable]="false"
                [canAutoResize]="true" headerClass="text-left" cellClass="text-left"
                [name]="translate.instant('Rating')" prop="rating">
                <ng-template let-column="column" ngx-datatable-header-template>
                    <span class="datatable-header-cell-label">{{ column.name }}</span>
                </ng-template>
                <ng-template let-value="value" ngx-datatable-cell-template>
                    <span *ngFor="let value of [].constructor(value)" class="vi-star-full"></span>
                    <span *ngFor="let value of [].constructor(5-value)" class="vi-star-empty"></span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column *ngFor="let column of columns; let i = index;"
                name="{{translate.instant(column.name)}}" prop="{{column.prop}}" width="{{column.width}}"
                [minWidth]="100">
            </ngx-datatable-column>
            <ngx-datatable-column [width]="90" [resizeable]="true" [sortable]="true" [draggable]="false"
                [canAutoResize]="true" headerClass="text-left" cellClass="text-left"
                [name]="translate.instant('Fecha')" prop="createdAt">
                <ng-template let-column="column" ngx-datatable-header-template>
                    <span class="datatable-header-cell-label">{{ column.name }}</span>
                </ng-template>
                <ng-template let-value="value" ngx-datatable-cell-template>
                    {{formatDateString(value)}}
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [width]="90" [resizeable]="true" [sortable]="false" [draggable]="false"
                [canAutoResize]="true" headerClass="d-none d-sm-block text-right" cellClass="text-right"
                [name]="translate.instant('Acciones')" prop="actions">
                <ng-template let-column="column" let-sort="sortFn" ngx-datatable-header-template>
                    <span class="datatable-header-cell-label d-none d-sm-block">{{ column.name }}</span>
                </ng-template>
                <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                    <button (click)="setReviewStatus(row['@id'],'validated')" id="btn-validate"
                        [ngClass]="row.status == 'validated' ? 'validated' : '' "
                        class="btn btn-sm btn-validate d-none d-sm-inline-block mr-1"
                        [tooltip]="translate.instant('Publicar')" placement="bottom">
                        <span class="vi-thumbs-up"></span>
                    </button>
                    <button (click)="setReviewStatus(row['@id'],'denied')" id="btn-cancel"
                        [ngClass]="row.status == 'denied' ? 'denied' : '' " class="btn btn-sm btn-cancel mr-1"
                        [tooltip]="translate.instant('Ocultar')" placement="bottom">
                        <span class="vi-thumbs-down"></span>
                    </button>
                </ng-template>
            </ngx-datatable-column>
        </ngx-datatable>
        <ngx-datatable #table class="bootstrap expandable d-lg-none d-block" [rowHeight]="'auto'" [columns]="columns"
            [columnMode]="'force'" [headerHeight]="90" [footerHeight]="50" [limit]="3" [rows]="rows">
            <ngx-datatable-column name="{{'Nombre'}}" prop="{{'name'}}">
            </ngx-datatable-column>
            <ngx-datatable-column [resizeable]="true" [sortable]="false" [draggable]="false" [canAutoResize]="true"
                [name]="translate.instant('Acciones')" prop="actions">
                <ng-template let-column="column" let-sort="sortFn" ngx-datatable-header-template>
                    <span class="datatable-header-cell-label">{{ column.name }}</span>
                </ng-template>
                <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                    <button (click)="setReviewStatus(row['@id'],'validated')" id="btn-validate"
                        [ngClass]="row.status == 'validated' ? 'validated' : '' " class="btn btn-sm btn-validate mr-1"
                        [tooltip]="translate.instant('Publicar')" placement="bottom">
                        <span class="vi-thumbs-up"></span>
                    </button>
                    <button (click)="setReviewStatus(row['@id'],'denied')" id="btn-cancel"
                        [ngClass]="row.status == 'denied' ? 'denied' : '' " class="btn btn-sm btn-cancel"
                        [tooltip]="translate.instant('Ocultar')" placement="bottom">
                        <span class="vi-thumbs-down"></span>
                    </button>
                </ng-template>
            </ngx-datatable-column>
        </ngx-datatable>
    </div>
</div>
import { Injectable, Inject, Renderer2, RendererFactory2, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class JsonLdService {
  private renderer: Renderer2;

  constructor(
    private rendererFactory: RendererFactory2,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
  }

  setData(schema: any): void {
    if (!isPlatformBrowser(this.platformId)) {
      return; // Ensure this code only runs in the browser
    }

    // Serialize the schema into JSON
    const jsonLDContent = JSON.stringify(schema, null, 2);

    // Check if a script with the same content already exists
    const existingScripts = Array.from(document.head.querySelectorAll('script[type="application/ld+json"]'));
    const isAlreadyPresent = existingScripts.some((script) => script.textContent === jsonLDContent);

    if (isAlreadyPresent) {
      console.log("YA TIENE SCHEMA");
      return; // Avoid appending duplicate scripts
    }

    console.log("Sending schema", jsonLDContent);

    // Create a new script element
    const script = this.renderer.createElement('script');
    this.renderer.setAttribute(script, 'type', 'application/ld+json');
    this.renderer.setProperty(script, 'textContent', jsonLDContent);

    // Append the script to the <head>
    this.renderer.appendChild(document.head, script);
  }

  clearAllData(): void {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }

    // Remove all existing JSON-LD scripts
    const existingScripts = document.head.querySelectorAll('script[type="application/ld+json"]');
    existingScripts.forEach((script) => this.renderer.removeChild(document.head, script));
  }
}

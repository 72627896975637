import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FacebookLoginProvider, GoogleLoginProvider, SocialAuthService, SocialUser } from 'angularx-social-login';
import { User } from '../../../shared/users/user.model';
import { UsersService } from '../../../shared/users/users.service';
import { AuthService } from '../../shared/auth.service';
import { errorMessages, regExps } from '../../shared/custom-validators';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../../environments/environment';
import { ResellerService } from '../../shared/reseller.service';

const FRONT_URL = environment.frontUrl;

@Component({
  selector: 'app-register-reseller',
  templateUrl: './register-reseller.component.html',
})
export class RegisterResellerComponent implements OnInit {
  userForm: UntypedFormGroup;
  user: User;
  alert = {message: null, type: null};
  errors = errorMessages;
  plan: string;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private socialAuthService: SocialAuthService,
    private userService: UsersService,
    private resellerService: ResellerService,
    public translate: TranslateService
  ) {
    this.createForm();
  }

  ngOnInit(): void {
  }

  createForm() {
    this.userForm = this.formBuilder.group({
      email: [ '', [
        Validators.required,
        Validators.email
      ]],
      password: [ '', [
        Validators.required,
        Validators.pattern(regExps.password)
      ]
      ],
      role: ['ROLE_RESELLER', Validators.required]
    });
  }

  createUser() {
    if (this.userForm.invalid) {
      this.showErrors(this.userForm);
      return;
    }
    const user = {
      email: this.userForm.get('email').value,
      password: this.userForm.get('password').value,
      roles: this.userForm.get('role').value
    };
    this.userService.postUser(user).subscribe((result) => {
      this.alert.type = 1;
      this.alert.message = 'Usuario creado correctamente';
      setTimeout(() => {
        this.alert = {message: null, type: null};
        this.authService.login(user.email, user.password)
          .then(() => {
            if (user.roles.includes('ROLE_RESELLER')) {
              this.router.navigateByUrl(this.translate.getDefaultLang() + '/reseller/admin/perfil');
            }
            // } else if (user.roles.includes('ROLE_TAKER')) {
            //   this.router.navigateByUrl(this.translate.getDefaultLang() + '/taker/admin/perfil');
            // }
          })
          .catch();
      }, 1000);
      this.createForm();
    }, (error) => {
      this.alert.message = error.error.message;
      this.alert.type = 2;
      setTimeout(() => {this.alert = {message: null, type: null};
      }, 5000);
    });
  }

  signInWithFB(): void {
    this.socialAuthService.signIn(FacebookLoginProvider.PROVIDER_ID).then((user: SocialUser) => {
      this.authService.loginByFacebook(user, 'ROLE_RESELLER')
        .then(
          (response) => {
            this.router.navigateByUrl(this.translate.getDefaultLang() + '/reseller/admin/perfil');
          },
          (error) => {
            this.alert.type = 2;
            this.alert.message = 'Ocurrió un error al intentar registrarte con Facebook, por favor vuelve a intentarlo';
          });
    });
  }

  signInWithGoogle(): void {
    this.socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID).then((user: SocialUser) => {
      this.authService.loginByGoogle(user.idToken, 'ROLE_RESELLER')
        .then(
          (response) => {
            this.router.navigateByUrl(this.translate.getDefaultLang() + '/reseller/admin/perfil');
          },
          (error) => {
            this.alert.type = 2;
            this.alert.message = 'Ocurrió un error al intentar registrarte con Google, por favor vuelve a intentarlo';
          });
    });
  }

  showErrors(formGroup) {
    Object.keys(formGroup.controls).forEach(key => {
      formGroup.get(key).markAsTouched();
    });
    return;
  }

  showError(formValue) {
    return this.userForm.get(formValue).invalid && this.userForm.get(formValue).touched;
  }
}

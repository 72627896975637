<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <div class="container justify-content-end px-3">
    <button class="navbar-toggler btn btn-light rounded-circle p-1" (click)="isCollapsed = !isCollapsed"
        [attr.aria-expanded]="!isCollapsed" aria-controls="collapseBasic">
      <i-feather name="menu"></i-feather>
    </button>
    <div class="collapse navbar-collapse" id="collapseBasic" [collapse]="isCollapsed">
      <ul class="navbar-nav ml-auto mb-2 mt-2 my-lg-0 small text-right">
        <li class="nav-item">
          <a class="nav-link" [href]="translate.getDefaultLang() + '/precios-venntur'">{{ 'Planes' | translate }}</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [href]="translate.getDefaultLang() + '/blog'">{{ 'Blog' | translate }}</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [href]="translate.getDefaultLang() + '/contacto'">{{ 'Contacto' | translate }}</a>
        </li>
      </ul>
    </div>
  </div>
</nav>

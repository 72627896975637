<h1 class="page-title"><i class="fa fa-user small"></i> {{title}}</h1>
<div class="row justify-content-center" *ngIf="!taker">
  <p class="alert alert-danger">{{ 'Completa los datos del taker para poder añadir favoritos' | translate}}</p>
</div>
<div *ngIf="alert.message">
  <p class="alert" [class.alert-success]="alert.type == 1" [class.alert-danger]="alert.type == 2">{{alert.message}}</p>
</div>
<div class="card mt-4">
  <div class="card-body p-4">
    <form [formGroup]="takerForm" class="maker-admin-form">
      <h4 class="border-bottom pb-2 my-3"><i class="fa fa-eye small mr-1"></i> {{ 'Datos públicos' | translate}}</h4>
      <div class="row">
        <div class="col-md-6 form-group">
          <label>{{ 'Nombre' | translate}} <span class="form-required text-danger">*</span></label>
          <input placeholder="Nombre" type="text" class="form-control" formControlName="name">
          <small class="text-danger" *ngIf="showError('name')">
            {{errors['takerName']}}
          </small>
        </div>
        <div class="col-md-6 form-group">
          <label>{{ 'Apellido' | translate}} <span class="form-required text-danger">*</span></label>
          <input placeholder="Apellido" type="text" class="form-control" formControlName="surname">
          <small class="text-danger" *ngIf="showError('surname')">
            {{errors['takerSurname']}}
          </small>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <button *ngIf="takerId" type="button" class="btn btn-primary"
                  (click)="updateTaker()" >
            {{ 'Guardar datos' | translate}}
          </button>
          <button *ngIf="!takerId" type="button" class="btn btn-primary"
                  (click)="createTaker()" >
            {{ 'Guardar datos' | translate}}
          </button>
          <a [href]="translate.getDefaultLang() + '/taker/admin/favoritos'" class="btn btn-light ml-2">{{ 'Cancelar' | translate}}</a>
        </div>
      </div>
    </form>
  </div>
</div>

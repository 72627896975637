<h1 class="page-title"><i class="fa fa-bar-chart small mr-3"></i>{{translate.instant(title)}}</h1>

<div class="d-flex mt-4">
    <p class="filters" (click)="changeFilter('months')" [ngClass]="{'active-filter': activeFilter == 'months'}">
        Últimos
        12 meses</p>
    <p class="filters" (click)="changeFilter('days')" [ngClass]="{'active-filter': activeFilter == 'days'}">Últimos
        30
        días</p>
    <p class="filters" (click)="changeFilter('hours')" [ngClass]="{'active-filter': activeFilter == 'hours'}">
        24 horas</p>

    <!-- <p class="filters" (click)="changeFilter('hours')" [ngClass]="{'active-filter': activeFilter == 'hours'}"> -->
    <!-- Últimas 24
        horas</p> -->

</div>

<div class="row">
    <div class="col-lg-6">
        <ul *ngIf="maker" class="nav nav-tabs mb-n1 small mt-5">
            <li class="nav-item">
                <a class="nav-link active font-weight-bold">{{"Interacciones" | translate}}</a>
            </li>
        </ul>
        <div class="card">
            <div class="card-body p-4">
                <div class="chart-container">
                    <canvas id="interactionChart">{{ interactionChart }}</canvas>
                </div>
            </div>
        </div>
    </div>


    <div class="col-lg-6">
        <ul *ngIf="maker" class="nav nav-tabs mb-n1 small mt-5">
            <li class="nav-item">
                <a class="nav-link" [ngClass]="{'active font-weight-bold' : showTab == 'directory'}"
                    (click)="showTab = 'directory'; createDirectoryChart()">{{"Directorio" | translate}}</a>
            </li>
            <li class="nav-item" *ngIf="maker.makerPlan.plan.sku == ('PROW-A' || 'PROW-M')">
                <a class="nav-link" [ngClass]="{'active font-weight-bold' : showTab == 'web'}"
                    (click)="showTab = 'web'; createDirectoryChart()">{{"Web" | translate}}</a>
            </li>
        </ul>

        <div class="card">
            <div class="card-body p-4">
                <div *ngIf="showTab == 'directory'">
                    <div class="chart-container">
                        <canvas id="directoryChart">{{ directoryChart }}</canvas>
                    </div>
                    <!-- <strong>Impresiones de marca:</strong>
                    <p>{{this.directoryAnalytics.brandImpressions | json}}</p>
        
                    <strong>Visitas ficha maker:</strong>
                    <p>{{this.directoryAnalytics.brandVisits | json}}</p>
        
                    <strong>Impresiones experiencias:</strong>
                    <p>{{this.directoryAnalytics.productsImpressions | json}}</p> -->
                </div>
                <div *ngIf="showTab == 'web'">

                </div>
            </div>
        </div>
    </div>
</div>




<div *ngIf="showTab == 'directory'" class="mt-5">
    <div class="my-fake-table">
        <div class="d-flex header-my-fake-table">
            <div class="header-name-fake-column" style="border-radius: 10px;">
                <div>
                    <span class="font-weight-bold">Directorio</span>
                </div>
            </div>
            <div class="header-fake-column font-weight-bold" *ngFor="let value of labelsArray; let i = index"
                [ngClass]="{'top-right-border' : i == labelsArray.length-1}">
                <span style="font-size: 0.8rem;">{{value}}</span>
            </div>
        </div>
        <div class="d-flex">
            <div class="name-fake-column">
                <div class="d-flex align-items-center">
                    <div class="ball" style="background-color: blue;"></div>
                    <span>Impresiones de marca</span>
                </div>
            </div>
            <div class="fake-column" *ngFor="let value of brandImpressionsArray">
                <span>{{value}}</span>
            </div>
        </div>
        <div class="d-flex">
            <div class="name-fake-column">
                <div class="d-flex align-items-center">
                    <div class="ball" style="background-color: red;"></div>
                    <span>Visitas ficha maker</span>
                </div>
            </div>
            <div class="fake-column" *ngFor="let value of brandVisitsArray">
                <span>{{value}}</span>
            </div>
        </div>
        <div class="d-flex">
            <div class="name-fake-column">
                <div class="d-flex align-items-center">
                    <div class="ball" style="background-color: purple;"></div>
                    <span>Impresiones de productos</span>
                </div>
            </div>
            <div class="fake-column" *ngFor="let value of productsImpressionsArray">
                <span>{{value}}</span>
            </div>
        </div>

    </div>

    <!-- <div>
        <span *ngFor="let value of labelsArray">{{value}},</span>
        <p>Impresiones de marca</p>
        <span *ngFor="let value of brandImpressionsArray">{{value}},</span>
    </div>
    <div>
        <p>Visitas ficha maker</p>
        <span *ngFor="let value of brandVisitsArray">{{value}},</span>
    </div>
    <div>
        <span *ngFor="let value of labelsArray">{{value}},</span>
        <p>Impresiones de productos</p>
        <span *ngFor="let value of productsImpressionsArray">{{value}},</span>
    </div> -->
</div>
<div *ngIf="showTab == 'web'">

</div>




<!-- <div class="mt-2" *ngIf="alert.message">
  <p class="alert" [class.alert-success]="alert.type == 1" [class.alert-danger]="alert.type == 2">{{alert.message}}
  </p>
</div> -->
<div class="pin-background" *ngIf="maker">
  <div class="container py-5">
    <div class="card first-card">
      <div class="row justify-content-center">
        <div class="col-lg-5 col-md-12 row">
          <div class="maker-image col-12 col-md-4 p-0">
            <img style="margin-bottom: 48px;"
              [src]="'https://venntur.com/assets/maker/files/' + (maker.mediaMakers.length > 0 ? maker.mediaMakers[0].contentUrl : '')"
              alt="Imagen de la empresa">
          </div>
          <div class="pl-3 d-lg-none d-none d-md-flex flex-column col-md-8">
            <strong>Nombre Comercial</strong>
            <strong style="font-size: 26px;">{{maker.name}}</strong>
          </div>
          <div class="d-none d-lg-block">
            <div class="pl-3 py-2 my-border-bottom d-flex flex-column" *ngIf="maker.id">
              <strong>ID</strong>
              <span>{{maker.id}}</span>
            </div>
            <div class="pl-3 py-2 my-border-bottom d-flex flex-column" *ngIf="maker.tripadvisor">
              <strong>API Tripadvisor</strong>
              <span>{{maker.tripadvisor}}</span>
            </div>
            <div class="pl-3 py-2 my-border-bottom d-flex flex-column" *ngIf="maker.bookingEngine">
              <strong>Sistemas de reservas</strong>
              <span>{{maker.bookingEngine}}</span>
            </div>
            <div class="pl-3 py-2 my-border-bottom d-flex flex-column">
              <strong>ID sistema de reservas ({{maker.bookingEngine}})</strong>
              <span>{{maker.bookingEngineWidget}}</span>
              <!-- <span>{{maker.id}}</span> -->
            </div>
            <div class="pl-3 py-2 my-border-bottom d-flex flex-column" *ngIf="maker.googleMaps">
              <strong>Google Maps</strong>
              <span>{{maker.googleMaps}}</span>
            </div>
          </div>
        </div>
        <div class="col-lg-7 col-md-12 d-flex flex-column flex-sm-reverse justify-content-between">
          <div class="pl-3 d-lg-flex d-none flex-column">
            <strong>Nombre Comercial</strong>
            <strong style="font-size: 26px;">{{maker.name}}</strong>
          </div>
          <div class="position-relative">
            <div class="keen-slider" #pinImagesSlider>
              <ng-container *ngFor="let url of makerImages; let i = index">
                <div class="keen-slider__slide" [ngClass]="{'active': i == 0}">
                  <img onError="this.src='assets/venntur-placeholder.jpeg'" [src]="url" alt="{{maker.name}} - Venntur"
                    title="{{maker.name}}" style="display: block; width: 100%;">
                </div>
              </ng-container>
            </div>
            <div class="dots" *ngIf="dotHelper.length > 1">
              <button (click)="slider.moveToIdx(i)" *ngFor="let slide of dotHelper; let i = index"
                [class]="'dot ' + (i === currentSlide ? 'active' : '')"></button>
            </div>
          </div>
        </div>
        <div class="pl-3 pt-4 pb-2 d-flex d-md-none flex-column w-100">
          <strong>Nombre Comercial</strong>
          <strong style="font-size: 26px;">{{maker.name}}</strong>
        </div>
        <div class="col-md-12 d-block d-lg-none pt-md-4">
          <div class="pl-3 py-2 my-border-bottom d-flex flex-column" *ngIf="maker.id">
            <strong>ID</strong>
            <span>{{maker.id}}</span>
          </div>
          <div class="pl-3 py-2 my-border-bottom d-flex flex-column" *ngIf="maker.tripadvisor">
            <strong>API Tripadvisor</strong>
            <span>{{maker.tripadvisor}}</span>
          </div>
          <div class="pl-3 py-2 my-border-bottom d-flex flex-column" *ngIf="maker.bookingEngine">
            <strong>Sistemas de reservas</strong>
            <span>{{maker.bookingEngine}}</span>
          </div>
          <div class="pl-3 py-2 my-border-bottom d-flex flex-column" *ngIf="maker.idFareharbor">
            <strong>ID sistema de reservas ({{maker.bookingEngine}})</strong>
            <span>{{maker.bookingEngineWidget}}</span>
          </div>
          <div class="pl-3 py-2 my-border-bottom d-flex flex-column" *ngIf="maker.googleSites">
            <strong>Google Maps</strong>
            <span>{{maker.googleSites}}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="card second-card">
      <ul class="nav nav-tabs mb-n1 small">
        <li class="nav-item">
          <a class="nav-link" [ngClass]="{ 'active font-weight-bold': showTab == 'companyData'}"
            (click)="showTab = 'companyData'"><span class="text" title="Datos de empresa">Datos de
              empresa</span></a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [ngClass]="{ 'active font-weight-bold': showTab == 'conditions'}"
            (click)="showTab = 'conditions'"><span class="text" title="Condiciones">{{
              translate.instant("Condiciones") }}</span></a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [ngClass]="{ 'active font-weight-bold': showTab == 'documents'}"
            (click)="showTab = 'documents'"><span class="text" title='Documentos'>{{
              translate.instant("Documentos") }}</span></a>
        </li>
      </ul>
      <div *ngIf="showTab == 'companyData'" class="companyData">
        <div class="row justify-content-between">
          <div class="col-lg-6">
            <div class="d-flex flex-column mb-3" *ngIf="maker.companyName">
              <strong>
                Razón Social
              </strong>
              <span>
                {{maker.companyName}}
              </span>
            </div>
            <div class="d-flex flex-column mb-3" *ngIf="maker.documentType && maker.document">
              <strong>
                {{maker.documentType}}
              </strong>
              <span>
                {{maker.document}}
              </span>
            </div>
            <div class="d-flex flex-column mb-3" *ngIf="maker.address">
              <strong>
                Dirección
              </strong>
              <span>
                {{maker.address}}
              </span>
            </div>
            <div class="d-flex flex-column mb-3" *ngIf="maker.location.name">
              <strong>
                Zona
              </strong>
              <span>
                {{maker.location.name}}
              </span>
            </div>
            <div class="d-flex flex-column mb-3" *ngIf="maker.country">
              <strong>
                País
              </strong>
              <span>
                {{maker.country}}
              </span>
            </div>
            <div class="d-flex align-items-center">
              <span class="vi-web mr-3" style="font-size: 35px;"></span>
              <a class="d-inline" style="font-size: 15px;" [href]="maker.web" target="_blank">{{maker.web}}</a>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="d-flex flex-column mb-3" *ngIf="maker.name">
              <strong>
                Contacto
              </strong>
              <span>
                {{maker.name}}
              </span>
            </div>
            <div class="d-flex flex-column mb-3" *ngIf="maker.phone">
              <strong>
                Teléfono
              </strong>
              <span>
                {{maker.phone}}
              </span>
            </div>
            <div class="d-flex flex-column mb-3" *ngIf="maker.phone2">
              <strong>
                Teléfono 2
              </strong>
              <span>
                {{maker.phone2}}
              </span>
            </div>
            <div class="d-flex flex-column mb-3" *ngIf="maker.bookingEmail">
              <strong>
                Mail Reservas
              </strong>
              <span>
                {{maker.bookingEmail}}
              </span>
            </div>
            <div class="d-flex flex-column mb-3" *ngIf="maker.email">
              <strong>
                Mail Comercial
              </strong>
              <span>
                {{maker.email}}
              </span>
            </div>
            <div class="d-flex flex-column mb-3">
              <strong>
                Mail Administración
              </strong>
              <span>
                {{maker.administrationEmail}}
              </span>
            </div>
            <div class="d-flex flex-column">
              <strong>
                RR SS
              </strong>
              <div class="d-flex">
                <span class="vi-facebook-outline" style="font-size: 35px;"></span>
                <span class="vi-insta"></span>
                <span class="vi-tiktok"></span>
                <span class="vi-x"></span>
                <span class="vi-linkedin"></span>
                <span class="vi-tripadvisor"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="showTab == 'conditions'" class="conditions">
        <div class="d-flex flex-column mb-5">
          <strong class="mb-3">
            Condiciones de reserva
          </strong>
          <span>
           <!-- maker.reservationConditions || raw or innerhtml -->
            {{maker.reservationConditions}}

          </span>
        </div>
        <div class="d-flex flex-column mb-5">
          <strong class="mb-3">
            Condiciones de cancelación y cambios
          </strong>
          <span>
            {{maker.cancelationConditions}}
          </span>
        </div>
        <div class="d-flex flex-column mb-5">
          <strong class="mb-3">
            Condiciones de pago
          </strong>
          <span>
            {{maker.paymentConditions}}
          </span>
        </div>
        <!-- <div class="d-flex flex-column mb-5">
          <strong class="mb-3">
            Condiciones de pago
          </strong>
          <span>
            Ofrecemos traslados desde la zona Sur. La confirmación de la hora y lugar de recogida se hará directamente
            con sus clientes vía whatsapp o Telegram.
          </span>
        </div> -->
      </div>
      <div *ngIf="showTab == 'documents'" class="documents">

        <div class="row justify-content-between">
          <div class="col-lg-3 col-md-4 col-6 d-flex flex-column text-center" *ngFor="let document of maker.mediaMakersDocs">

            <!-- Clean <a> attributes -->
              <a class="d-flex flex-column wrap" [href]="'https://venntur.com/assets/maker/pdf/files/' + document.contentUrl" target="_blank" download style="text-decoration: none; color: black;">
                <span class="vi-pdf mb-3" style="font-size: 40px;"></span>
                <strong>{{document.typeOf}}</strong>
              </a>
          </div>
        </div>

        <!-- <div class="row justify-content-between">
          


          


          <div class="col-lg-3 col-md-4 col-6 d-flex flex-column text-center">
            <span class="vi-pdf mb-3" style="font-size: 40px;"></span>
            <strong>Póliza RRCC</strong>
          </div>
          <div class="col-lg-3 col-md-4 col-6 d-flex flex-column text-center">
            <span class="vi-pdf mb-3" style="font-size: 40px;"></span>
            <strong>Recibo RRCC</strong>
          </div>
          <div class="col-lg-3 col-md-4 col-6 d-flex flex-column text-center">
            <span class="vi-pdf mb-3" style="font-size: 40px;"></span>
            <strong>Licencia de actividad</strong>
          </div>
          <div class="col-lg-3 col-md-4 col-6 d-flex flex-column text-center">
            <span class="vi-pdf mb-3" style="font-size: 40px;"></span>
            <strong>Plantilla de contrato de colaboración</strong>
          </div>
          <div class="col-lg-3 col-md-4 col-6 d-flex flex-column text-center">
            <span class="vi-pdf mb-3" style="font-size: 40px;"></span>
            <strong>Catálogos</strong>
          </div>
        </div> -->
      </div>
    </div>
    <div class="card third-card">
      <div class="d-flex justify-content-between align-items-center mb-3">
        <strong style="font-size: 26px;">Productos</strong>
        <div style="font-size: 14px;">
          <strong>Total: </strong>
          <span>{{originalExperiences.length}} productos</span>
        </div>
      </div>
      <div class="card border-0 position-relative product-container" style="background-color: #F7F7F7" *ngIf="originalExperiences.length > 0">
        <div class="row">
          <div class="column col-lg-12 col-md-6 col-12 mb-4"
            *ngFor="let experience of originalExperiences.slice((4*currentPage-4),currentPage*4)">
            <strong class="d-none d-lg-block" style="font-size: 16px;"
              [title]="experience.name">{{experience.name}}</strong>
            <div class="product-card rounded bg-white mt-2 d-lg-flex" style="font-size: 13px;">
              <img [src]="'https://venntur.com/assets/experience/files/' + experience.mediaExperiences[0].contentUrl"
                class="experience-img" alt="Imagen del Producto" onError="this.src='assets/venntur-placeholder.jpeg'">
              <strong class="experience-name d-lg-none mb-3" [title]="experience.name">{{experience.name}}</strong>
              <div
                class="data-container w-100 m-0 d-lg-flex justify-content-between align-items-center py-lg-4 pr-lg-3">
                <div class="w-100 mb-lg-0 mb-3">
                  <div class="my-border-bottom mb-lg-0 mb-2">
                    <strong>Ubicación</strong>
                  </div>
                  <span>{{experience.location.name}}</span>
                </div>
                <div class="w-100 mb-lg-0 mb-3">
                  <div class="my-border-bottom mb-lg-0 mb-2">
                    <strong>Modalidades</strong>
                  </div>
                  <div class="modalitites">
                    <span
                      *ngFor="let modality of experience.modalities;let last = last">{{modality.title}}XXX<ng-container
                        *ngIf="!last">, </ng-container></span>
                  </div>
                </div>
                <div class="w-100 mb-lg-0 mb-3 mr-3">
                  <div class="my-border-bottom mb-lg-0 mb-2">
                    <strong>Categorías</strong>
                  </div>
                  <div class="categories" [title]="setCategoryTitle(experience)">
                    <span *ngFor="let category of experience.categories;let last = last">{{category.name}}<ng-container
                        *ngIf="!last">, </ng-container></span>
                  </div>
                </div>
                <div class="w-100 d-lg-block d-flex">
                  <a class="btn btn-primary mb-lg-2 py-1 mr-lg-0 mr-1"
                    [href]="'https://venntur.com/es/product/' + experience.slug">Ver</a>
                  <a class="btn btn-primary py-1 ml-lg-0 ml-1">Descargar</a>
                </div>
              </div>
              <!-- <div class="m-0 h-50">
                  <div class="">
                    <strong>Ubicación</strong>
                  </div>
                  <div class="">
                    <strong>Modalidades</strong>
                  </div>
                  <div class="">
                    <strong>Categorías</strong>
                  </div>
                  <div class="">
                    <button>Comprar</button>
                  </div>
                </div> -->
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-center mb-3">
          <button class="btn btn-primary py-1 download">Descargar todos</button>
        </div>
        <div class="w-100 nav-parent">
          <nav aria-label="Page navigation" class="d-flex justify-content-center">
            <ul class="pagination">
              <li class="page-item">
                <a class="page-link" aria-label="Previous" (click)="prevPage()">
                  <span aria-hidden="true">&laquo;</span>
                  <span class="sr-only">Previous</span>
                </a>
              </li>
              <strong class="d-flex align-items-center">Página {{currentPage}} de {{pagesQuantity}}</strong>
              <!-- <li class="page-item" *ngFor="let page of [].constructor(pagesQuantity);let i = index"><a class="page-link" (click)="currentPage=i+1">{{i+1}}</a></li> -->
              <li class="page-item">
                <a class="page-link" aria-label="Next" (click)="nextPage()">
                  <span aria-hidden="true">&raquo;</span>
                  <span class="sr-only">Next</span>
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  </div>
</div>
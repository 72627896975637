import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CmsService } from '../cms/service/cms.service';
import { AuthService } from '../shared/auth.service';
import { MakerService } from '../shared/maker.service';

@Component({
  selector: 'app-webs',
  templateUrl: './webs.component.html',
  styleUrls: ['./webs.component.scss']
})
export class WebsComponent implements OnInit {
  webs: any;
  constructor(public translate: TranslateService, private cmsService: CmsService, private authService: AuthService, private makerService: MakerService) { }

  ngOnInit(): void {
    this.makerService.getMaker('/makers/'+this.authService.getMakerId()).subscribe((maker:any) => {
      this.cmsService.getAll('webs?externalUser=' + maker.user.email).subscribe((webs: any) => {
        this.webs = webs['hydra:member'];
        console.log(webs);
  
      })
    })

  }

  goMakerWeb(webId:any) {
    // const windowFeatures = 'width=600,height=400,location=no,toolbar=no,menubar=no';
    const newWindowURL = 'http://creator.venntur.com/login' + '?token=' + localStorage.getItem('token') + '&user=' + localStorage.getItem('user') + '&web=' + webId;
    window.open(newWindowURL, '_blank');
  }

  goMakerNewWebIa() {
    // const windowFeatures = 'width=600,height=400,location=no,toolbar=no,menubar=no';
    const newWindowURL = 'http://creator.venntur.com/login' + '?token=' + localStorage.getItem('token') + '&user=' + localStorage.getItem('user') + '&newWebIa=' + true + '&maker=' + this.getMaker();
    window.open(newWindowURL, '_blank');
  }

  goMakerNewWebBlank() {
    // const windowFeatures = 'width=600,height=400,location=no,toolbar=no,menubar=no';
    const newWindowURL = 'http://creator.venntur.com/login' + '?token=' + localStorage.getItem('token') + '&user=' + localStorage.getItem('user') + '&newWebBlank=' + true + '&maker=' + this.getMaker();
    window.open(newWindowURL, '_blank');
  }

  getUser() {
    return JSON.parse(localStorage.getItem('user')).id;
  }
  getMaker() {
    return JSON.parse(localStorage.getItem('maker')).id;
  }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Language, LanguageCollection } from './language.model';
import { map } from 'rxjs/operators';


const API_URL = environment.apiUrl;

@Injectable()
export class LanguagesService {
  constructor(
    private http: HttpClient
  ) { }

  public postLanguage(language): Observable<Language> {
    return this.http
      .post<Language>(API_URL + '/languages', language);
  }

  public getLanguage(id: string): Observable<Language> {
    return this.http
      .get<Language>(API_URL + '/languages/' + id);
  }

  public getLanguages(language): Observable<LanguageCollection> {
    return this.http
      .get<LanguageCollection>(API_URL + '/languages', { headers: {
          'Accept-Language': language
      }});
  }

  public deleteLanguage(experienceId: string): any {
    return this.http.delete<Language>(API_URL + '/languages/' + experienceId);
  }

  public putLanguage(languageId: string, language: Language): Observable<Language> {
    return this.http
      .put<Language>(API_URL + '/languages/' + languageId, language);
  }
}

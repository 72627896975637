// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,

  apiUrl: 'https://api.venntur.com',
  // apiUrl: 'https://dev-api.venntur.com',
  cmsApiUrl: 'https://venntur.cms.api.aititubi.es',
  // apiUrl: 'http://localhost:8000',
  // frontUrl: 'https://pre.venntur.aititubi.es/',
  // frontUrl: 'https://dev.venntur.com/',
  frontUrl: 'https://venntur.com/',

  // Nuevo proyecto google cloud creado por Alfredo
  // 546426374063-87aktrs4bpf22stspjn644dspgc52a18.apps.googleusercontent.com
  google: {
    // clientId: '327976957453-n4bea6ag7n962ottn1i1mno3d1b4lt1f.apps.googleusercontent.com'
    clientId: '306399346139-snsajbsqgf2dc48eeptm1ir0v94k00hi.apps.googleusercontent.com'
  },

  facebook: {
    clientId: '665407101052201',
  },

  stripe: {
    publicKey: 'pk_test_PpbS40l0Y5lUsMN5YzpGlDZj00duWmRb3T',
    basicPrice: 'price_1NlXlyECh0SazpvMs13qGXPS'
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SalesService } from '../sales.service';
import { MakerService } from '../../shared/maker.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { maxTableRows } from 'globals';


@Component({
  selector: 'app-sales-history',
  templateUrl: './sales-history.component.html',
  styleUrls: ['./sales-history.component.scss']
})
export class SalesHistoryComponent implements OnInit {
  maxTableRows = maxTableRows;

  @ViewChild('toTemplate') toTemplate: TemplateRef<any>;
  @ViewChild('messageTemplate') messageTemplate: TemplateRef<any>;
  columns = [];
  rows = [];
  initialRows = [];
  stopSales;
  modalRef: BsModalRef;
  bsValue = new Date();
  bsConfig?: Partial<BsDatepickerConfig>;
  datepickerModel?: Date;
  monthList: string[] = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
  selectedRangeDate = [];

  constructor(
    public translate: TranslateService,
    public makerService: MakerService,
    public salesService: SalesService,
    public modalService: BsModalService

  ) {
    this.bsConfig = Object.assign({}, { showWeekNumbers: false, displayOneMonthRange: true });

    this.salesService.getStopSalesByMakerId(makerService.getId()).subscribe((stopSales => {
      this.initialRows = stopSales['hydra:member'];
      this.rows = stopSales['hydra:member'];
    }));
  }

  ngOnInit(): void {

  }

  ngAfterViewInit() {
    this.columns = [
      { prop: 'subject', name: 'Asunto' },
      { prop: 'contacts', name: 'Para' },
      { prop: 'createdAt', name: 'Fecha y hora de envío' },
      { prop: 'message', name: 'Mensaje' }
    ];
  }

  formatCustomDateWithTime(inputDateString: string): string {
    const inputDate = new Date(inputDateString);

    const options: Intl.DateTimeFormatOptions = {
      day: "numeric",
      month: "long",
      hour: "2-digit",
      minute: "2-digit"
    };
    const formatter = new Intl.DateTimeFormat("es-ES", options);
    const formattedDate = formatter.format(inputDate);

    const parts = formattedDate.split(",");
    const dayAndMonth = parts[0];
    const time = parts[1].trim();

    return dayAndMonth + " - " + time;
  }

  formatCustomDate(inputDateString: string): string {
    const inputDate = new Date(inputDateString);

    const day = inputDate.getUTCDate().toString().padStart(2, "0");
    const month = (inputDate.getUTCMonth() + 1).toString().padStart(2, "0");
    const year = inputDate.getUTCFullYear();

    return `${day}/${month}/${year}`;
  }

  filterByRange(range) {
    if (range != null) {
      let startDate = this.formatDateToISO(new Date(range[0]));
      let endDate = this.formatDateToISO(new Date(range[1]));
      this.rows = this.rows.filter((objeto) => objeto.createdAt >= startDate && objeto.createdAt <= endDate);
    }
  }

  openMessageModal(template: TemplateRef<any>, config: any) {
    this.modalRef = this.modalService.show(template, config);
  }

  formatDateToISO(date: Date) {
    var año = date.getUTCFullYear();
    var mes = ("0" + (date.getUTCMonth() + 1)).slice(-2);
    var dia = ("0" + date.getUTCDate()).slice(-2);
    var hora = ("0" + date.getUTCHours()).slice(-2);
    var minutos = ("0" + date.getUTCMinutes()).slice(-2);
    var segundos = ("0" + date.getUTCSeconds()).slice(-2);

    return `${año}-${mes}-${dia}T${hora}:${minutos}:${segundos}.000Z`;
  }

  deleteRange(range) {
    this.datepickerModel = null;
    this.rows = this.initialRows;
  }
}


import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  styleUrls: ['./app.component.scss'],
  templateUrl: './app.component.html',
})
export class AppComponent {
  title = 'public';

  ngOnInit(): void {
    let fareharbor = document.getElementsByClassName("fareharbor-calendar-wrap")[0];
    (fareharbor as any).style.display = "none";
  }
}

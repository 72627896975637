import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { errorMessages, regExps } from '../../shared/custom-validators';
import { UsersService } from '../../../shared/users/users.service';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../../environments/environment';

const FRONT_URL = environment.frontUrl;

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html'
})
export class ContactFormComponent implements OnInit {
  contactForm: UntypedFormGroup;
  alert = {message: null, type: null};
  errors = errorMessages;
  public message = '';
  public isLoading = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private userService: UsersService,
    public translate: TranslateService
  ) {
    this.createForm();
  }

  ngOnInit(): void {
  }
  createForm() {
    this.contactForm = this.formBuilder.group({
      name: [ '', [
        Validators.required
      ]],
      phone: [ '', [
        Validators.required,
        Validators.pattern(regExps.phone)
      ]],
      acceptance: [ false, [
        Validators.requiredTrue
      ]],
    });
  }

  showError(formValue) {
    return this.contactForm.get(formValue).invalid && this.contactForm.get(formValue).touched;
  }

  showErrors(formGroup) {
    Object.keys(formGroup.controls).forEach(key => {
      formGroup.get(key).markAsTouched();
    });
    return;
  }

  sendContactForm() {
    this.isLoading = true;
    if (this.contactForm.invalid) {
      this.showErrors(this.contactForm);
      return;
    }
    const contactForm = {
      name: this.contactForm.get('name').value,
      phone: this.contactForm.get('phone').value,
      acceptance: this.contactForm.get('acceptance').value
    };
    this.userService.contactForm(contactForm).subscribe(
      (response) => {
        this.isLoading = false;
        this.contactForm.markAsPristine();

        this.alert.type = 1;
        this.alert.message = 'Tus datos de contacto se han enviado correctamente. En breve nos pondremos en contacto contigo';
        setTimeout(() => {
          window.location.replace(FRONT_URL + this.translate.getDefaultLang() + '/precios-venntur');
        }, 3000);
      },
      (error) => {
        this.isLoading = false;
        this.contactForm.markAsPristine();

        this.alert.type = 2;
        this.alert.message = 'No se han podido enviar tus datos de contacto. Prueba de nuevo más tarde';
      }
    );
  }
}

<div class="container-fluid w-430 pt-4 mb-5">
  <div class="text-center">
    <h1>{{ 'Regístrate' | translate}}</h1>
    <p>{{ 'Regístrate ya y crea tu propio panel de venta y contratación' | translate}}</p>
  </div>
  <div class="mb-4">
    <ul class="list-unstyled list-icons">
      <li><i-feather name="globe" class="small text-primary"></i-feather> <span
          [innerHTML]="'RegResList.item1' | translate"></span></li>
      <li><i-feather name="heart" class="small text-primary"></i-feather> <span
          [innerHTML]="'RegResList.item2' | translate"></span></li>
      <li><i-feather name="check-circle" class="small text-primary"></i-feather> <span
          [innerHTML]="'RegResList.item3' | translate"></span></li>
      <li><i-feather name="arrow-right-circle" class="small text-primary"></i-feather> <span
          [innerHTML]="'RegResList.item4' | translate"></span></li>
    </ul>
  </div>
  <button class="btn btn-secondary w-100 mb-3" (click)="signInWithFB()"><i-feather name="facebook"
      class="small"></i-feather> {{ 'Regístrate con Facebook' | translate}}</button>
  <button class="btn btn-secondary w-100" (click)="signInWithGoogle()"><i-feather name="google"
      class="small"></i-feather> {{ 'Regístrate con Google' | translate}}</button>
  <div class="divider-or my-4">
    <span>o</span>
    <hr>
  </div>
  <form [formGroup]="userForm" class="pb-5">
    <div class="form-group">
      <input type="email" name="email" formControlName="email"
        [placeholder]="translate.instant('Correo electrónico') + '*'" class="form-control">
      <small class="text-danger" *ngIf="showError('email')">
        {{translate.instant(errors['email'])}}
      </small>
    </div>
    <div class="form-group">
      <input type="password" passToggle maxlength="15" name="password" formControlName="password"
        [placeholder]="translate.instant('Contraseña') + '*'" class="form-control">
      <small class="text-danger" *ngIf="showError('password')">
        {{translate.instant(errors['password'])}}
      </small>
    </div>
    <!-- <h5 class="font-weight-bold">¿Qué tipo de usuario eres?</h5>
    <div class="form-group custom-control custom-radio mb-1">
      <input class="custom-control-input" type="radio" id="taker" formControlName="role" value="ROLE_TAKER" name="role" checked>
      <label class="custom-control-label d-block" for="taker">
        <span class="icon"></span>
        <span class="text">Taker</span>
        <i-feather name="help-circle" class="small ml-1 text-muted" tooltip="Los que disfrutan de las experiencias" placement="right"></i-feather>
      </label>
    </div>
    <div class="form-group custom-control custom-radio">
      <input class="custom-control-input" type="radio" id="reseller" formControlName="role" value="ROLE_MAKER" name="role">
      <label class="custom-control-label d-block" for="reseller">
        <span class="icon"></span>
        <span class="text">Reseller</span>
        <i-feather name="help-circle" class="small ml-1 text-muted" tooltip="Los que organizan las experiencias" placement="right"></i-feather>
      </label>
    </div>
    <small class="text-danger" *ngIf="showError('role')">
      {{errors['role']}}
    </small> -->
    <div class="form-group custom-control custom-checkbox">
      <input class="custom-control-input" type="checkbox" id="subscription" value="subscription" checked>
      <label class="custom-control-label" for="subscription">
        <span class="icon"></span>
        <span class="text">{{ '¿Quieres recibir información útil?' | translate}}</span>
      </label>
    </div>
    <p class="small text-muted">{{ 'Al Hacer clic en “Crear cuenta” aceptas nuestras' | translate}} <a class="text-dark"
        href="#" target="_blank" rel="noopener noreferrer">{{ 'Condiciones de uso' | translate}}</a>,
      <a class="text-dark" href="https://pro.venntur.com/politica-de-privacidad/" target="_blank"
        rel="noopener noreferrer">{{ 'Política de privacidad' | translate}}</a> {{ 'y la' | translate}} <a
        class="text-dark" href="https://pro.venntur.com/politica-de-cookies/" target="_blank"
        rel="noopener noreferrer">{{ 'Política de cookies' | translate}}</a>.
    </p>
    <button class="btn btn-primary w-100" type="submit" name="button" (click)="createUser()">{{ 'Crear cuenta' |
      translate}}</button>
  </form>

  <!-- Create a button that your customers click to complete their purchase. Customize the styling to suit your branding. -->
  <!--  <button-->
  <!--    style="background-color:#6772E5;color:#FFF;padding:8px 12px;border:0;border-radius:4px;font-size:1em"-->
  <!--    id="checkout-button-price_1HtuT8DQOX9k1ARpo2xGkQpJ"-->
  <!--    role="link"-->
  <!--    type="button"-->
  <!--    (click)="test()"-->
  <!--  >-->
  <!--    Checkout-->
  <!--  </button>-->

  <div class="mt-4" *ngIf="alert.message">
    <p class="alert" [class.alert-success]="alert.type == 1" [class.alert-danger]="alert.type == 2">{{alert.message}}
    </p>
  </div>
</div>
<div class="d-flex align-items-center">
  <h1 class="page-title m-0"><i class="fa fa-binoculars small"></i> Productos </h1>
</div>
<div class="mt-4" *ngIf="alert.message">
  <p class="alert" [class.alert-success]="alert.type == 1" [class.alert-danger]="alert.type == 2">{{alert.message}}</p>
</div>
<div class="card mt-4">
  <div class="card-body p-3">
    <h5>Buscar por: </h5>
    <form [formGroup]="searchForm" (submit)="applyFilter()">
      <div class="form-row flex-fill">
        <div class="col">
          <input placeholder="ID" type="number" formControlName="id" class="form-control form-control-sm">
        </div>
        <div class="col">
          <input placeholder="Nombre" type="text" formControlName="name" class="form-control form-control-sm">
        </div>
        <div class="col">
          <ng-select [placeholder]="'Destinos'"
                     aria-label="Location"
                     multiple="true"
                     [closeOnSelect]="false"
                     formControlName="locations"
                     [(ngModel)]="locationsSelected">
            <ng-option *ngFor="let location of locations" [value]="location['@id']">{{ location.name }}</ng-option>
          </ng-select>
        </div>
        <div class="col">
          <input placeholder="Maker" type="email" formControlName="maker" class="form-control form-control-sm">
        </div>
        <div class="col">
          <ng-select bindLabel="Etiquetas"
                     [placeholder]="'Etiquetas'"
                     multiple="true"
                     [closeOnSelect]="false"
                     formControlName="tags"
                     [(ngModel)]="tagsSelected">
            <ng-option [value]="tag['@id']" *ngFor="let tag of tags">{{tag.name}}</ng-option>
          </ng-select>
        </div>
        <div class="col">
          <label><input placeholder="Mis productos" type="checkbox" formControlName="isFavorites">Mis productos</label>
        </div>
        <button type="submit" class="btn btn-success btn-sm col-md-2">Buscar</button>
      </div>
    </form>
  </div>
</div>
<div class="card mt-2">
  <div class="card-body">
    <ngx-datatable
      #table
      class="bootstrap"
      [rowHeight]="'auto'"
      [columns]="columns"
      [columnMode]="'force'"
      [headerHeight]="50"
      [footerHeight]="50"
      [limit]="maxTableRows"
      [rows]="rows">
      <ngx-datatable-column *ngFor="let column of columns; let i = index;" name="{{column.name}}" prop="{{column.prop}}">
      </ngx-datatable-column>
      <ngx-datatable-column name="Contacto" prop="contact">
        <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
          <div class="maker__ctc-info">
            <ul *ngIf="!isFavorites" class="list-unstyled d-flex small mb-0 mt-2">
              <li><a [href]="row.maker['web']" [tooltip]="row.maker['web']" title="Página web" target="_blank" rel="noopener noreferrer"><i-feather name="globe"></i-feather></a></li>
              <li><a [href]="'tel:'+row.maker['phone']" [tooltip]="'telf: ' + row.maker['phone']" title="Teléfono"><i-feather name="smartphone"></i-feather></a></li>
              <li><a [href]="'mailto:'+row.maker['email']" [tooltip]="row.maker['email']" title="Email"><i-feather name="mail"></i-feather></a></li>
              <li *ngIf="row.maker['whatsapp']" [tooltip]="'whatsapp: ' + row.maker['whatsapp']" class="maker__ctc-info--whatsapp"><a [href]="'https://api.whatsapp.com/send?phone='+row.maker['whatsapp']" title="WhatsApp" target="_blank" rel="noopener noreferrer"><i-feather name="phone"></i-feather></a></li>
              <li *ngIf="row.maker['makerPlan'] && row.maker['makerPlan']['plan']['name'] !== 'Gratis'"><a [href]="'es' + '/maker/' + row.maker['name'].split(' ').join('-') + '?id=' + row.maker['id']" title="Ficha Maker"><i-feather name="plus"></i-feather></a></li>
            </ul>
            <ul *ngIf="isFavorites" class="list-unstyled d-flex small mb-0 mt-2">
              <li><a [href]="row.experience.maker['web']" [tooltip]="row.experience.maker['web']" title="Página web" target="_blank" rel="noopener noreferrer"><i-feather name="globe"></i-feather></a></li>
              <li><a [href]="'tel:'+row.experience.maker['phone']" [tooltip]="'telf: ' + row.experience.maker['phone']" title="Teléfono"><i-feather name="smartphone"></i-feather></a></li>
              <li><a [href]="'mailto:'+row.experience.maker['email']" [tooltip]="row.experience.maker['email']" title="Email"><i-feather name="mail"></i-feather></a></li>
              <li *ngIf="row.experience.maker['whatsapp']" [tooltip]="'whatsapp: ' + row.experience.maker['whatsapp']" class="maker__ctc-info--whatsapp"><a [href]="'https://api.whatsapp.com/send?phone='+row.experience.maker['whatsapp']" title="WhatsApp" target="_blank" rel="noopener noreferrer"><i-feather name="phone"></i-feather></a></li>
              <li *ngIf="row.experience.maker['makerPlan'] && row.experience.maker['makerPlan']['plan']['name'] !== 'Gratis'"><a [href]="'es' + '/maker/' + row.experience.maker['name'].split(' ').join('-') + '?id=' + row.experience.maker['id']" title="Ficha Maker"><i-feather name="plus"></i-feather></a></li>
            </ul>
          </div>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column name="Etiquetas" prop="tags">
        <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
          <span *ngIf="!isFavorites">
            <span *ngFor="let tag of row['tags']; let i = index">
            {{tag.name}}{{i === row['tags'].length - 1 ? '' : ', '}}
            </span>
          </span>
          <span *ngIf="isFavorites">
            <span *ngFor="let tag of row.experience['tags']; let i = index">
            {{tag.name}}{{i === row.experience['tags'].length - 1 ? '' : ', '}}
            </span>
          </span>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column name="Mis productos" prop="isFavorite">
        <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
          <span *ngIf="!isFavorites">
            <input type="checkbox" class="form-check-input" id="addFavorite" (click)="addFavorite($event.target['checked'], row['@id'])" [checked]="resellerExperiences.includes(row['@id'])" [disabled]="resellerExperiences.includes(row['@id'])">
            <label class="form-check-label" for="addFavorite"></label>
          </span>
          <span *ngIf="isFavorites">
            <input type="checkbox" class="form-check-input" id="removeFavorite" (click)="removeFavorite(row['@id'])" [checked]="true">
            <label class="form-check-label" for="removeFavorite"></label>
          </span>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column *ngIf="isFavorites" name="Estado" prop="status">
        <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
          <ng-select bindLabel="Estado"
                     [placeholder]=""
                     (change)="changeStatus($event, row['@id'])"
                     [(ngModel)]="row['status']">
            <ng-option [value]="status" *ngFor="let status of statusArray">{{status}}</ng-option>
          </ng-select>
        </ng-template>
      </ngx-datatable-column>
    </ngx-datatable>
  </div>
</div>

<div class="maker-card rounded-lg shadow overflow-hidden">
    <div class="maker-photos position-relative">
        <!-- <carousel [interval]="0" class="w-100 carousel" data-ride="carousel">
            <slide *ngFor="let image of maker.mediamakers" class="slide">
                <img onError="this.src='assets/venntur-placeholder.jpeg'"
                    [src]="getUrl(maker.civitatisId, image.contentUrl)" alt="{{maker.name}} - Venntur"
                    title="{{maker.name}}" style="display: block; width: 100%;">
            </slide>
        </carousel> -->
        <div class="keen-slider" #photosSlider *ngIf="isDashboard == false">
            <ng-container *ngFor="let image of maker.mediaMakersHeader; let i = index">
                <div class="keen-slider__slide" [ngClass]="{'active': i == 0}">
                    <img onError="this.src='assets/venntur-placeholder.jpeg'"
                        [src]="'https://venntur.com/assets/makerHeader/files/' + image.contentUrl"
                        alt="{{maker.name}} - Venntur" title="{{maker.name}}" style="display: block; width: 100%;">
                </div>
            </ng-container>
        </div>

        <div class="maker-logo-container justify-content-center align-content-center align-items-center d-flex ml-4">
            <img class=""
                [src]="'https://venntur.com/assets/maker/files/' + (maker.mediaMakers.length > 0 ? maker.mediaMakers[0].contentUrl : 'assets/venntur-isotype-70.png')"
                alt="{{maker.name}}" title="{{maker.name}}">
        </div>

        <!--  <div *ngIf="isDashboard">
            <img onError="this.src='assets/venntur-placeholder.jpeg'"
                [src]="'https://venntur.com/assets/makerHeader/files/' + maker.mediaMakersHeader[0].contentUrl" alt="{{maker.name}} - Venntur"
                title="{{maker.name}}" style="display: block; width: 100%;">
        </div> -->
    </div>
    <div class="maker-info position-relative px-4 px-md-3 px-lg-4 py-3">

        <h2 class="m-0 mt-2 h6 text-truncate-2" [title]="maker.name">{{maker.name}}</h2>

        <span class="position-relative badge badge-pill badge-primary font-weight-bold bg-orange mb-3">Nº de productos:
            {{maker.experiences.length}}</span>


        <div class="description mb-2" *ngIf="maker.description != null">
            <div>
                <span [innerHtml]="maker.description"></span>
            </div>
        </div>

        <!-- <div class="w-100 small">
            <div class="description" *ngIf="maker.description != null">
                <span [innerHtml]="maker.description"></span>
            </div>
        </div> -->

        <span class="text-xs font-weight-bold d-flex">Categorías:</span>

        <div class="category mb-2 text-xxs font-weight-bold" [title]="setCategoryTitle(maker)" *ngIf="maker.activeCategories.length > 0">
            <span *ngFor="let category of maker.activeCategories; let last = last">{{category[1]}}<ng-container
                    *ngIf="!last"> ,
                </ng-container></span>
        </div>

        <div class="category mb-2 text-xxs font-weight-bold" *ngIf="maker.activeCategories.length == 0">
            <span>
                No hay categorías
            </span>
        </div>


        <span class="text-xs font-weight-bold">Subcategorías:</span>

        <div class="category mb-2 text-xxs font-weight-bold" [title]="setCategoryTitle(maker)" *ngIf="maker.activeSubcategories.length > 0">
            <span *ngFor="let subcategory of maker.activeSubcategories; let last = last">{{subcategory[1]}}
                <ng-container *ngIf="!last"> ,
                </ng-container></span>
        </div>

        <div class="category mb-2 text-xxs font-weight-bold" *ngIf="maker.activeSubcategories.length == 0">
            <span>
                No hay subcategorías
            </span>
        </div>


        <span class="text-xs font-weight-bold">
            Tecnología de reservas
        </span>
        <div class="my-1 m-0 p-0 d-flex align-items-end">

            <div class="col-6 p-0">
                <img *ngIf="maker.bookingEngine == 'Fareharbor'"
                    src="https://media.licdn.com/dms/image/C4E12AQHurTdpnKmW6A/article-cover_image-shrink_600_2000/0/1604010066974?e=2147483647&v=beta&t=hxzQc7MUt35lgWEXsxZ3Mwvjc48oSEqwS9qsJDIEe7A" class="w-100 h-auto" style="max-height: 65px;" alt="">
                <img *ngIf="maker.bookingEngine == 'Turitop'"
                    src="https://turismoasturdigital.com/wp-content/uploads/2023/04/turitop.png" class="w-100 h-auto" style="max-height: 65px;" alt="">
                <img *ngIf="!maker.bookingEngine || maker.bookingEngine == 'Others'" src="https://venntur.com/assets/venntur-placeholder.jpeg" class="w-100" style="max-height: 65px;" alt="">
            </div>

            <div class="col-6 p-0 d-flex justify-content-end">
                <a [routerLink]="'/' + translate.getDefaultLang() +'/maker/' + maker.slug" target="_blank">
                    <button
                        *ngIf="maker['makerPlan'] && maker['makerPlan']['plan']['shortName'] !== 'Plan Free' && maker['sheetCompleted']"
                        class="btn btn-sm btn-secondary px-4 rounded-md gray-border">
                        <p class="m-0 font-weight-bold button-see-more">
                            Ver más
                        </p>
                    </button></a>

            </div>
        </div>
    </div>
    <footer *ngIf="isFooterActive == true" class="maker-maker-info border-top py-3 px-4">
        <!-- <div class="d-flex justify-content-between align-items-center mb-2" style="height: 24px;">
            <div *ngIf="!maker.civitatisId" class="text-xxs font-weight-bold">{{ 'Organizado por' | translate}}
            </div>
            <div *ngIf="maker.civitatisId" class="text-xxs font-weight-bold">{{ 'Experiencia ofrecida por' |
                translate}}</div>
            <div
                *ngIf="maker.makerPlan && maker.makerPlan['plan']['shortName'] !== 'Plan Free' && !maker.civitatisId && maker.sheetCompleted">
                <a [routerLink]="'/' + translate.getDefaultLang() +'/maker/' + maker.slug" target="_blank"
                    class="btn btn-sm btn-secondary rounded-sm text-xxs">{{ 'Ver ficha de empresa' | translate}}</a>
            </div>
        </div> -->
        <!-- <div class="d-flex align-items-center justify-content-between">
            <div class="mr-4">
                <ng-container
                    *ngIf="!maker.makerPlan || maker.makerPlan.plan.shortName === 'Plan Free' || maker.civitatisId || !maker.sheetCompleted; else makerProfileImage">
                    <div class="maker-logo">
                        <img class="img-fluid"
                            [src]="'https://venntur.com/assets/maker/files/' + (maker.mediaMakers.length > 0 ? maker.mediaMakers[0].contentUrl : 'assets/venntur-isotype-70.png')"
                            alt="{{maker.name}}" title="{{maker.name}}">
                    </div>
                </ng-container>
                <ng-template #makerProfileImage>
                    <div class="maker-logo d-flex align-items-center">
                        <a [routerLink]="'/' + translate.getDefaultLang() +'/maker/' + maker.slug"
                            target="_blank">
                            <img class="img-fluid"
                                [src]="'https://venntur.com/assets/maker/files/' + (maker.mediaMakers.length > 0 ? maker.mediaMakers[0].contentUrl : 'assets/venntur-isotype-70.png')"
                                alt="{{maker.name}}" title="{{maker.name}}">
                        </a>
                    </div>
                </ng-template>
            </div>
            <div class="small">
                <ng-container
                    *ngIf="!maker.makerPlan || maker.makerPlan['plan']['shortName'] === 'Plan Free' || maker.civitatisId || !maker.sheetCompleted; else makerProfileName">
                    <strong class="d-block">{{maker.name}}</strong>
                </ng-container>
                <ng-template #makerProfileName>
                    <a [routerLink]="'/' + translate.getDefaultLang() +'/maker/' + maker.slug"
                        target="_blank" class="d-block font-weight-bold text-dark">
                        {{maker.name}}
                    </a>
                </ng-template>
            </div>

        </div> -->
        <div class="maker-ctc-info">
            <ul class="list-unstyled d-flex justify-content-between small mb-0 mt-2">
                <li><a [href]="maker.civitatisId ? maker.url + '?ag_aid=4732' : maker.web + '?utm_source=venntur'"
                        title="Página web" target="_blank" rel="noopener noreferrer"><span class="vi-web"></span></a>
                </li>
                <li><a [href]="'tel:'+maker.phone" title="Teléfono"><span class="vi-mobile"></span></a>
                </li>
                <li><a [href]="'mailto:'+maker.email" title="Email"><span class="vi-email"></span></a>
                </li>
                <li *ngIf="maker.whatsapp"><a [href]="'https://api.whatsapp.com/send?phone='+maker.whatsapp"
                        title="WhatsApp" target="_blank" rel="noopener noreferrer"><span class="vi-whatsapp"></span></a>
                </li>
            </ul>
        </div>
    </footer>
</div>

<div *ngIf="isAlertShown" [config]="{ show: true }" (onHidden)="onHidden()" bsModal #autoShownModal="bs-modal"
    class="modal fade" tabindex="-1" role="dialog" aria-labelledby="dialog-auto-name">
    <div class="modal-dialog">
        <div class="modal-content">
            <button type="button" class="btn btn-sm btn-light btn-close pull-right ml-auto" aria-label="Close"
                (click)="hideModal()">
                <i-feather name="x"></i-feather>
            </button>
            <div class="modal-body text-center ">
                <p><i-feather name="alert-triangle" class="text-warning large"></i-feather></p>
                <p>Inicia sesión y añade actividades a tus <strong>favoritos</strong></p>
                <app-login></app-login>
            </div>
        </div>
    </div>
</div>
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Category, CategoryCollection } from './category.model';


const API_URL = environment.apiUrl;

@Injectable()
export class CategoriesService {
  constructor(
    private http: HttpClient
  ) { }

  public postCategory(category): Observable<Category> {
    return this.http
      .post<Category>(API_URL + '/categories', category);
  }

  public getCategory(id: string): Observable<Category> {
    return this.http
      .get<Category>(API_URL + '/categories/' + id);
  }

  public getCategories(language): Observable<CategoryCollection> {
    return this.http
      .get<CategoryCollection>(API_URL + '/categories', { headers: {
      'Accept-Language': language,
    }});
  }

  public getActiveCategories(language): Observable<CategoryCollection> {
    return this.http
      .get<CategoryCollection>(API_URL + '/categories?exists[experiences]=true', { headers: {
      'Accept-Language': language,
    }});
  }

  public getActiveCategoriesByParentId(parentId:any ,language): Observable<CategoryCollection> {
    return this.http
      .get<CategoryCollection>(API_URL + '/categories?exists[experiences]=true&parents[]=' + parentId, { headers: {
      'Accept-Language': language,
    }});
  }

  public deleteCategory(categoryId: string): any {
    return this.http.delete<Category>(API_URL + '/categories/' + categoryId);
  }

  public putCategory(categoryId: string, category: Category): Observable<Category> {
    return this.http
      .put<Category>(API_URL + '/categories/' + categoryId, category);
  }
}

<div class="cta-makers" (click)="openContent()"
      [ngClass]="status ? 'opened' : 'closed'">
  <div class="close" [ngClass]="status ? 'd-block' : 'd-none'">
		<div class="content text-right">
      <i-feather name="x"></i-feather>
    </div>
	</div>
  <div class="container text-white text-center">
    <div class="content">
      <i-feather name="help-circle"></i-feather>
      <h4>{{ '¿Eres maker?' | translate}}</h4>
      <div class="maker-content hide">
  			<p class="small">{{ 'Si eres propietario o gestor de una actividad en destino turístico, estás en el sitio adecuado. Gracias por dedicar un minuto a conocernos.' | translate}}</p>
  			<p><strong><a [href]="translate.getDefaultLang() + '/makers'">{{ '¿Quieres saber más?' | translate}}</a></strong></p>
  		</div>
      <p class="small m-0"><a [href]="translate.getDefaultLang() + '/registro-maker/gratis'"><strong>{{ 'Regístrate' | translate}}</strong> {{ 'como maker' | translate}}</a></p>
    </div>
  </div>
</div>

<div class="d-flex align-items-center">
    <h1 class="page-title m-0"><i class="fa fa-usd small mr-3"></i> {{ 'Stop Sales' | translate}} </h1>
    <!-- <a (click)="changeLang()" [href]="translate.getDefaultLang() + '/maker/admin/nueva-experiencia'" routerLink="../nueva-experiencia"
      class="btn btn-sm btn-primary ml-3 text-capitalize">{{ 'Añadir nueva Experiencia' | translate}}</a> -->
</div>
<div class="mt-4 mb-n4" *ngIf="alert.message">
    <p class="alert" [class.alert-success]="alert.type == 1" [class.alert-danger]="alert.type == 2">{{alert.message}}
    </p>
</div>
<div class="progressDesktop d-lg-flex d-none card" style="margin-top: 30px;margin-bottom: 20px;">
    <div class="card-body p-3 row justify-content-between">
        <div class="col-3 d-flex flex-column">
            <span class="mb-5">
                Tu progreso {{flameFill > 100 ? 100 : flameFill}}%
            </span>
            <span class="small">
                5 Pasos
            </span>
        </div>
        <div class="col-9 d-flex progress-container">
            <div id="1" class="d-none"></div>
            <div class="progress-node">
                <span class="node-position active glowing" (animationend)="onNumberAnimationEnd()">1</span>
                <span class="node-text">Seleccionar productos</span>
            </div>
            <div id="2" class="progress" (animationend)="onAnimationEnd()"></div>
            <div class="progress-node">
                <span class="node-position" (animationend)="onNumberAnimationEnd()">2</span>
                <span class="node-text">Seleccionar fechas</span>
            </div>
            <div id="3" class="progress" (animationend)="onAnimationEnd()"></div>
            <div class="progress-node">
                <span class="node-position" (animationend)="onNumberAnimationEnd()">3</span>
                <span class="node-text">Seleccionar contactos</span>
            </div>
            <div id="4" class="progress" (animationend)="onAnimationEnd()"></div>
            <div class="progress-node">
                <span class="node-position" (animationend)="onNumberAnimationEnd()">4</span>
                <span class="node-text">Editar mensaje y enviar</span>
            </div>
            <div id="5" class="progress" style="border-top-right-radius: 15px;border-bottom-right-radius: 15px"
                (animationend)="onAnimationEnd()"></div>
            <div class="progress-node">
                <svg width="100" height="100" viewBox="0 0 67 67" xmlns="http://www.w3.org/2000/svg">
                    <defs>
                        <linearGradient id="progress" x1="0" y1="1" x2="0" y2="0">
                            <stop id="stop1" offset="0" stop-color="orange" />
                            <stop id="stop2" offset="0" stop-color="#ddd" />
                        </linearGradient>
                    </defs>
                    <path class="path" fill="url(#progress)" stroke="transparent" stroke-width="1"
                        d="M49.3005 31.2667C48.6585 30.4292 47.8768 29.7033 47.151 28.9775C45.2805 27.3025 43.1589 26.1021 41.3722 24.3433C37.2126 20.2675 36.2914 13.5396 38.9435 8.375C36.2914 9.01708 33.9743 10.4688 31.9922 12.06C24.7618 17.8667 21.9143 28.1121 25.3201 36.9058C25.4318 37.185 25.5435 37.4642 25.5435 37.8271C25.5435 38.4413 25.1247 38.9996 24.5664 39.2229C23.9243 39.5021 23.2543 39.3346 22.7239 38.8879C22.5645 38.7561 22.4318 38.595 22.333 38.4133C19.1785 34.4213 18.676 28.6983 20.7976 24.12C16.1355 27.9167 13.5951 34.3375 13.958 40.3954C14.1255 41.7913 14.293 43.1871 14.7676 44.5829C15.1585 46.2579 15.9122 47.9329 16.7497 49.4125C19.7647 54.2421 24.9851 57.7038 30.5964 58.4017C36.5705 59.1554 42.9635 58.0667 47.5418 53.935C52.6505 49.3008 54.4372 41.875 51.813 35.51L51.4501 34.7842C50.8639 33.5 49.3005 31.2667 49.3005 31.2667ZM40.4789 48.8542C39.6972 49.5242 38.413 50.25 37.408 50.5292C34.2814 51.6458 31.1547 50.0825 29.3122 48.24C32.6343 47.4583 34.6164 45.0017 35.2026 42.5171C35.6772 40.2838 34.7839 38.4413 34.421 36.2917C34.086 34.2258 34.1418 32.4671 34.8955 30.5408C35.426 31.6017 35.9843 32.6625 36.6543 33.5C38.8039 36.2917 42.1818 37.52 42.9076 41.3167C43.0193 41.7075 43.0751 42.0983 43.0751 42.5171C43.1589 44.8063 42.1539 47.3188 40.4789 48.8542Z" />
                </svg>
                <span>Cierre de ventas</span>
            </div>
        </div>
    </div>
</div>
<div class="progressMobile d-flex d-lg-none card" style="margin-top: 30px;margin-bottom: 20px;">
    <div class="card-body p-3 justify-content-between">
        <div class="d-flex justify-content-between">
            <span class="mb-5">
                Tu progreso {{flameFill > 100 ? 100 : flameFill}}%
            </span>
            <span class="small">
                5 Pasos
            </span>
        </div>
        <div class="w-100">
            <div class="d-flex justify-content-center progress-container">
                <div id="1m" class="d-none"></div>
                <div class="progress-node">
                    <span class="node-position active glowing"
                        (animationend)="onNumberAnimationEnd()">{{firstStepNumber}}</span>
                    <span class="node-text">{{firstStepText}}</span>
                </div>
                <div id="2m" class="progress mobile-bar" (animationend)="onAnimationEnd()"></div>
                <div class="progress-node">
                    <span class="node-position" (animationend)="onNumberAnimationEnd()">{{secondStepNumber}}</span>
                    <span class="node-text">{{secondStepText}}</span>
                </div>
            </div>
        </div>

    </div>
</div>
<div class="d-flex flex-sm-row flex-column-reverse justify-content-between align-items-sm-end align-items-start"
    *ngIf="showTab == 0">
    <div class="mb-sm-0 mb-3 w-100">
        <h5><strong>{{ 'Buscar por:' | translate}}</strong></h5>
        <form [formGroup]="searchForm" (submit)="applyFilter()">
            <div class="d-flex flex-column flex-sm-row">
                <div class="mr-3 searchInputContainer">
                    <input type="text" placeholder="Nombre" formControlName="name" class="form-control mb-3 mb-sm-0">
                </div>
                <button type="submit" class="btn btn-success">{{ 'Buscar' |
                    translate}}</button>
            </div>
        </form>
    </div>
    <div class="w-100 text-right">
        <button class="recordButton" routerLink="../historial-ventas"> <i class="fa fa-history" aria-hidden="true"></i>
            Ver historial</button>
    </div>
</div>

<div class="d-flex flex-sm-row flex-column-reverse justify-content-between align-items-sm-end align-items-start"
    *ngIf="showTab == 2">
    <div class="mb-sm-0 mb-3 w-100">
        <h5><strong>{{ 'Buscar por:' | translate}}</strong> </h5>
        <form [formGroup]="contactSearchForm" (submit)="applyContactFilter()">
            <div class="d-flex flex-column flex-sm-row">
                <div class="mr-0 mr-sm-3">
                    <input type="text" [placeholder]="translate.instant('Nombre empresa')" formControlName="companyName"
                        class="form-control form-control-sm mb-3 mb-sm-0">
                </div>
                <div class="mr-0 mr-sm-3">
                    <input [placeholder]="translate.instant('Nombre contacto')" type="text"
                        formControlName="contactName" class="form-control form-control-sm mb-3 mb-sm-0">
                </div>
                <button type="submit" class="btn btn-success">{{ 'Buscar' |
                    translate}}</button>
            </div>
        </form>
    </div>
    <div class="w-100 text-right">
        <button class="recordButton" routerLink="../historial-ventas"> <i class="fa fa-history" aria-hidden="true"></i>
            Ver historial</button>
    </div>
</div>

<div class="mb-5 d-flex justify-content-end" *ngIf="showTab != 0 && showTab !=2">
    <div>
        <button class="recordButton" routerLink="../historial-ventas"> <i class="fa fa-history" aria-hidden="true"></i>
            Ver historial</button>
    </div>
</div>
<ul class="nav nav-tabs mb-n1 small mt-4">
    <li class="nav-item">
        <a class="nav-link h-100" [ngClass]="{'active font-weight-bold' : showTab == 0}"
            (click)="showTab = 0">Productos</a>
    </li>
    <li class="nav-item">
        <a class="nav-link h-100"
            [ngClass]="{'active font-weight-bold' : showTab == 1, 'disabled' :selectedRangeDate.length == 0}"
            (click)="showTab = 1">Fechas</a>
    </li>
    <li class="nav-item">
        <a class="nav-link disabled h-100"
            [ngClass]="{'active font-weight-bold' : showTab == 2, 'disabled' :contactDetails.length == 0}"
            (click)="showTab = 2">Contactos</a>
    </li>
    <li class="nav-item">
        <a class="nav-link disabled h-100" [ngClass]="{'active font-weight-bold' : showTab == 3}"
            (click)="showTab = 3">Editar
            mensaje</a>
    </li>
</ul>
<div class="card product-card" id="stopSales">
    <div class="card-header">
        <b *ngIf="showTab == 0">Selecciona del listado de productos aquellos en los que quieras cerrar ventas para pasar
            al siguiente paso.</b>
        <b *ngIf="showTab == 1">Selecciona las fechas de inicio y fin para cerrar ventas y seguir al paso de
            contactos.</b>
        <b class="d-lg-flex align-items-lg-center w-100" *ngIf="showTab == 2">Selecciona los contactos
            y grupos para cerrar ventas e
            ir al paso de enviar mensaje.
            <ng-select class="type-select" (change)="changeList($event)" [placeholder]="translate.instant('Contactos')">
                <ng-option [value]="contactType.id"
                    *ngFor="let contactType of contacts">{{contactType.name}}</ng-option>
            </ng-select>
        </b>
        <b *ngIf="showTab == 3">Rellena la plantilla de cierre de ventas para su posterior envío por correo
            electrónico.</b>
        <button *ngIf="showTab == 0" class="pink-button mr-0" [disabled]="productDetails.length == 0"
            (click)="nextTab()">
            Continuar
        </button>
        <button *ngIf="showTab == 1" class="pink-button mr-0" [disabled]="selectedRangeDate.length == 0"
            (click)="nextTab()">
            Continuar
        </button>
        <button *ngIf="showTab == 2" class="pink-button mr-0" [disabled]="contactDetails.length == 0"
            (click)="nextTab()">
            Continuar
        </button>
        <button *ngIf="showTab == 3" class="pink-button mr-0" (click)="stopSales()" style="width: 200px;">
            Cerrar ventas y enviar
        </button>
    </div>
    <div class="card-body p-0 mb-3">
        <div *ngIf="showTab == 0">
            <ngx-datatable #myTable class="bootstrap" [rowHeight]="'auto'" [columns]="columns" [columnMode]="'force'"
                [headerHeight]="90" [footerHeight]="60" [limit]="maxTableRows" [rows]="rows">
                <ngx-datatable-column [sortable]="false" [minWidth]="100" [maxWidth]="100" [width]="100">
                    <ng-template ngx-datatable-header-template let-value="value" let-allRowsSelected="allRowsSelected"
                        let-selectFn="selectFn">
                        <input type="checkbox" (change)="addProducts($event)"
                            [checked]="productDetails.length == rows.length" />
                    </ng-template>
                    <ng-template let-row="row" ngx-datatable-cell-template>
                        <input [ngModel]="productDetails.includes(row)" [checked]="productDetails.includes(row)"
                            type="checkbox" href="javascript:void(0)" (click)="addProduct(row)">
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column *ngFor="let column of columns; let i = index;"
                    name="{{translate.instant(column.name)}}" prop="{{column.prop}}">
                </ngx-datatable-column>

                <ngx-datatable-footer>
                    <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize"
                        let-selectedCount="selectedCount" let-curPage="curPage" let-offset="offset">
                        <div class="footer-content">
                            <div class="left-content">
                                <span class="">
                                    Total: {{ rowCount }} productos
                                </span>
                            </div>
                        </div>
                    </ng-template>
                </ngx-datatable-footer>
            </ngx-datatable>
            <!-- <div class="footer-content">
                <div class="right-content">
                    <button class="pink-button mr-0" [disabled]="productDetails.length == 0" (click)="nextTab()">
                        Continuar
                    </button>
                </div>
            </div> -->
        </div>
        <div *ngIf="showTab == 1" class="px-3">
            <div style="width: fit-content; margin: 0 auto;">
                <div class="form-group my-3">
                    <bs-daterangepicker-inline (bsValueChange)="storeRangeDate($event)" [bsValue]="bsInlineRangeValue"
                        [bsConfig]="bsConfig"></bs-daterangepicker-inline>
                </div>
                <div *ngFor="let range of selectedRangeDate">
                    <i class="fa fa-ban mr-1" aria-hidden="true"></i>
                    <span class="mr-1">Desde el {{range[0].getDate()}} de {{monthList[(range[0].getMonth())]}} al
                        {{range[1].getDate()}} de {{
                        monthList[(range[1].getMonth())]}}
                        de {{range[1].getFullYear()}}</span>
                    <button (click)="deleteRange(range)" class="trash-can-button"><i class="fa fa-trash"
                            aria-hidden="true"></i></button>
                </div>
            </div>
            <!-- <div class="footer-content">
                <div class="right-content">
                    <button class="pink-button mr-0" [disabled]="selectedRangeDate.length == 0" (click)="nextTab()">
                        Continuar
                    </button>
                </div>
            </div> -->
        </div>
        <div *ngIf="showTab == 2">
            <ngx-datatable #table class="d-none d-lg-block bootstrap expandable" [rowHeight]="'auto'"
                [columns]="columnsContacts" [columnMode]="'force'" [headerHeight]="90" [footerHeight]="90"
                [limit]="maxTableRows" [rows]="rowsContacts">

                <ngx-datatable-column [width]="10" [sortable]="false" [minWidth]="100" [maxWidth]="100" [width]="100">
                    <ng-template ngx-datatable-header-template let-value="value" let-allRowsSelected="allRowsSelected"
                        let-selectFn="selectFn">
                        <input type="checkbox" (change)="addContacts($event)"
                            [checked]="contactDetails.length == rowsContacts.length" />
                    </ng-template>
                    <ng-template let-row="row" let-expanded="expanded" ngx-datatable-cell-template>
                        <input [ngModel]="isChecked(row['@id'])" *ngIf="listType == 'contacts'"
                            [checked]="isChecked(row['@id'])" type="checkbox" href="javascript:void(0)"
                            title="Expand/Collapse Row" (click)="addContact(row)">

                        <input [ngModel]="isChecked(row['@id'])" *ngIf="listType == 'groups'"
                            [checked]="groupDetails.includes(row['@id'])" type="checkbox" href="javascript:void(0)"
                            title="Expand/Collapse Row" (click)="addContact(row)">
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column *ngFor="let column of columnsContacts; let i = index;"
                    name="{{translate.instant(column.name)}}" prop="{{column.prop}}">
                    <ng-template #toTemplate let-value="value" let-row="row" ngx-datatable-cell-template>
                        <ng-container *ngIf="i!=4">
                            <span>
                                {{value}}
                            </span>
                        </ng-container>
                        <ng-container *ngIf="i==4">
                            <span>
                                {{value.length > 0 ? value[0].name : ''}}
                            </span>
                        </ng-container>
                    </ng-template>

                </ngx-datatable-column>

                <ngx-datatable-footer>
                    <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize"
                        let-selectedCount="selectedCount" let-curPage="curPage" let-offset="offset">
                        <div class="footer-content">
                            <div class="left-content">
                                <span class="">
                                    Total: {{ rowCount }} {{listType == 'contacts' ? 'contactos' : 'grupos'}}
                                </span>
                            </div>
                        </div>
                    </ng-template>
                </ngx-datatable-footer>
            </ngx-datatable>
            <ngx-datatable #table class="d-lg-none d-block bootstrap expandable" [rowHeight]="'auto'"
                [columns]="columnsContacts" [columnMode]="'force'" [headerHeight]="90" [footerHeight]="90"
                [limit]="maxTableRows" [rows]="rowsContacts">

                <ngx-datatable-column [width]="10" [sortable]="false" [minWidth]="100" [maxWidth]="100" [width]="100">
                    <ng-template let-row="row" let-expanded="expanded" ngx-datatable-cell-template>
                        <input *ngIf="listType == 'contacts'" [checked]="isChecked(row['@id'])" type="checkbox"
                            href="javascript:void(0)" title="Expand/Collapse Row" (click)="addContact(row)">

                        <input *ngIf="listType == 'groups'" [checked]="groupDetails.includes(row['@id'])"
                            type="checkbox" href="javascript:void(0)" title="Expand/Collapse Row"
                            (click)="addContact(row)">
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="Nombre" prop="name">
                </ngx-datatable-column>

                <ngx-datatable-footer>
                    <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize"
                        let-selectedCount="selectedCount" let-curPage="curPage" let-offset="offset">
                        <div class="footer-content">
                            <div class="left-content">
                                <span class="">
                                    Total: {{ rowCount }} {{listType == 'contacts' ? 'contactos' : 'grupos'}}
                                </span>
                            </div>
                        </div>
                    </ng-template>
                </ngx-datatable-footer>
            </ngx-datatable>
            <!-- <div class="footer-content">
                <div class="right-content">
                    <button class="pink-button mr-0" [disabled]="contactDetails.length == 0" (click)="nextTab()">
                        Continuar
                    </button>
                </div>
            </div> -->
        </div>
        <div *ngIf="showTab == 3" style="max-width: 800px;margin: 0 auto;" class="pt-3">
            <form [formGroup]="notificationEmailForm" method="post">
                <div class="d-flex flex-column">
                    <div class="card emailTemplate">
                        <div class="d-flex align-items-center px-3 pt-3">
                            <b class="mr-3">Para:</b>
                            <ng-select class="w-100" appendTo="invisibleContainerContacts"
                                (keydown.enter)="insertTag($event, 'contacts')" (remove)="onRemove($event)"
                                [placeholder]="translate.instant('¿A quien va dirigido este mensaje?')" multiple="true"
                                [closeOnSelect]="false" formControlName="contacts">
                                <!-- <ng-option [value]="notInclude"
                *ngFor="let notInclude of experienceForm.controls['notIncludes'].value">{{notInclude}}</ng-option> -->
                            </ng-select>
                            <div id="invisibleContainerContacts" style="display: none;"></div>
                        </div>
                        <hr>
                        <div class="d-flex align-items-center px-3">
                            <b class="mr-3">Asunto:</b>
                            <input [placeholder]="translate.instant('STOP SALES - ') + maker.name" type="text"
                                class="w-100" formControlName="subject">
                        </div>
                        <hr>
                        <div class="px-3">
                            <!-- <editor apiKey="sjgwd4uj0fldb1yk4wv7846bmubr5yqqcq94k63mz61deiz3" [init]="{ 
                                plugins: 'lists link image table code help wordcount', contextmenu: false}"
                                [initialValue]="editorTemplate" formControlName="message"></editor> -->
                            <ckeditor [editor]="Editor" formControlName="message"></ckeditor>
                            <div class="mt-3" [innerHTML]="editorTemplateStaticData"></div>
                            <!-- <textarea id="mytextarea">Hello, World!</textarea> -->
                            <!-- <textarea class="w-100" type="textarea" placeholder="Introduce aquí tu mensaje..."
                                formControlName="message"></textarea> -->
                        </div>
                    </div>
                </div>
                <!-- <div class="footer-content">
                    <div class="right-content">
                        <button class="pink-button mr-0" (click)="stopSales()" style="width: 200px;">
                            Cerrar ventas y enviar
                        </button>
                    </div>
                </div> -->
            </form>
        </div>
    </div>
</div>
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ExperiencesService } from '../../experiences/experiences/experiences.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import KeenSlider, { KeenSliderInstance } from 'keen-slider';
import JSZip from 'jszip';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-product-pin-page',
  templateUrl: './product-pin-page.component.html',
  styleUrls: ['./product-pin-page.component.scss',
    '../../../../../node_modules/keen-slider/keen-slider.min.css']
})
export class ProductPinPageComponent implements OnInit {

  @ViewChild("pinImagesSlider") pinImagesSlider: ElementRef<HTMLElement>;

  productId = null;
  experience = null;
  showTab = 'generalData'
  currentModality = 0;
  savedUrls: string[] = [];
  savedVideoUrls: string[] = [];
  allImages: string[] = [];
  disableDownload = false;

  currentSlide: number = 0
  dotHelper: Array<Number> = []
  slider: KeenSliderInstance = null

  constructor(
    private router: Router,
    public translate: TranslateService,
    private experienceService: ExperiencesService,
    private http: HttpClient
  ) { }

  ngOnInit(): void {
    var productSlug = this.router['rawUrlTree'].root.children.primary.segments[2].path;

    this.experienceService.getExperienceBySlug(productSlug, this.translate.getDefaultLang()).subscribe(experience => {

      experience = experience['hydra:member'][0];
      this.productId = experience.id;

      this.experience = experience;

      experience.mediaExperiences.forEach(image => {
        this.allImages.push('https://venntur.com/assets/experience/files/' + image.contentUrl)
      });
    });

  }

  ngAfterViewInit() {

    setTimeout(() => {
      this.slider = new KeenSlider(this.pinImagesSlider.nativeElement, {
        initial: this.currentSlide,
        slideChanged: (s) => {
          this.currentSlide = s.track.details.rel
        },
      })
      this.dotHelper = [
        ...Array(this.slider.track.details.slides.length).keys(),
      ]
    }, 1000);
  }

  ngOnDestroy() {
    if (this.slider) this.slider.destroy()
  }

  setTitle(array) {
    return array.map(obj => obj.name).join(', ')
  }

  setModality(index) {
    this.currentModality = index;
  }

  saveImage(url) {
    console.log(url);

    if (this.savedUrls.includes('https://venntur.com/assets/experience/files/' + url)) {
      this.savedUrls = this.savedUrls.filter(item => item !== 'https://venntur.com/assets/experience/files/' + url);
    } else {
      this.savedUrls.push('https://venntur.com/assets/experience/files/' + url)
    }
    console.log(this.savedUrls);

  }

  saveVideo(url) {

    console.log(url);

    if(this.savedVideoUrls.includes(url)){
      this.savedVideoUrls = this.savedVideoUrls.filter(item => item !== url);
    } else {
      this.savedVideoUrls.push(url);
    }

    console.log(this.savedVideoUrls);

  }

  downloadFiles(urls) {
    for (let i = 0; i < urls.length; i++) {
      const url = urls[i];
      const fileName = url.substring(url.lastIndexOf('/') + 1); // Extract file name from URL

      // Create an <a> element
      const link = document.createElement('a');
      link.href = url;
      link.download = fileName;

      console.log(url);

      // Trigger the download programmatically
      link.style.display = 'none'; // Hide the link
      document.body.appendChild(link);
      link.click();

      // Clean up
      document.body.removeChild(link);
    }
  }

  async downloadAllFiles(savedUrls) {
    this.disableDownload = true;
    const zip = new JSZip();

    // Create a folder in the ZIP archive
    const folder = zip.folder('files');

    // Add each file to the folder
    for (const [index, url] of savedUrls.entries()) {
      this.experienceService.getFileDatas({ 'url': url }).subscribe((response => {
        const data = this.base64toBlob(response.response);
        const fileName = `${this.experience.slug}${index + 1}.${url.split('.').pop()}`;
        folder.file(fileName, data);
      }))

    }

    setTimeout(async () => {
      const zipBlob = await zip.generateAsync({ type: 'blob' });

      // Create a download link for the ZIP file
      const link = document.createElement('a');
      link.href = URL.createObjectURL(zipBlob);
      link.download = 'Images.zip';
      link.style.display = 'none';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      
      this.disableDownload = false;
    }, 1000);

    // Generate the ZIP file

  }





  async downloadAllVideos(savedUrls) {
    this.disableDownload = true;
    const zip = new JSZip();

    // Create a folder in the ZIP archive
    const folder = zip.folder('archivos');

    // Add each file to the folder
    for (const [index, url] of savedUrls.entries()) {
      this.experienceService.getFileDatas({ 'url': url }).subscribe((response => {

        var cleanUrl = url.replace('https://venntur-media.s3.eu-west-3.amazonaws.com/', '');
        var videoFormat = 'video/' + cleanUrl.split('.').pop();
        var format = cleanUrl.split('.').pop();

        console.log(videoFormat);

        //convert response.response to blob video
        const videoData = this.base64ToBlobVideo(response.response, videoFormat);
        const fileName = this.experience.slug + '.' + format;
        folder.file(fileName, videoData);
      }))

    }

    setTimeout(async () => {
      const zipBlob = await zip.generateAsync({ type: 'blob' });

      // Create a download link for the ZIP file
      const link = document.createElement('a');
      link.href = URL.createObjectURL(zipBlob);
      link.download = 'Venntur-videos.zip';
      link.style.display = 'none';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      
      this.disableDownload = false;
    }, 1000);

    // Generate the ZIP file

  }






















 /*  async downloadAllVideos(savedUrls) {
    this.disableDownload = true;
    const zip = new JSZip();
    const folder = zip.folder('files');
  
    for (const [index, url] of savedUrls.entries()) {
      // Utiliza tu método o servicio para obtener los datos del video

      const videoHtml = document.getElementById(url);
      console.log(videoHtml);

      var cleanUrl = url.replace('https://venntur-media.s3.eu-west-3.amazonaws.com/', '');
      var videoFormat = cleanUrl.split('.').pop();

       this.getVideoDataHtml(videoHtml, videoFormat).then((data) => {
        console.log(data);
        //folder.file(`${this.experience.slug}${index + 1}.${videoFormat}`, data, { binary: true });
       });


      //url: https://---.com/---.extension we need just the extension
      const fileName = `${this.experience.slug}${index + 1}.${url.split('.').pop()}`;

      console.log(url);
      console.log(fileName);
      console.log(videoFormat);


  
      // Agrega los datos del video al archivo ZIP
      //folder.file(fileName, videoData, { binary: true });
    }
  
    const zipBlob = await zip.generateAsync({ type: 'blob' });
  
    const link = document.createElement('a');
    link.href = URL.createObjectURL(zipBlob);
    link.download = 'Videos.zip';
    link.style.display = 'none';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  
    this.disableDownload = false;
  }

  getVideoDataHtml(videoHtml, videoFormat) {
    return new Promise((resolve, reject) => {
      videoHtml.onloadeddata = () => {
        const canvas = document.createElement('canvas');
        canvas.width = videoHtml.videoWidth;
        canvas.height = videoHtml.videoHeight;
        canvas.getContext('2d').drawImage(videoHtml, 0, 0, canvas.width, canvas.height);
        canvas.toBlob(resolve, 'video/'+videoFormat, 0.95);

        console.log(canvas);


      };
    });
  }
  
  async getVideoData(url) {
    // Utiliza tu método o servicio para obtener los datos del video en el formato adecuado
    // y devuélvelos como un objeto Blob o en el formato necesario para ser agregados al archivo ZIP

    // NO CORS MODE, GET VIDEO DATA FROM VIDEO URL

   


    try {

      const response = await fetch(url, {
        mode: 'no-cors'
      });
      
      if (!response.ok) {
        throw new Error('Error al obtener los datos del video');
      }

        console.log(url);
      console.log(response);
  
      const videoData = await response.blob();
      console.log(videoData);
      
      return videoData;

    } catch (error) {
      console.error(error);
      throw error;
    }

  } */



























  base64toBlob(base64Data) {
    // contentType = contentType || '';
    const byteCharacters = atob(base64Data);
    const byteArrays = [];

    for (let i = 0; i < byteCharacters.length; i++) {
      byteArrays.push(byteCharacters.charCodeAt(i));
    }

    return new Blob([new Uint8Array(byteArrays)]);
  }

  base64ToBlobVideo(base64Data, contentType) {
    const byteCharacters = atob(base64Data);
    const byteArrays = [];
  
    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);
  
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
  
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
  
    return new Blob(byteArrays, { type: contentType });
  }
  

}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LocationService } from 'src/app/shared/locations/location.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
})
export class FooterComponent implements OnInit {
  locations: any;
  constructor(
    public translate: TranslateService,
    private locationService: LocationService,
    private router: Router
  ) {

  }

  ngOnInit(): void {
    this.locationService.getActiveLocations().subscribe((locations: any) => {
      this.locations = locations['hydra:member'];
    })
  }

  get maker() {
    if (this.router.url.includes('maker/admin')) {
      return true;
    } else {
      return false;
    }
  }

  searchExperiences(value) {
    let searchUrl = this.translate.getDefaultLang() + '/experiencias?';

    searchUrl += 'localidad=' + value;

    location.replace(searchUrl);
  }
}

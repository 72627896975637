import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { LocationService } from '../../../shared/locations/location.service';
import {Reseller} from '../reseller-profile/reseller.model';
import {ResellerService} from '../../shared/reseller.service';
import { maxTableRows } from 'globals';


@Component({
  selector: 'app-reseller-locations-list',
  templateUrl: './reseller-locations-list.component.html',
  styleUrls: ['./reseller-locations-list.component.css']
})
export class ResellerLocationsListComponent implements OnInit {
  maxTableRows = maxTableRows;
  columns = [
    { prop: 'name', name: 'Nombre' }];
  rows = [];
  resellerLocations = [];
  resellerId;
  searchForm: UntypedFormGroup;
  alert = {message: null, type: null};
  @ViewChild(DatatableComponent) table: DatatableComponent;
  dialogRef: BsModalRef;

  constructor(
    private locationsService: LocationService,
    private resellerService: ResellerService,
    private searchFormBuilder: UntypedFormBuilder,
    public dialog: BsModalService,
  ) {
    this.resellerId = this.resellerService.getId();
    // this.locationsService.getLocations().subscribe((locations => {
    //   this.rows = locations[ 'hydra:member' ];
    // }));
    this.locationsService.getLocationsByResellerId(this.resellerId).subscribe(experiences => {
      this.resellerLocations = Array.from(experiences['hydra:member']).map((item) => item['@id']);
    });
  }

  ngOnInit(): void {
    this.searchForm = this.searchFormBuilder.group({
      name: [''],
      isFavorites: [true],
      resellerId: [this.resellerId]
    });
    this.applyFilter();
  }

  applyFilter() {
    this.locationsService.getLocations(this.searchForm.value).subscribe(
      data => {
        this.rows = data['hydra:member'];
        this.table.offset = 0;
      }
    );
  }

  clickFavorite(event, locationId) {
    this.resellerLocations.includes(locationId) ?
      this.resellerLocations.splice(this.resellerLocations.indexOf(locationId), 1) : this.resellerLocations.push(locationId);
    const reseller = {
      locations: this.resellerLocations
    };
    const editedReseller = new Reseller(reseller);
    this.resellerService.putReseller(this.resellerService.getId(), editedReseller).subscribe();
  }
}

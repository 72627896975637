<h1 class="page-title"><i class="fa fa-heart small"></i> Tus experiencias favoritas</h1>
<div class="card mt-4">
  <div class="card-body p-4 row">
    <div class="col-lg-12">
      <div class="experiences-container search-results row mb-4"
           infiniteScroll
           [infiniteScrollDistance]="scrollDistance"
           [infiniteScrollThrottle]="throttle"
           (scrolled)="onScroll()">
        <article *ngFor="let experience of experiences; let i = index" class="experience col-md-4 py-4 border-bottom">
          <div class="experience__top-content position-relative">
            <ng-template [ngIf]="experience.promotion">
              <ng-template #tooltipPromoDescription>
                <h5 class="m-0">{{experience.promotionType}}</h5>
                <div class="small">{{experience.promotion}}</div>
              </ng-template>
              <div class="experience__promo promo-container" [tooltip]="tooltipPromoDescription" placement="bottom left">
                <div class="promo-label text-white font-weight-bold">PROMO</div>
              </div>
            </ng-template>

            <!-- NOTE: oculto temporalmente -->
<!--            <div class="experience__favorite bg-white">-->
<!--                  <span (click)="clickFavorite($event)" [ngClass]="{'is-active': toggleFav}">-->
<!--                    <i-feather name="heart"></i-feather>-->
<!--                  </span>-->
<!--            </div>-->

            <carousel [isAnimated]="true" [interval]="false">
              <div *ngFor="let mediaExperience of experience.mediaExperiences">
                <slide>
                  <img
                    src="{{'assets/experience/files/' + mediaExperience['contentUrl']}}"
                    alt="{{experience.name}}"
                    title="{{experience.name}}"
                    style="display: block; width: 100%;"
                    onError="this.src='assets/venntur-placeholder.jpeg'">
                </slide>
              </div>
            </carousel>

          </div>
          <div class="experience__content position-relative">
            <!-- <div *ngIf="experience.sustainableSeal" class="experience__eco-label" tooltip="Experiencia sostenible" placement="top">
              <i-feather name="eco"></i-feather>
            </div> -->
            <div class="experience__category small mt-1">
                  <span *ngFor="let category of experience.categories; let i = index">
                    <span [ngSwitch]="category.name">
                      <ng-container *ngSwitchCase="'Por mar'"><i-feather name="sea"></i-feather></ng-container>
                      <ng-container *ngSwitchCase="'Por tierra'"><i-feather name="land"></i-feather></ng-container>
                      <ng-container *ngSwitchCase="'Por aire'"><i-feather name="wind"></i-feather></ng-container>
                    </span>
                    {{category.name}}{{i === experience.categories.length - 1 ? '' : ', '}}
                  </span>
            </div>
            <h2 class="experience__title text-dark font-weight-bold mt-2">
              <a *ngIf="experience.url; else experienceTitle" [href]="experience.url" target="_blank" rel="noopener noreferrer">
                {{experience.name}}
              </a>
              <ng-template #experienceTitle>{{experience.name}}</ng-template>
            </h2>
            <div class="experience__tripadvisor text-success small mb-2">{{experience.reviewTripadvisor}}</div>
            <ul class="experience__meta small list-unstyled">
              <li><i-feather name="clock"></i-feather> {{ 'Duración' | translate}}: {{experience.duration}}</li>
              <li><i-feather name="message-circle"></i-feather> {{ 'Idiomas' | translate}}:
                <span *ngFor="let language of experience.languages; let i = index">
                      {{language.name}}{{i === experience.languages.length - 1 ? '' : ', '}}
                    </span>
              </li>
              <!-- <li *ngIf="experience.seasonStartMonth">
                <i-feather name="calendar"></i-feather> Temporada:
                <ng-container *ngIf="experience.seasonEndMonth">de</ng-container> {{experience.seasonStartMonth}}
                <ng-container *ngIf="experience.seasonEndMonth">a</ng-container> {{experience.seasonEndMonth}}
              </li> -->
              <li><i-feather name="map-pin"></i-feather> {{ 'Lugar' | translate}}: {{experience.location["name"]}}, {{experience.country}} </li>
            </ul>
            <div class="experience__description mb-3">
              <span class="experience__description-text" [ngClass]="{'show-txt': i === expandedIndex}">{{experience.description}}</span>
              <a *ngIf="experience.description.length > 150" class="experience__description-link small" [ngClass]="{'read-less': i === expandedIndex}" (click)="expandText(i)">
                ... leer <i-feather name="plus" *ngIf="i != expandedIndex; else lessTextIcon"></i-feather> <ng-template #lessTextIcon><i-feather name="minus"></i-feather></ng-template>
              </a>
            </div>
            <div class="text-right" *ngIf="experience.url">
              <a class="btn btn-primary btn-sm mb-3" [href]="experience.url" target="_blank" rel="noopener noreferrer">
                <ng-container *ngIf="experience.urlButtonText === 'book'; else moreInfo">{{ 'Reservar' | translate}}</ng-container>
                <ng-template #moreInfo><i-feather name="plus"></i-feather> Info</ng-template>
              </a>
            </div>
          </div>
          <footer class="experience__maker-info bg-light px-4 pb-2">
            <div class="maker__avatar text-white mx-auto"><img [src]="'/assets/maker/files/' + experience.maker['mediaMakers'][0]['contentUrl']" alt="{{experience.maker['name']}}" title="{{experience.maker['name']}}"></div>
            <div class="maker__name mb-1 text-center">{{ 'Organizado por' | translate}} <strong>{{experience.maker["name"]}}</strong></div>
            <div class="maker__ctc-info">
              <ul class="list-unstyled d-flex justify-content-center">
                <li><a [href]="experience.maker['web']" title="Página web" target="_blank"><i-feather name="globe"></i-feather></a></li>
                <li><a [href]="'tel:'+experience.maker['phone']" title="Teléfono"><i-feather name="smartphone"></i-feather></a></li>
                <li><a [href]="'mailto:'+experience.maker['email']" title="Email"><i-feather name="mail"></i-feather></a></li>
                <li *ngIf="experience.maker['whatsapp']" class="maker__ctc-info--whatsapp"><a [href]="'https://api.whatsapp.com/send?phone='+experience.maker['whatsapp']" target="_blank" title="WhatsApp"><i-feather name="phone"></i-feather></a></li>
              </ul>
            </div>
          </footer>
        </article>
      </div>
    </div>
  </div>
</div>

import { Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, TemplateRef, ElementRef, ViewChild } from '@angular/core';
import { CategoriesService } from '../categories/categories.service';
import { LocationService } from '../locations/location.service';
import { TagsService } from '../tags/tags.service';
import { FormArray, UntypedFormBuilder, FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import KeenSlider, { KeenSliderInstance } from "keen-slider"


@Component({
  selector: 'app-maker-search',
  styleUrls: [
    './maker-search.component.scss',
    '../../../../node_modules/keen-slider/keen-slider.min.css',
  ],
  templateUrl: './maker-search.component.html',
})
export class MakerSearch implements OnInit, OnDestroy {
  @Input() parentVariable: string;
  @Input() totalExperiences: any;
  @Output() parentVariableChange = new EventEmitter<string>();

  @ViewChild("catSlider") catSlider: ElementRef<HTMLElement>;
  @ViewChild("subcatSlider1") subcatSlider1: ElementRef<HTMLElement>;
  @ViewChild("subcatSlider2") subcatSlider2: ElementRef<HTMLElement>;
  // private _slider: any = null;
  totalslides: number;
  currentSlide: number = 0;
  currentsubSlide1: number = 0;
  currentsubSlide2: number = 0;
  doResize: boolean = false;

  slider: KeenSliderInstance = null;
  subslider1: KeenSliderInstance = null;
  subslider2: KeenSliderInstance = null;


  experienceSearchForm: UntypedFormGroup;
  cities = [];
  zones = [];
  categories = null;
  subcategories = [];
  subcategories1 = [];
  subcategories2 = [];
  tags = null;
  searchUrl = null;
  citySelected = null;
  searchHome = true;
  isTagsCollapsed = false;
  screenWidth: number;
  modalRef: BsModalRef;
  total = 0;
  parentsSelected = [];
  parentsIndexSelected = [];
  iconsArray = [];
  monthsWithYear: string[];

  categoryTotals = 0;

  constructor(
    private formBuilder: UntypedFormBuilder,
    public locationService: LocationService,
    public categoriesService: CategoriesService,
    public tagsService: TagsService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public translate: TranslateService,
    private modalService: BsModalService,
  ) {
    this.createForm();

    this.searchHome = this.activatedRoute.snapshot.routeConfig.path === ':lang'
      || this.activatedRoute.snapshot.routeConfig.path === '';
    this.locationService.getActiveLocations().subscribe(locations => {
      this.cities = locations['hydra:member'];
      if (this.activatedRoute.snapshot.queryParams['localidad']) {
        this.experienceSearchForm.get('location').setValue(parseInt(this.activatedRoute.snapshot.queryParams['localidad']));
        this.searchExperiences(true);
      }
    });
    setTimeout(() => {
      this.categoriesService.getActiveCategories(this.translate.getDefaultLang()).subscribe(categories => {
        this.categories = categories['hydra:member'].slice(0, 9);
        setTimeout(() => {
          if (this.activatedRoute.snapshot.queryParams['categorias']) {
            this.activatedRoute.snapshot.queryParams['categorias'].split(',').forEach(categoryId => {
              document.getElementById(categoryId.toString())['checked'] = true;
            });
          }
        }, 0);
      });
    }, 1000);
    setTimeout(() => {
      this.tagsService.getTags(this.translate.getDefaultLang()).subscribe(tags => {
        this.tags = tags['hydra:member'];
        setTimeout(() => {
          if (this.activatedRoute.snapshot.queryParams['etiquetas']) {
            this.activatedRoute.snapshot.queryParams['etiquetas'].split(',').forEach(tagId => {
              document.getElementById('tag-' + tagId.toString())['checked'] = true;
            });
          }
        }, 0);
      });
    }, 1000);

    this.getScreenSize();

    // Months on filter modal
    const monthNames = ["Enero", "Febrero", "Marzo", "Abril",
      "Mayo", "Junio", "Julio", "Agosto",
      "Septiembre", "Octubre", "Noviembre", "Diciembre"];
    const today = new Date;
    this.monthsWithYear = Array.from({ length: 12 }, (_, i) => {
      const month = (today.getMonth() + i) % 12;
      const year = today.getFullYear() + Math.floor((today.getMonth() + i) / 12);
      return `${monthNames[month]} ${year}`;
    });
    // End of months on filter modal
  }

  ngOnInit(): void {
    // this.totalslides = 9;
    window.dispatchEvent(new Event('resize'));
  }

  openFilterModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  createForm() {
    this.experienceSearchForm = this.formBuilder.group({
      location: [null,
        Validators.required
      ],
      area: [null,
        Validators.required
      ],
      date: [null,
        Validators.required
      ]
    });
  }

  getLocation() {
    let currentCity = 'Todos los destinos';
    this.cities.forEach((city: any) => {
      if (city.id == this.experienceSearchForm.get('location').value) {
        currentCity = city.name;
        return;
      }
    });
    return currentCity;
  }

  getZone() {
    let currentZone = 'Todas las zonas';
    this.zones.forEach((city: any) => {
      if (city['@id'].replace('/zones/', '') == this.experienceSearchForm.get('area').value) {
        currentZone = city.name;
        return;
      }
    });
    return currentZone;
  }

  getDate() {
    if (this.experienceSearchForm.get('date').value) {
      return this.monthsWithYear[this.experienceSearchForm.get('date').value]
    } else {
      return 'Todas las fechas';
    }
  }

  searchExperiences(isClicked, event?: any) {
    this.searchUrl = this.translate.getDefaultLang() + '/experiencias?';
    let categories = [];

    // if (index >= 0) {
    //   this.parentsIndexSelected = index;
    // }

    if (!isClicked) {
      if (!this.parentsSelected.includes(event.target.id)) {
        this.parentsSelected = [];
        categories.push(event.target.id);
        this.parentsSelected.push(event.target.id);
      } else {
        this.parentsSelected = [];
      }

      Array.from(document.getElementsByClassName('category-input')).forEach((categoryElement: any) => {
        categoryElement.checked = false;
      });
    }

    if (this.searchHome && isClicked || !this.searchHome) {
      let tags = [];
      if (this.experienceSearchForm.get('location').value) {
        this.searchUrl += 'localidad=' + this.experienceSearchForm.get('location').value;
        this.locationService.getZonesFiltered(this.experienceSearchForm.get('location').value).subscribe(zones => {
          this.zones = zones;
        })
      }

      if (this.experienceSearchForm.get('area').value) {
        this.searchUrl += '&zonas=' + this.experienceSearchForm.get('area').value;
      }

      if (this.experienceSearchForm.get('date').value) {
        if (new Date().getMonth() + this.experienceSearchForm.get('date').value < 12) {
          this.searchUrl += '&monthsString=' + (new Date().getMonth() + parseInt(this.experienceSearchForm.get('date').value));
        } else {
          this.searchUrl += '&monthsString=' + ((new Date().getMonth() + this.experienceSearchForm.get('date').value) - 12);
        }
      }

      let hasSubcategory = false;
      Array.from(document.getElementsByClassName('subcategory')).forEach(categoryElement => {
        if (categoryElement['checked'] && this.parentsSelected.length > 0) {
          categories.push(categoryElement.id);
          categories = categories.filter(category => category !== this.parentsSelected[this.parentsIndexSelected[0]]);
          // categories.push(this.parentsSelected[0]);
          hasSubcategory = true;
        }
      });

      Array.from(document.getElementsByClassName('tag')).forEach(tagElement => {
        if (tagElement['checked']) {
          tags.push(tagElement.id.replace('tag-', ''));
        }
      });
      if (!hasSubcategory) {
        if (this.parentsSelected.length > 0) {
          categories = this.parentsSelected[0];
        }
      }
      this.searchUrl += (categories.length > 0 ? '&categorias=' + categories : '') + (tags.length > 0 ? '&etiquetas=' + tags : '') + '&page=1';

      this.parentVariable = this.searchUrl;

      this.parentVariableChange.emit(this.parentVariable);

      if (event) {
        if (this.parentsSelected.length == 0) {
          event.target.checked = false;
        } else {
          event.target.checked = true;
        }
        this.setIcons(event);
      }

      if (!isClicked) {
        this.subcategories = [];

        if (this.iconsArray.length > 1) {
          this.categoriesService.getActiveCategoriesByParentId(this.iconsArray[0], this.translate.getDefaultLang()).subscribe(categories => {
            this.subcategories.push(categories['hydra:member']);

            this.categoriesService.getActiveCategoriesByParentId(this.iconsArray[1], this.translate.getDefaultLang()).subscribe(categories => {
              this.subcategories.push(categories['hydra:member']);
            });
          });
        } else {
          this.categoriesService.getActiveCategoriesByParentId(this.iconsArray[0], this.translate.getDefaultLang()).subscribe(categories => {
            this.subcategories.push(categories['hydra:member']);
          });
        }
      }
    }

    setTimeout(() => {
      this.subslider1 = new KeenSlider(this.subcatSlider1.nativeElement, {
        loop: false,
        mode: "snap",
        initial: this.currentsubSlide1,
        slideChanged: (s) => {
          this.currentsubSlide1 = s.track.details.rel
        },
        rtl: false,
        slides: { perView: "auto" },
      })
    }, 1000);

    // setTimeout(() => {
    //   this.subslider2 = new KeenSlider(this.subcatSlider2.nativeElement, {
    //     loop: false,
    //     mode: "snap",
    //     initial: this.currentsubSlide2,
    //     slideChanged: (s) => {
    //       this.currentsubSlide2 = s.track.details.rel
    //     },
    //     rtl: false,
    //     slides: { perView: "auto" },
    //   })
    // }, 1000);
  }

  removeFilters() {
    this.searchUrl = this.translate.getDefaultLang() + '/experiencias?localidad=' + this.experienceSearchForm.get('location').value;
    this.parentVariable = this.searchUrl;
    console.log(this.searchUrl)

    this.parentVariableChange.emit(this.parentVariable);
  }

  setIcons(event: any) {
    this.iconsArray = [event.target.id];
    // if (event.target.checked) {
    //   this.iconsArray.push(event.target.id)
    // } else {
    //   this.iconsArray = this.iconsArray.filter(item => item !== event.target.id);
    // }
    // this.iconsArray = this.iconsArray.sort((a, b) => a - b);
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.screenWidth = window.innerWidth;
    if (this.screenWidth < 992) {
      this.isTagsCollapsed = true;
    } else {
      this.isTagsCollapsed = false;
    }
  }

  ngOnChanges(): void {
    this.doResize = true
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.slider = new KeenSlider(this.catSlider.nativeElement, {
        loop: false,
        mode: "snap",
        initial: this.currentSlide,
        slideChanged: (s) => {
          this.currentSlide = s.track.details.rel
        },
        rtl: false,
        slides: { perView: "auto" },
      })
    }, 3000);
  }

  ngAfterViewChecked(): void {
    if (this.slider && this.doResize) {
      this.doResize = false
    }
    if (this.subslider1 && this.doResize) {
      this.doResize = false
    }
    if (this.subslider2 && this.doResize) {
      this.doResize = false
    }

  }

  ngOnDestroy() {
    if (this.slider) this.slider.destroy()
    if (this.subslider1) this.subslider1.destroy()
    if (this.subslider2) this.subslider2.destroy()
  }

  trackByFn(index, item): number {
    return index; // or item.id
  }

  displayMonthIndex(date) {
    if (new Date().getMonth() + date > 11) {
      return new Date().getMonth() + date
    } else {
      return new Date().getMonth() + date - 12
    }
  }

}

<div class="d-flex align-items-center">
  <h1 class="page-title m-0"><i class="fa fa-address-book menu-icon  mr-3"></i> {{ 'Contactos' | translate}} </h1>
</div>



<div class="my-4" *ngIf="alert.message">
  <p class="alert" [class.alert-success]="alert.type == 1" [class.alert-danger]="alert.type == 2">{{alert.message}}</p>
</div>
<!--<div class="card mt-4">-->
<!--  <div class="card-body p-3">-->
<!--    <h5>{{ 'Buscar por:' | translate}} </h5>-->
<!--    <form [formGroup]="searchForm" (submit)="applyFilter()">-->
<!--      <div class="form-row flex-fill">-->
<!--        &lt;!&ndash; <div class="col">-->
<!--          <input placeholder="ID" type="number" formControlName="id" class="form-control form-control-sm">-->
<!--        </div> &ndash;&gt;-->
<!--        <div class="col">-->
<!--          <input [placeholder]="translate.instant('Nombre')" type="text" formControlName="name" class="form-control form-control-sm">-->
<!--        </div>-->
<!--        <div class="col">-->
<!--          <input [placeholder]="translate.instant('Posición')" type="text" formControlName="position" class="form-control form-control-sm">-->
<!--        </div>-->
<!--        <button type="submit" class="btn btn-success btn-sm col-sm-3 mt-sm-0 mt-2">{{ 'Buscar' | translate}}</button>-->
<!--      </div>-->
<!--    </form>-->
<!--  </div>-->
<!--</div>-->
<div class="my-5 d-flex justify-content-between">
  <div class="d-flex flex-column justify-content-between">
    <h5>{{ 'Buscar:' | translate}} </h5>
    <form [formGroup]="searchForm" (submit)="applyFilter()">
      <div class="d-flex flex-column flex-lg-row mr-3 mr-lg-0">
        <div class="mr-lg-3 mb-2 mb-lg-0">
          <input [placeholder]="translate.instant('Contacto')" type="text" formControlName="name"
            class="form-control form-control-sm">
        </div>
        <div class="mr-lg-3 mb-2 mb-lg-0">
          <input [placeholder]="translate.instant('Grupo')" type="text" formControlName="group"
            class="form-control form-control-sm">
        </div>
        <button type="submit" class="btn btn-success btn-sm mt-sm-0 search-button mr-3">{{ 'Buscar' |
          translate}}</button>
      </div>
    </form>
  </div>
</div>
<ul class="nav nav-tabs mb-n1 small">
  <li class="nav-item">
    <a class="nav-link" [ngClass]="{'active font-weight-bold' : showTab == 'contacts'}"
      (click)="showTab = 'contacts'">Contactos</a>
  </li>
  <li class="nav-item">
    <a class="nav-link" [ngClass]="{'active font-weight-bold' : showTab == 'groups'}"
      (click)="showTab = 'groups'">Grupos</a>
  </li>
</ul>
<div class="card">
  <div class="card-header d-flex align-items-center justify-content-between">
    <strong *ngIf="showTab == 'contacts'">Listado de tus contactos</strong>
    <strong *ngIf="showTab == 'groups'">Listado de tus grupos</strong>
    <div *ngIf="showTab == 'contacts'">
      <a routerLink="../nuevo-contacto" class="btn btn-sm btn-primary text-capitalize">{{ 'Añadir nuevo contacto'
        | translate}} </a>
    </div>
    <div *ngIf="showTab == 'groups'">
      <a routerLink="../nuevo-grupo" class="btn btn-sm btn-primary text-capitalize">{{ 'Añadir nuevo grupo' |
        translate}} </a>
    </div>
  </div>
  <div class="card-body p-0">
    <div *ngIf="showTab == 'contacts'">
      <ngx-datatable #table class="d-none d-lg-block bootstrap expandable" [rowHeight]="'auto'" [columns]="columns"
        [columnMode]="'force'" [headerHeight]="90" [footerHeight]="50" [limit]="maxTableRows" [rows]="rows">

        <ngx-datatable-column *ngFor="let column of columns; let i = index;" name="{{translate.instant(column.name)}}"
          prop="{{column.prop}}" [minWidth]="100">
        </ngx-datatable-column>

        <ngx-datatable-column [minWidth]="150" [resizeable]="false" [sortable]="false" [draggable]="false"
          [canAutoResize]="false" headerClass="d-none d-sm-block text-right" cellClass="text-right"
          [name]="translate.instant('Acciones')" prop="actions">
          <ng-template let-column="column" let-sort="sortFn" ngx-datatable-header-template>
            <span class="datatable-header-cell-label d-none d-sm-block">{{ column.name }}</span>
          </ng-template>
          <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
            <a class="btn btn-sm btn-success mr-1"
              [href]="translate.getDefaultLang() + '/maker/admin/editar-contacto/'+row['@id'].replace('/contacts/', '')"
              [tooltip]="translate.instant('Editar')" placement="bottom">
              <i class="fa fa-pencil"></i>
            </a>
            <button class="btn btn-sm btn-outline-primary d-none d-sm-inline-block" (click)="deleteContact(row['@id'])"
              [tooltip]="translate.instant('Eliminar')" placement="bottom">
              <i class="fa fa-trash"></i>
            </button>
          </ng-template>
        </ngx-datatable-column>
      </ngx-datatable>
      <ngx-datatable #table class="d-lg-none d-block bootstrap expandable" [rowHeight]="'auto'" [columns]="columns"
        [columnMode]="'force'" [headerHeight]="90" [footerHeight]="50" [limit]="maxTableRows" [rows]="rows">

        <ngx-datatable-column name="Nombre" prop="name" [minWidth]="100">
        </ngx-datatable-column>

        <ngx-datatable-column [minWidth]="150" [resizeable]="false" [sortable]="false" [draggable]="false"
          [canAutoResize]="false" [name]="translate.instant('Acciones')" prop="actions">
          <ng-template let-column="column" let-sort="sortFn" ngx-datatable-header-template>
            <span class="datatable-header-cell-label">{{ column.name }}</span>
          </ng-template>
          <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
            <a class="btn btn-sm btn-success mr-1"
              [href]="translate.getDefaultLang() + '/maker/admin/editar-contacto/'+row['@id'].replace('/contacts/', '')"
              [tooltip]="translate.instant('Editar')" placement="bottom">
              <i class="fa fa-pencil"></i>
            </a>
            <button class="btn btn-sm btn-outline-primary" (click)="deleteContact(row['@id'])"
              [tooltip]="translate.instant('Eliminar')" placement="bottom">
              <i class="fa fa-trash"></i>
            </button>
          </ng-template>
        </ngx-datatable-column>
      </ngx-datatable>
    </div>
    <div *ngIf="showTab == 'groups'">
      <ngx-datatable #table class="d-none d-lg-block bootstrap expandable" [rowHeight]="'auto'"
        [columns]="columnsGroups" [columnMode]="'force'" [headerHeight]="50" [footerHeight]="50" [limit]="maxTableRows"
        [rows]="rowsGroups">

        <ngx-datatable-column *ngFor="let columnGroup of columnsGroups; let i = index;"
          name="{{translate.instant(columnGroup.name)}}" prop="{{columnGroup.prop}}" [minWidth]="100">
        </ngx-datatable-column>

        <ngx-datatable-column [resizeable]="false" [sortable]="false" [draggable]="false" [canAutoResize]="false"
          headerClass="d-none d-sm-block text-right" cellClass="text-right" [name]="translate.instant('Acciones')"
          prop="actions">
          <ng-template let-column="column" let-sort="sortFn" ngx-datatable-header-template>
            <span class="datatable-header-cell-label d-none d-sm-block">{{ column.name }}</span>
          </ng-template>
          <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
            <a class="btn btn-sm btn-success mr-1"
              [href]="translate.getDefaultLang() + '/maker/admin/editar-grupo/'+row['id'].replace('/contact_groups/', '') "
              [tooltip]="translate.instant('Editar')" placement="bottom">
              <i class="fa fa-pencil"></i>
            </a>
            <button class="btn btn-sm btn-outline-primary d-none d-sm-inline-block" (click)="deleteGroup(row['id'])"
              [tooltip]="translate.instant('Eliminar')" placement="bottom">
              <i class="fa fa-trash"></i>
            </button>
          </ng-template>
        </ngx-datatable-column>
      </ngx-datatable>
      <ngx-datatable #table class="d-lg-none d-block bootstrap expandable" [rowHeight]="'auto'"
        [columns]="columnsGroups" [columnMode]="'force'" [headerHeight]="50" [footerHeight]="50" [limit]="maxTableRows"
        [rows]="rowsGroups">

        <ngx-datatable-column name="Name" prop="name" [minWidth]="100">
        </ngx-datatable-column>

        <ngx-datatable-column [resizeable]="false" [sortable]="false" [draggable]="false" [canAutoResize]="false"
          [name]="translate.instant('Acciones')" prop="actions">
          <ng-template let-column="column" let-sort="sortFn" ngx-datatable-header-template>
            <span class="datatable-header-cell-label">{{ column.name }}</span>
          </ng-template>
          <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
            <a class="btn btn-sm btn-success mr-1"
              [href]="translate.getDefaultLang() + '/maker/admin/editar-grupo/'+row['id'].replace('/contact_groups/', '') "
              [tooltip]="translate.instant('Editar')" placement="bottom">
              <i class="fa fa-pencil"></i>
            </a>
            <button class="btn btn-sm btn-outline-primary" (click)="deleteGroup(row['id'])"
              [tooltip]="translate.instant('Eliminar')" placement="bottom">
              <i class="fa fa-trash"></i>
            </button>
          </ng-template>
        </ngx-datatable-column>
      </ngx-datatable>
    </div>
  </div>
</div>
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { HttpEventType } from '@angular/common/http';

import { Subscription } from 'rxjs';

import { CropperComponent as AngularCropperjsComponent } from 'angular-cropperjs';


import { MediaExperiencesService } from '../../experiences/experiences/media-experiences.service';
import { MediaMakerService } from '../../maker/maker-profile/media-maker';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-cropper-2',
  styleUrls: ['./cropper.component.scss'],
  templateUrl: './cropper2.component.html',
})
export class Cropper2Component {

  @Input() aspectratio: number;
  @Input() preview: string;
  @Input() type: string;
  @ViewChild('angularCropper2', { static: false }) public angularCropper: AngularCropperjsComponent;
  @Output() uploadedImage = new EventEmitter<object>();
  didUploadImage = {};
  featuredImageTemp2 = '';
  config;

  featuredFileToUpload: File = null;
  fileUploadSub: Subscription;
  translate: TranslateService;
  uploadProgress = 0;
  uploadingProgressing = false;
  uploadComplete = false;
  serverResponse: any;

  loading = false;

  constructor(private fb: UntypedFormBuilder,
    private mediaExperiencesService: MediaExperiencesService,
    private mediaMakersService: MediaMakerService) {
    this.config = { aspectRatio: this.aspectratio, preview: '', autoCropArea: 1 };
  }



  insertImage(files: FileList, destination: string) {
    this.config.aspectRatio = this.aspectratio;
    this.config.preview = '.' + this.preview;
    const fileItem = files.item(0);
    if (fileItem === null || fileItem.type.indexOf('image') < 0) {
      this.featuredFileToUpload = null;
      return;
    }
    this.featuredFileToUpload = fileItem;
    const reader = new FileReader();
    reader.readAsDataURL(fileItem);

    reader.onloadend = (progress) => {
      this[destination] = reader.result;
      if (this.angularCropper) {
        this.angularCropper.cropper.destroy();
      }
      return this[destination];
    };
  }

  uploadFeaturedFile(event: any) {
    this.loading = true;
    event.preventDefault();

    function toFile(b: any, name: string) {
      b.lastModifiedDate = new Date();
      b.name = name;
      return b as File;
    }

    this.angularCropper.cropper.getCroppedCanvas({ maxWidth: 1920, maxHeight: 640, minWidth: 1080, minHeight: 640 }).toBlob(
      (blobToUpload: Blob) => {
        const fileToUpload = toFile(blobToUpload, this.featuredFileToUpload.name);
        this.fileUploadSub = this.mediaMakersService.mediaMakersUpload(
          fileToUpload, '/media_makers_headers',
          null).subscribe(
            uploadProgress => {
              this.handleProgress(uploadProgress);
            },
            error => {
            });
      },
      'image/jpeg', 1);
  }

  handleProgress(event) {
    if (event.type === HttpEventType.DownloadProgress) {
      this.uploadingProgressing = true;
      this.uploadProgress = Math.round(100 * event.loaded / event.total);
    }

    if (event.type === HttpEventType.UploadProgress) {
      this.uploadingProgressing = true;
      this.uploadProgress = Math.round(100 * event.loaded / event.total);
    }

    if (event.type === HttpEventType.Response) {
      // console.log(event.body);
      this.uploadComplete = true;
      this.uploadingProgressing = false;
      this.serverResponse = event.body;

      this.didUploadImage = { id: event.body['@id'], url: event.body['contentUrl'] };
      this.uploadedImage.emit(this.didUploadImage);
      this.loading = false;
    }
  }
}

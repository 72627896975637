<!-- <h1 class="page-title"><i class="fa fa-user small"></i> {{ 'Editar datos de acceso' | translate}}</h1> -->
<div *ngIf="alert.message">
  <p class="alert" [class.alert-success]="alert.type == 1" [class.alert-danger]="alert.type == 2">{{alert.message}}</p>
</div>
<div class="card">
  <div class="card-body p-0">
    <h4 class="border-bottom pb-2 mb-3"><i class="fa fa-lock small mr-1"></i> {{ 'Datos acceso' | translate}}</h4>
    <div>
      <form [formGroup]="userForm">
        <div class="row">
          <div class="col-md-6 form-group">
            <label>Dirección de correo electrónico<span class="form-required text-danger">*</span></label>
            <input placeholder="Dirección de correo electrónico" type="email" class="form-control"
              formControlName="email" disabled>
            <small class="text-danger" *ngIf="showError('email')">
              {{translate.instant(errors['email'])}}
            </small>
          </div>
          <!-- <div class="col-md-6 form-group">
            <label>Dirección de correo electrónico de recuperación</label>
            <input placeholder="Dirección de correo electrónico de recuperación" type="email" class="form-control"
              formControlName="recoverEmail" disabled>
            <small class="text-danger" *ngIf="showError('recoverEmail')">
              {{translate.instant(errors['recoverEmail'])}}
            </small>
          </div> -->
          <div class="col-md-6 form-group">
            <label>{{ 'Cambiar contraseña' | translate }}<span class="form-required text-danger">*</span></label>
            <input [placeholder]="translate.instant('Contraseña')" passToggle maxlength="15" type="password"
              class="form-control" formControlName="password">
            <small class="text-danger" *ngIf="showError('password')">
              {{translate.instant(errors['password'])}}
            </small>
          </div>
        </div>
        <!-- <div class="row">
          <div class="col-md-6 form-group">
            <label>{{ 'Cambiar contraseña' | translate }}<span class="form-required text-danger">*</span></label>
            <input [placeholder]="translate.instant('Contraseña')" passToggle maxlength="15" type="password"
              class="form-control" formControlName="password">
            <small class="text-danger" *ngIf="showError('password')">
              {{translate.instant(errors['password'])}}
            </small>
          </div>
          <div class="col-md-6 form-group">
            <label>{{ 'Cambiar contraseña' | translate }}</label>
            <input [placeholder]="translate.instant('Cambiar contraseña')" passToggle maxlength="15" type="password"
              class="form-control" formControlName="changePassword">
            <small class="text-danger" *ngIf="showError('changePassword')">
              {{translate.instant(errors['changePassword'])}}
            </small>
          </div>
        </div> -->
        <div class="row">
          <div class="col-12 text-right">
            <button type="button" class="btn btn-primary" *ngIf="user" (click)="updateUser()">
              {{ 'Guardar Usuario' | translate}}
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
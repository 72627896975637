import { Component, ElementRef, Inject, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { MakerService } from '../../shared/maker.service';
import { TranslateService } from '@ngx-translate/core';
import { DOCUMENT } from '@angular/common';
import { ExperiencesService } from '../../experiences/experiences/experiences.service';
import { finalize } from 'rxjs/operators';
import { Experience } from '../../experiences/experiences/experience.model';
import { AnalyticsService } from '../../analytics/analytics.service';
import { TakerService } from '../../shared/taker.service';
import KeenSlider, { KeenSliderInstance } from 'keen-slider';

@Component({
  selector: 'app-maker-pin',
  templateUrl: './maker-pin.component.html',
  styleUrls: ['./maker-pin.component.scss',
    '../../../../../node_modules/keen-slider/keen-slider.min.css'],

})
export class MakerPinComponent implements OnInit {

  @ViewChild("pinImagesSlider") pinImagesSlider: ElementRef<HTMLElement>;
  public originalExperiences: Experience[];
  experiences = [];
  takerExperiences = [];
  makerImages = [];
  loading = false;
  makerId = null;
  maker = null;
  cities = null;
  showTab = 'companyData';
  pagesQuantity = 0;
  currentPage = 1;

  currentSlide: number = 0
  dotHelper: Array<Number> = []
  slider: KeenSliderInstance = null

  constructor(
    private titleService: Title,
    private router: Router,
    private makerService: MakerService,
    public translate: TranslateService,
    private renderer2: Renderer2,
    private experienceService: ExperiencesService,
    private takerService: TakerService,
    @Inject(DOCUMENT) private _document

  ) { }

  ngOnInit(): void {
    this.loading = true;
    this.titleService.setTitle('Encuentra tu maker | Venntur');

    var makerSlug = this.router['rawUrlTree'].root.children.primary.segments[2].path;

    this.makerService.getMakerBySlug(makerSlug, this.translate.getDefaultLang()).subscribe(maker => {

      maker = maker['hydra:member'][0];

      this.makerService.getMakerById(maker.id).subscribe(maker => {

        if (maker.mediaMakersDocs.length > 0) {
          maker.mediaMakersDocs.forEach(doc => {

            //cases: Licencia de actividad, Plantilla de contrato de colaboracion, Poliza RRCC, ReciboRRCC, Catalogos

            switch (doc.typeOf) {
              case 'Licencia de actividad':
                doc.typeOf = 'Licencia de actividad';
                break;
              case 'Plantilla de contrato de colaboracion':
                doc.typeOf = 'Plantilla de contrato de colaboración';
                break;
              case 'Poliza RRCC': 
                doc.typeOf = 'Póliza RRCC';
                break;
              case 'Recibo RRCC':
                doc.typeOf = 'Recibo RRCC';
                break;
              case 'Catalogos':
                doc.typeOf = 'Catálogos';
                break;
            }



          });
        }

        this.makerId = maker.id;

        this.maker = maker;

        maker.mediaMakersHeader.forEach(image => {
          this.makerImages.push('https://venntur.com/assets/makerHeader/files/' + image.contentUrl)
        });
        maker.mediaMakersStaff.forEach(image => {
          this.makerImages.push('https://venntur.com/assets/makerStaff/files/' + image.contentUrl)
        });

        this.cities = maker['activeLocations'];
        const s = this.renderer2.createElement('script');
        s.type = 'text/javascript';
        s.src = `https://www.jscache.com/wejs?wtype=cdsratingsonlywide&uniq=609&border=true&lang=en_US&locationId=${maker['tripadvisor']}&border=false&display_version=2`;
        s.text = ``;
        this.renderer2.appendChild(this._document.body, s);

        this.experienceService.getExperiencesByMakerId(this.makerId, {}, 'es')
          .pipe(
            finalize(() => this.loading = false)
          )
          .subscribe((experiences => {
            this.originalExperiences = experiences['hydra:member'];
            this.pagesQuantity = Math.ceil(this.originalExperiences.length / 4)
            console.log(this.pagesQuantity);

          }));

      });


    });
    this.experienceService.getExperiencesByTakerId(this.takerService.getId()).subscribe(experiences => {
      this.takerExperiences = Array.from(experiences['hydra:member']).map((item) => item['@id']);
    });
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.slider = new KeenSlider(this.pinImagesSlider.nativeElement, {
        initial: this.currentSlide,
        slideChanged: (s) => {
          this.currentSlide = s.track.details.rel
        },
      })
      this.dotHelper = [
        ...Array(this.slider.track.details.slides.length).keys(),
      ]
    }, 1000);
  }

  ngOnDestroy() {
    if (this.slider) this.slider.destroy()
  }

  setCategoryTitle(experience) {
    return experience.categories.map(obj => obj.name).join(', ')
  }

  nextPage() {
    if (this.currentPage == this.pagesQuantity) {
      return
    } else {
      this.currentPage++;
    }
  }

  prevPage() {
    if (this.currentPage == 1) {
      return
    } else {
      this.currentPage--;
    }
  }


}

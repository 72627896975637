import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ExperiencesPageComponent } from './pages/experiences/experiences-page/experiences-page.component';
import { HomeComponent } from './pages/home/home.component';
import { ExperiencesListComponent } from './pages/experiences/experiences-list/experiences-list.component';
import { ExperiencesComponent } from './pages/experiences/experiences/experiences.component';
// import { LoginComponent } from './pages/login/login.component';
import { PasswordRecoverComponent } from './pages/users/password-recover/password-recover.component';
import { RegisterComponent } from './pages/register/register.component';
import { RegisterMakerComponent } from './pages/register/register-maker/register-maker.component';
import { MakerAdminComponent } from './pages/maker/maker-admin.component';
import { MakerComponent } from './pages/maker/maker-profile/maker.component';
import { AuthGuard } from './pages/shared/auth-guard.service';
import { UsersComponent } from './shared/users/users.component';
import { TakerComponent } from './pages/taker/taker-profile/taker.component';
import { TakerAdminComponent } from './pages/taker/taker-admin.component';
import { ResetPasswordComponent } from './pages/users/reset-password/reset-password.component';
import { TakerFavoritesComponent } from './pages/taker/taker-favorites/taker-favorites.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { WordpressPageComponent } from './pages/wordpress-page/wordpress-page.component';
import { MakerPageComponent } from './pages/maker/maker-list/maker-page.component';
import { MakerPinComponent } from './pages/maker/maker-pin/maker-pin.component';
import { MakerContactsListComponent } from './pages/maker/maker-contacts/maker-contacts-list/maker-contacts-list.component';
import { MakerContactsComponent } from './pages/maker/maker-contacts/maker-contacts/maker-contacts.component';
import { ContactFormComponent } from './pages/users/contact-form/contact-form.component';
import { ResellerAdminComponent } from './pages/reseller/reseller-admin.component';
import { ResellerComponent } from './pages/reseller/reseller-profile/reseller.component';
import { ResellerContactsListComponent } from './pages/reseller/reseller-contacts/reseller-contacts-list/reseller-contacts-list.component';
import { ResellerContactsComponent } from './pages/reseller/reseller-contacts/reseller-contacts/reseller-contacts.component';
import { RegisterResellerComponent } from './pages/register/register-reseller/register-reseller.component';
import { ResellerMakersListComponent } from './pages/reseller/reseller-makers-list/reseller-makers-list.component';
import { ResellerExperiencesListComponent } from './pages/reseller/reseller-experiences-list/reseller-experiences-list.component';
import { ResellerCountriesListComponent } from './pages/reseller/reseller-countries-list/reseller-countries-list.component';
import { ResellerLocationsListComponent } from './pages/reseller/reseller-locations-list/reseller-locations-list.component';
import { MakerResellersListComponent } from './pages/maker/maker-resellers-list/maker-resellers-list.component';
import { RedirectComponent } from './pages/shared/redirect/redirect.component';
import { MakerGroupComponent } from './pages/maker/maker-contacts/maker-group/maker-group.component';
import { SalesListComponent } from './pages/sales/sales-list/sales-list.component';
import { SalesHistoryComponent } from './pages/sales/sales-history/sales-history.component';
import { AnalyticsComponent } from './pages/analytics/analytics.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { ProductPageComponent } from './pages/product/product-page/product-page.component';
import { ProductPinPageComponent } from './pages/product/product-pin-page/product-pin-page.component';
import { ReviewsPageComponent } from './pages/reviews/reviews-page.component';
import { ProductReviewsListComponent } from './pages/product-reviews-list/product-reviews-list.component';
import { MakerDirectories } from './pages/maker/maker-directories/maker-directories.component';
import { CmsComponent } from './pages/cms/cms.component';
import { WebsComponent } from './pages/webs/webs.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: ':lang', component: HomeComponent },
  { path: ':lang/experiencias', component: ExperiencesPageComponent },
  { path: ':lang/makers', component: MakerDirectories },
  { path: ':lang/maker/:makerName', component: MakerPageComponent },
  { path: ':lang/maker/:id/pin', component: MakerPinComponent },
  { path: ':lang/product/:productName', component: ProductPageComponent },
  // { path: ':lang/product/:productName/:id', component: ProductPageComponent },
  { path: ':lang/product/:productName/pin', component: ProductPinPageComponent },
  {
    path: ':lang/maker/admin',
    component: MakerAdminComponent,
    data: { breadcrumb: 'Organizadores' },
    canActivate: [AuthGuard],
    children: [
      {
        path: 'datos-acceso',
        component: UsersComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'perfil/:plan',
        component: MakerComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'perfil',
        component: MakerComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'dashboard',
        component: DashboardComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'experiencias',
        component: ExperiencesListComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'webs',
        component: WebsComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'cms/:id',
        component: CmsComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'reviews',
        component: ReviewsPageComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'reviews/:id',
        component: ProductReviewsListComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'analiticas',
        component: AnalyticsComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'ventas',
        component: SalesListComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'historial-ventas',
        component: SalesHistoryComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'nueva-experiencia',
        component: ExperiencesComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'editar-experiencia/:id',
        component: ExperiencesComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'mis-resellers',
        component: MakerResellersListComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'contactos',
        component: MakerContactsListComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'nuevo-contacto',
        component: MakerContactsComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'editar-contacto/:id',
        component: MakerContactsComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'nuevo-grupo',
        component: MakerGroupComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'editar-grupo/:id',
        component: MakerGroupComponent,
        canActivate: [AuthGuard]
      },
    ]
  },
  {
    path: ':lang/taker/admin',
    component: TakerAdminComponent,
    data: { breadcrumb: 'Takers' },
    canActivate: [AuthGuard],
    children: [
      {
        path: 'datos-acceso',
        component: UsersComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'perfil',
        component: TakerComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'favoritos',
        component: TakerFavoritesComponent,
        canActivate: [AuthGuard]
      },
    ]
  },
  {
    path: ':lang/reseller/admin',
    component: ResellerAdminComponent,
    data: { breadcrumb: 'Resellers' },
    canActivate: [AuthGuard],
    children: [
      {
        path: 'datos-acceso',
        component: UsersComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'perfil',
        component: ResellerComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'mis-productos',
        component: ResellerExperiencesListComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'mis-makers',
        component: ResellerMakersListComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'mis-paises',
        component: ResellerCountriesListComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'mis-destinos',
        component: ResellerLocationsListComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'contactos',
        component: ResellerContactsListComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'nuevo-contacto',
        component: ResellerContactsComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'editar-contacto/:id',
        component: ResellerContactsComponent,
        canActivate: [AuthGuard]
      }
    ]
  },
  // { path: 'login', component: LoginComponent },
  { path: ':lang/registro', component: RegisterComponent },
  { path: ':lang/registro-maker', component: RegisterMakerComponent },
  { path: ':lang/registro-reseller', component: RegisterResellerComponent },
  { path: ':lang/recuperar-contrasena', component: PasswordRecoverComponent },
  { path: ':lang/formulario-contacto', component: ContactFormComponent },
  { path: ':lang/reset-password/:token', component: ResetPasswordComponent },
  { path: ':lang/categoria/:category', component: WordpressPageComponent },
  { path: ':lang/experiencias/:slug/redirigir', component: RedirectComponent },
  { path: ':lang/:page', component: WordpressPageComponent },
  { path: '**', component: NotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy', scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }

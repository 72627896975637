<div class="container-fluid w-430 py-4 my-5">
  <div class="text-center mb-4">
    <h1>{{ 'Restablecer contraseña' | translate}}</h1>
    <p>{{ 'Por favor, introduce tu contraseña nueva' | translate}}</p>
  </div>
    <form [formGroup]="userResetPasswordForm" (submit)="reset()" class="pb-5">
      <div class="form-group">
        <input passToggle [placeholder]="translate.instant('Contraseña')" type="password" formControlName="password" class="form-control">
        <small class="text-danger" *ngIf="showError('password')">
          {{translate.instant(errors['password'])}}
        </small>
      </div>
      <button class="btn btn-primary w-100" [disabled]="userResetPasswordForm.pristine || userResetPasswordForm.invalid || isLoading">{{ 'Restablecer' | translate}}</button>
      <div class="mt-4" *ngIf="alert.message">
        <p class="alert" [class.alert-success]="alert.type == 1" [class.alert-danger]="alert.type == 2">{{alert.message}}</p>
      </div>
    </form>
</div>

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

const API_URL = environment.apiUrl;

@Injectable()
export class SalesService {
  constructor(
    private http: HttpClient
  ) { }

  public postNotificationEmail(notificationData): Observable<any> {
    return this.http
      .post(API_URL + '/email/send', notificationData);
  }

  public postBookingEmail(bookingData): Observable<any> {
    return this.http
      .post(API_URL + '/send_booking', bookingData);
  }

  public postStopSale(notificationData): Observable<any> {
    return this.http
      .post(API_URL + '/stop_sales', notificationData);
  }

  public getStopSalesByMakerId(id): Observable<any> {
    return this.http
      .get(API_URL + '/stop_sales?maker.id=' + id);
  }

}

import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { errorMessages, regExps } from '../../../shared/custom-validators';
import { MakerService } from '../../../shared/maker.service';
import { environment } from '../../../../../environments/environment';
import { ModalAlertComponent } from 'src/app/shared/modal-alert/modal-alert.component';

const FRONT_URL = environment.frontUrl;

@Component({
  selector: 'app-maker-contacts',
  templateUrl: './maker-contacts.component.html',
  styleUrls: ['./maker-contacts.component.css']
})
export class MakerContactsComponent implements OnInit {
  contactForm: UntypedFormGroup;
  contactImage = null;
  selectedFile: File;
  selectedFiles = [];
  uploadedFiles = [];
  uploadingProgressing = false;
  uploadComplete = false;
  serverResponse: any;
  uploadProgress = 0;
  alert = { message: null, type: null };
  errors = errorMessages;
  maker = null;
  contact = null;
  title = 'Nuevo contacto';
  selectedGroups = [];
  groups = [];

  dialogRef: BsModalRef;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private makerService: MakerService,
    public translate: TranslateService,
    public dialog: BsModalService,
  ) {
    if (this.makerService.getId()) {
      this.maker = '/makers/' + this.makerService.getId();
      this.createForm();
    } else {
      this.router.navigateByUrl('/maker/admin/perfil');
    }
  }

  ngOnInit(): void {
    if (this.activatedRoute.snapshot.params.id) {
      this.title = 'Editar contacto';
      setTimeout(() => {
        this.editContact(this.activatedRoute.snapshot.params.id);
      }, 1000);
    }


  }

  createForm() {
    this.makerService.getContactsGroupsByMakerId(this.makerService.getId()).subscribe((res: any) => {

      this.groups = res['hydra:member'];

    });
    this.contactForm = this.formBuilder.group({
      mediaContacts: [[],
        // Validators.required
      ],
      name: ['',
        Validators.required
      ],
      group: [null
      ],
      position: ['UNDEFINED',
        // Validators.required
      ],
      email: ['', [
        Validators.required,
        Validators.email
      ]
      ],
      phone: ['+', [
        Validators.required,
        Validators.pattern(regExps.phone)
      ]
      ],

      company: ['', Validators.required],
      maker: [this.maker]
    });
  }

  editContact(contactId) {
    this.makerService.getContact(contactId, this.translate.getDefaultLang()).subscribe((contact: any) => {
      this.contact = contact;

      this.contactForm.get('name').setValue(contact.name);
      this.contactForm.get('position').setValue(contact.position);
      this.contactForm.get('email').setValue(contact.email);
      this.contactForm.get('phone').setValue(contact.phone);
      this.contactForm.get('company').setValue(contact.company);
      this.contactForm.get('group').setValue(contact.contactGroups.length > 1 ? contact.contactGroups[0]['@id'] : []);
      var contactGroup = { value: contact.contactGroups[0]['@id'], name: contact.contactGroups[0].name };
      this.contactForm.get('group').setValue(contactGroup);


      this.selectedGroups = [];

      // if(contact.contactGroups.length > 1){
      //   contact.contactGroups.forEach(group => {
      //     this.selectedGroups.push({value: group['@id'], name: group.name});
      //   });

      //   this.contactForm.get('group').setValue(this.selectedGroups);


      // } else {
      //   this.selectedGroups.push({value: contact.contactGroups[0]['@id'], name: contact.contactGroups[0].name});
      //   var contactGroup = {value: contact.contactGroups[0]['@id'], name: contact.contactGroups[0].name};
      //   this.contactForm.get('group').setValue(contactGroup);
      // }
      /*  contact.contactGroups.forEach(group => {
         this.selectedGroups.push({value: group['@id'], name: group.name});
       }); */


      //this.contactForm.get('group').setValue(this.selectedGroups);

      if (contact.mediaContacts.length > 0) {
        this.contactForm.get('mediaContacts').setValue([contact.mediaContacts[0]['@id']]);
        this.contactImage = '/assets/makerContact/files/' + contact.mediaContacts[0]['contentUrl'];
      }
    });
  }

  updateContact() {
    if (this.contactForm.invalid) {
      this.showErrors(this.contactForm);
      this.openAlert('Ha habido un error!', 'w-300', false)
      return;
    }

    // var groupsIris = [];

    console.log(this.contactForm.get('group').value)

    // if(this.contactForm.get('group').value){
    //   if(this.contactForm.get('group').value.length > 1){
    //     this.contactForm.get('group').value.forEach(group => {
    //       groupsIris.push(group['@id']);
    //     });
    //   } else {
    //     groupsIris.push(this.contactForm.get('group').value['@id']);
    //   }
    // } else {
    //   groupsIris = null;
    // }

    /* if(this.contactForm.get('group').value.length > 1){
      this.contactForm.get('group').value.forEach(group => {
        groupsIris.push(group['@id']);
      });
    } else {
      groupsIris.push(this.contactForm.get('group').value['@id']);
    } */

    /* this.contactForm.get('group').value.forEach(group => {
      groupsIris.push(group['@id']);
    }); */

    let contactGroup = [];
    if (this.contactForm.get('group').value && this.contactForm.get('group').value['@id']) {
      contactGroup = [this.contactForm.get('group').value['@id']];
    } else if (this.contactForm.get('group').value && this.contactForm.get('group').value.value) {
      contactGroup = [this.contactForm.get('group').value.value]
    }


    const editedContact = {
      name: this.contactForm.get('name').value,
      position: this.contactForm.get('position').value,
      email: this.contactForm.get('email').value,
      phone: this.contactForm.get('phone').value,
      company: this.contactForm.get('company').value,
      mediaContacts: this.contactForm.get('mediaContacts').value,
      maker: this.contactForm.get('maker').value,
      contactGroups: contactGroup
    };

    this.makerService.putContact(this.contact.id, editedContact, this.translate.getDefaultLang())
      .subscribe(
        (result) => {
          // this.alert.type = 1;
          // this.alert.message = 'Contacto guardado correctamente';
          // setTimeout(() => {
          //   this.alert = { message: null, type: null };
          // }, 2000);
          // this.contact = null;
          // this.createForm();
          // location.replace(this.translate.getDefaultLang() + '/maker/admin/contactos');
          this.openAlert('Contacto Guardado!', 'w-300', true)
        },
        error => {
          this.alert.message = error.message;
          this.alert.type = 2;
          setTimeout(() => {
            this.alert = { message: null, type: null };
          }, 5000);
        },
        () => console.log('complete'));
  }

  createContact() {
    if (this.contactForm.invalid) {
      this.showErrors(this.contactForm);
      this.openAlert('Ha habido un error!', 'w-300', false)
      return;
    }
    const contact = {
      mediaContacts: this.contactForm.get('mediaContacts').value,
      name: this.contactForm.get('name').value,
      position: this.contactForm.get('position').value,
      email: this.contactForm.get('email').value,
      phone: this.contactForm.get('phone').value,
      company: this.contactForm.get('company').value,
      maker: this.contactForm.get('maker').value,
      group: this.contactForm.get('group').value,
    };
    this.makerService.postContact(contact).subscribe(() => {
      // this.alert.type = 1;
      // this.alert.message = this.translate.instant('Contacto creado correctamente');
      // setTimeout(() => {
      //   location.replace(this.translate.getDefaultLang() + '/maker/admin/contactos');
      //   this.alert = { message: null, type: null };
      // }, 2000);
      this.openAlert('Contacto Guardado!', 'w-300', true)
    }, (error) => {
      // this.alert.message = error.error.code + '- ' + error.error.message;
      // this.alert.type = 2;
      // setTimeout(() => {
      //   this.alert = { message: null, type: null };
      // }, 5000);
      this.openAlert('Ha habido un error!', 'w-300', false)
    });
  }

  onFileChanged(event) {
    if (event.target.files[0].type === 'image/jpeg' || event.target.files[0].type === 'image/png') {
      this.selectedFile = event.target.files[0];
    }
  }

  onUploadedImage(uploadedImage: object, formControlName: string) {
    if (formControlName === 'mediaContacts') {
      this.contactForm.get(formControlName).patchValue([uploadedImage['id']]);
      this.contactImage = uploadedImage['url'];
    }
  }

  showErrors(formGroup) {
    Object.keys(formGroup.controls).forEach(key => {
      formGroup.get(key).markAsTouched();
    });
    return;
  }

  showError(formValue) {
    return this.contactForm.get(formValue).invalid && this.contactForm.get(formValue).touched;
  }

  changeLanguage(language) {
    this.translate.setDefaultLang(language);
    setTimeout(() => {
      this.editContact(this.activatedRoute.snapshot.params.id);
    }, 100);
  }

  openAlert(modalText: string, className: string, status: boolean) {
    let iconClass: string;
    if (status) {
      iconClass = 'vi-check';
    } else {
      iconClass = 'vi-sad';
    }
    const modalConfig = {
      class: className,
      initialState: {
        alertMessage: this.translate.instant(
          modalText
        ),
        iconClass,
        status
      }
    }
    this.dialogRef = this.dialog.show(ModalAlertComponent, modalConfig);
    setTimeout(() => {
    this.dialogRef.hide();
    }, 2500);
  }
}

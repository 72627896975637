<!-- <button class="btn btn-secondary btn-icon w-100 mb-3" (click)="loginWithFB()"><span class="vi-facebook"></span><span class="btn-icon-text">{{ 'Login con Facebook' | translate}}</span></button>
<button class="btn btn-secondary btn-icon w-100" (click)="loginWithGoogle()"><span class="vi-google"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span></span><span class="btn-icon-text">{{ 'Login con Google' | translate}}</span></button>
 -->
<!-- <button class="btn btn-secondary btn-icon w-100" id="g_id_onload" data-client_id="TU_CLIENT_ID_DE_GOOGLE"
  data-login_uri="http://localhost:4200" data-callback="handleCredentialResponse" data-auto_prompt="false"
  (click)="loginWithGoogle()"><span class="vi-google"><span class="path1"></span><span class="path2"></span><span
      class="path3"></span><span class="path4"></span></span><span class="btn-icon-text">{{ 'Login con Google' |
    translate}}</span></button> -->

    <div id="g_id_onload" data-client_id="306399346139-snsajbsqgf2dc48eeptm1ir0v94k00hi.apps.googleusercontent.com"
      data-callback="handleCredentialResponse" data-auto_prompt="false">
    </div>
    
    <div class="g_id_signin" data-type="standard" data-size="large" data-theme="outline" data-text="sign_in_with"
      data-shape="rectangular" data-logo_alignment="left">
    </div>
    <div id="buttonDiv"></div>

<div class="my-3 h4 text-center">{{ 'o' | translate}}</div>
<form (ngSubmit)="login()" [formGroup]="loginForm" novalidate>
  <div class="form-group">
    <input [placeholder]="translate.instant('Correo electrónico')" type="text" class="form-control" formControlName="email">
  </div>
  <div class="form-group">
    <input type="password" passToggle [placeholder]="translate.instant('Contraseña')" class="form-control" formControlName="password">
  </div>
  <button class="btn btn-primary w-100 mt-3" type="submit">{{ 'Iniciar sesión' | translate}}</button>
</form>
<div class="mt-3" *ngIf="alert.message">
  <p class="alert" [class.alert-success]="alert.type == 1" [class.alert-danger]="alert.type == 2">{{alert.message}}</p>
</div>
<div class="text-center">
  <a [href]="translate.getDefaultLang() + '/recuperar-contrasena/'" class="small">{{ '¿Olvidaste la contraseña?' | translate}}</a>
  <div class="mt-4 pt-3">
    <div>
      <div class="h4">{{ '¿No tienes cuenta?' | translate}}</div>
      <p class="small">{{ 'Regístrate como:' | translate}}</p>
      <div class="h5">
        <a href="https://pro.venntur.com/" class="text-dark" target="_blank">{{ 'Proveedor' | translate}}</a>
        <!-- <a href="https://pro.venntur.com/" class="text-dark" target="_blank">{{ 'Proveedor' | translate}}</a> | -->
        <!-- <a [href]="translate.getDefaultLang() + '/registro'" class="text-dark">{{ 'Viajero' | translate}}</a> |
        <a [href]="translate.getDefaultLang() + '/registro-reseller'" class="text-dark">{{ 'Agencia' | translate}}</a> -->
      </div>
  </div>
</div>

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Taker } from '../taker/taker-profile/taker.model';


const API_URL = environment.apiUrl;

@Injectable()
export class TakerService {
  constructor(private http: HttpClient) { }

  public getTakerByUserId(userId: number): Observable<any> {
    return this.http
      .get<any>(API_URL + '/users/' + userId + '/taker');
  }

  public getTaker(takerId: number): Observable<any> {
    return this.http
      .get<any>(API_URL + takerId);
  }

  public getName() {
    return JSON.parse(localStorage.getItem('taker')) ? JSON.parse(localStorage.getItem('taker'))['name'] : null;
  }

  public getId() {
    return JSON.parse(localStorage.getItem('taker')) ? JSON.parse(localStorage.getItem('taker'))['id'] : null;
  }

  public putTaker(takerId: string, taker: any): Observable<Taker> {
    return this.http
      .put<Taker>(API_URL + '/takers/' + takerId, taker);
  }

  public postTaker(taker): Observable<Taker> {
    return this.http
      .post<Taker>(API_URL + '/takers', taker);
  }
}

import {Component, OnInit} from '@angular/core';
import { Experience } from '../../experiences/experiences/experience.model';
import { ExperiencesService } from '../../experiences/experiences/experiences.service';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import {TakerService} from '../../shared/taker.service';

@Component({
  selector: 'app-taker-favorites',
  templateUrl: './taker-favorites.component.html',
  styleUrls: ['./taker-favorites.component.scss']
})
export class TakerFavoritesComponent implements OnInit {
  public expandedIndex = -1;
  public originalExperiences: Experience[];
  experiences;
  public toggleFav = false;

  throttle = 150;
  scrollDistance = 0.1;
  notEmptyExperience = true;
  notScrolly = true;
  loading = false;

  constructor(
    private experienceService: ExperiencesService,
    private takerService: TakerService,
    private router: Router,
    private titleService: Title,
  ) {
  }

  ngOnInit(): void {
    // this.loading = true;
    this.experienceService.getExperiencesByTakerId(this.takerService.getId()).subscribe((experiences => {
      this.originalExperiences = experiences[ 'hydra:member' ];
      this.experiences = this.originalExperiences.slice(0, 10);
    }));
  }

  clickFavorite(event) {
    this.toggleFav = !this.toggleFav;
  }

  expandText(index: number): void {
    this.expandedIndex = index === this.expandedIndex ? -1 : index;
  }

  onScroll() {
    this.loading = true;
    if (this.notScrolly && this.notEmptyExperience) {
      this.notScrolly = false;
      setTimeout(() => {
        this.loadNextExperience();
      }, 500);
    } else {
      this.loading = false;
    }
  }

  loadNextExperience() {
    if (this.experiences.length < this.originalExperiences.length) {
      let i;
      const length = this.experiences.length;
      const experiencePerPage = 9;
      const experienceToLoad = this.originalExperiences.length - length;

      if ( experienceToLoad >= experiencePerPage ) {
        for (i = length; i <= length + experiencePerPage; i++) {
          this.experiences.push(this.originalExperiences[i]);
        }
      } else {
        for (i = length; i <= length + experienceToLoad - 1; i++) {
          this.experiences.push(this.originalExperiences[i]);
        }
      }
      this.notScrolly = true;
    } else {
      this.notEmptyExperience = false;
    }
    this.loading = false;
  }
}

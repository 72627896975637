import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

import { TranslateService } from '@ngx-translate/core';
import { ResellerService } from '../../../shared/reseller.service';
import { ConfirmationDialogComponent } from '../../../shared/confirmation-dialog/confirmation-dialog.component';
import { maxTableRows } from 'globals';

@Component({
  selector: 'app-reseller-contacts-list',
  styleUrls: ['./reseller-contacts-list.component.scss'],
  templateUrl: './reseller-contacts-list.component.html',

})
export class ResellerContactsListComponent implements OnInit {
  maxTableRows = maxTableRows;
  columns = [];
  rows = [];
  searchForm: UntypedFormGroup;
  alert = {message: null, type: null};
  @ViewChild(DatatableComponent) table: DatatableComponent;

  dialogRef: BsModalRef;

  constructor(
    private resellerService: ResellerService,
    private searchFormBuilder: UntypedFormBuilder,
    public dialog: BsModalService,
    public translate: TranslateService
  ) {
    this.resellerService.getContactsByResellerId(resellerService.getId()).subscribe((contacts => {
      this.rows = contacts[ 'hydra:member' ];
    }));
  }

  ngOnInit(): void {
    this.searchForm = this.searchFormBuilder.group({
      name: [''],
      position: [''],
      email: [''],
      phone: [''],
      id: ['']
    });
    this.columns = [
      { prop: 'name', name: 'Nombre' },
      { prop: 'position', name: 'Cargo' },
      { prop: 'email', name: 'Email' },
      { prop: 'phone', name: 'Teléfono' }];
  }

  applyFilter() {
    this.resellerService.getContactsByResellerId(this.resellerService.getId()).subscribe(
      data => {
        this.rows = data['hydra:member'];
        this.table.offset = 0;
      }
    );
  }

  deleteContact(contactId) {
    this.dialogRef = this.dialog.show(ConfirmationDialogComponent, {
      initialState: {
        confirmMessage: '¿Seguro que quieres eliminar este contacto?',
        callback: (confirm) => {
          if (confirm) {
            this.resellerService.deleteContact(contactId).subscribe(
              (result) => {
                this.alert.type = 1;
                this.alert.message = 'Contacto eliminado correctamente';
                setTimeout(() => {
                  this.alert = {message: null, type: null};
                }, 5000);
              },
              error => {
                this.alert.message = error.error.code + '- ' + error.error.message;
                this.alert.type = 2;
                setTimeout(() => {this.alert = {message: null, type: null};
                }, 5000);
              },
            );
            setTimeout(() => {
              this.resellerService.getContactsByResellerId(this.resellerService.getId()).subscribe((contacts => {
                this.rows = contacts[ 'hydra:member' ];
                this.table.offset = 0;
              }));
            }, 1000);
          }
          this.dialogRef = null;
        }
      }
    });
   }

}

import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ExperiencesService } from '../../experiences/experiences/experiences.service';
import { ResellerService } from '../../shared/reseller.service';
import { CategoriesService } from '../../../shared/categories/categories.service';
import { Location } from '../../../shared/locations/location.model';
import { LocationService } from '../../../shared/locations/location.service';
import { Router } from '@angular/router';
import { Tag } from '../../../shared/tags/tag.model';
import {TagsService} from '../../../shared/tags/tags.service';
import { maxTableRows } from 'globals';

@Component({
  selector: 'app-reseller-experiences-list',
  styleUrls: ['./reseller-experiences-list.component.scss'],
  templateUrl: './reseller-experiences-list.component.html',

})
export class ResellerExperiencesListComponent implements OnInit {
  maxTableRows = maxTableRows;
  columns = [];
  rows = [];
  resellerId;
  resellerExperiences = [];
  statusArray = ['Pendiente contacto', 'Contactado', 'Contratado', 'Terminado'];
  isFavorites = true;
  tags: Tag[] = [];
  tagsSelected = [];
  locations: Location[] = [];
  locationsSelected = [];
  searchForm: UntypedFormGroup;
  alert = {message: null, type: null};
  @ViewChild(DatatableComponent) table: DatatableComponent;
  dialogRef: BsModalRef;

  constructor(
    private experienceService: ExperiencesService,
    private resellerService: ResellerService,
    private router: Router,
    private tagsService: TagsService,
    private locationsService: LocationService,
    private searchFormBuilder: UntypedFormBuilder,
    public dialog: BsModalService,
  ) {
    this.tagsService.getActiveTags('es').subscribe(tags => {
      this.tags = tags['hydra:member'];
    });
    this.locationsService.getActiveLocations().subscribe(locations => {
        this.locations = locations['hydra:member'];
      }
    );
    this.resellerId = this.resellerService.getId();
    if (!this.router['rawUrlTree'].queryParams['maker']) {
      this.experienceService.getExperiencesByResellerId(this.resellerId, {}).subscribe(experiences => {
        this.resellerExperiences = experiences[ 'hydra:member' ];
      });
    }
  }

  ngOnInit(): void {
    this.searchForm = this.searchFormBuilder.group({
      name: [''],
      maker: [''],
      locations: [''],
      tags: [''],
      isFavorites: [true],
      resellerId: [this.resellerId],
      id: ['']
    });
    if (this.router['rawUrlTree'].queryParams['maker']) {
      this.searchForm.get('maker').setValue(this.router['rawUrlTree'].queryParams['maker']);
      this.searchForm.get('isFavorites').setValue(false);
      this.isFavorites = false;
    }
    this.columns = [
      { prop: 'experience.id', name: 'ID' },
      { prop: 'experience.name', name: 'Nombre' },
      { prop: 'experience.location.name', name: 'Ubicación' },
      { prop: 'experience.maker.name', name: 'Maker' }];

    this.applyFilter();
  }

  applyFilter() {
    if (!this.searchForm.value.isFavorites) {
      this.experienceService.getExperiences(this.searchForm.value, 'es').subscribe(
        data => {
          this.experienceService.getExperiencesByResellerId(this.resellerId, {}).subscribe(experiences => {
            this.resellerExperiences = Array.from(experiences['hydra:member']).map((item) => item['experience']['@id']);
          });
          this.rows = data['hydra:member'];
          this.table.offset = 0;
          this.isFavorites = false;
          this.columns = [
            { prop: 'id', name: 'ID' },
            { prop: 'name', name: 'Nombre' },
            { prop: 'location.name', name: 'Ubicación' },
            { prop: 'maker.name', name: 'Maker' }];
        }
      );
    } else {
      this.experienceService.getExperiencesByResellerId(this.resellerId, this.searchForm.value).subscribe(
        data => {
          this.rows = data['hydra:member'];
          this.table.offset = 0;
          this.isFavorites = true;
          this.columns = [
            { prop: 'experience.id', name: 'ID' },
            { prop: 'experience.name', name: 'Nombre' },
            { prop: 'experience.location.name', name: 'Ubicación' },
            { prop: 'experience.maker.name', name: 'Maker' }];
        }
      );
    }
  }

  addFavorite(event, experienceId) {
    this.resellerExperiences.includes(experienceId) ?
      this.resellerExperiences.splice(this.resellerExperiences.indexOf(experienceId), 1) : this.resellerExperiences.push(experienceId);
    const favoriteExperience = {
      reseller: '/resellers/' + this.resellerId,
      experience: experienceId,
      status: 'Pendiente contacto'
    };
    this.resellerService.postFavoriteExperience(favoriteExperience).subscribe();
  }

  removeFavorite(favoriteExperienceId) {
    this.resellerService.deleteFavoriteExperience(favoriteExperienceId).subscribe( () => {
        this.applyFilter();
      }
    );
  }

  changeStatus(status, favoriteExperienceId) {
    this.resellerService.putFavoriteExperience(favoriteExperienceId, {status: status}).subscribe();
  }
}

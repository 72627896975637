import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-secondary-menu',
  styleUrls: ['./secondary-menu.component.scss'],
  templateUrl: './secondary-menu.component.html',
})
export class SecondaryMenuComponent implements OnInit {
  isCollapsed = true;

  constructor(
    public translate: TranslateService
  ) { }

  ngOnInit(): void {
  }

}

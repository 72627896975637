import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { errorMessages, regExps } from '../../../shared/custom-validators';
import { MakerService } from '../../../shared/maker.service';
import { environment } from '../../../../../environments/environment';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalAlertComponent } from 'src/app/shared/modal-alert/modal-alert.component';

const FRONT_URL = environment.frontUrl;

@Component({
  selector: 'app-maker-group',
  templateUrl: './maker-group.component.html',
  styleUrls: ['./maker-group.component.scss']
})
export class MakerGroupComponent implements OnInit {

  groupForm: FormGroup;
  contactImage = null;
  selectedFile: File;
  selectedFiles = [];
  uploadedFiles = [];
  uploadingProgressing = false;
  uploadComplete = false;
  serverResponse: any;
  uploadProgress = 0;
  alert = { message: null, type: null };
  errors = errorMessages;
  maker = null;
  contact = null;
  group = null;
  title = 'Nuevo grupo';
  selectedContacts = [];
  contacts: any = [];

  dialogRef: BsModalRef;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private makerService: MakerService,
    public translate: TranslateService,
    public dialog: BsModalService
  ) {
    if (this.makerService.getId()) {
      this.maker = '/makers/' + this.makerService.getId();
      this.createForm();
    } else {
      this.router.navigateByUrl('/maker/admin/perfil');
    }
  }

  ngOnInit(): void {
    if (this.activatedRoute.snapshot.params.id) {
      this.title = 'Editar grupo';
      setTimeout(() => {
        this.editGroup(this.activatedRoute.snapshot.params.id);
      }, 1000);
    }
  }


  createForm() {
    this.makerService.getContactsByMakerId(this.makerService.getId()).subscribe((res: any) => {
      var hydraContacts = [];

      res['hydra:member'].forEach(contact => {
        // if (contact.contactGroups.length > 0 && contact.contactGroups[0].id != this.activatedRoute.snapshot.params.id) {
        //   hydraContacts.splice(hydraContacts.indexOf(contact), 1);
        // }
        // if (contact.contactGroups.length == 0 || contact.contactGroups[0].id == this.activatedRoute.snapshot.params.id) {
        hydraContacts.push(contact);
        // }
      });

      this.contacts = hydraContacts;

    });

    this.groupForm = this.formBuilder.group({

      name: [null,
        Validators.required
      ],
      contact: [[], Validators.required], // Agrega el control 'contact' con valor inicial null
    });
  }

  editGroup(contact_groupsId: any) {


    this.makerService.getGroup(contact_groupsId, this.translate.getDefaultLang()).subscribe((group: any) => {
      this.group = group;

      this.groupForm.get('name').setValue(group.name);

      const contactsMaker = [];
      group.contacts.forEach(contact => {
        contactsMaker.push({ name: contact.name, value: contact['@id'] });
      });

      console.log(contactsMaker)

      this.groupForm.get('contact').setValue(contactsMaker);


    });
  }

  updateGroup() {
    if (this.groupForm.invalid) {
      this.showErrors(this.groupForm);
      this.openAlert('Ha habido un error!', 'w-300', false)
      return;
    }

    var contactIris = [];
    this.groupForm.get('contact').value.forEach(contact => {
      contactIris.push(contact.value ? contact.value : contact['@id']);
    });

    console.log(contactIris)

    const editedGroup = {
      name: this.groupForm.get('name').value,
      contacts: contactIris
      // maker: this.groupForm.get('maker').value,
    };

    this.makerService.putGroupIri(this.group['@id'], editedGroup, this.translate.getDefaultLang())
      .subscribe(
        (result) => {
          // this.alert.type = 1;
          // this.alert.message = 'Grupo guardado correctamente';
          // setTimeout(() => {
          //   this.alert = { message: null, type: null };
          // }, 2000);
          this.contact = null;
          this.createForm();
          location.replace(this.translate.getDefaultLang() + '/maker/admin/contactos');
          this.openAlert('Grupo guardado!', 'w-300', true)
        },
        error => {
          // this.alert.message = error.message;
          // this.alert.type = 2;
          // setTimeout(() => {
          //   this.alert = { message: null, type: null };
          // }, 5000);
          this.openAlert('Ha habido un error!', 'w-300', false)
        },
        () => console.log('complete'));
  }

  createGroup() {
    if (this.groupForm.invalid) {
      this.showErrors(this.groupForm);
      this.openAlert('Ha habido un error!', 'w-300', false)
      return;
    }

    var contactsIris = [];

    this.groupForm.get('contact').value.forEach(contact => {
      contactsIris.push(contact['@id']);
    });

    const group = {
      name: this.groupForm.get('name').value,
      contacts: contactsIris,
      maker: '/makers/' + this.makerService.getId()
      // maker: this.groupForm.get('maker').value
      // name: document.getElementById('name').innerHTML,
      // maker: this.groupForm.get('maker').value

    };
    this.makerService.postGroup(group).subscribe(() => {
      // this.alert.type = 1;
      // this.alert.message = this.translate.instant('Grupo creado correctamente');
      // setTimeout(() => {
      //   location.replace(this.translate.getDefaultLang() + '/maker/admin/contactos');
      //   this.alert = { message: null, type: null };
      // }, 2000);
      this.openAlert('Grupo creado!', 'w-300', true)
    }, (error) => {
      // this.alert.message = error.error.code + '- ' + error.error.message;
      // this.alert.type = 2;
      // setTimeout(() => {
      //   this.alert = { message: null, type: null };
      // }, 5000);
      this.openAlert('Ha habido un error!', 'w-300', false)
    });
  }

  onFileChanged(event) {
    if (event.target.files[0].type === 'image/jpeg' || event.target.files[0].type === 'image/png') {
      this.selectedFile = event.target.files[0];
    }
  }

  onUploadedImage(uploadedImage: object, formControlName: string) {
    if (formControlName === 'mediaContacts') {
      this.groupForm.get(formControlName).patchValue([uploadedImage['id']]);
      this.contactImage = FRONT_URL + uploadedImage['url'];
    }
  }

  showErrors(formGroup) {
    Object.keys(formGroup.controls).forEach(key => {
      formGroup.get(key).markAsTouched();
    });
    return;
  }

  showError(formValue) {
    return this.groupForm.get(formValue).invalid && this.groupForm.get(formValue).touched;
  }

  changeLanguage(language) {
    this.translate.setDefaultLang(language);
    setTimeout(() => {
      this.editGroup(this.activatedRoute.snapshot.params.id);
    }, 100);
  }

  openAlert(modalText: string, className: string, status: boolean) {
    let iconClass: string;
    if (status) {
      iconClass = 'vi-check';
    } else {
      iconClass = 'vi-sad';
    }
    const modalConfig = {
      class: className,
      initialState: {
        alertMessage: this.translate.instant(
          modalText
        ),
        iconClass,
        status
      }
    }
    this.dialogRef = this.dialog.show(ModalAlertComponent, modalConfig);
    setTimeout(() => {
      this.dialogRef.hide();
    }, 2500);
  }
}

<div class="py-4">
    <div id="accordion">
        <div class="card shadow">
            <div class="card-header p-0" id="headingOne">
                <h5 class="mb-0">
                    <button class="btn btn-link w-100" data-toggle="collapse" data-target="#collapseOne"
                        aria-expanded="true" aria-controls="collapseOne">
                        <div class="d-flex flex-column align-items-start">
                            <span class="title">{{ '¿Qué te ha parecido ésta actividad?' | translate }}</span>
                            <span class="subtitle">{{ 'Tu opinión nos ayuda a mejorar la experiencia de nuestros clientes en el futuro.' | translate }}</span>
                        </div>
                    </button>
                </h5>
            </div>
            <div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                <div class="card-body">
                    <form [formGroup]="reviewForm" (submit)="createReview()">
                        <div class="row gx-3">
                            <div class="col-lg-4 d-flex flex-column">
                                <div class="border-bottom mb-3">
                                    <label for="name">{{ 'Nombre' | translate }}</label>
                                    <input placeholder="--" type="text" name="name" formControlName="name">
                                </div>
                                <div class="border-bottom mb-3">
                                    <label for="location">{{ 'Localidad' | translate }}</label>
                                    <input placeholder="--" type="text" name="location" formControlName="location">
                                </div>
                            </div>
                            <div class="col-lg-8">
                                <label for="comments">{{ 'Comentario' | translate }}</label>
                                <textarea formControlName="comments" placeholder="--" class="border-bottom"
                                    name="comments" id="" cols="30" rows="4"></textarea>
                            </div>
                        </div>
                        <div class="row mt-auto align-items-center">
                            <div class="col-lg-6">
                                <label for="stars">{{ 'Valoración' | translate }}</label>
                                <div class="stars mt-2" name="stars" #starsRef>
                                    <span (click)="setRating(i)" class="vi-star-empty" [ngClass]="'star-' + i"
                                        *ngFor="let item of [].constructor(5);let i = index"></span>
                                </div>
                            </div>
                            <div class="col-lg-6 text-right mt-auto">
                                <button type="submit" class="btn btn-primary">{{ 'Enviar' | translate }}</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
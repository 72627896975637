<div class="elemento1">
  <div class="input-group mb-4">
    <div class="input-group-prepend">
      <span class="input-group-text" id="inputMediaExperiences"><i class="fa fa-image"></i></span>
    </div>
    <div class="custom-file">
      <input multiple #fileInput1 type="file" id="media" class="custom-file-input" accept=".png, .jpg, .jpeg"
        aria-describedby="inputMediaMakers" (change)="insertImage($event.target.files, 'featuredImageTempFw')"
        style="display: none" [lang]="'es' | translate">
      <label class="custom-file-label" #labelUpload for="media">{{ 'Seleccionar una Imagen' | translate}}</label>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-6 border-right">
    <p class="small font-weight-bold" *ngIf="featuredImageTempFw">{{ 'Selecciona el area de la Imagen' | translate}}</p>
    <angular-cropper #fwangularCropper [cropperOptions]="config" [imageUrl]="featuredImageTempFw"
      *ngIf="featuredImageTempFw"></angular-cropper>
  </div>
  <div class="col-6 previewer">
    <p class="small font-weight-bold" *ngIf="featuredImageTempFw">{{ 'Previsualización' | translate}}</p>
    <div>
      <div [ngClass]="preview" class="float-none" *ngIf="featuredImageTempFw"></div>
    </div>
    <button class="btn btn-success uploader float-none" type="button" (click)="uploadFeaturedFile($event)"
      *ngIf="featuredImageTempFw" [disabled]="uploadingProgressing">
      <span>{{ 'Subir Imagen' | translate}}</span>
    </button>
    <div *ngIf="loading" class="text-center">
      <div class="spinner-border text-primary" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
  </div>
</div>
import { Component, OnInit } from '@angular/core';
import { AnalyticsService } from './analytics.service';
import { MakerService } from '../shared/maker.service';
import { TranslateService } from '@ngx-translate/core';
import Chart from 'chart.js/auto';

@Component({
  selector: 'app-analytics',
  templateUrl: './analytics.component.html',
  styleUrls: ['./analytics.component.scss']
})
export class AnalyticsComponent implements OnInit {
  title = 'Analíticas';
  directoryAnalytics: any;
  showTab = 'directory';
  interactionChart: any;
  directoryChart: any;
  currentDate: any;
  activeFilter = 'months';

  labelsArray = [];
  brandImpressionsArray = [];
  brandVisitsArray = [];
  productsImpressionsArray = [];
  maker:any;

  constructor(private analyticsService: AnalyticsService, private makerService: MakerService, public translate: TranslateService) { }

  ngOnInit(): void {
    var currentDate = new Date();

    // Format the date as 'YYYY-MM-DD'
    this.currentDate = currentDate.toISOString().split('T')[0];
    this.analyticsService.getDirectoryAnalyticsByMakerId(this.makerService.getId()).subscribe(((resp: any) => {
      this.directoryAnalytics = resp['hydra:member'][0];
      this.createInteractionChart();
      this.createDirectoryChart();
    }))

    this.makerService.getMaker('/makers/'+this.makerService.getId()).subscribe(((resp: any) => {
      this.maker = resp;
    }))
  }

  createInteractionChart() {
    this.labelsArray = [];
    let dataArray = [];
    if (this.activeFilter == 'hours') {
      this.labelsArray = Array.from({ length: 24 }, (_, i) => (i < 10 ? '0' + i : i.toString()));
      this.labelsArray.forEach(hour => {
        dataArray.push(this.directoryAnalytics['calculateAnalyticsHour']['interactions'][hour] ? this.directoryAnalytics['calculateAnalyticsHour']['interactions'][hour] : 0);
      });
    } else if (this.activeFilter == 'days') {
      const today = new Date();

      for (let i = 0; i < 30; i++) {
        const currentDate = new Date(today);
        currentDate.setDate(today.getDate() - i);

        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, '0');
        const day = String(currentDate.getDate()).padStart(2, '0');

        this.labelsArray.push(`${year}-${month}-${day}`);
      }

      this.labelsArray = this.labelsArray.reverse();

      this.labelsArray.forEach(day => {
        dataArray.push(this.directoryAnalytics['calculateAnalyticsDayMonth']['interactionsDays'][day] ? this.directoryAnalytics['calculateAnalyticsDayMonth']['interactionsDays'][day] : 0);
      });
    } else {
      const today = new Date();

      for (let i = 0; i < 12; i++) {
        const currentMonth = new Date(today);
        currentMonth.setMonth(today.getMonth() - i);

        const year = currentMonth.getFullYear();
        const month = String(currentMonth.getMonth() + 1).padStart(2, '0');

        this.labelsArray.push(`${year}-${month}`);
      }

      this.labelsArray = this.labelsArray.reverse();

      this.labelsArray.forEach(month => {
        dataArray.push(this.directoryAnalytics['calculateAnalyticsDayMonth']['interactionsMonths'][month] ? this.directoryAnalytics['calculateAnalyticsDayMonth']['interactionsMonths'][month] : 0);
      });
    }

    setTimeout(() => {
      const data = {
        labels: this.labelsArray,
        datasets: [
          {
            label: 'Directorio',
            data: dataArray,
            borderColor: '#ff341f82',
            backgroundColor: '#ff341f82',
            pointStyle: 'circle',
            pointRadius: 5,
            pointHoverRadius: 8
          },
          {
            label: 'Totales',
            data: dataArray,
            borderColor: '#f9c64875',
            backgroundColor: '#f9c64875',
            pointStyle: 'circle',
            pointRadius: 5,
            pointHoverRadius: 8
          }
        ]
      };

      this.interactionChart = new Chart("interactionChart", {
        type: 'line', //this denotes tha type of chart

        data: data,
        options: {
          responsive: true,
          plugins: {
            title: {
              display: true,
              // text: (ctx) => 'Point Style: ' + ctx.chart.data.datasets[0].pointStyle,
            }
          }
        }

      });
    }, 500);
  }

  createDirectoryChart() {
    this.brandImpressionsArray = [];
    this.brandVisitsArray = [];
    this.productsImpressionsArray = [];

    // let labelsArray = [];
    if (this.activeFilter == 'hours') {
      // labelsArray = Array.from({ length: 24 }, (_, i) => (i < 10 ? '0' + i : i.toString()));

      this.labelsArray.forEach(hour => {
        this.brandImpressionsArray.push(this.directoryAnalytics['calculateAnalyticsHour']['directory']['brandImpressions'][hour] ? this.directoryAnalytics['calculateAnalyticsHour']['directory']['brandImpressions'][hour] : 0);
        this.brandVisitsArray.push(this.directoryAnalytics['calculateAnalyticsHour']['directory']['brandVisits'][hour] ? this.directoryAnalytics['calculateAnalyticsHour']['directory']['brandVisits'][hour] : 0);
        this.productsImpressionsArray.push(this.directoryAnalytics['calculateAnalyticsHour']['directory']['productsImpressions'][hour] ? this.directoryAnalytics['calculateAnalyticsHour']['directory']['productsImpressions'][hour] : 0);
      });
    } else if (this.activeFilter == 'days') {
      // const today = new Date();

      // for (let i = 0; i < 30; i++) {
      //   const currentDate = new Date(today);
      //   currentDate.setDate(today.getDate() - i);

      //   const year = currentDate.getFullYear();
      //   const month = String(currentDate.getMonth() + 1).padStart(2, '0');
      //   const day = String(currentDate.getDate()).padStart(2, '0');

      //   this.labelsArray.push(`${year}-${month}-${day}`);
      // }

      // this.labelsArray = labelsArray.reverse();

      this.labelsArray.forEach(day => {
        this.brandImpressionsArray.push(this.directoryAnalytics['calculateAnalyticsDayMonth']['directory']['brandImpressionsDays'][day] ? this.directoryAnalytics['calculateAnalyticsDayMonth']['directory']['brandImpressionsDays'][day] : 0);
        this.brandVisitsArray.push(this.directoryAnalytics['calculateAnalyticsDayMonth']['directory']['brandVisitsDays'][day] ? this.directoryAnalytics['calculateAnalyticsDayMonth']['directory']['brandVisitsDays'][day] : 0);
        this.productsImpressionsArray.push(this.directoryAnalytics['calculateAnalyticsDayMonth']['directory']['productsImpressionsDays'][day] ? this.directoryAnalytics['calculateAnalyticsDayMonth']['directory']['productsImpressionsDays'][day] : 0);
      });
    } else {
      // const today = new Date();

      // for (let i = 0; i < 12; i++) {
      //   const currentMonth = new Date(today);
      //   currentMonth.setMonth(today.getMonth() - i);

      //   const year = currentMonth.getFullYear();
      //   const month = String(currentMonth.getMonth() + 1).padStart(2, '0');

      //   labelsArray.push(`${year}-${month}`);
      // }

      // labelsArray = labelsArray.reverse();

      this.labelsArray.forEach(month => {
        this.brandImpressionsArray.push(this.directoryAnalytics['calculateAnalyticsDayMonth']['directory']['brandImpressionsMonths'][month] ? this.directoryAnalytics['calculateAnalyticsDayMonth']['directory']['brandImpressionsMonths'][month] : 0);
        this.brandVisitsArray.push(this.directoryAnalytics['calculateAnalyticsDayMonth']['directory']['brandVisitsMonths'][month] ? this.directoryAnalytics['calculateAnalyticsDayMonth']['directory']['brandVisitsMonths'][month] : 0);
        
        if(this.directoryAnalytics['calculateAnalyticsDayMonth']['directory']['productsImpressionsMonths']) {
          this.productsImpressionsArray.push(this.directoryAnalytics['calculateAnalyticsDayMonth']['directory']['productsImpressionsMonths'][month] ? this.directoryAnalytics['calculateAnalyticsDayMonth']['directory']['productsImpressionsMonths'][month] : 0);
        } else {
          this.productsImpressionsArray.push(0);
        }
      });
    }

    setTimeout(() => {
      const data = {
        labels: this.labelsArray,
        datasets: [
          {
            label: 'Impresiones de marca',
            data: this.brandImpressionsArray,
            borderColor: '#ff341f82',
            backgroundColor: '#ff341f82',
            pointStyle: 'circle',
            pointRadius: 5,
            pointHoverRadius: 8
          },
          {
            label: 'Visitas ficha maker',
            data: this.brandVisitsArray,
            borderColor: '#f9c64875',
            backgroundColor: '#f9c64875',
            pointStyle: 'circle',
            pointRadius: 5,
            pointHoverRadius: 8
          },
          {
            label: 'Impresiones de productos',
            data: this.productsImpressionsArray,
            borderColor: '#e5408f7d',
            backgroundColor: '#e5408f7d',
            pointStyle: 'circle',
            pointRadius: 5,
            pointHoverRadius: 8
          }
          // {
          //   label: 'Visitas ficha productos',
          //   data: [0, 1, 2, 1, 2, 3, 1, 3, 2, 0, 1, 2, 1, 2, 3, 1, 3, 2, 0, 1, 2, 1, 2, 3, 1, 3],
          //   borderColor: '#0867ff80',
          //   backgroundColor: '#0867ff80',
          //   pointStyle: 'circle',
          //   pointRadius: 5,
          //   pointHoverRadius: 8
          // }
        ]
      };

      this.directoryChart = new Chart("directoryChart", {
        type: 'line', //this denotes tha type of chart

        data: data,
        options: {
          responsive: true,
          plugins: {
            title: {
              display: true,
              // text: (ctx) => 'Point Style: ' + ctx.chart.data.datasets[0].pointStyle,
            }
          }
        }

      });
    }, 500);
  }

  changeFilter(type: any) {
    this.activeFilter = type;
    this.interactionChart.destroy();
    this.directoryChart.destroy();
    this.createInteractionChart();
    this.createDirectoryChart();
  }
}

import { Component, OnInit } from '@angular/core';
import { UsersService } from '../../shared/users/users.service';
import { ActivatedRoute } from '@angular/router';
import {DomSanitizer, Meta} from '@angular/platform-browser';
import {finalize} from 'rxjs/operators';

@Component({
  selector: 'app-wordpress-page',
  templateUrl: './wordpress-page.component.html',
  styleUrls: ['./wordpress-page.component.scss']
})
export class WordpressPageComponent implements OnInit {

  href = null;
  constructor(
    private activatedRoute: ActivatedRoute,
    private userService: UsersService,
    private sanitizer: DomSanitizer,
    private metaService: Meta
  ) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      if (params.page) {
        this.userService.getWordpressPage(params.page).subscribe( page => {
          if (page.length === 0) {
            this.userService.getWordpressPost(params.page).subscribe( post => {
              this.loadCSS(post[0].id, post[0].content.css_files);
              this.loadHead(post[0].head_tags);
              document.getElementById('wordpress').innerHTML = post[0].content.rendered;
            });
          } else {
            this.loadCSS(page[0].id, page[0].content.css_files);
            this.loadHead(page[0].head_tags);
            document.getElementById('wordpress').innerHTML = page[0].content.rendered;
          }
        });
      } else if (params.category) {
        this.userService.getWordpressPostsByCategory(params.category).subscribe( page => {
          this.loadCSS(page[0].id, page[0].content.css_files);
          this.loadHead(page[0].head_tags);
          document.getElementById('wordpress').innerHTML = page[0].content.rendered;
          page[0].content.categories.forEach( category => {
            let categories = Array.from(document.getElementsByClassName('category-' + category));
            categories.forEach(category => {
              if (!category.className.includes('category-' + params.category)) {
                category.remove();
              }
            });
          });
        });
      }
    });
  }

  loadHead(params) {
    params.forEach(param => {
      if (param.tag === 'meta') {
        this.metaService.addTag({name: param.attributes.name, content: param.attributes.content});
      }
    });
  }

  loadCSS(id, cssFiles) {
    const link = document.createElement('link');
    link.href = 'https://pro.venntur.com/wp-content/themes/Divi/style.dev.css';
    link.rel = 'stylesheet';
    link.type = 'text/css';

    const head = document.getElementsByTagName('head')[0];
    const style = head.getElementsByTagName('style')[0];
    head.insertBefore(link, style);

    // const link5 = document.createElement('link');
    // link5.href = 'https://wp.ttl.aititubi.es/wp-content/themes/Divi/style.dev.css';
    // link5.rel = 'stylesheet';
    // link5.type = 'text/css';
    //
    // const head5 = document.getElementsByTagName('head')[0];
    // const style5 = head.getElementsByTagName('style')[0];
    // head5.insertBefore(link5, style5);

    const link2 = document.createElement('link');
    link2.href = 'https://pro.venntur.com/wp-content/themes/venntur/style.css?ver=4.9.1';
    link2.rel = 'stylesheet';
    link2.type = 'text/css';

    const head2 = document.getElementsByTagName('head')[0];
    const style2 = head2.getElementsByTagName('style')[0];
    head2.insertBefore(link2, style2);

    // const link3 = document.createElement('link');
    // link3.href = 'https://pro.venntur.com/wp-content/plugins/monarch/css/style.css?ver=1.4.14';
    // link3.rel = 'stylesheet';
    // link3.type = 'text/css';
    //
    // const head3 = document.getElementsByTagName('head')[0];
    // const style3 = head3.getElementsByTagName('style')[0];
    // head3.insertBefore(link3, style3);

    if (cssFiles) {
      const link4 = document.createElement('link');
      link4.href = `https://pro.venntur.com/wp-content/et-cache/${id}/${cssFiles[2]}`;
      link4.rel = 'stylesheet';
      link4.type = 'text/css';

      const head4 = document.getElementsByTagName('head')[0];
      const style4 = head4.getElementsByTagName('style')[0];
      head4.insertBefore(link4, style4);
    }

    const script = document.createElement('script');

    script.setAttribute('src', 'https://pro.venntur.com/wp-content/themes/Divi/includes/builder/frontend-builder/build/frontend-builder-global-functions.js');

    document.head.appendChild(script);

    setTimeout(() => {
      const script2 = document.createElement('script');
      script2.setAttribute('src', 'https://pro.venntur.com/wp-content/themes/Divi/includes/builder/scripts/ext/salvattore.min.js?ver=4.9.1');
      document.body.appendChild(script2);
    }, 500);
  }
}

<h1 class="page-title"><i class="fa fa-address-book small mr-3"></i> {{translate.instant(title)}}</h1>
<ul class="nav nav-tabs mb-n1 text-uppercase small">
  <li class="nav-item">
    <a class="nav-link active font-weight-bold" (click)="changeLanguage('es')">Español</a>
  </li>
  <!--  <li class="nav-item">-->
  <!--    <a class="nav-link active bg-light" (click)="changeLanguage('en')">Inglés</a>-->
  <!--  </li>-->
</ul>
<div class="card">
  <div class="card-body p-4">
    <form [formGroup]="contactForm" class="maker-admin-form">
      <div class="row">
        <div class="col-md-6 form-group">
          <label>{{ 'Nombre' | translate}} <span class="form-required text-danger">*</span></label>
          <input [placeholder]="translate.instant('Nombre')" type="text" class="form-control" formControlName="name">
          <small class="text-danger" *ngIf="showError('name')">
            {{translate.instant(errors['contactName'])}}
          </small>
        </div>
        <!-- <div class="col-md-6 form-group">
          <label>{{ 'Cargo' | translate}} <span class="form-required text-danger">*</span></label>
          <input [placeholder]="translate.instant('Cargo')" type="text" class="form-control" formControlName="position">
          <small class="text-danger" *ngIf="showError('position')">
            {{translate.instant(errors['contactPosition'])}}
          </small>
        </div> -->
        <div class="col-md-6 form-group">
          <label>{{ 'Empresa' | translate}} <span class="form-required text-danger">*</span></label>
          <input [placeholder]="translate.instant('Empresa')" type="text" class="form-control"
            formControlName="company">
          <small class="text-danger" *ngIf="showError('company')">
            {{errors['company']}}
          </small>
        </div>
        <div class="col-md-6 form-group">
          <label>{{ 'Email' | translate}} <span class="form-required text-danger">*</span></label>
          <input [placeholder]="translate.instant('Email')" type="text" class="form-control" formControlName="email">
          <small class="text-danger" *ngIf="showError('email')">
            {{translate.instant(errors['contactEmail'])}}
          </small>
        </div>
        <div class="col-md-6 form-group">
          <label>{{ 'Teléfono' | translate}} <span class="form-required text-danger">*</span></label>
          <input [placeholder]="translate.instant('Teléfono')" type="text" class="form-control" formControlName="phone">
          <small class="text-danger" *ngIf="showError('phone')">
            {{translate.instant(errors['contactPhone'])}}
          </small>
        </div>



        <div class="col-md-6 form-group">
          <label>{{ 'Grupos de contactos' | translate}} <span class="form-required text-danger">*</span></label>

          <ng-select [multiple]="false" [searchable]="true" [items]="groups" formControlName="group" bindLabel="name"
            placeholder="{{ translate.instant('Seleccionar grupos') }}">
          </ng-select>
        </div>
      </div>
      <div class="row">
        <div class="col form-group">
          <label for="mediaCropper">{{ 'Subir avatar contacto' | translate}} <span class="form-required text-danger">*</span> </label>
          <small class="d-block text-muted mb-2">{{ 'Selecciona un avatar de contacto. Formatos aceptados: .jpeg, .jpg, .png' | translate}}</small>
          <small class="form-text text-muted">{{ 'Dimensiones recomendadas:' | translate}} 150x150</small>
          <app-cropper id="mediaCropper" [type]="'contact'" [aspectratio]= "1" [preview]="'preview1'" (uploadedImage)="onUploadedImage($event, 'mediaContacts')"></app-cropper>
          <small class="text-danger" *ngIf="showError('mediaContacts')">
            {{translate.instant(errors['mediaContacts'])}}
          </small>
        </div>
      </div>
      <div *ngIf="contactImage" class="mb-4">
        <p class="small">Avatar subido:</p>
        <img [src]="'https://venntur.com' + contactImage" height="150" width="150"> <br/>
      </div>
      <div class="row">
        <div class="col-12">
          <button type="button" class="btn btn-primary mr-2 mb-2" *ngIf="contact" (click)="updateContact()">
            {{ 'Guardar contacto' | translate}}
          </button>
          <button type="button" class="btn btn-primary mr-2 mb-2" *ngIf="!contact" (click)="createContact()">
            {{ 'Crear nuevo contacto' | translate}}
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
<div class="mt-2" *ngIf="alert.message">
  <p class="alert" [class.alert-success]="alert.type == 1" [class.alert-danger]="alert.type == 2">{{alert.message}}</p>
</div>
import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { TranslateService } from '@ngx-translate/core';
import { errorMessages } from 'src/app/pages/shared/custom-validators';
import { CmsService } from '../service/cms.service';

@Component({
  selector: 'app-cms-module',
  templateUrl: './cms-module.component.html',
  styleUrls: ['./cms-module.component.scss']
})
export class CmsModuleComponent implements OnInit {
  public Editor = ClassicEditor;
  cmsForm: UntypedFormGroup;

  @Input() module;
  @Input() currentModule;

  mediaExperiences = [];
  expandedMediaExperiences = [];
  uploadedFiles = [];

  showTab = 'question1';
  isMicroSheetValid = false;
  isWebSheetValid = false;

  isTooManyImages = false;
  errors = errorMessages;

  pages = [];

  @Input() orderClass;

  constructor(private formBuilder: UntypedFormBuilder, public translate: TranslateService, public cmsService: CmsService) { }

  ngOnInit(): void {
    this.createForm();

    this.currentModule.fields = [];

  }

  createForm() {
    this.cmsForm = this.formBuilder.group({
      mediaExperiences: [null, [Validators.required, Validators.minLength(5), Validators.maxLength(11)]],
      mainSectionButtonText: [''],
      mainSectionTitleText: [''],
      mainSectionSubtitleText: [''],
      destinySectionTitleText: [''],
      destinySectionDescriptionText: [''],
      bannerSectionTitleText: [''],
      bannerSectionDescriptionText: [''],
      aboutUsSectionTitleText: [''],
      aboutUsSectionDescriptionText: [''],
      faqSectionTitleText: [''],
      faqSectionDescriptionText: ['']
    });
  }

  onUploadedImage(uploadedImage: object, formControlName: string) {
    this.mediaExperiences.push({
      url: uploadedImage['url'],
      id: uploadedImage['id'],
    });
    this.expandedMediaExperiences.push({
      url: uploadedImage['url'],
      id: uploadedImage['id'],
    });
    this.uploadedFiles.push(uploadedImage['id']);
    this.cmsForm.get(formControlName).patchValue(this.uploadedFiles);
  }

  showError(formValue) {
    return (
      false
    )
  }

}

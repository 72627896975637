import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { MakerService } from '../shared/maker.service';
import { ExperiencesService } from '../experiences/experiences/experiences.service';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { maxTableRows } from 'globals';

@Component({
  selector: 'app-reviews-page',
  templateUrl: './reviews-page.component.html',
  styleUrls: ['./reviews-page.component.scss']
})
export class ReviewsPageComponent implements OnInit {
  maxTableRows = maxTableRows;
  title = 'Reviews';
  searchForm: UntypedFormGroup;
  @ViewChild(DatatableComponent) table: DatatableComponent;

  columns = [];
  rows = [];

  maker: any = [];

  constructor(
    public translate: TranslateService,
    private searchFormBuilder: UntypedFormBuilder,
    private experienceService: ExperiencesService,
    private makerService: MakerService,
  ) {    

    this.experienceService.getAllExperiencesByMakerId(makerService.getId()).subscribe((experiences => {
      this.rows = experiences['hydra:member'];
    }));

    this.makerService.getMaker('/makers/' + makerService.getId()).subscribe((maker => {
      this.maker = maker;
    }));
  }

  ngOnInit(): void {
    this.searchForm = this.searchFormBuilder.group({
      name: [''],
    });
    this.columns = [
      // { prop: 'id', name: 'ID' },
      { prop: 'name', name: 'Experiencia' },
      { prop: 'reviews.length', name: 'Reviews' }];
  }

  applyFilter() {
    this.experienceService.getAllExperiencesByMakerId(this.makerService.getId(), this.searchForm.value).subscribe(
      data => {
        this.rows = data['hydra:member'];
        this.table.offset = 0;
      }
    )
  }


  importReviews(makerId) {

    this.experienceService.importReviews(makerId).subscribe(
      data => {
        console.log(data);
      }
    )

  }


}

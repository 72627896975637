import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { errorMessages } from '../../shared/custom-validators';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { UntypedFormGroup } from '@angular/forms';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-cms-field',
  templateUrl: './cms-field.component.html',
  styleUrls: ['./cms-field.component.scss']
})
export class CmsFieldComponent implements OnInit {
  public Editor = ClassicEditor;
  cmsForm: UntypedFormGroup;

  @Input() field;
  @Input() currentField;

  errors = errorMessages;
  isTooManyImages = false;
  CMS_API_URL = environment.cmsApiUrl

  constructor(public translate: TranslateService) { }

  ngOnInit(): void {

  }

  // onUploadedImage(uploadedImage: any, formControlName: string) {
  //   console.log(uploadedImage);
    // this.field.value.valueMedia.push(uploadedImage['id']);
  // }

  // onUploadedImage(uploadedImage: object, formControlName: string) {
  //   this.mediaExperiences.push({
  //     url: uploadedImage['url'],
  //     id: uploadedImage['id'],
  //   });
  //   this.expandedMediaExperiences.push({
  //     url: uploadedImage['url'],
  //     id: uploadedImage['id'],
  //   });
  //   this.uploadedFiles.push(uploadedImage['id']);
  //   this.experienceForm.get(formControlName).patchValue(this.uploadedFiles);
  // }

  showError(formValue) {
    return (
      false
    )
  }

}

import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ExperiencesService } from '../experiences/experiences.service';
import { MakerService } from '../../shared/maker.service';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ConfirmationDialogComponent } from '../../shared/confirmation-dialog/confirmation-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { maxTableRows } from 'globals';


@Component({
  selector: 'app-experiences-list',
  styleUrls: ['./experiences-list.component.scss'],
  templateUrl: './experiences-list.component.html',

})


export class ExperiencesListComponent implements OnInit {
  maxTableRows = maxTableRows;
  columns = [];
  rows = [];
  searchForm: UntypedFormGroup;
  alert = { message: null, type: null };
  modalRef: BsModalRef;
  @ViewChild(DatatableComponent) table: DatatableComponent;

  dialogRef: BsModalRef;

  actualUrl: string = window.location.href;
  makerPinUrl = 'https://venntur.com/';
  productPinUrl = 'https://venntur.com/';
  maker: any = [];
  experiences: any = [];
  language: string;
  copied: boolean = false;

  maxExperiences: any;
  publishedExperiences = 0;
  productsSelected = 1;
  currentProducts = 0;
  regulation = 'monthly'

  constructor(
    private experienceService: ExperiencesService,
    private makerService: MakerService,
    private searchFormBuilder: UntypedFormBuilder,
    public dialog: BsModalService,
    public translate: TranslateService,
    public modalService: BsModalService

  ) {
    this.experienceService.getAllExperiencesByMakerId(makerService.getId()).subscribe((experiences => {
      this.rows = experiences['hydra:member'];
      this.rows.forEach(experience => {
        if (experience.isActive) {
          this.publishedExperiences++
        }
      });
    }));

    this.makerService.getMaker('/makers/'+makerService.getId()).subscribe((maker => {
      this.maker = maker;
      this.maxExperiences = this.maker['makerPlan']['plan']['maxProducts'];
    }));

  }

  ngOnInit(): void {
    this.searchForm = this.searchFormBuilder.group({
      name: [''],
      maker: [''],
      location: [''],
      id: ['']
    });
    this.columns = [
      // { prop: 'id', name: 'ID' },
      { prop: 'name', name: 'Nombre' },
      { prop: 'location.name', name: 'Ubicación' }];
  }

  applyFilter() {
    this.experienceService.getAllExperiencesByMakerId(this.makerService.getId(), this.searchForm.value).subscribe(
      data => {
        this.rows = data['hydra:member'];
        this.table.offset = 0;
      }
    )
  }

  deleteExperience(experienceId) {
    this.dialogRef = this.dialog.show(ConfirmationDialogComponent, {
      initialState: {
        confirmMessage: this.translate.instant('¿Seguro que quieres eliminar esta experiencia?'),
        callback: (confirm) => {
          if (confirm) {
            this.experienceService.deleteExperience(experienceId).subscribe(
              (result) => {
                this.alert.type = 1;
                this.alert.message = 'Experiencia eliminada correctamente';
                setTimeout(() => {
                  this.alert = { message: null, type: null };
                }, 5000);
              },
              error => {
                this.alert.message = error.error.code + '- ' + error.error.message;
                this.alert.type = 2;
                setTimeout(() => {
                  this.alert = { message: null, type: null };
                }, 5000);
              },
            );
            setTimeout(() => {
              this.experienceService.getAllExperiencesByMakerId(this.makerService.getId()).subscribe((experiences => {
                this.rows = experiences['hydra:member'];
                this.table.offset = 0;
              }));
            }, 1000);
          }
          this.dialogRef = null;
        }
      }
    });
  }

  openShareModal(template: TemplateRef<any>, config: any) {
    if (config.initialState.data.message.type == 'maker') {
      this.maker.name = this.maker.name.replace(/ /g, '-');
      this.maker.name = this.maker.name.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

      if (this.actualUrl.includes('/en/')) {
        this.language = 'en';
      } else if (this.actualUrl.includes('/es/')) {
        this.language = 'es';
      } else if (this.actualUrl.includes('/de/')) {
        this.language = 'de';
      } else {
        this.language = 'es';
      }


      this.makerPinUrl = 'https://venntur.com/' + this.language + '/maker/' + this.maker.slug + '/pin';
      config.initialState.data.message.url = this.makerPinUrl;
    }

    if (config.initialState.data.message.type == 'product') {

      var product = config.initialState.data.message.product;

      product.name = product.name.replace(/ /g, '-');
      product.name = product.name.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
      //remove . : , ? ¿ = ( ) ' " ` from name
      product.name = product.name.replace(/[.,?:¿=()'"`]/g, '');

      if (this.actualUrl.includes('/en/')) {
        this.language = 'en';
      } else if (this.actualUrl.includes('/es/')) {
        this.language = 'es';
      } else if (this.actualUrl.includes('/de/')) {
        this.language = 'de';
      } else {
        this.language = 'es';
      }

      this.productPinUrl = 'https://venntur.com/' + this.language + '/product/' + product.slug + '/pin';
      config.initialState.data.message.url = this.productPinUrl;
    }

    if (config.initialState.data.message.url) {
      console.log(config.initialState.data.message.url);
    }



    this.modalRef = this.modalService.show(template, config);
  }

  openMoreProductsModal(template: TemplateRef<any>, config: any) {
    this.modalRef = this.modalService.show(template, config);
  }

  changeLang() {
    this.translate.setDefaultLang('es');
  }

  downloadQRCode() {
    //Select the qr code image from the html
    var qrCode = document.getElementById('qrCodeImage');
    //get image tag from qr code
    var qrCodeImage = qrCode.getElementsByTagName('img')[0];

    // save the image to the local file system
    var link = document.createElement('a');
    link.download = 'Venntur-Pin-QR.png';
    link.href = qrCodeImage.getAttribute('src');
    link.click();
  }

  copyLink() {
    var copyText = document.getElementById('pinLink') as HTMLInputElement;

    var elementoTemp = document.createElement("textarea");
    elementoTemp.value = copyText.value;
    document.body.appendChild(elementoTemp);
    elementoTemp.select();
    document.execCommand("copy");
    document.body.removeChild(elementoTemp);
    /* 
        //copy the value of the input pinLink
    
        console.log(copyText.value);
    
        //save the value of the input in the clipboard
        console.log(copyText.select());
    
    
    
        
        document.execCommand('copy'); */

    //show alert
    var alert = document.getElementById('alertCopy');
    this.copied = true;
    setTimeout(() => {
      this.copied = false;
    }, 3000);
  }

  shareUrl(){
    var copyText = document.getElementById('pinLink') as HTMLInputElement;

    var enlace = copyText.value;

    var mensaje = this.translate.instant('¡Mira esta experiencia en Venntur!');

    if(enlace.includes('maker')){
      mensaje = this.translate.instant('¡Mira mis productos en Venntur!');
    }


    mensaje = encodeURIComponent(mensaje);
    enlace = encodeURIComponent(enlace);

    var enlaceWhatsApp = 'https://wa.me/?text=' + mensaje + '%20' + enlace;

    window.open(enlaceWhatsApp);

  }
}

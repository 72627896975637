export interface CountryInterface {
  id: string;
  text: string;
  code: string;
}

export class Country implements CountryInterface {
  id: string;
  text: string;
  code: string;

  constructor(values: any = {}) {
    Object.assign(this, values);
  }

  getName() {
    if (this['country']) {
      return this.text;
    }
    return this.text;
  }
}

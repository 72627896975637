import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ExperiencesService } from '../../experiences/experiences/experiences.service';
import { MakerService } from '../../shared/maker.service';
import { BsDatepickerConfig, BsLocaleService } from 'ngx-bootstrap/datepicker';
import { listLocales } from 'ngx-bootstrap/chronos';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { SalesService } from '../sales.service';
import { maxTableRows } from 'globals';
import { ModalAlertComponent } from 'src/app/shared/modal-alert/modal-alert.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

@Component({
  selector: 'app-sales-list',
  templateUrl: './sales-list.component.html',
  styleUrls: ['./sales-list.component.scss']
})
export class SalesListComponent implements OnInit {
  public Editor = ClassicEditor;
  
  maxTableRows = maxTableRows;

  @ViewChild('myTable') table: any;
  alert = { message: null, type: null };
  showTab = 0; // 0
  steps = ['Seleccionar productos', 'Seleccionar fechas', 'Seleccionar contactos', 'Editar mensaje y envíar', 'Cierre de ventas'];
  tabs = ['products', 'stopSales', 'contacts', 'send']
  monthList: string[] = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
  columns = [];
  rows = [];
  columnsContacts = [];
  rowsContacts = [];
  makerId = localStorage.getItem('maker')['id'];
  locale = 'es';
  locales = listLocales();
  bsConfig?: Partial<BsDatepickerConfig>;
  bsInlineRangeValue: Date[];
  selectedRangeDate = [];
  isMobile: boolean;
  currentNode = 1;
  flameFill = 0;
  productDetails = [];
  contactDetails = [];
  groupDetails = [];
  stopSalesDetails = [];
  searchForm: UntypedFormGroup;
  contactSearchForm: UntypedFormGroup;
  notificationEmailForm: UntypedFormGroup;
  notificationEmailData = {
    products: this.productDetails,
    dates: this.selectedRangeDate,
    contacts: this.contactDetails,
    groups: this.groupDetails,
    extraContacts: [],
    subject: null,
    message: null,
    maker: null
  }
  maker;
  makerName = '';
  contactsEmails = [];
  productList = '';
  editorTemplate = '';
  editorTemplateStaticData = '';
  contacts = [{ 'id': 'contacts', 'name': 'Contactos' }, { 'id': 'groups', 'name': 'Grupos' }]
  listType = 'contacts';
  isDecreasing = false;
  firstStepNumber = 1;
  firstStepText = this.steps[this.firstStepNumber - 1]
  secondStepNumber = this.firstStepNumber + 1;
  secondStepText = this.steps[this.secondStepNumber - 1]
  productCheckboxValue = false;
  contactCheckboxValue = false;
  dialogRef: BsModalRef;

  constructor(
    public translate: TranslateService,
    private router: Router,
    public experiencesService: ExperiencesService,
    public makerService: MakerService,
    public salesService: SalesService,
    public bsLocaleService: BsLocaleService,
    private searchFormBuilder: UntypedFormBuilder,
    public dialog: BsModalService
  ) {
    this.bsConfig = Object.assign({}, { displayOneMonthRange: !this.isMobile, showWeekNumbers: false });
    this.bsLocaleService.use('es');//fecha en español, datepicker
    this.experiencesService.getAllExperiencesByMakerId(makerService.getId()).subscribe((experiences => {
      this.rows = experiences['hydra:member'];
    }));
    this.makerService.getContactsByMakerId(makerService.getId()).subscribe((contacts => {
      this.rowsContacts = contacts['hydra:member'];
    }));
    this.makerService.getMaker('/makers/' + makerService.getId(), this.translate.getDefaultLang()).subscribe((maker => {
      this.makerName = maker.name;
      this.maker = maker;
    }));


  }

  ngOnInit(): void {
    this.detectMobile();
    this.makerId = this.router['rawUrlTree'].queryParams['id'];

    this.searchForm = this.searchFormBuilder.group({
      name: [''],
      maker: [''],
      location: [''],
      id: ['']
    });
    this.contactSearchForm = this.searchFormBuilder.group({
      contactName: [''],
      companyName: ['']
    });
    this.notificationEmailForm = this.searchFormBuilder.group({
      contacts: [''],
      subject: [''],
      message: ['']
    });
    this.columns = [
      { prop: 'name', name: 'Nombre' }
    ];
    this.columnsContacts = [
      { prop: 'name', name: 'Nombre' },
      { prop: 'company', name: 'Empresa' },
      { prop: 'email', name: 'Email' },
      { prop: 'phone', name: 'Teléfono' },
      { prop: 'contactGroups', name: 'Grupo' },
    ];

  }

  stopSales() {
    this.notificationEmailData.subject = 'STOP SALES - ' + this.makerName + (this.notificationEmailForm.value['subject'] != '' ? ' - ' + this.notificationEmailForm.value['subject'] : '')
    this.notificationEmailData.message = this.notificationEmailForm.value['message']
    if (this.notificationEmailData.message == '') {
      this.notificationEmailData.message = this.editorTemplateStaticData;
    }
    this.notificationEmailData.maker = this.maker
    this.salesService.postNotificationEmail(this.notificationEmailData).subscribe(
      response => {
        this.sendPostSale();

        const currentDiv = document.getElementById('4');

        currentDiv?.nextElementSibling.firstElementChild.classList.remove('glowing');

        const nextDiv = document.getElementById('5');
        nextDiv?.classList.add('active');

        setTimeout(() => {
          nextDiv?.classList.add('final-state');
        }, 2000);

        setTimeout(() => {
          nextDiv?.nextElementSibling.firstElementChild.classList.add('active');
          nextDiv?.nextElementSibling.firstElementChild.classList.add('glowing');
        }, 2000);


        this.currentNode++;
        this.flameFill += 25;
        this.setProgress(this.flameFill / 100);

        this.addNumbersToMobileProgressBar(5)
        this.openAlert('Cierre de ventas enviado!', 'w-300', true)
      },
      error => {
        console.log('error al enviar el email');

        console.log(error);
        this.openAlert('Ha habido un error!', 'w-300', false)
      });
  }

  changeLang() {
    this.translate.setDefaultLang('es');
  }

  detectMobile() {
    const userAgent = navigator.userAgent.toLowerCase();
    this.isMobile = /mobile|android|iphone|ipad|ipod|blackberry|windows phone/i.test(userAgent);
  }

  nextTab(bool?: any) {
    if (bool != false) {
      this.showTab++
      if (this.showTab != 0) {
        this.notificationEmailData.contacts.forEach(contact => {
          if (!this.contactsEmails.includes(contact.email)) {
            this.contactsEmails.push(contact.email);
          }
        });
        this.notificationEmailForm.get('contacts').setValue(this.contactsEmails);

      }
    }

    if (this.productDetails.length > 0 && this.selectedRangeDate.length == 0) {
      const currentDiv = document.getElementById('1');

      currentDiv?.nextElementSibling.firstElementChild.classList.remove('glowing');

      const nextDiv = document.getElementById('2');
      nextDiv?.classList.add('active');

      setTimeout(() => {
        nextDiv?.classList.add('final-state');
      }, 2000);

      setTimeout(() => {
        nextDiv?.nextElementSibling.firstElementChild.classList.add('active');
        nextDiv?.nextElementSibling.firstElementChild.classList.add('glowing');
      }, 2000);
    }

    if (this.selectedRangeDate.length > 0 && this.contactDetails.length == 0) {
      const currentDiv = document.getElementById('2');

      currentDiv?.nextElementSibling.firstElementChild.classList.remove('glowing');

      const nextDiv = document.getElementById('3');
      nextDiv?.classList.add('active');

      setTimeout(() => {
        nextDiv?.classList.add('final-state');
      }, 2000);

      setTimeout(() => {
        nextDiv?.nextElementSibling.firstElementChild.classList.add('active');
        nextDiv?.nextElementSibling.firstElementChild.classList.add('glowing');
      }, 2000);
    }

    if (this.contactDetails.length > 0) {
      const currentDiv = document.getElementById('3');

      currentDiv?.nextElementSibling.firstElementChild.classList.remove('glowing');

      const nextDiv = document.getElementById('4');
      nextDiv?.classList.add('active');

      setTimeout(() => {
        nextDiv?.classList.add('final-state');
      }, 2000);

      setTimeout(() => {
        nextDiv?.nextElementSibling.firstElementChild.classList.add('active');
        nextDiv?.nextElementSibling.firstElementChild.classList.add('glowing');
      }, 2000);
    }

    this.currentNode++;
    this.flameFill += 25;
    this.setProgress(this.flameFill / 100);

    if (this.flameFill == 1) {
      const fireDiv = document.querySelector('.path');
      fireDiv.classList.add('glowing');
    }

    if (this.showTab == 3) {
      let rangeList = ' ';
      this.selectedRangeDate.forEach(range => {
        let date1 = new Date(range[0])
        let day1 = date1.getDate()
        let month1 = date1.getMonth() + 1
        let year1 = date1.getFullYear()

        let date2 = new Date(range[1])
        let day2 = date2.getDate()
        let month2 = date2.getMonth() + 1
        let year2 = date2.getFullYear()

        rangeList += day1 + '/' + month1 + '/' + year1 + ' - ' + day2 + '/' + month2 + '/' + year2 + '<br>'
      });

      this.editorTemplateStaticData = '';
      this.editorTemplate = '<div class="content">' +
        '<p>Hola [contactname],</p>' +
        '<p>Informamos de los siguientes cierres de venta:</p>' +
        '<br>';
      this.productDetails.forEach(product => {
        this.editorTemplateStaticData += '<p>' + product.name + '</p>'
      });
      this.editorTemplateStaticData += '<p>Las fechas de los cierres son:' +
        '<br>' +
        rangeList +
        '<p> Un saludo</p>' +
        '<p> [Tu firma]</p>'
      '</div>';

      this.notificationEmailForm.controls['message'].setValue(this.editorTemplate);
    }
  }

  storeRangeDate(event) {
    let selectedDaysofRange = []
    event.forEach(range => {
      selectedDaysofRange.push(range)
    });
    this.selectedRangeDate.push(selectedDaysofRange)

    this.addNumbersToMobileProgressBar(3)
  }

  // Función para formatear un número con ceros a la izquierda (por ejemplo, 01, 02, 03, ...)
  formatWithLeadingZeros(value: number, length: number): string {
    return value.toString().padStart(length, '0');
  }

  // Función para formatear una fecha en el formato "dd-mm-yyyy"
  formatDateToDDMMYYYY(date: Date): string {
    const day = this.formatWithLeadingZeros(date.getDate(), 2);
    const month = this.formatWithLeadingZeros(date.getMonth() + 1, 2); // Los meses en JavaScript son base 0 (enero = 0)
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
  }

  onAnimationEnd(): void {
    // Agregar la clase 'final-state' al div para mantener el color después de la animación
    const progressDiv = document.querySelector('.progress');

    if (this.isDecreasing == false) {
      progressDiv?.classList.add('final-state');
    } else {
      progressDiv?.classList.remove('final-state');
      progressDiv?.classList.remove('active');
      progressDiv?.classList.remove('decreasing');
    }
  }

  onNumberAnimationEnd(): void {
    // Agregar la clase 'final-state' al div para mantener el color después de la animación
    const positionDiv = document.querySelector('.node-position');

    if (this.isDecreasing == false) {
      positionDiv?.classList.add('final-state');
    } else {
      positionDiv?.classList.remove('final-state');
      positionDiv?.classList.remove('active');
    }

  }

  setProgress(amt) {
    amt = (amt < 0) ? 0 : (amt > 1) ? 1 : amt;
    document.getElementById("stop1").setAttribute("offset", amt);
    document.getElementById("stop2").setAttribute("offset", amt);
  }

  addProduct(product) {    
    console.log(this.productCheckboxValue);

    if (!this.productDetails.includes(product)) {
      this.productDetails.push(product);
      this.addNumbersToMobileProgressBar(2)
    } else {
      this.productDetails = this.productDetails.filter(deleteProduct => deleteProduct.id !== product.id);
      this.removeNumbersToMobileProgressBar();
    }
  }

  addContact(contact) {
    if (this.listType == 'groups') {
      if (!this.groupDetails.includes(contact['@id'])) {
        this.groupDetails.push(contact['@id']);
        this.addNumbersToMobileProgressBar(4)
      } else {
        this.groupDetails = this.groupDetails.filter(deleteContact => deleteContact !== contact['@id']);
        this.removeNumbersToMobileProgressBar();
      }
      contact.contacts.forEach(groupContact => {
        if (!this.contactDetails.includes(groupContact)) {
          this.contactDetails.push(groupContact)
          this.addNumbersToMobileProgressBar(4)
        } else {
          this.contactDetails = this.contactDetails.filter(deleteContact => deleteContact['@id'] !== groupContact['@id']);
          this.removeNumbersToMobileProgressBar();
        }
      });
    } else {
      if (!this.contactDetails.includes(contact)) {
        this.contactDetails.push(contact);
        this.addNumbersToMobileProgressBar(4)
      } else {
        this.contactDetails = this.contactDetails.filter(deleteContact => deleteContact.id !== contact.id);
        this.removeNumbersToMobileProgressBar();
      }
    }

  }

  addProducts(event: any) {
    let checked = event.target.checked;
    this.productCheckboxValue = checked;
    
    if (checked) {
      this.rows.forEach(row => {
        this.productDetails.push(row);
      });
    } else {
      this.productDetails = [];
    }
  }

  addContacts(event: any) {
    let checked = event.target.checked;
    this.contactCheckboxValue = checked;
    if (checked) {
      this.rowsContacts.forEach(row => {
        this.contactDetails.push(row);
      });
    } else {
      this.contactDetails = [];
    }
  }

  applyFilter() {
    this.experiencesService.getAllExperiencesByMakerId(this.makerService.getId(), this.searchForm.value).subscribe(
      data => {
        this.rows = data['hydra:member'];
        this.table.offset = 0;
      }
    );
  }

  applyContactFilter() {
    this.makerService.getContactsByMakerId(this.makerService.getId(), this.contactSearchForm.value).subscribe(
      data => {
        this.rows = data['hydra:member'];
        this.table.offset = 0;
      }
    );
  }

  deleteRange(range) {
    var index = this.selectedRangeDate.indexOf(range);
    if (index !== -1) {
      this.selectedRangeDate.splice(index, 1);
    }
    this.removeNumbersToMobileProgressBar();
  }

  insertTag(event, type) {

    this[type + 'Selected'] = this.notificationEmailForm.controls[type].value ? this.notificationEmailForm.controls[type].value : [];

    if (event.target.value != '') {
      this[type + 'Selected'].push(event.target.value);
    }
    this.notificationEmailData.extraContacts.push(event.target.value)

    this.notificationEmailForm.controls[type].setValue(this[type + 'Selected']);
    event.target.value = null;
    // this[type + 'Selected'] = this.experienceForm.controls[type].value;

  }

  onRemove(event) {
    this.notificationEmailData.contacts.filter(function (obj) {
      return obj.email !== event.value;
    });
    this.notificationEmailData.extraContacts.filter(function (obj) {
      return obj.email !== event.value;
    });
  }

  changeList(listType: any) {
    this.listType = listType;
    if (this.listType == 'groups') {
      this.columnsContacts = [
        { prop: 'name', name: 'Nombre' },
      ];
      this.makerService.getContactsGroupsByMakerId(this.makerService.getId()).subscribe((groups => {
        this.rowsContacts = groups['hydra:member'];
      }));
    } else {
      this.listType = 'contacts';
      this.columnsContacts = [
        { prop: 'name', name: 'Nombre' },
        { prop: 'company', name: 'Empresa' },
        { prop: 'email', name: 'Email' },
        { prop: 'phone', name: 'Teléfono' },
        { prop: 'contactGroups', name: 'Grupo' },
      ];
      this.makerService.getContactsByMakerId(this.makerService.getId()).subscribe((contacts => {
        this.rowsContacts = contacts['hydra:member'];
      }));
    }
  }

  sendPostSale() {
    let notificationEmailDataToSend = {
      contacts: null,
      groups: null,
      extraContacts: null,
      dates: null,
      maker: null,
      experiences: null,
      message: null,
      subject: null,
    }
    let contactIds = [];
    this.notificationEmailData.contacts.forEach(data => {
      contactIds.push(data['@id'])
    });

    let groupIds = [];
    this.notificationEmailData.groups.forEach(data => {
      groupIds.push(data)
    });

    notificationEmailDataToSend.contacts = contactIds;
    notificationEmailDataToSend.groups = groupIds;

    notificationEmailDataToSend.maker = [this.notificationEmailData.maker['@id']];

    let productsIds = [];
    this.notificationEmailData.products.forEach(data => {
      productsIds.push(data['@id'])
    });
    notificationEmailDataToSend.experiences = productsIds;

    notificationEmailDataToSend.extraContacts = this.notificationEmailData.extraContacts;
    notificationEmailDataToSend.dates = this.notificationEmailData.dates;
    notificationEmailDataToSend.message = this.notificationEmailData.message;
    notificationEmailDataToSend.subject = this.notificationEmailData.subject;

    this.salesService.postStopSale(notificationEmailDataToSend).subscribe(
      response => {
        console.log(response);
      },
      error => {
        console.log(error);
      }
    )
  }

  isChecked(contact) {
    let filterContacts = this.contactDetails.filter(deleteContact => deleteContact['@id'] == contact);
    return filterContacts.length > 0;
  }

  ngOnDestroy(): void {
    //clear cache
    location.reload()
  }

  addNumbersToMobileProgressBar(number) {
    if (number == 5) {
      const progressDiv = document.getElementById('2m');
      progressDiv?.classList.add('active');
      const prevBall = progressDiv.previousElementSibling.firstElementChild;
      prevBall.classList.remove('glowing');
      const nextBall = progressDiv.nextElementSibling.firstElementChild;
      setTimeout(() => {
        progressDiv?.classList.add('final-state');
        nextBall.classList.add('active');
        nextBall.classList.add('glowing');
      }, 2000);
    } else {
      this.firstStepNumber = number;
      this.firstStepText = this.steps[this.firstStepNumber - 1];

      const progressDiv = document.getElementById('2m');
      progressDiv?.classList.add('active');

      setTimeout(() => {
        this.secondStepNumber = number + 1;
        this.secondStepText = this.steps[this.secondStepNumber - 1];
        progressDiv?.classList.remove('active');
      }, 2000);
    }

  }

  removeNumbersToMobileProgressBar() {
    this.secondStepNumber = this.firstStepNumber;
    this.secondStepText = this.steps[this.secondStepNumber - 1];

    const progressDiv = document.getElementById('2m');
    progressDiv?.classList.add('decreasing');

    setTimeout(() => {
      this.firstStepNumber--;
      this.firstStepText = this.steps[this.firstStepNumber - 1];
      progressDiv?.classList.remove('decreasing');
    }, 2000);
  }

  openAlert(modalText: string, className: string, status: boolean) {
    let iconClass: string;
    if (status) {
      iconClass = 'vi-check';
    } else {
      iconClass = 'vi-sad';
    }
    const modalConfig = {
      class: className,
      initialState: {
        alertMessage: this.translate.instant(
          modalText
        ),
        iconClass,
        status
      }
    }
    this.dialogRef = this.dialog.show(ModalAlertComponent, modalConfig);
    setTimeout(() => {
      this.dialogRef.hide();
    }, 2500);
  }

}

<h1 class="page-title">
  <i class="fa fa-address-book small mr-3"></i> {{ translate.instant(title) }}
</h1>
<ul class="nav nav-tabs mb-n1 small">
  <li class="nav-item">
    <a class="nav-link active font-weight-bold">Ficha grupo</a>
  </li>
  <!--  <li class="nav-item">-->
  <!--    <a class="nav-link active bg-light" (click)="changeLanguage('en')">Inglés</a>-->
  <!--  </li>-->
</ul>
<div class="card">
  <div class="card-body p-4">
    <form [formGroup]="groupForm" class="maker-admin-form">
      <div class="row">
        <div class="col-md-6 form-group">
          <label>Nombre <span class="form-required text-danger">*</span></label>
          <input [placeholder]="translate.instant('Nombre')" type="text" class="form-control" formControlName="name"
            id="name" />
          <small class="text-danger" *ngIf="showError('name')">
            {{ translate.instant(errors["contactName"]) }}
          </small>
        </div>
        <div class="col-md-12 form-group">
          <label>{{ "Contactos de maker" | translate }}
            <span class="form-required text-danger">*</span></label>
          <ng-select [multiple]="true" [searchable]="true" [items]="contacts" formControlName="contact" bindLabel="name"
            placeholder="{{ translate.instant('Seleccionar contactos') }}">
          </ng-select>

          <!--   <ng-select
            [placeholder]="translate.instant('Seleccionar etiquetas')"
            multiple="true"
            appendTo="body"
            formControlName="contact"
            [(ngModel)]="selectedContacts"
            bindLabel="name"
          >
            <ng-option [value]="contact.id" *ngFor="let contact of contacts">{{
              contact.name
            }}</ng-option>
          </ng-select> -->
          <!-- <ng-select [placeholder]="translate.instant('Seleccionar etiquetas')" multiple="true"
                      appendTo="body" formControlName="contact" [(ngModel)]="selectedContacts" bindLabel="name">
                      <ng-option [value]="contact.id" *ngFor="let contact of contacts">{{contact.name}}</ng-option> 
                    </ng-select> -->
          <!--  <small class="text-danger" *ngIf="showError('')">
                      {{translate.instant(errors['experienceTags'])}}
                    </small> -->
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <button type="button" class="btn btn-primary mr-2 mb-2" *ngIf="group" (click)="updateGroup()">
            Guardar grupo
          </button>
          <button type="button" class="btn btn-primary mr-2 mb-2" *ngIf="!group" (click)="createGroup()">
            Crear nuevo grupo
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
<div class="mt-2" *ngIf="alert.message">
  <p class="alert" [class.alert-success]="alert.type == 1" [class.alert-danger]="alert.type == 2">
    {{ alert.message }}
  </p>
</div>
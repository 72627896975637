import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../pages/shared/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  isCollapsed = {
    Experiences: true,
    Maker: true,
    Taker: true,
    Reseller: true,
    MiWeb: true,
  };

  public iconOnlyToggled = false;
  isAdmin = false;
  public activeLang = 'es';

  constructor(
    public authService: AuthService,
    public translate: TranslateService,
    public router: Router,
    public location: Location,

  ) {
    router.events.subscribe(val => {
      if (location.path().indexOf('/' + this.activeLang + '/maker/admin/') === 0 ||
        location.path().indexOf('/' + this.activeLang + '/taker/admin/') === 0 ||
        location.path().indexOf('/' + this.activeLang + '/reseller/admin/') === 0) {
        this.isAdmin = true;
      }
    });
    this.activeLang = location.path().substring(1, 3);
    this.translate.setDefaultLang(this.activeLang === 'ev' ? 'es' : this.activeLang);
  }

  ngOnInit(): void {
    const body = document.querySelector('body');

    document.querySelectorAll('.sidebar .nav-item').forEach(function (el) {
      el.addEventListener('mouseover', function () {
        if (body.classList.contains('sidebar-icon-only')) {
          el.classList.add('hover-open');
        }
      });
      el.addEventListener('mouseout', function () {
        if (body.classList.contains('sidebar-icon-only')) {
          el.classList.remove('hover-open');
        }
      });
    });
  }

  // toggle sidebar
  toggleSidebar() {
    let body = document.querySelector('body');
    this.iconOnlyToggled = !this.iconOnlyToggled;
    if (this.iconOnlyToggled) {
      body.classList.add('sidebar-icon-only');
    } else {
      body.classList.remove('sidebar-icon-only');
    }
  }

  getMaker() {
    return JSON.parse(localStorage.getItem('maker')).id;
  }

}

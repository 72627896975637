import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-not-found',
  template: `
    <div class="container text-center">
      <img src="assets/venntur-404.png" alt="Error 404" title="Error 404" class="img-fluid"/>
      <h1>Página no encontrada</h1>
      <p>Ups.. Error 404, parece que la página que buscas no existe :(</p>
    </div>
  `,
})
export class NotFoundComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

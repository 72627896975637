<div class="container ">
<!--  <div class="container ad-wrap">-->
<!--    banner-->
<!--  </div>-->
  <div class="d-flex justify-content-center">
    <img class="m-5" src="assets/venntur-isotype-38.png" alt="Venntur" />
    <div class="m-5 spinner-border text-primary" role="status">
      <span class="sr-only"></span>
    </div>
    <img class="m-5 maker-logo" [src]="makerLogo" alt="Logo maker" />
  </div>

  <h4>La mejor experiencia de compra la encontrarás en la página del proveedor</h4>
  <h1>Te estamos redirigiendo a la experiencia:</h1>
  <h2 class="text-warning mb-5">{{ name }}</h2>
  <h4>Vuelve cuando quieras a venntur.com a seleccionar los mejores anfitriones para tus Vacaciones</h4>
  <h4>¡Las experiencias nos unen!</h4>
</div>

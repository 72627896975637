import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';

import { environment } from '../../../environments/environment';
import { Country, CountryInterface } from './country.model';

const API_URL = environment.apiUrl;

@Injectable()
export class CountryService {

  countries: CountryInterface[] = [
    {'id': '65', 'text': 'España', 'code': 'ES'}
    // {'id': '3', 'text': 'Afganistán', 'code': 'AF'}, {'id': '6', 'text': 'Albania', 'code': 'AL'},
    // {'id': '54', 'text': 'Alemania', 'code': 'DE'},  {'id': '1', 'text': 'Andorra', 'code': 'AD'},
    // {'id': '9', 'text': 'Angola', 'code': 'AO'}, {'id': '5', 'text': 'Anguilla', 'code': 'AI'},
    // {'id': '10', 'text': 'Antártida', 'code': 'AQ'}, {'id': '4', 'text': 'Antigua y Barbuda', 'code': 'AG'},
    // {'id': '8', 'text': 'Antillas Holandesas', 'code': 'AN'}, {'id': '185', 'text': 'Arabia Saudí (o Saudita)', 'code': 'SA'},
    // {'id': '59', 'text': 'Argelia', 'code': 'DZ'}, {'id': '11', 'text': 'Argentina', 'code': 'AR'},
    // {'id': '7', 'text': 'Armenia', 'code': 'AM'}, {'id': '15', 'text': 'Aruba', 'code': 'AW'},
    // {'id': '14', 'text': 'Australia', 'code': 'AU'}, {'id': '13', 'text': 'Austria', 'code': 'AT'},
    // {'id': '16', 'text': 'Azerbaiyán', 'code': 'AZ'}, {'id': '30', 'text': 'Bahamas', 'code': 'BS'},
    // {'id': '23', 'text': 'Bahrain', 'code': 'BH'}, {'id': '19', 'text': 'Bangladesh', 'code': 'BD'},
    // {'id': '18', 'text': 'Barbados', 'code': 'BB'}, {'id': '20', 'text': 'Bélgica', 'code': 'BE'},
    // {'id': '35', 'text': 'Belice', 'code': 'BZ'}, {'id': '25', 'text': 'Benín', 'code': 'BJ'},
    // {'id': '26', 'text': 'Bermuda', 'code': 'BM'}, {'id': '34', 'text': 'Bielorrusia', 'code': 'BY'},
    // {'id': '28', 'text': 'Bolívia', 'code': 'BO'}, {'id': '17', 'text': 'Bosnia-Herzegovina', 'code': 'BA'},
    // {'id': '33', 'text': 'Botsuana', 'code': 'BW'}, {'id': '29', 'text': 'Brasil', 'code': 'BR'},
    // {'id': '27', 'text': 'Brunei', 'code': 'BN'}, {'id': '22', 'text': 'Bulgaria', 'code': 'BG'},
    // {'id': '21', 'text': 'Burkina Faso', 'code': 'BF'}, {'id': '24', 'text': 'Burundi', 'code': 'BI'},
    // {'id': '31', 'text': 'Bután', 'code': 'BT'}, {'id': '50', 'text': 'Cabo Verde', 'code': 'CV'},
    // {'id': '112', 'text': 'Camboya', 'code': 'KH'}, {'id': '45', 'text': 'Camerún', 'code': 'CM'},
    // {'id': '36', 'text': 'Canadá', 'code': 'CA'}, {'id': '205', 'text': 'Chad', 'code': 'TD'},
    // {'id': '44', 'text': 'Chile', 'code': 'CL'}, {'id': '46', 'text': 'China', 'code': 'CN'},
    // {'id': '52', 'text': 'Chipre', 'code': 'CY'}, {'id': '47', 'text': 'Colombia', 'code': 'CO'},
    // {'id': '114', 'text': 'Comoras', 'code': 'KM'}, {'id': '40', 'text': 'Congo', 'code': 'CG'},
    // {'id': '38', 'text': 'Congo, República Democrática del', 'code': 'CD'}, {'id': '42', 'text': 'Costa de Marfil', 'code': 'CI'},
    // {'id': '48', 'text': 'Costa Rica', 'code': 'CR'}, {'id': '95', 'text': 'Croacia', 'code': 'HR'},
    // {'id': '49', 'text': 'Cuba', 'code': 'CU'}, {'id': '56', 'text': 'Dinamarca', 'code': 'DK'},
    // {'id': '57', 'text': 'Dominica', 'code': 'DM'}, {'id': '60', 'text': 'Ecuador', 'code': 'EC'},
    // {'id': '62', 'text': 'Egipto', 'code': 'EG'}, {'id': '201', 'text': 'El Salvador', 'code': 'SV'},
    // {'id': '2', 'text': 'Emiratos Árabes Unidos', 'code': 'AE'}, {'id': '64', 'text': 'Eritrea', 'code': 'ER'},
    // {'id': '194', 'text': 'Eslovaquia', 'code': 'SK'}, {'id': '192', 'text': 'Eslovenia', 'code': 'SI'},
    // {'id': '65', 'text': 'España', 'code': 'ES'}, {'id': '223', 'text': 'Estados Unidos (EE.UU)', 'code': 'US'},
    // {'id': '61', 'text': 'Estonia', 'code': 'EE'}, {'id': '66', 'text': 'Etiopía', 'code': 'ET'},
    // {'id': '69', 'text': 'Fiji', 'code': 'FJ'}, {'id': '170', 'text': 'Filipinas', 'code': 'PH'},
    // {'id': '68', 'text': 'Finlandia', 'code': 'FI'}, {'id': '73', 'text': 'Francia', 'code': 'FR'},
    // {'id': '74', 'text': 'Gabón', 'code': 'GA'}, {'id': '82', 'text': 'Gambia', 'code': 'GM'},
    // {'id': '77', 'text': 'Georgia', 'code': 'GE'}, {'id': '87', 'text': 'Georgia del Sr y Sandwich del Sur', 'code': 'GS'},
    // {'id': '79', 'text': 'Ghana', 'code': 'GH'}, {'id': '80', 'text': 'Gibraltar', 'code': 'GI'},
    // {'id': '86', 'text': 'Grecia', 'code': 'GR'}, {'id': '76', 'text': 'Grenada', 'code': 'GD'},
    // {'id': '81', 'text': 'Groenlandia', 'code': 'GL'}, {'id': '84', 'text': 'Guadalupe', 'code': 'GP'},
    // {'id': '89', 'text': 'Guam', 'code': 'GU'}, {'id': '88', 'text': 'Guatemala', 'code': 'GT'},
    // {'id': '78', 'text': 'Guayana Francesa', 'code': 'GF'}, {'id': '83', 'text': 'Guinea', 'code': 'GN'},
    // {'id': '90', 'text': 'Guinea Bissau', 'code': 'GW'}, {'id': '85', 'text': 'Guinea Equatorial', 'code': 'GQ'},
    // {'id': '91', 'text': 'Guyana', 'code': 'GY'}, {'id': '96', 'text': 'Haití', 'code': 'HT'},
    // {'id': '256', 'text': 'Hegoalde', 'code': ''}, {'id': '242', 'text': 'Holanda', 'code': 'NL'},
    // {'id': '94', 'text': 'Honduras', 'code': 'HN'}, {'id': '92', 'text': 'Hong Kong', 'code': 'HK'},
    // {'id': '97', 'text': 'Hungría', 'code': 'HU'}, {'id': '101', 'text': 'India', 'code': 'IN'},
    // {'id': '98', 'text': 'Indonesia', 'code': 'ID'}, {'id': '255', 'text': 'Iparralde', 'code': ''},
    // {'id': '103', 'text': 'Irak', 'code': 'IQ'}, {'id': '104', 'text': 'Irán', 'code': 'IR'},
    // {'id': '99', 'text': 'Irlanda', 'code': 'IE'}, {'id': '32', 'text': 'Isla Bouvet', 'code': 'BV'},
    // {'id': '105', 'text': 'Islandia', 'code': 'IS'}, {'id': '119', 'text': 'Islas Caimán', 'code': 'KY'},
    // {'id': '51', 'text': 'Islas Christmas', 'code': 'CX'}, {'id': '37', 'text': 'Islas Cocos (Keeling)', 'code': 'CC'},
    // {'id': '43', 'text': 'Islas Cook', 'code': 'CK'}, {'id': '93', 'text': 'Islas de Heard y McDonald', 'code': 'HM'},
    // {'id': '72', 'text': 'Islas Feroe', 'code': 'FO'}, {'id': '70', 'text': 'Islas Malvinas', 'code': 'FK'},
    // {'id': '142', 'text': 'Islas Marianas del Norte', 'code': 'MP'}, {'id': '136', 'text': 'Islas Marshall', 'code': 'MH'},
    // {'id': '156', 'text': 'Islas Norfolk', 'code': 'NF'},
    // {'id': '222', 'text': 'Islas Periféricas Menores de los Estados Unidos', 'code': 'UM'},
    // {'id': '174', 'text': 'Islas Pitcairn', 'code': 'PN'}, {'id': '186', 'text': 'Islas Salomón', 'code': 'SB'},
    // {'id': '229', 'text': 'Islas Vírgenes Británicas', 'code': 'VG'},
    // {'id': '230', 'text': 'Islas Vírgenes de Estados Unidos', 'code': 'VI'},
    // {'id': '100', 'text': 'Israel', 'code': 'IL'}, {'id': '106', 'text': 'Italia', 'code': 'IT'},
    // {'id': '107', 'text': 'Jamaica', 'code': 'JM'}, {'id': '109', 'text': 'Japón', 'code': 'JP'},
    // {'id': '108', 'text': 'Jordania', 'code': 'JO'}, {'id': '120', 'text': 'Kazajstán', 'code': 'KZ'},
    // {'id': '110', 'text': 'Kenya', 'code': 'KE'}, {'id': '111', 'text': 'Kirguistán', 'code': 'KG'},
    // {'id': '113', 'text': 'Kiribati', 'code': 'KI'}, {'id': '116', 'text': 'Korea del Norte', 'code': 'KP'},
    // {'id': '117', 'text': 'Korea del Sur', 'code': 'KR'}, {'id': '118', 'text': 'Kuwait', 'code': 'KW'},
    // {'id': '121', 'text': 'Laos', 'code': 'LA'}, {'id': '127', 'text': 'Lesotho', 'code': 'LS'},
    // {'id': '130', 'text': 'Letonia', 'code': 'LV'}, {'id': '122', 'text': 'Líbano', 'code': 'LB'},
    // {'id': '126', 'text': 'Liberia', 'code': 'LR'}, {'id': '131', 'text': 'Libia', 'code': 'LY'},
    // {'id': '124', 'text': 'Liechtenstein', 'code': 'LI'}, {'id': '128', 'text': 'Lituania', 'code': 'LT'},
    // {'id': '129', 'text': 'Luxemburgo', 'code': 'LU'}, {'id': '141', 'text': 'Macao', 'code': 'MO'},
    // {'id': '137', 'text': 'Macedonia', 'code': 'MK'}, {'id': '135', 'text': 'Madagascar', 'code': 'MG'},
    // {'id': '151', 'text': 'Malasia', 'code': 'MY'}, {'id': '149', 'text': 'Malawi', 'code': 'MW'},
    // {'id': '148', 'text': 'Maldivas', 'code': 'MV'}, {'id': '138', 'text': 'Mali', 'code': 'ML'},
    // {'id': '146', 'text': 'Malta', 'code': 'MT'}, {'id': '132', 'text': 'Marruecos', 'code': 'MA'},
    // {'id': '143', 'text': 'Martinica', 'code': 'MQ'}, {'id': '147', 'text': 'Mauricio', 'code': 'MU'},
    // {'id': '144', 'text': 'Mauritania', 'code': 'MR'}, {'id': '236', 'text': 'Mayotte', 'code': 'YT'},
    // {'id': '150', 'text': 'México', 'code': 'MX'}, {'id': '71', 'text': 'Micronesia', 'code': 'FM'},
    // {'id': '134', 'text': 'Moldavia', 'code': 'MD'}, {'id': '133', 'text': 'Mónaco', 'code': 'MC'},
    // {'id': '140', 'text': 'Mongolia', 'code': 'MN'}, {'id': '243', 'text': 'Montenegro', 'code': 'ME'},
    // {'id': '145', 'text': 'Montserrat', 'code': 'MS'}, {'id': '152', 'text': 'Mozambique', 'code': 'MZ'},
    // {'id': '139', 'text': 'Myanmar', 'code': 'MM'}, {'id': '153', 'text': 'Namibia', 'code': 'NA'},
    // {'id': '162', 'text': 'Nauru', 'code': 'NR'}, {'id': '161', 'text': 'Nepal', 'code': 'NP'},
    // {'id': '158', 'text': 'Nicaragua', 'code': 'NI'}, {'id': '155', 'text': 'Niger', 'code': 'NE'},
    // {'id': '157', 'text': 'Nigeria', 'code': 'NG'}, {'id': '163', 'text': 'Niue', 'code': 'NU'},
    // {'id': '160', 'text': 'Noruega', 'code': 'NO'}, {'id': '154', 'text': 'Nueva Caledonia', 'code': 'NC'},
    // {'id': '164', 'text': 'Nueva Zelanda', 'code': 'NZ'}, {'id': '165', 'text': 'Omán', 'code': 'OM'},
    // {'id': '159', 'text': 'Países Bajos', 'code': 'NL'}, {'id': '171', 'text': 'Pakistán', 'code': 'PK'},
    // {'id': '178', 'text': 'Palau', 'code': 'PW'}, {'id': '176', 'text': 'Palestina', 'code': 'PS'},
    // {'id': '166', 'text': 'Panamá', 'code': 'PA'}, {'id': '169', 'text': 'Papúa Nueva Guinea', 'code': 'PG'},
    // {'id': '179', 'text': 'Paraguay', 'code': 'PY'}, {'id': '167', 'text': 'Perú', 'code': 'PE'},
    // {'id': '168', 'text': 'Polinesia Francesa', 'code': 'PF'}, {'id': '172', 'text': 'Polonia', 'code': 'PL'},
    // {'id': '177', 'text': 'Portugal', 'code': 'PT'}, {'id': '175', 'text': 'Puerto Rico', 'code': 'PR'},
    // {'id': '180', 'text': 'Qatar', 'code': 'QA'}, {'id': '75', 'text': 'Reino Unido', 'code': 'GB'},
    // {'id': '39', 'text': 'República Centroafricana', 'code': 'CF'}, {'id': '53', 'text': 'República Checa', 'code': 'CZ'},
    // {'id': '58', 'text': 'República Dominicana', 'code': 'DO'}, {'id': '181', 'text': 'Reunión', 'code': 'RE'},
    // {'id': '184', 'text': 'Ruanda', 'code': 'RW'}, {'id': '182', 'text': 'Rumanía', 'code': 'RO'},
    // {'id': '183', 'text': 'Rusia', 'code': 'RU'}, {'id': '63', 'text': 'Sáhara Occidental', 'code': 'EH'},
    // {'id': '234', 'text': 'Samoa', 'code': 'WS'}, {'id': '12', 'text': 'Samoa Americana', 'code': 'AS'},
    // {'id': '115', 'text': 'San Cristóbal y Nieves', 'code': 'KN'}, {'id': '196', 'text': 'San Marino', 'code': 'SM'},
    // {'id': '173', 'text': 'San Pedro y Miquelón', 'code': 'PM'}, {'id': '227', 'text': 'San Vicente y las Granadinas', 'code': 'VC'},
    // {'id': '191', 'text': 'Santa Helena', 'code': 'SH'}, {'id': '123', 'text': 'Santa Lucía', 'code': 'LC'},
    // {'id': '200', 'text': 'Sao Tomé y Príncipe', 'code': 'ST'}, {'id': '197', 'text': 'Senegal', 'code': 'SN'},
    // {'id': '244', 'text': 'Serbia', 'code': 'RS'}, {'id': '187', 'text': 'Seychelles', 'code': 'SC'},
    // {'id': '195', 'text': 'Sierra Leone', 'code': 'SL'}, {'id': '190', 'text': 'Singapur', 'code': 'SG'},
    // {'id': '202', 'text': 'Siria', 'code': 'SY'}, {'id': '198', 'text': 'Somalia', 'code': 'SO'},
    // {'id': '125', 'text': 'Sri Lanka', 'code': 'LK'}, {'id': '238', 'text': 'Sudáfrica, República de', 'code': 'ZA'},
    // {'id': '188', 'text': 'Sudán', 'code': 'SD'}, {'id': '189', 'text': 'Suecia', 'code': 'SE'},
    // {'id': '41', 'text': 'Suiza', 'code': 'CH'}, {'id': '199', 'text': 'Surinam', 'code': 'SR'},
    // {'id': '193', 'text': 'Svalbard y Jan Mayen', 'code': 'SJ'}, {'id': '203', 'text': 'Swazilandia', 'code': 'SZ'},
    // {'id': '209', 'text': 'Tadjikistán', 'code': 'TJ'}, {'id': '218', 'text': 'Taiwán', 'code': 'TW'},
    // {'id': '219', 'text': 'Tanzania', 'code': 'TZ'}, {'id': '102', 'text': 'Territorio Británico del Oceano Índico', 'code': 'IO'},
    // {'id': '206', 'text': 'Territorios Franceses del Sur', 'code': 'TF'}, {'id': '208', 'text': 'Thailandia', 'code': 'TH'},
    // {'id': '214', 'text': 'Timor Oriental', 'code': 'TP'}, {'id': '207', 'text': 'Togo', 'code': 'TG'},
    // {'id': '210', 'text': 'Tokelau', 'code': 'TK'}, {'id': '213', 'text': 'Tonga', 'code': 'TO'},
    // {'id': '216', 'text': 'Trinidad y Tobago', 'code': 'TT'}, {'id': '212', 'text': 'Túnez', 'code': 'TN'},
    // {'id': '204', 'text': 'Turcos y Caicos', 'code': 'TC'}, {'id': '211', 'text': 'Turkmenistán', 'code': 'TM'},
    // {'id': '215', 'text': 'Turquía', 'code': 'TR'}, {'id': '217', 'text': 'Tuvalu', 'code': 'TV'},
    // {'id': '220', 'text': 'Ucrania', 'code': 'UA'}, {'id': '221', 'text': 'Uganda', 'code': 'UG'},
    // {'id': '67', 'text': 'Unión Europea', 'code': 'EU'}, {'id': '224', 'text': 'Uruguay', 'code': 'UY'},
    // {'id': '225', 'text': 'Uzbekistán', 'code': 'UZ'}, {'id': '232', 'text': 'Vanuatu', 'code': 'VU'},
    // {'id': '226', 'text': 'Vaticano', 'code': 'VA'}, {'id': '228', 'text': 'Venezuela', 'code': 'VE'},
    // {'id': '231', 'text': 'Vietnam', 'code': 'VN'}, {'id': '233', 'text': 'Wallis y Futuna', 'code': 'WF'},
    // {'id': '235', 'text': 'Yemen', 'code': 'YE'}, {'id': '55', 'text': 'Yibouti', 'code': 'DJ'},
    // {'id': '237', 'text': 'Yugoslavia', 'code': 'YU'}, {'id': '240', 'text': 'Zaire', 'code': 'ZR'},
    // {'id': '239', 'text': 'Zambia', 'code': 'ZM'}, {'id': '241', 'text': 'Zimbabwe', 'code': 'ZW'}
  ];

  constructor(
    private http: HttpClient
  ) { }

  public getCountries(): Observable<CountryInterface[]> {
    return of(this.countries);
  }
}

<h1 class="page-title"><i class="fa fa-tachometer small mr-3"></i>{{title}}</h1>
<!-- <div class="d-flex my-5">
    <p class="filters" (click)="changeFilter('months')" [ngClass]="{'active-filter': activeFilter == 'months'}">
        Últimos
        12 meses</p>
    <p class="filters" (click)="changeFilter('days')" [ngClass]="{'active-filter': activeFilter == 'days'}">Últimos
        30
        días</p>
    <p class="filters" (click)="changeFilter('hours')" [ngClass]="{'active-filter': activeFilter == 'hours'}">
        Últimas 24
        horas</p>
</div> -->
<div *ngIf="maker" class="row firstRow">
    <!-- <div class="col-lg-4 mb-3">
        <div class="card">
            <div class="d-flex align-items-center mb-2">
                <strong class="mr-1">Número de contactos / grupos</strong>
                <span [tooltip]="translate.instant('Vista previa de las fichas de tus productos que vendes.')"
                    class="vi-info"></span>
            </div>
            <span class="numbers">
                {{totalContacts}} / {{totalGroups}}
            </span>
            <div class="text-right mt-3">
                <button class="btn btn-primary px-3" style="height: 48px;font-weight: 400"
                    routerLink="../nuevo-contacto"><span class="vi-add mr-3"></span>Añadir
                    contacto</button>
            </div>
        </div>
    </div> -->
    <div class="col-lg-4 mb-3">
        <div class="card">
            <div class="d-flex align-items-center mb-2">
                <strong class="mr-1">Número de productos publicados</strong>
                <span [tooltip]="translate.instant('Número de productos publicados en tu plan')" class="vi-info"></span>
            </div>
            <span class="numbers">
                {{publishedExperiences}} / {{totalExperiences}}
            </span>
            <div class="text-right mt-3">
                <button class="btn btn-primary px-3" style="height: 48px;font-weight: 400"
                    routerLink="../experiencias"><span class="vi-add mr-3"></span>Añadir
                    producto</button>
            </div>
        </div>
    </div>
    <div class="col-lg-4 mb-3">
        <div class="card position-relative suscription-card">
            <div class="d-flex justify-content-between mb-2">
                <div class="mr-3">
                    <strong class="mr-1">Suscripción</strong>
                    <span [tooltip]="translate.instant('Plan actual con posibilidad de mejorar tu plan')"
                        class="vi-info"></span>
                </div>
                <div class="text-right">
                    <!-- <a class="btn btn-primary px-3 d-flex align-items-center"
                        style="height: 48px;font-weight: 400; width: fit-content;"
                        href="https://www.venntur.com/es/precios-venntur" target="_blank" disabled>MEJORAR PLAN</a> -->
                    <button class="btn btn-primary w-100" style="height: 46px;font-weight: 500;" disabled>MEJORAR
                        PLAN
                    </button>
                </div>
            </div>
            <div class="position-absolute bottom">
                <div class="planNumbers d-flex justify-content-between">
                    <span>{{maker.makerPlan['plan']['shortName'] | translate}}</span>
                    <div>
                        <span>{{maker['makerPlan']['price'] + '€'}}</span>
                        <span>{{maker['makerPlan']['plan']['paymentType'] == 'yearly' ? '/Anual' : '/Mes'}}</span>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
<div *ngIf="maker && makerExperiences.length > 0" class="row mb-3">
    <div class="col-lg-4 col-md-6 col-12 mb-3 mb-lg-0">
        <div class="card p-3" style="height:800px">
            <div class="d-flex align-items-center">
                <strong class="mr-1">Preview minificha</strong>
                <span [tooltip]="translate.instant('Vista previa de tu minificha')" class="vi-info"></span>
            </div>
            <div class="py-4">
                <app-experience-card [isDashboard]="false" [experience]="makerExperiences[dashboardCardsIndex]"
                    [isFooterActive]="true"></app-experience-card>
                <div class="bullets">
                    <button *ngFor="let experience of [].constructor(makerExperiences.length);let i = index" [ngClass]="dashboardCardsIndex == i ? 'active' : ''" (click)="dashboardCardsIndex = i" class="bullet"></button>
                </div>
            </div>
        </div>
    </div>
    <div class="col-lg-4 col-md-6 col-12 mb-3 mb-lg-0">
        <div class="card p-3" style="height:800px">
            <div [ngClass]="{'disabled-element' : (maker.makerPlan.plan.sku == 'FREE')}" style="display: grid;">
                <div class="d-flex align-items-center">
                    <strong class="mr-1">Preview Ficha de producto</strong>
                    <span [tooltip]="translate.instant('Vista previa de las fichas de tus productos que vendes.')"
                        class="vi-info"></span>
                </div>
                <carousel [interval]="0">
                    <slide class="p-3 py-4" *ngFor="let experience of makerExperiences; let i = index">
                        <iframe class="inlineFrameExample" title="Inline Frame Example" [src]="safeUrls[i]">
                        </iframe>
                    </slide>
                    <!-- <slide class="p-3 py-4">
                        <iframe class="inlineFrameExample" title="Inline Frame Example"
                            src="https://mallorcaseatours.com/es/excursiones/crucero-con-vision-submarina-a-las-islas-malgrats">
                        </iframe>
                    </slide>
                    <slide class="p-3 py-4">
                        <iframe class="inlineFrameExample" title="Inline Frame Example"
                            src="https://mallorcaseatours.com/es/excursiones/crucero-con-vision-submarina-a-las-islas-malgrats">
                        </iframe>
                    </slide>
                    <slide class="p-3 py-4">
                        <iframe class="inlineFrameExample" title="Inline Frame Example"
                            src="https://mallorcaseatours.com/es/excursiones/crucero-con-vision-submarina-a-las-islas-malgrats">
                        </iframe>
                    </slide>
                    <slide class="p-3 py-4">
                        <iframe class="inlineFrameExample" title="Inline Frame Example"
                            src="https://mallorcaseatours.com/es/excursiones/crucero-con-vision-submarina-a-las-islas-malgrats">
                        </iframe>
                    </slide> -->
                </carousel>
            </div>
        </div>
    </div>
    <div class="col-lg-4 col-md-6 col-12 mb-3 mb-lg-0">
        <div class="card p-3" style="height:800px">
            <!-- <div [ngClass]="{'disabled-element' : (maker.makerPlan.plan.sku != 'PROW-A' || 'PROW-M')}" -->
            <div [ngClass]="{'disabled-element' : true}"
                style="display: grid; height: 100%;">
                <div style="height: fit-content;" class="d-flex align-items-center">
                    <strong class="mr-1">Mi web</strong>
                    <span [tooltip]="translate.instant('Vista previa de la plantilla de tu sitio web')"
                        class="vi-info"></span>
                </div>
                <carousel [interval]="0" style="padding: 60px;">
                    <h4 class="text-center">Crea tu web con inteligencia artificial en tan solo 3 pasos.</h4>
                    <button class="btn btn-primary w-100" style="height: 46px;font-weight: 500;" disabled>PRÓXIMAMENTE
                    </button>
                    <!-- <slide class="p-3 py-4">
                        <iframe class="inlineFrameExample" title="Inline Frame Example"
                            src="https://mallorcaseatours.com/es">
                        </iframe>
                    </slide>
                    <slide class="p-3 py-4">
                        <iframe class="inlineFrameExample" title="Inline Frame Example"
                            src="https://mallorcaseatours.com/es">
                        </iframe>
                    </slide>
                    <slide class="p-3 py-4">
                        <iframe class="inlineFrameExample" title="Inline Frame Example"
                            src="https://mallorcaseatours.com/es">
                        </iframe>
                    </slide>
                    <slide class="p-3 py-4">
                        <iframe class="inlineFrameExample" title="Inline Frame Example"
                            src="https://mallorcaseatours.com/es">
                        </iframe>
                    </slide>
                    <slide class="p-3 py-4">
                        <iframe class="inlineFrameExample" title="Inline Frame Example"
                            src="https://mallorcaseatours.com/es">
                        </iframe>
                    </slide> -->
                </carousel>
            </div>
        </div>
    </div>
</div>
<!-- <div *ngIf="maker && makerExperiences.length > 0" class="row mb-3">
    <div class="col">
        <div class="card p-3">
            <div class="d-flex align-items-center justify-content-between">
                <div>
                    <strong class="mr-1">Analítica Interacciones</strong>
                    <span [tooltip]="translate.instant('Vista gráfica de las interacciones en directorio y sitios web')"
                        class="vi-info"></span>
                </div>
                <ng-select (change)="changeFilter($event)" [placeholder]="translate.instant('Últimos 12 meses')"
                    style="min-width: 200px;">
                    <ng-option value="months">
                        <a>Últimos 12 meses</a>
                    </ng-option>
                    <ng-option value="days">
                        <a>Últimos 30 días</a>
                    </ng-option>
                    <ng-option value="hours">
                        <a>24 horas</a>
                    </ng-option>
                </ng-select>
            </div>
            <div class="chart-container">
                <canvas id="interactionChart">{{ interactionChart }}</canvas>
            </div>
        </div>
    </div>
</div> -->
import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { MakerService } from '../../shared/maker.service';
import {Reseller} from '../reseller-profile/reseller.model';
import {ResellerService} from '../../shared/reseller.service';
import {LocationService} from '../../../shared/locations/location.service';
import {Location} from '../../../shared/locations/location.model';
import { maxTableRows } from 'globals';

@Component({
  selector: 'app-reseller-makers-list',
  templateUrl: './reseller-makers-list.component.html',
  styleUrls: ['./reseller-makers-list.component.css']
})
export class ResellerMakersListComponent implements OnInit {
  maxTableRows = maxTableRows;
  columns = [];
  rows = [];
  resellerMakers = [];
  statusArray = ['Pendiente contacto', 'Contactado', 'Contratado', 'Terminado'];
  resellerId;
  locations: Location[] = [];
  locationsSelected = [];
  isFavorites = true;
  searchForm: UntypedFormGroup;
  alert = {message: null, type: null};
  @ViewChild(DatatableComponent) table: DatatableComponent;
  dialogRef: BsModalRef;

  constructor(
    private makersService: MakerService,
    private resellerService: ResellerService,
    private locationsService: LocationService,
    private searchFormBuilder: UntypedFormBuilder,
    public dialog: BsModalService,
  ) {
    this.locationsService.getActiveLocations().subscribe(locations => {
        this.locations = locations['hydra:member'];
      }
    );
    this.resellerId = this.resellerService.getId();
    this.makersService.getMakersByResellerId(this.resellerId, {}).subscribe(experiences => {
      this.resellerMakers = experiences[ 'hydra:member' ];
    });
  }

  ngOnInit(): void {
    this.searchForm = this.searchFormBuilder.group({
      name: [''],
      phone: [''],
      email: [''],
      web: [''],
      locations: [''],
      isFavorites: [true],
      isFavoritesProducts: [false],
      resellerId: [this.resellerId]
    });
    this.columns = [
      { prop: 'maker.name', name: 'Nombre' },
      { prop: 'maker.phone', name: 'Teléfono' },
      { prop: 'maker.email', name: 'Email' },
      { prop: 'maker.web', name: 'Página web' }];

    this.applyFilter();
  }

  applyFilter() {
    if (!this.searchForm.value.isFavorites) {
      this.makersService.getMakers(this.searchForm.value).subscribe(
        data => {
          this.makersService.getMakersByResellerId(this.resellerId, {}).subscribe(makers => {
            this.resellerMakers = Array.from(makers['hydra:member']).map((item) => item['maker']['@id']);
          });
          this.rows = data['hydra:member'];
          this.table.offset = 0;
          this.isFavorites = false;
          this.columns = [
            { prop: 'name', name: 'Nombre' },
            { prop: 'phone', name: 'Teléfono' },
            { prop: 'email', name: 'Email' },
            { prop: 'web', name: 'Página web' }];
        }
      );
    } else {
      this.makersService.getMakersByResellerId(this.resellerId, this.searchForm.value).subscribe(
        data => {
        this.rows = data['hydra:member'];
        this.table.offset = 0;
        this.isFavorites = true;
        this.columns = [
          { prop: 'maker.name', name: 'Nombre' },
          { prop: 'maker.phone', name: 'Teléfono' },
          { prop: 'maker.email', name: 'Email' },
          { prop: 'maker.web', name: 'Página web' }];
      });
    }
  }

  addFavorite(event, makerId) {
    this.resellerMakers.includes(makerId) ?
      this.resellerMakers.splice(this.resellerMakers.indexOf(makerId), 1) : this.resellerMakers.push(makerId);
    const favoriteMaker = {
      reseller: '/resellers/' + this.resellerId,
      maker: makerId,
      status: 'Pendiente contacto'
    };
    this.resellerService.postFavoriteMaker(favoriteMaker).subscribe();
  }

  removeFavorite(favoriteMakerId) {
    this.resellerService.deleteFavoriteMaker(favoriteMakerId).subscribe(() => {
        this.applyFilter();
      }
    );
  }

  changeStatus(status, favoriteMakerId) {
    this.resellerService.putFavoriteMaker(favoriteMakerId, {status: status}).subscribe();
  }
}

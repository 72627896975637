<div class="py-4">
    <div class="preview d-flex justify-content-end">
        <button>
            <i class="fa fa-eye"></i>
            <span>
                Vista
            </span>
        </button>
    </div>
    <div class="d-flex" style="column-gap: .5rem;">
        <ng-container *ngFor="let page of web['pages'].slice(0,1)">
            <div class="tab" *ngIf="page['sections'].length > 0" (click)="currentTab=page.slug">
                <strong>
                    {{page.name}}
                </strong>
            </div>
        </ng-container>
    </div>

    <ng-container *ngFor="let page of web['pages']">
        <ng-container *ngIf="currentTab == page.slug">
            <ng-container *ngFor="let section of page.sections; let i = index; let last = last">
                <app-cms-module [orderClass]="{'first' : i == 0 , 'last': last}" class="app-cms-module"
                    [currentModule]="pageObject.modules[i] = {}" [module]="section['modules'][0]"></app-cms-module>
            </ng-container>
            <div class="mt-3 text-right">
                <button class="btn btn-primary py-2" (click)="saveWeb()">Guardar Cambios</button>
            </div>
        </ng-container>
    </ng-container>
</div>
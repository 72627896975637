<h1 class="page-title">{{translate.instant(title)}}</h1>
<div *ngIf="!reseller">
  <p class="alert alert-danger">{{ 'Completa los datos de la agencia' | translate }}</p>
</div>

<ul class="nav nav-tabs mb-n1 text-uppercase small">
  <li class="nav-item">
    <a class="nav-link active font-weight-bold" (click)="changeLanguage('es')">{{ 'Español' | translate }}</a>
  </li>
<!--  <li class="nav-item">-->
<!--    <a class="nav-link active bg-light" (click)="changeLanguage('en')">{{ 'Inglés' | translate }}</a>-->
<!--  </li>-->
</ul>
<div class="card">
  <div class="card-body p-4">
    <h4 class="border-bottom pb-2 mb-3"><i class="fa fa-lock small mr-1 text-black-50"></i> {{ 'Datos privados' | translate }}</h4>
    <form [formGroup]="resellerForm" class="reseller-admin-form">
      <div class="row">
        <div class="col-md-6 form-group">
          <label>CIF <span class="form-required text-danger">*</span></label>
            <input [placeholder]="'CIF'" type="text" class="form-control" formControlName="cif">
            <small class="text-danger" *ngIf="showError('cif')">
              {{ translate.instant(errors['document']) }}
            </small>
        </div>
      </div>
      <h4 class="border-bottom pb-2 my-3"><i class="fa fa-eye small mr-1 text-black-50"></i> {{ 'Datos públicos' | translate }}</h4>
      <div class="row">
        <div class="col-md-6 form-group">
          <label>{{ 'Nombre' | translate }} <span class="form-required text-danger">*</span></label>
          <input [placeholder]="translate.instant('Nombre empresa u organizador')" type="text" class="form-control" formControlName="name">
          <small class="text-danger" *ngIf="showError('name')">
            {{ translate.instant(errors['resellerName']) }}
          </small>
        </div>
        <div class="col-md-6 form-group">
          <label>{{ 'Nombre fiscal' | translate }} <span class="form-required text-danger">*</span></label>
          <input [placeholder]="translate.instant('Nombre fiscal')" type="text" class="form-control" formControlName="fiscalName">
          <small class="text-danger" *ngIf="showError('fiscalName')">
            {{ translate.instant(errors['fiscalName']) }}
          </small>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 form-group">
          <label>{{ 'Página web' | translate }} <span class="form-required text-danger">*</span></label>
          <input [placeholder]="translate.instant('Página web')" type="text" class="form-control" formControlName="urlWeb">
          <small class="text-danger" *ngIf="showError('urlWeb')">
            {{ translate.instant(errors['makerWeb']) }}
          </small>
        </div>
        <div class="col-md-6 form-group">
          <label>{{ 'Página web supplier' | translate }} <span class="form-required text-danger"></span></label>
          <input [placeholder]="translate.instant('Página web supplier')" type="text" class="form-control" formControlName="urlWebSupplier">
          <small class="text-danger" *ngIf="showError('urlWebSupplier')">
            {{ translate.instant(errors['makerWeb']) }}
          </small>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 form-group">
          <label>{{ 'País' | translate }} <span class="form-required text-danger">*</span></label>
            <ng-select [placeholder]="translate.instant('Seleccionar el país')" aria-label="Country" formControlName="country" [(ngModel)]="countrySelected" required>
              <ng-option *ngFor="let country of countries" [value]="country.code">{{ country.text }}</ng-option>
            </ng-select>
            <small class="text-danger" *ngIf="showError('country')">
              {{ translate.instant(errors['countryName']) }}
            </small>
        </div>
        <div class="col-md-6 form-group">
          <label>{{ 'Dirección' | translate }} <span class="form-required text-danger">*</span></label>
          <input [placeholder]="translate.instant('Dirección')" type="text" class="form-control" formControlName="address">
          <small class="text-danger" *ngIf="showError('address')">
            {{ translate.instant(errors['address']) }}
          </small>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 form-group">
          <label>{{ 'Código Postal' | translate }} <span class="form-required text-danger">*</span></label>
          <input [placeholder]="translate.instant('Código Postal')" type="text" class="form-control" formControlName="cp">
          <small class="text-danger" *ngIf="showError('cp')">
            {{ translate.instant(errors['cp']) }}
          </small>
        </div>
      </div>
      <div class="row">
        <div class="col form-group">
          <label for="mediaCropper">{{ 'Subir' | translate }} logo Agencia <span class="form-required text-danger">*</span> </label>
          <small class="d-block text-muted mb-2">{{ 'Selecciona un logo de Agencia. Formatos aceptados: .jpeg, .jpg, .png' | translate}}</small>
          <small class="form-text text-muted">{{ 'Dimensiones recomendadas:' | translate}}</small>
          <app-cropper id="mediaCropper" [type]="'reseller'" [aspectratio]= "0" [preview]="'preview'" (uploadedImage)="onUploadedImage($event, 'mediaResellersLogos')"></app-cropper>
          <small class="text-danger" *ngIf="showError('mediaResellersLogos')">
            {{ translate.instant(errors['mediaReseller']) }}
          </small>
        </div>
      </div>
      <div *ngIf="logoImage" class="row mb-4">
        <p class="small">Logo {{ 'subido' | translate }}:</p>
<!--        class="img-fluid"-->
        <img [src]="logoImage" height="100" width="250"> <br/>

<!--        <p class="small">Avatar {{ 'subido' | translate }}:</p>-->
<!--        <img [src]="image" height="30" width="80"> <br/>-->
      </div>
      <h4 class="border-bottom pb-2 my-3"><i class="fa fa-user-circle small mr-1 text-black-50"></i> {{ 'Datos de tu ficha de Agencia' | translate}}</h4>
      <div class="row">
        <div class="col-12 form-group">
          <label>{{ 'Eslogan' | translate }}<span class="form-required text-danger">*</span></label>
          <textarea [placeholder]="translate.instant('Frase, claim o llamada a la acción...')" class="form-control" formControlName="claim"
                    [(ngModel)]="phraseText" maxlength="{{ phraseMaxChar }}" rows="3"></textarea>
          <span class="pull-right badge badge-dark mt-1">{{phraseText ? phraseText.length : 0}} / {{ phraseMaxChar }}</span>
          <small class="form-text text-muted">{{ 'Max. 160 caracteres' | translate}}</small>
        </div>
        <div class="col-12 form-group">
          <label>{{ 'Texto libre' | translate }}<span class="form-required text-danger"></span></label>
          <textarea [placeholder]="translate.instant('Descripción de la agencia')" class="form-control" formControlName="observations"
                    [(ngModel)]="descriptionText" maxlength="{{ descriptionMaxChar }}" rows="3"></textarea>
          <span class="pull-right badge badge-dark mt-1">{{descriptionText ? descriptionText.length : 0}} / {{ descriptionMaxChar }}</span>
          <small class="form-text text-muted">{{ 'Max. 160 caracteres' | translate }}</small>
<!--          <small class="text-danger" *ngIf="showError('description')">-->
<!--            {{translate.instant(errors['description'])}}-->
<!--          </small>-->
        </div>
      </div>
      <div class="row">
<!--        <div class="col-md-6 form-group">-->
<!--          <label>{{ 'Mis categorías' | translate}} <span class="form-required text-danger">*</span></label>-->
<!--          <ng-select bindLabel="Categorías"-->
<!--                     [placeholder]="translate.instant('Seleccionar categoría')"-->
<!--                     multiple="true"-->
<!--                     formControlName="categories"-->
<!--                     [(ngModel)]="categoriesSelected">-->
<!--            <ng-option [value]="category['@id']" *ngFor="let category of categories">{{category.name}}</ng-option>-->
<!--          </ng-select>-->
<!--          <small class="text-danger" *ngIf="showError('categories')">-->
<!--            {{translate.instant(errors['resellerCategories'])}}-->
<!--          </small>-->
<!--        </div>-->
        <div class="col-md-6 form-group">
          <label>{{ 'Mis etiquetas' | translate}} <span class="form-required text-danger">*</span></label>
          <ng-select bindLabel="Etiquetas"
                     [placeholder]="translate.instant('Seleccionar etiqueta')"
                     multiple="true"
                     [closeOnSelect]="false"
                     formControlName="tags"
                     [(ngModel)]="tagsSelected">
            <ng-option [value]="tag['@id']" *ngFor="let tag of tags">{{tag.name}}</ng-option>
          </ng-select>
          <small class="text-danger" *ngIf="showError('tags')">
            {{translate.instant(errors['resellerTags'])}}
          </small>
        </div>
        <div class="col-md-6 form-group">
          <label>{{ 'Mis países' | translate }} <span class="form-required text-danger">*</span></label>
          <ng-select
            [placeholder]="translate.instant('Seleccionar país')"
            bindLabel="Países"
            formControlName="countries"
            multiple="true"
            [closeOnSelect]="false"
            [(ngModel)]="countriesSelected">
            <ng-option *ngFor="let country of countries" [value]="country.code">{{ country.text }}</ng-option>
          </ng-select>
          <small class="text-danger" *ngIf="showError('countries')">
            {{ translate.instant(errors['countryName']) }}
          </small>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 form-group">
          <label>Tripavisor <span class="form-required text-danger"></span></label>
          <input [placeholder]="translate.instant('Id de tripadvisor')" type="text" class="form-control" formControlName="tripadvisor">
<!--          <small class="text-danger" *ngIf="showError('tripadvisor')">-->
<!--            {{translate.instant(errors['tripadvisor'])}}-->
<!--          </small>-->
        </div>
        <div class="col-md-6 form-group">
          <label>LinkedIn <span class="form-required text-danger"></span></label>
          <input [placeholder]="translate.instant('LinkedIn')" type="text" class="form-control" formControlName="linkedIn">
          <small class="form-text text-muted">{{ 'Usuario de' | translate }} LinkedIn(Ej: company/12345678/admin/)</small>
          <small class="text-danger" *ngIf="showError('linkedIn')">
            {{translate.instant(errors['linkedIn'])}}
          </small>
        </div>
        <div class="col-md-6 form-group">
          <label>Facebook <span class="form-required text-danger"></span></label>
          <input [placeholder]="translate.instant('Facebook')" type="text" class="form-control" formControlName="facebook">
          <small class="form-text text-muted">{{ 'Nombre de usuario de' | translate }} Facebook</small>
          <small class="text-danger" *ngIf="showError('facebook')">
            {{translate.instant(errors['facebook'])}}
          </small>
        </div>
        <div class="col-md-6 form-group">
          <label>Instagram <span class="form-required text-danger"></span></label>
          <input [placeholder]="translate.instant('Instagram')" type="text" class="form-control" formControlName="instagram">
          <small class="form-text text-muted">{{ 'Nombre de usuario de' | translate }} Instagram</small>
          <small class="text-danger" *ngIf="showError('instagram')">
            {{translate.instant(errors['instagram'])}}
          </small>
        </div>
        <div class="col-md-6 form-group">
          <label>Twitter <span class="form-required text-danger"></span></label>
          <input [placeholder]="translate.instant('Twitter')" type="text" class="form-control" formControlName="twitter">
          <small class="form-text text-muted">{{ 'Nombre de usuario de' | translate }} Twitter</small>
          <small class="text-danger" *ngIf="showError('twitter')">
            {{translate.instant(errors['twitter'])}}
          </small>
        </div>
        <div class="col-md-6 form-group">
          <label>WhatsApp</label>
          <input placeholder="WhatsApp" type="text" class="form-control" formControlName="whatsapp">
          <small class="text-danger" *ngIf="showError('whatsapp')">
            {{translate.instant(errors['phone'])}}
          </small>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <button *ngIf="resellerId" type="button" class="btn btn-primary mr-2 mb-2"
                  (click)="updateReseller()" >
            {{ 'Guardar datos' | translate }}
          </button>
          <button *ngIf="!reseller" type="button" class="btn btn-primary mr-2 mb-2"
                  (click)="createReseller()" >
            {{ 'Continuar' | translate }}
          </button>
          <a [href]="translate.getDefaultLang() + '/reseller/admin/experiencias'" class="btn btn-light mb-2">{{ 'Cancelar' | translate }}</a>
        </div>
      </div>
    </form>
  </div>
</div>
<div class="mt-2" *ngIf="alert.message">
  <p class="alert" [class.alert-success]="alert.type == 1" [class.alert-danger]="alert.type == 2">{{alert.message}}</p>
</div>

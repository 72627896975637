import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-maker-cta',
  styleUrls: ['./maker-cta.component.scss'],
  templateUrl: './maker-cta.component.html',
})
export class MakerCtaComponent implements OnInit {
  public status: boolean = false;

  constructor(
    public translate: TranslateService
  ) { }

  ngOnInit(): void {
  }

  public openContent() {
    this.status = !this.status;
  }

}

<nav class="sidebar sidebar-offcanvas" id="sidebar">
  <!-- <div class="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center mb-5 pt-3" [ngClass]="{'w-50' : !isAdmin}"> -->
  <!--      <a class="navbar-brand brand-logo" [routerLink]="'/' + translate.getDefaultLang()"><img src="assets/venntur-logo-150.png" alt="Venntur" /></a>-->
  <!-- <a class="navbar-brand brand-logo" [href]="'/' + translate.getDefaultLang()">
      <img src="assets/venntur-logo-150.png" alt="Venntur" />
    </a>
    <ng-container *ngIf="authService.isMaker() && isAdmin || authService.isTaker() && isAdmin"> -->
  <!-- <a class="navbar-brand brand-logo-mini" routerLink="/">
        <img src="assets/venntur-isotype-38.png" alt="Venntur" />
      </a> -->
  <!-- <button class="btn btn-light" type="button" data-toggle="minimize" (click)="toggleSidebar()">
        <i class="fa fa-bars"></i>
      </button> -->
  <!-- </ng-container>
  </div> -->
  <ul class="nav pt-3">
    <!-- <li *ngIf="authService.hasMakerRole()" class="nav-item pb-4">
      <a class="nav-link submenu-toggle" (click)="isCollapsed.Experiences = !isCollapsed.Experiences"
        [attr.aria-expanded]="!isCollapsed.Experiences" aria-controls="Experiences">
        <i class="fa fa-binoculars menu-icon"></i>
        <span class="ml-2 menu-title">{{ 'Experiencias' | translate}}</span>
        <i class="fa fa-chevron-down small"></i>
      </a>
      <ul id="Experiences" class="submenu list-unstyled" [collapse]="isCollapsed.Experiences" [isAnimated]="true">
        <li><a [href]="translate.getDefaultLang() + '/maker/admin/experiencias'">{{ 'Todas las experiencias' | translate}}</a></li>
        <li><a [href]="translate.getDefaultLang() + '/maker/admin/nueva-experiencia'">{{ 'Nueva experiencia' | translate}}</a></li>
      </ul>
    </li> -->
    <li class="nav-item pb-4" *ngIf="authService.hasMakerRole()">
      <a class="nav-link" routerLink="dashboard">
        <i class="fa fa-tachometer menu-icon"></i>
        <span class="ml-2 menu-title">{{ 'Dashboard' | translate}}</span>
      </a>
    </li>
    <li class="nav-item pb-4" *ngIf="authService.hasMakerRole()">
      <a class="nav-link" routerLink="experiencias">
        <i class="fa fa-binoculars menu-icon"></i>
        <span class="ml-2 menu-title">{{ 'Productos' | translate}}</span>
      </a>
    </li>
    <!-- <li class="nav-item pb-4" *ngIf="authService.hasMakerRole()">
      <a class="nav-link" routerLink="cms">
        <i class="fa fa-window-restore menu-icon"></i>
        <span class="ml-2 menu-title">{{ 'Cms' | translate}}</span>
      </a>
    </li> -->
    <li class="nav-item pb-4" *ngIf="authService.isAdminUser()">
      <!-- <a class="nav-link" [href]="'https://vennturcreator.aititubi.es/form?code='+getMaker()" target="_blank"> -->
      <a class="nav-link" routerLink="webs">
        <i class="fa fa-desktop menu-icon"></i>
        <span class="ml-2 menu-title">{{ 'Mis landings' | translate}}</span>
      </a>
    </li>
    <li class="nav-item pb-4" *ngIf="authService.hasMakerRole()">
      <a class="nav-link" routerLink="reviews">
        <i class="fa fa-pencil menu-icon"></i>
        <span class="ml-2 menu-title">{{ 'Reviews' | translate}}</span>
      </a>
    </li>

    <!-- <li *ngIf="authService.hasMakerRole()" class="nav-item pb-4">
      <a class="nav-link submenu-toggle" (click)="isCollapsed.Experiences = !isCollapsed.Experiences"
        [attr.aria-expanded]="!isCollapsed.Experiences" aria-controls="Experiences">
        <i class="fa fa-binoculars menu-icon"></i>
        <span class="ml-2 menu-title">{{ 'Experiencias' | translate}}</span>
        <i class="fa fa-chevron-down small"></i>
      </a>
      <ul id="Experiences" class="submenu list-unstyled" [collapse]="isCollapsed.Experiences" [isAnimated]="true">
        <li><a [href]="translate.getDefaultLang() + '/maker/admin/experiencias'">{{ 'Todas las experiencias' | translate}}</a></li>
        <li><a [href]="translate.getDefaultLang() + '/maker/admin/nueva-experiencia'">{{ 'Nueva experiencia' | translate}}</a></li>
      </ul>
    </li> -->

    <!-- <li class="nav-item pb-4" *ngIf="authService.hasMakerRole()">
      <a class="nav-link" routerLink="ventas">
        <i class="fa fa-usd menu-icon"></i>
        <span class="ml-2 menu-title">{{ 'STOP SALES' | translate}}</span>
      </a>
    </li> -->
    <!-- <li class="nav-item pb-4" *ngIf="authService.hasMakerRole()">
      <a class="nav-link" routerLink="analiticas">
        <i class="fa fa-bar-chart menu-icon"></i>
        <span class="ml-2 menu-title">{{ 'Analíticas' | translate}}</span>
      </a>
    </li> -->
    <li *ngIf="authService.hasMakerRole()" class="nav-item pb-4">
      <a class="nav-link" [href]="translate.getDefaultLang() + '/maker/admin/contactos'" routerLink="contactos">
        <i class="fa fa-address-book menu-icon"></i>
        <span class="ml-2 menu-title">{{ 'Contactos' | translate}}</span>
      </a>
    </li>
    <li *ngIf="authService.hasMakerRole()" class="nav-item pb-4">
      <a class="nav-link" (click)="isCollapsed.Maker = !isCollapsed.Maker" [attr.aria-expanded]="!isCollapsed.Maker"
        aria-controls="Maker" routerLink="perfil">
        <i class="fa fa-user menu-icon"></i>
        <span class="ml-2 menu-title">{{ 'Mi perfil' | translate}}</span>
        <!-- <i class="fa fa-chevron-down small"></i> -->
      </a>
      <!--  <ul id="Maker" class="submenu list-unstyled" [collapse]="isCollapsed.Maker" [isAnimated]="true">
        <li><a [href]="translate.getDefaultLang() + '/maker/admin/perfil'">{{ 'Perfil Maker' | translate}}</a></li>
        <li><a [href]="translate.getDefaultLang() + '/maker/admin/mis-resellers'">{{ 'Mis resellers' | translate}}</a>
        </li>
        <li><a [href]="translate.getDefaultLang() + '/maker/admin/contactos'">{{ 'Todos los contactos' | translate}}</a>
        </li>
        <li><a [href]="translate.getDefaultLang() + '/maker/admin/nuevo-contacto'">{{ 'Nuevo contacto' | translate}}</a>
        </li>
      </ul> -->
    </li>
    <!-- <li class="nav-item pb-4">
      <a class="nav-link submenu-toggle" (click)="isCollapsed.MiWeb = !isCollapsed.MiWeb"
        [attr.aria-expanded]="!isCollapsed.MiWeb" aria-controls="MiWeb">
        <i class="fa fa-connectdevelop menu-icon"></i>
        <span class="ml-2 menu-title">{{ 'Páginas' | translate}}</span>
        <i class="fa fa-chevron-down small"></i>
      </a>
      <ul id="MiWeb" class="submenu list-unstyled" [collapse]="isCollapsed.MiWeb" [isAnimated]="true">
        <li><a [href]="translate.getDefaultLang() + '/taker/admin/perfil'">{{ 'Todas las páginas' | translate}}</a></li>
        <li><a [href]="translate.getDefaultLang() + '/taker/admin/favoritos'">{{ 'Nueva página' | translate}}</a></li>
      </ul>
    </li> -->
    <li *ngIf="authService.hasTakerRole()" class="nav-item pb-4">
      <a class="nav-link submenu-toggle" (click)="isCollapsed.Taker = !isCollapsed.Taker"
        [attr.aria-expanded]="!isCollapsed.Taker" aria-controls="Taker">
        <i class="fa fa-user menu-icon"></i>
        <span class="ml-2 menu-title">{{ 'Taker' | translate}}</span>
        <i class="fa fa-chevron-down small"></i>
      </a>
      <ul id="Taker" class="submenu list-unstyled" [collapse]="isCollapsed.Taker" [isAnimated]="true">
        <li><a [href]="translate.getDefaultLang() + '/taker/admin/perfil'">{{ 'Perfil Taker' | translate}}</a></li>
        <li><a [href]="translate.getDefaultLang() + '/taker/admin/favoritos'">{{ 'Favoritos' | translate}}</a></li>
      </ul>
    </li>
    <li *ngIf="authService.hasResellerRole()" class="nav-item pb-4">
      <a class="nav-link submenu-toggle" (click)="isCollapsed.Reseller = !isCollapsed.Reseller"
        [attr.aria-expanded]="!isCollapsed.Reseller" aria-controls="Taker">
        <i class="fa fa-user menu-icon"></i>
        <span class="ml-2 menu-title">{{ 'Agencia' | translate}}</span>
        <i class="fa fa-chevron-down small"></i>
      </a>
      <ul id="Reseller" class="submenu list-unstyled" [collapse]="isCollapsed.Reseller" [isAnimated]="true">
        <li><a [href]="translate.getDefaultLang() + '/reseller/admin/perfil'">{{ 'Perfil Agencia' | translate}}</a></li>
        <li><a [href]="translate.getDefaultLang() + '/reseller/admin/mis-productos'">{{ 'Mis productos' |
            translate}}</a></li>
        <li><a [href]="translate.getDefaultLang() + '/reseller/admin/mis-makers'">{{ 'Mis makers' | translate}}</a></li>
        <!--        <li><a [href]="translate.getDefaultLang() + '/reseller/admin/mis-paises'">{{ 'Mis países' | translate}}</a></li>-->
        <li><a [href]="translate.getDefaultLang() + '/reseller/admin/mis-destinos'">{{ 'Mis destinos' | translate}}</a>
        </li>
        <li><a [href]="translate.getDefaultLang() + '/reseller/admin/contactos'">{{ 'Todos los contactos' |
            translate}}</a></li>
        <li><a [href]="translate.getDefaultLang() + '/reseller/admin/nuevo-contacto'">{{ 'Nuevo contacto' |
            translate}}</a></li>
      </ul>
    </li>
    <li *ngIf="false" class="nav-item pb-4">
      <a class="nav-link" [href]="translate.getDefaultLang() + '/maker/admin/mis-resellers'" routerLink="mis-resellers">
        <i class="fa fa-lock menu-icon"></i>
        <span class="ml-2 menu-title">{{ 'Mis resellers' | translate}}</span>
      </a>
    </li>
  </ul>
</nav>
import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CountryService } from '../../../shared/locations/country.service';
import { maxTableRows } from 'globals';


@Component({
  selector: 'app-reseller-countries-list',
  templateUrl: './reseller-countries-list.component.html',
  styleUrls: ['./reseller-countries-list.component.css']
})
export class ResellerCountriesListComponent implements OnInit {
  maxTableRows = maxTableRows;
  columns = [
    { prop: 'text', name: 'Nombre' }];
  rows = [];
  searchForm: UntypedFormGroup;
  alert = {message: null, type: null};
  @ViewChild(DatatableComponent) table: DatatableComponent;
  dialogRef: BsModalRef;

  constructor(
    private countriesService: CountryService,
    private searchFormBuilder: UntypedFormBuilder,
    public dialog: BsModalService,
  ) {
    this.countriesService.getCountries().subscribe((countries => {
      this.rows = countries[ 'hydra:member' ];
    }));
  }

  ngOnInit(): void {
    this.searchForm = this.searchFormBuilder.group({
      name: [''],
    });
  }

  applyFilter() {
    this.countriesService.getCountries().subscribe(
      data => {
        this.rows = data;
        this.table.offset = 0;
      }
    );
  }
}

<style>
  @media(min-width:992px) {
    .main-panel {
      width: calc(100% - 220px)
    }
  }

  @media(max-width:991px) {
    .main-panel {
      width: 100% !important;
    }
  }
</style>
<div class="container-scroller">
  <div class="container-fluid page-body-wrapper d-flex">
    <app-sidebar></app-sidebar>
    <div class="main-panel p-md-5 p-3 bg-light">
      <div class="content-wrapper">
        <div class="h-100">
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>
  </div>
</div>

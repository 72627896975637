<div *ngIf="receivedData.length != 0" class='py-4 border-top'>
    <div class="review" style="margin-bottom: 2rem !important;" *ngFor="let review of receivedData.slice(0,showedReviews);let i = index;let last = last"
        [ngClass]="getClassNames(i,last)">
        <div class="review-header">
            <div>
                <img src="../../../assets/venntur-isotype-38.png" alt="Imagen reviewer">
            </div>
            <div>
                <div class="review-name">
                    {{review.name}}
                </div>
                <!-- <div class="review-location">
                    {{review.reviewerLocation}}
                </div> -->
                <div class="review-channel">
                    {{ 'Opinión de un usuario de' | translate }} {{review.channel}}
                </div>
            </div>
        </div>
        <div class="review-body">
            <div class="review-rating">
                <div class="review-stars">
                    <span class="vi-star-full" *ngFor="let item of [].constructor(review.rating)"></span>
                    <span class="vi-star-empty" *ngFor="let item of [].constructor(5 - review.rating)"></span>
                </div>
                <span>·</span>
                <div class="review-created-at">
                    {{obtainTimeString(review.createdAt)}}
                </div>
            </div>
            <div class="review-comments">
                {{review.comments}}
            </div>
        </div>
    </div>
    <div class="view-more" *ngIf="receivedData.length > 5 && viewEvenMore == false">
        <button (click)="showedReviews = 10; viewEvenMore = true">{{ 'Ver más' | translate }}</button>
    </div>
    <div class="view-more" *ngIf="receivedData.length > 10 && viewEvenMore">
        <button (click)="openModal()">{{ 'Ver todas' | translate }}
            ({{receivedData.length}})</button>
    </div>
</div>

<div *ngIf="isAlertShown" [config]="{ show: true }" (onHidden)="onHidden()" bsModal #autoShownModal="bs-modal"
    class="modal-container modal fade" tabindex="-1" role="dialog" aria-labelledby="dialog-auto-name">
    <div class="modal-dialog">
        <div class="modal-content">
            <button type="button" class="btn btn-sm btn-light btn-close pull-right ml-auto" aria-label="Close"
                (click)="hideModal()">
                <i-feather name="x"></i-feather>
            </button>
            <div class="modal-body px-sm-5 p-3">
                <div class="modal-body-header d-lg-flex align-items-center mb-4 pb-4 border-bottom">
                    <div class="fixed-rating d-flex">
                        <div class="circle d-flex align-items-center justify-content-center">
                            <span class="vi-star-full grey"></span>
                            <span>{{(fixedNumber(ratingSumatory/receivedData.length)) + '/5'}}</span>
                        </div>
                    </div>
                    <div class="total-reviews">
                        <div class="d-sm-flex justify-content-between w-100 mb-3">
                            <span>
                                {{receivedData.length}} {{ 'opiniones' | translate }}
                            </span>
                            <select name="order" id="order">
                                <option value="">{{ 'Los más recientes' | translate }}</option>
                                <option value="">{{ 'Los más antíguos' | translate }}</option>
                                <option value="">{{ 'Los más valorados' | translate }}</option>
                                <option value="">{{ 'Los menos valorados' | translate }}</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="review" *ngFor="let review of receivedData;let i = index;let last = last"
                    [ngClass]="getClassNames(i,last)">
                    <div class="review-header">
                        <div>
                            <img src="../../../assets/venntur-isotype-38.png" alt="Imagen reviewer">
                        </div>
                        <div>
                            <div class="review-name">
                                {{review.name}}
                            </div>
                            <!-- <div class="review-location">
                                {{review.reviewerLocation}}
                            </div> -->
                            <div class="review-channel">
                                {{ 'Opinión de un usuario de' | translate }} {{review.channel}}
                            </div>
                        </div>
                    </div>
                    <div class="review-body">
                        <div class="review-rating">
                            <div class="review-stars">
                                <span class="vi-star-full" *ngFor="let item of [].constructor(review.rating)"></span>
                                <span class="vi-star-empty"
                                    *ngFor="let item of [].constructor(5 - review.rating)"></span>
                            </div>
                            <span>·</span>
                            <div class="review-created-at">
                                {{obtainTimeString(review.createdAt)}}
                            </div>
                        </div>
                        <div class="review-comments">
                            {{review.comments}}
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
            </div>
        </div>
    </div>
</div>
<div class="container-fluid w-430 py-4 my-5">
  <div class="text-center mb-4">
    <h1>{{ 'Déjanos tu teléfono' | translate}}</h1>
    <p>{{ 'Te contaremos lo que Venntur puede hacer por los organizadores de experiencias.' | translate}}</p>
  </div>
  <form [formGroup]="contactForm" class="pb-5">
      <div class="row">
        <div class="col-md-12 form-group">
          <input type="text" formControlName="name" [placeholder]="translate.instant('Nombre')" class="form-control">
          <small class="text-danger" *ngIf="showError('name')">
            {{translate.instant(errors['name'])}}
          </small>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 form-group">
          <input type="text" formControlName="phone" [placeholder]="translate.instant('Teléfono')" class="form-control">
          <small class="text-danger" *ngIf="showError('phone')">
            {{translate.instant(errors['phone'])}}
          </small>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 form-group custom-control custom-checkbox">
          <input class="custom-control-input" type="checkbox" id="acceptance" formControlName="acceptance" value="acceptance">
          <label class="custom-control-label" for="acceptance">
            <span class="icon"></span>
            <span class="text">{{ 'He leído y acepto la política de privacidad de este sitio web' | translate}}</span>
          </label>
          <small class="text-danger" *ngIf="showError('acceptance')">
            {{translate.instant(errors['acceptance'])}}
          </small>
        </div>
      </div>

    <button class="btn btn-primary w-100" type="submit" name="button" (click)="sendContactForm()" [disabled]="contactForm.pristine || contactForm.invalid || isLoading">{{ 'ENVIAR' | translate}}</button>
    <div class="mt-4" *ngIf="alert.message">
      <p class="alert" [class.alert-success]="alert.type == 1" [class.alert-danger]="alert.type == 2">{{alert.message}}</p>
    </div>
  </form>
</div>

export class ResellerCollection {
  total: number;
  resellers: Reseller[];
}

export class Reseller {
  id: string;
  name: string;
  fiscalName: string;
  cif: string;
  address: string;
  cp: string;
  country: string;
  claim: string;
  whatsapp: string;
  instagram: string;
  twitter: string;
  linkedIn: string;
  facebook: string;
  observations: string;
  urlWeb: string;
  urlWebSupplier: boolean;
  user: string;
  tripadvisor: string;
  mediaResellersLogos: any[];
  categories: any[];
  tags: any[];
  countries: any[];
  constructor(values: any = {}) {
    Object.assign(this, values);
  }
}

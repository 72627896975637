export class ExperienceCollection {
  total: number;
  experiences: Experience[];
}

export class Experience {
  id: string;
  name: string;
  promoCode: string;
  url: string;
  urlButtonText: string;
  description: string;
  duration: string;
  seasonStartMonth: string;
  seasonEndMonth: string;
  promotionType: string;
  promotion: string;
  sustainableSeal: boolean;
  reviewTripadvisor: string;
  mediaExperiences: any[];
  maker: string;
  languages: any[];
  categories: any[];
  tags: any[];
  country: string;
  location: string;
  zones: any[];
  whyUs: string;
  includes: any[];
  notIncludes: any[];
  price: number;
  cancellation: string;
  persons: number;
  moreInfo: any [];
  carryInfo: any [];
  slug: string;
  fareharborId: string;
  turitopId: string;
  bokunId: string;
  rezdyId: string;
  donicusId: string;
  fromDate: Date;
  toDate: Date;
  typePrice: any;
  weekDays: any [];
  status: string;
  currency: string;
  descriptionExtended: string;
  highlights: any [];
  modalities: any [];
  months: any [];
  mediaExperiencesVideos: any [];
  reviewGetyourguide: string;
  bookingEngine: string;
  bookingEngineWidget: string;

  constructor(values: any = {}) {
    Object.assign(this, values);
  }
}

import { Component, HostListener, Inject, OnInit, Renderer2, TemplateRef } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { finalize } from 'rxjs/operators';
import { TakerService } from '../../shared/taker.service';
import { Taker } from '../../taker/taker-profile/taker.model';
import { TranslateService } from '@ngx-translate/core';
import { Experience } from '../../experiences/experiences/experience.model';
import { ExperiencesService } from '../../experiences/experiences/experiences.service';
import { MakerService } from '../../shared/maker.service';
import { DOCUMENT } from '@angular/common';
import { LocationService } from '../../../shared/locations/location.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AnalyticsService } from 'src/app/pages/analytics/analytics.service';

@Component({
  selector: 'app-maker-page',
  styleUrls: ['./maker-page.component.scss'],
  templateUrl: './maker-page.component.html'
})

export class MakerPageComponent implements OnInit {
  public expandedIndex = -1;
  public originalExperiences: Experience[];
  experiences = [];
  takerExperiences = [];
  shownDays = [
    { value: 0, text: 'Todos los días' },
    { value: 1, text: 'Lun' },
    { value: 2, text: 'Mar' },
    { value: 3, text: 'Mié' },
    { value: 4, text: 'Jue' },
    { value: 5, text: 'Vie' },
    { value: 6, text: 'Sáb' },
    { value: 7, text: 'Dom' },
  ];
  maker = null;
  makerId = null;
  isAlertShown = false;
  throttle = 150;
  scrollDistance = 0.1;
  notEmptyExperience = true;
  notScrolly = true;
  loading = false;
  cities = null;
  citySelected = null;
  showCategories: boolean = false;
  experienceSearchForm: UntypedFormGroup;
  modalRef: BsModalRef;
  customPhoneTooltip = 'Copiar al portapapeles';
  customEmailTooltip = 'Copiar al portapapeles';
  myImageArray = ['https://venntur.com/assets/experience/files/649198d9952cf975573530.jpeg', 'https://venntur.com/assets/experience/files/600c20ad9f5d5384735017.jpg', 'https://venntur.com/assets/experience/files/60d2f09f2e710932983928.jpeg']

  constructor(
    private experienceService: ExperiencesService,
    private makerService: MakerService,
    public locationService: LocationService,
    private modalService: BsModalService,
    private router: Router,
    private titleService: Title,
    private takerService: TakerService,
    public translate: TranslateService,
    private renderer2: Renderer2,
    private formBuilder: UntypedFormBuilder,
    private analyticsService: AnalyticsService,
    private meta: Meta,
    private title: Title,
    @Inject(DOCUMENT) private _document
  ) {
    this.createForm();
  }

  ngOnInit(): void {
    this.removeMetaTags();
    this.loading = true;
    this.titleService.setTitle('Encuentra tu maker | Venntur');

    // this.makerId = this.router['browserUrlTree'].root.children.primary.segments[2].path;

    var makerSlug = this.router['rawUrlTree'].root.children.primary.segments[2].path;

    this.makerService.getMakerBySlug(makerSlug, this.translate.getDefaultLang()).subscribe(maker => {
      maker = maker['hydra:member'][0];
      this.makerService.getMaker(maker['@id'], this.translate.getDefaultLang()).subscribe((maker => {

        this.makerId = maker.id;

        this.maker = maker;
        this.cities = maker['activeLocations'];
        const s = this.renderer2.createElement('script');
        s.type = 'text/javascript';
        s.src = `https://www.jscache.com/wejs?wtype=cdsratingsonlywide&uniq=609&border=true&lang=en_US&locationId=${maker['tripadvisor']}&border=false&display_version=2`;
        s.text = ``;
        this.renderer2.appendChild(this._document.body, s);

        this.meta.addTags([
          { name: 'description', content: this.removeHtmlTags(this.maker.description) },
          { name: 'lang', content: 'es' },
          { name: 'robots', content: 'index, follow' },
          { property: 'og:title', content: this.maker.name },
          { property: 'og:description', content: this.removeHtmlTags(this.maker.description) },
          { property: 'og:image', content: 'https://venntur.com/assets/maker/files/' + maker.mediaMakers[0].contentUrl },
          { property: 'og:url', content: 'https://venntur.com/' + this.maker.slug },
          { property: 'og:site_name', content: 'Venntur' }
        ]);
        this.setTitle(this.maker.name);

        /* ****************************************** */
        /* *********** GET EXPERIENCES ************** */
        /* Movida la obtención de experiencias fuera */
        /* del timeout porque no siempre cargaban... */
        /* ****************************************** */

        this.experienceService.getExperiencesByMakerId(this.makerId, {}, this.translate.getDefaultLang())
          .pipe(
            finalize(() => this.loading = false)
          )
          .subscribe((experiences => {
            this.originalExperiences = experiences['hydra:member'];
            this.analyticsService.calculateAnalytic({ 'type': 'makerPage', 'directoryAnalyticsId': this.maker.directoriesAnalytics[0], 'experiences': experiences['hydra:member'] }).subscribe();
            this.experiences = this.originalExperiences.slice(0, 9);
          }));

        /* ****************************************** */
        /* ****************************************** */
        /* ****************************************** */



      }))

    });
    /* setTimeout(() => {
     
    }, 1000); */
    this.experienceService.getExperiencesByTakerId(this.takerService.getId()).subscribe(experiences => {
      this.takerExperiences = Array.from(experiences['hydra:member']).map((item) => item['@id']);
    });
    // this.locationService.getLocations().subscribe(locations =>  {
    //   this.cities = locations['hydra:member'];
    // });
  }

  createForm() {
    this.experienceSearchForm = this.formBuilder.group({
      location: ['',
        Validators.required
      ]
    });
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    // Check if the click event target is not inside the element
    if (!this.isDescendant(event.target as Node, document.querySelector('.tags-container'))) {
      this.showCategories = false;
    }
  }

  // Helper function to check if an element is a descendant of another element
  private isDescendant(element: Node, parent: Node | null): boolean {
    while (element !== null) {
      if (element === parent) {
        return true;
      }
      element = element.parentNode;
    }
    return false;
  }

  clickFavorite(event, experienceId) {
    if (this.takerService.getId()) {
      this.takerExperiences.includes(experienceId) ?
        this.takerExperiences.splice(this.takerExperiences.indexOf(experienceId), 1) : this.takerExperiences.push(experienceId);
      const taker = {
        favoriteExperiences: this.takerExperiences
      };
      const editedTaker = new Taker(taker);
      this.takerService.putTaker(this.takerService.getId(), editedTaker).subscribe();
    } else {
      this.isAlertShown = true;
    }
  }

  expandText(index: number): void {
    this.expandedIndex = index === this.expandedIndex ? -1 : index;
  }

  onScroll() {
    this.loading = true;
    if (this.notScrolly && this.notEmptyExperience) {
      this.notScrolly = false;
      setTimeout(() => {
        this.loadNextExperience();
      }, 500);
    } else {
      this.loading = false;
    }
  }

  loadNextExperience() {
    if (this.experiences.length < this.originalExperiences.length) {
      let i;
      const length = this.experiences.length;
      const experiencePerPage = 8;
      const experienceToLoad = this.originalExperiences.length - length;

      if (experienceToLoad >= experiencePerPage) {
        for (i = length; i <= length + experiencePerPage; i++) {
          this.experiences.push(this.originalExperiences[i]);
        }
      } else {
        for (i = length; i <= length + experienceToLoad - 1; i++) {
          this.experiences.push(this.originalExperiences[i]);
        }
      }
      this.notScrolly = true;
    } else {
      this.notEmptyExperience = false;
    }
    this.loading = false;
  }

  searchExperiences() {
    this.loading = true;
    this.experienceService.getExperiencesByMakerId(this.makerId, { location: this.experienceSearchForm.get('location').value },
      this.translate.getDefaultLang())
      // this.experienceService.getExperiencesByMakerId(this.makerId, this.router['rawUrlTree'].queryParams, )
      .pipe(
        finalize(() => this.loading = false)
      )
      .subscribe((experiences => {
        this.originalExperiences = experiences['hydra:member'];
        this.experiences = this.originalExperiences.slice(0, 10);
        this.loading = false;
      }));
  }

  openPhotosModal(template: TemplateRef<any>, config: any) {
    this.modalRef = this.modalService.show(template, config);
  }

  changePhoneTooltip(text: string) {
    const textarea = document.createElement('textarea');
    textarea.style.position = 'fixed';
    textarea.style.opacity = '0';
    textarea.value = text;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);
    this.customEmailTooltip = 'Copiar al portapapeles'
    this.customPhoneTooltip = 'Copiado!'
  }

  changeEmailTooltip(text: string) {
    const textarea = document.createElement('textarea');
    textarea.style.position = 'fixed';
    textarea.style.opacity = '0';
    textarea.value = text;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);
    this.customPhoneTooltip = 'Copiar al portapapeles'
    this.customEmailTooltip = 'Copiado!'
  }

  convertTimeToCustomFormat(inputTime: string): string {
    let formattedText;
    const [hours, minutes] = inputTime.split(':').map(part => parseInt(part, 10));

    if (minutes != 0) {
      formattedText = hours + ' h y ' + minutes + ' min'
    } else {
      formattedText = hours + ' h'
    }

    return formattedText;
  }

  getUrl(isCivitatis, image) {
    return !isCivitatis ? 'https://venntur.com/assets/experience/files/' + image : image;
  }

  setCategoryTitle(experience) {
    return experience.categories.map(obj => obj.name).join(' / ')
  }
  setActiveCategoryTitle(activeCategories) {
    return activeCategories.map(obj => obj[1]).join(' - ')
  }

  setLanguagesTitle(experience) {
    return experience.languages.map(obj => obj.name).join(' , ')
  }

  shareExperience(experience) {
    var enlace = 'https://venntur.com/product/' + experience.slug;
    var mensaje = '¡Mira esta experiencia en Venntur!';

    mensaje = encodeURIComponent(mensaje);
    enlace = encodeURIComponent(enlace);

    var enlaceWhatsApp = 'https://wa.me/?text=' + mensaje + '%20' + enlace;

    window.open(enlaceWhatsApp);
  }

  removeHtmlTags(input) {
    if (input) {
      let stringWithoutEntities = input.replace(/&[a-zA-Z]+;/g, function (match) {
        const entityMap = {
          '&aacute;': 'á',
          '&oacute;': 'ó',
          '&eacute;': 'é',
          '&iacute;': 'í',
          '&uacute;': 'ú',
        };
        return entityMap[match] || match;
      });
  
      return stringWithoutEntities.replace(/<\/?[^>]+(>|$)/g, "");
    }
    
    return input;
  }

  removeMetaTags() {
    const metaTags = Array.from(document.querySelectorAll('meta'));

    metaTags.forEach((metaTag: Element) => {
      this.renderer2.removeChild(document.head, metaTag);
    });
  }

  public setTitle(newTitle: string) {
    this.title.setTitle(newTitle);
  }
}

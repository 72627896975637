import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';

import { Observable } from 'rxjs';

import { environment } from '../../../../environments/environment';
import { Experience } from './experience.model';

const API_URL = environment.apiUrl;

@Injectable()
export class MediaExperiencesService {
  constructor(private http: HttpClient) {
  }

  mediaExperiencesUpload(fileItem: any, extraData?: object): Observable<any> {
    const apiCreateEndpoint = `${API_URL}/media_experiences`;
    const formData: FormData = new FormData();

    formData.append('file', fileItem);
    if (extraData) {
      for (const key in extraData) {
        if (extraData.hasOwnProperty(key)) {
          formData.append(key, extraData[ key ]);
        }
      }
    }
    const req = new HttpRequest('POST', apiCreateEndpoint, formData, {
      reportProgress: true // for progress data
    });
    return this.http.request(req);
  }

  deleteMediaExperiences(mediaExperiencesId) {
    return this.http
      .delete(`${API_URL}${mediaExperiencesId}`);
  }

  getMediaExperiences(mediaExperiencesId) {
    return this.http
      .get(`${API_URL}${mediaExperiencesId}`);
  }

  //mediaExperienceVideosUpload
  mediaExperiencesVideosUpload(fileItem: any, extraData?: object): Observable<any> {
    const apiCreateEndpoint = `${API_URL}/media_experiences_videos`;
    const formData: FormData = new FormData();

    formData.append('file', fileItem);
    if (extraData) {
      for (const key in extraData) {
        if (extraData.hasOwnProperty(key)) {
          formData.append(key, extraData[ key ]);
        }
      }
    }
    const req = new HttpRequest('POST', apiCreateEndpoint, formData, {
      reportProgress: true // for progress data
    });
    return this.http.request(req);
  }

  deleteMediaExperiencesVideos(videoId) {
    return this.http
      .delete(`${API_URL}${videoId}`);
  }

}

<div class="container" *ngIf="maker">
  <div class="border rounded maker-card my-5 row w-100">
    <div class="col-md-8 p-0 col-12 h-md-100">
      <img class="w-100 h-100 main-photo"
        [src]="'https://venntur.com/assets/makerHeader/files/' + (maker.mediaMakersHeader.length > 0 ? maker.mediaMakersHeader[0].contentUrl : '')"
        alt="Imagen de cabecera" onError="this.src='https://venntur.com/assets/image-not-available-placeholder.png'">
      <!-- <button class="view-photos" (click)="openPhotosModal(photosContent,{class: 'photosModal'})"><span
          class="vi-photos mr-2"></span><strong>Ver
          fotos</strong></button> -->
    </div>
    <div class="col-md-4 p-0 col-12 py-md-4 h-md-100">
      <div class="maker-info">
        <div class="grid-item my-md-3 circle-border-parent">
          <img class="circle-border" width="50%"
            [src]="'https://venntur.com/assets/maker/files/' + (maker.mediaMakers.length > 0 ? maker.mediaMakers[0].contentUrl : '')"
            alt="{{maker['phrase']}}" title="{{maker['phrase']}}"
            onError="this.src='assets/image-not-available-placeholder.png'">
        </div>
        <div class="grid-item text-md-center flex-column maker-texts">
          <h3 class="h3"><strong>{{maker.name}}</strong></h3>
          <span><strong>{{maker.address}}</strong></span>
          <span *ngIf="maker.postalCode && maker.location"><strong>{{maker.postalCode}},
              {{maker.location.name}}</strong></span>
        </div>
      </div>
      <div class="w-100" style="position: absolute;bottom: 0;left: 0;">
        <div class="grid-item flex-wrap align-content-around p-2 tags-container">
          <span class="activeCategory"
            *ngFor="let activeCategory of maker.activeCategories.slice(0,2);let last = last">{{activeCategory[1]}}
          </span>
        </div>
        <div class="maker-card-footer row m-0 w-100">
          <a [href]="maker.web" target="_blank" class="col-3 w-100 h-100"><span class="vi-web"></span></a>

          <ng-template #tol1Template>{{ customPhoneTooltip }}</ng-template>
          <span class="d-none d-lg-flex col-3 w-100 h-100 pointer" [tooltip]="tol1Template"
            (click)="changePhoneTooltip(maker.phone)"><span class="vi-mobile"></span></span>
          <a [href]="'tel:' + maker.phone" class="d-flex d-lg-none col-3 w-100 h-100"><span
              class="vi-mobile"></span></a>

          <ng-template #tol2Template>{{ customEmailTooltip }}</ng-template>
          <span class="d-none d-lg-flex col-3 w-100 h-100 pointer" [tooltip]="tol2Template"
            (click)="changeEmailTooltip(maker.email)"><span class="vi-email"></span></span>
          <a [href]="'mailto:' + maker.email + '?Subject=Hola%20Maker'" class="d-flex d-lg-none col-3 w-100 h-100"><span
              class="vi-email"></span></a>

          <a *ngIf="maker.whatsapp" [href]="'https://wa.me/' + maker.whatsapp"
            class="pointer d-none d-lg-flex col-3 w-100 h-100"><span class="vi-whatsapp"></span></a>
          <a *ngIf="maker.whatsapp" [href]="'whatsapp://send?phone=' + maker.whatsapp.replace('+','')"
            class="d-flex d-lg-none col-3 w-100 h-100"><span class="vi-whatsapp"></span></a>
        </div>
      </div>

    </div>
  </div>

  <div class="row justify-content-center mb-5 w-100" style="margin: 0 auto" *ngIf="maker.description">
    <div class="col-10 p-0">
      <span [innerHTML]="maker.description">
      </span>
    </div>
  </div>

  <div class="text-center mb-5" style="margin: 0 auto;font-size: 22px" *ngIf="experiences.length > 0">
    <span>
      <strong>
        {{ 'Estas son las experiencias que tenemos para ti' | translate }}
      </strong>
    </span>
  </div>

  <div class="row w-100 m-0 mb-5 experience-card-parent justify-content-center" infiniteScroll
    [infiniteScrollDistance]="scrollDistance" [infiniteScrollThrottle]="throttle" (scrolled)="onScroll()">
    <div *ngFor="let experience of experiences" class="col-lg-4 col-md-6 col-12 mb-5">
      <app-experience-card [experience]="experience" [isFooterActive]="false"></app-experience-card>
    </div>
    <div *ngIf="loading" class="text-center">
      <div class="spinner-border text-primary" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
  </div>
</div>
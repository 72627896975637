import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Tag, TagCollection } from './tag.model';


const API_URL = environment.apiUrl;

@Injectable()
export class TagsService {
  constructor(
    private http: HttpClient
  ) { }

  public postTag(tag): Observable<Tag> {
    return this.http
      .post<Tag>(API_URL + '/tags', tag);
  }

  public getTag(id: string): Observable<Tag> {
    return this.http
      .get<Tag>(API_URL + '/tags/' + id);
  }

  public getTags(language): Observable<TagCollection> {
    return this.http
      .get<TagCollection>(API_URL + '/tags', { headers: {
          'Accept-Language': language
      }});
  }

  public getActiveTags(language): Observable<TagCollection> {
    return this.http
      .get<TagCollection>(API_URL + '/tags?exists[experiences]=true', { headers: {
          'Accept-Language': language
        }});
  }

  public getResellerActiveTags(language): Observable<TagCollection> {
    return this.http
      .get<TagCollection>(API_URL + '/tags?exists[resellers]=true', { headers: {
          'Accept-Language': language
      }});
  }

  public deleteTag(tagId: string): any {
    return this.http.delete<Tag>(API_URL + '/tags/' + tagId);
  }

  public putTag(tagId: string, tag: Tag): Observable<Tag> {
    return this.http
      .put<Tag>(API_URL + '/tags/' + tagId, tag);
  }
}

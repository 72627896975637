<div [id]="module.moduleKey + '-accordion'" class="accordion" [ngClass]="orderClass">
    <div class="card">
        <div class="card-header p-0" [id]="module.moduleKey + 'headingOne'">
            <button class="btn w-100" data-toggle="collapse" [attr.data-target]="'#' + module.moduleKey + 'collapseOne'"
                aria-expanded="true" [attr.aria-controls]="'#' + module.moduleKey + 'collapseOne'">
                <div class="d-flex align-items-start" style="column-gap: .5rem;">
                    <span class="title">{{module.name}}</span>
                    <a [tooltip]="'Sección principal de la web'" target="_blank" rel="noopener noreferrer">
                        <i-feather name="help-circle"></i-feather>
                    </a>
                </div>
            </button>
        </div>
        <div [id]="module.moduleKey + 'collapseOne'" class="collapse show"
            [attr.aria-labelledby]="module.moduleKey + 'headingOne'"
            [attr.data-parent]="'#' + module.moduleKey + '-accordion'">
            <div class="card-body">
                <div class="row">
                    <ng-container *ngFor="let field of module.dynamicFields; let i = index">
                        <div class="col-lg-6 col">
                            <app-cms-field [currentField]="currentModule.fields[i] = {}" [field]="field"></app-cms-field>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</div>
import { NgModule } from '@angular/core';

import { FeatherModule } from 'angular-feather';
import * as icon from 'angular-feather/icons';
import { Eco } from './eco';
import { Google } from './google';
import { Land } from './land';
import { Sea } from './sea';

// Select some icons (use an object, not an array)
const icons = {
  AlertTriangle: icon.AlertTriangle,
  ArrowRightCircle: icon.ArrowRightCircle,
  Calendar: icon.Calendar,
  Camera: icon.Camera,
  CheckCircle: icon.CheckCircle,
  ChevronDown: icon.ChevronDown,
  ChevronLeft: icon.ChevronLeft,
  ChevronRight: icon.ChevronRight,
  ChevronUp: icon.ChevronUp,
  Clock: icon.Clock,
  Eco: Eco,
  Facebook: icon.Facebook,
  Globe: icon.Globe,
  Google: Google,
  Heart: icon.Heart,
  HelpCircle: icon.HelpCircle,
  Instagram: icon.Instagram,
  Key: icon.Key,
  Land: Land,
  Linkedin: icon.Linkedin,
  Lock: icon.Lock,
  LogIn: icon.LogIn,
  LogOut: icon.LogOut,
  Mail: icon.Mail,
  MapPin: icon.MapPin,
  Menu: icon.Menu,
  MessageCircle: icon.MessageCircle,
  MessageSquare: icon.MessageSquare,
  Minus: icon.Minus,
  Phone: icon.Phone,
  PlayCircle: icon.PlayCircle,
  Plus: icon.Plus,
  Sea: Sea,
  Smartphone: icon.Smartphone,
  Twitter: icon.Twitter,
  User: icon.User,
  Wind: icon.Wind,
  X: icon.X,
  FolderPlus: icon.FolderPlus,
  Folder: icon.Folder
};

@NgModule({
  exports: [
    FeatherModule,
  ],
  imports: [
    FeatherModule.pick(icons),
  ],
})
export class IconsModule { }

import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ResellerService } from '../../shared/reseller.service';
import { MakerService } from '../../shared/maker.service';
import { Location } from '../../../shared/locations/location.model';
import { LocationService } from '../../../shared/locations/location.service';
import { TagsService } from '../../../shared/tags/tags.service';
import { Tag } from '../../../shared/tags/tag.model';
import { maxTableRows } from 'globals';

@Component({
  selector: 'app-maker-resellers-list',
  templateUrl: './maker-resellers-list.component.html',
  styleUrls: ['./maker-resellers-list.component.css']
})
export class MakerResellersListComponent implements OnInit {
  maxTableRows = maxTableRows;
  columns = [];
  rows = [];
  makersResellers = [];
  statusArray = ['Pendiente contacto', 'Contactado', 'Contratado', 'Terminado'];
  isFavorites = true;
  tags: Tag[] = [];
  tagsSelected = [];
  locations: Location[] = [];
  locationsSelected = [];
  makerId;
  searchForm: UntypedFormGroup;
  alert = {message: null, type: null};
  @ViewChild(DatatableComponent) table: DatatableComponent;
  dialogRef: BsModalRef;

  constructor(
    private resellerService: ResellerService,
    private makersService: MakerService,
    private tagsService: TagsService,
    private locationsService: LocationService,
    private searchFormBuilder: UntypedFormBuilder,
    public dialog: BsModalService,
  ) {
    this.makerId = this.makersService.getId();
    this.tagsService.getResellerActiveTags('es').subscribe(tags => {
      this.tags = tags['hydra:member'];
    });
    this.locationsService.getResellerActiveLocations().subscribe(locations => {
        this.locations = locations['hydra:member'];
      }
    );
    this.resellerService.getResellersByMakerId(this.makerId, {}).subscribe(experiences => {
      this.makersResellers = experiences[ 'hydra:member' ];
    });
  }

  ngOnInit(): void {
    this.searchForm = this.searchFormBuilder.group({
      name: [''],
      fiscalName: [''],
      // email: [''],
      tags: [''],
      locations: [''],
      urlWeb: [''],
      isFavorites: [true],
      makerId: [this.makerId]
    });
    this.columns = [
      { prop: 'name', name: 'Nombre' },
      { prop: 'fiscalName', name: 'Nombre fiscal' },
      // { prop: 'email', name: 'Email' },
      { prop: 'urlWeb', name: 'Página web' }];
    this.applyFilter();
  }

  applyFilter() {
    if (!this.searchForm.value.isFavorites) {
      this.resellerService.getResellers(this.searchForm.value).subscribe(
        data => {
          this.resellerService.getResellersByMakerId(this.makerId, {}).subscribe(resellers => {
            this.makersResellers = Array.from(resellers['hydra:member']).map((item) => item['reseller']['@id']);
          });
          this.rows = data['hydra:member'];
          this.table.offset = 0;
          this.isFavorites = false;
          this.columns = [
            { prop: 'name', name: 'Nombre' },
            { prop: 'fiscalName', name: 'Nombre fiscal' },
            // { prop: 'email', name: 'Email' },
            { prop: 'urlWeb', name: 'Página web' }];
        }
      );
    } else {
      this.resellerService.getResellersByMakerId(this.makerId, this.searchForm.value).subscribe(
        data => {
        this.rows = data['hydra:member'];
        this.table.offset = 0;
        this.isFavorites = true;
        this.columns = [
          { prop: 'reseller.name', name: 'Nombre' },
          { prop: 'reseller.fiscalName', name: 'Nombre fiscal' },
          // { prop: 'email', name: 'Email' },
          { prop: 'reseller.urlWeb', name: 'Página web' }];
      });
    }
  }

  addFavorite(event, resellerId) {
    this.makersResellers.includes(resellerId) ?
      this.makersResellers.splice(this.makersResellers.indexOf(resellerId), 1) : this.makersResellers.push(resellerId);
    const favoriteReseller = {
      maker: '/makers/' + this.makerId,
      reseller: resellerId,
      status: 'Pendiente contacto'
    };
    this.makersService.postFavoriteReseller(favoriteReseller).subscribe();
  }

  removeFavorite(favoriteResellerId) {
    this.makersService.deleteFavoriteReseller(favoriteResellerId).subscribe(() => {
        this.applyFilter();
      }
    );
  }

  changeStatus(status, favoriteResellerId) {
    this.makersService.putFavoriteReseller(favoriteResellerId, {status: status}).subscribe();
  }
}

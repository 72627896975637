<div class="d-flex align-items-center">
  <h1 class="page-title"><i class="fa fa-users small mr-3"></i> Resellers</h1>
</div>
<div *ngIf="alert.message">
  <p class="alert" [class.alert-success]="alert.type == 1" [class.alert-danger]="alert.type == 2">{{alert.message}}</p>
</div>
<div class="card mt-4">
  <div class="card-body p-3">
    <h5>Buscar por: </h5>
    <form [formGroup]="searchForm" (submit)="applyFilter()">
      <div class="form-row flex-fill">
        <div class="col">
          <input placeholder="Nombre" type="text" formControlName="name" class="form-control form-control-sm">
        </div>
        <div class="col">
          <input placeholder="Nombre fiscal" type="text" formControlName="fiscalName"
            class="form-control form-control-sm">
        </div>
        <!--        <div class="col">-->
        <!--          <input placeholder="Email" type="text" formControlName="email" class="form-control form-control-sm">-->
        <!--        </div>-->
        <div class="col">
          <input placeholder="Página web" type="text" formControlName="urlWeb" class="form-control form-control-sm">
        </div>
        <div class="col">
          <ng-select [placeholder]="'Destinos'" aria-label="Location" multiple="true" [closeOnSelect]="false"
            formControlName="locations" [(ngModel)]="locationsSelected">
            <ng-option *ngFor="let location of locations" [value]="location['@id']">{{ location.name }}</ng-option>
          </ng-select>
        </div>
        <div class="col">
          <ng-select bindLabel="Etiquetas" [placeholder]="'Etiquetas'" multiple="true" [closeOnSelect]="false"
            formControlName="tags" [(ngModel)]="tagsSelected">
            <ng-option [value]="tag['@id']" *ngFor="let tag of tags">{{tag.name}}</ng-option>
          </ng-select>
        </div>
        <div class="col">
          <label><input placeholder="Mis resellers" type="checkbox" formControlName="isFavorites">Mis resellers</label>
        </div>
        <button type="submit" class="btn btn-success btn-sm col-md-2">Buscar</button>
      </div>
    </form>
  </div>
</div>
<div class="card mt-2">
  <div class="card-body p-0">
    <ngx-datatable #table class="bootstrap" [rowHeight]="'auto'" [columns]="columns" [columnMode]="'force'"
      [headerHeight]="50" [footerHeight]="50" [limit]="maxTableRows" [rows]="rows">
      <ngx-datatable-column *ngFor="let column of columns; let i = index;" name="{{column.name}}"
        prop="{{column.prop}}">
      </ngx-datatable-column>
      <ngx-datatable-column name="Etiquetas" prop="tags">
        <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
          <span *ngIf="!isFavorites">
            <span *ngFor="let tag of row['tags']; let i = index">
              {{tag.name}}{{i === row['tags'].length - 1 ? '' : ', '}}
            </span>
          </span>
          <span *ngIf="isFavorites">
            <span *ngFor="let tag of row.reseller['tags']; let i = index">
              {{tag.name}}{{i === row.reseller['tags'].length - 1 ? '' : ', '}}
            </span>
          </span>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column name="Mis resellers" prop="isFavorite">
        <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
          <span *ngIf="!isFavorites">
            <input type="checkbox" class="form-check-input" id="addFavorite"
              (click)="addFavorite($event.target['checked'], row['@id'])"
              [checked]="makersResellers.includes(row['@id'])" [disabled]="makersResellers.includes(row['@id'])">
            <label class="form-check-label" for="addFavorite"></label>
          </span>
          <span *ngIf="isFavorites">
            <input type="checkbox" class="form-check-input" id="removeFavorite" (click)="removeFavorite(row['@id'])"
              [checked]="true">
            <label class="form-check-label" for="removeFavorite"></label>
          </span>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column *ngIf="isFavorites" name="Estado" prop="status">
        <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
          <ng-select bindLabel="Estado" [placeholder]="" (change)="changeStatus($event, row['@id'])"
            [(ngModel)]="row['status']">
            <ng-option [value]="status" *ngFor="let status of statusArray">{{status}}</ng-option>
          </ng-select>
        </ng-template>
      </ngx-datatable-column>
    </ngx-datatable>
  </div>
</div>